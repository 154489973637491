import React from 'react';
import ReactDOM from 'react-dom';
import ColorPicker, { rgbToHex } from '../../ColorPicker/ColorPicker';

//Social Block
import { socialTypes } from '../../GrapesJSComponents/customBlocks/socialTypes.js';

//Icons
import addRowTop from '../../../../../assets/icons/icon-table-row-add.svg';
import addRowBottom from '../../../../../assets/icons/icon-table-row-add.svg';
import addColTop from '../../../../../assets/icons/icon-table-column-add.svg';
import addColBottom from '../../../../../assets/icons/icon-table-column-add.svg';
import removeRow from '../../../../../assets/icons/icon-table-row-remove.svg';
import removeCol from '../../../../../assets/icons/icon-table-column-remove.svg';
import rearrageIcon from '../../../../../assets/icons/icon-rearrange.svg';
import iconUpload from '../../../../../assets/icons/icon-upload.svg';
import iconEdit from '../../../../../assets/icons/icon-edit.svg';
import iconAdd from '../../../../../assets/icons/icon-plus.svg';

//MUI
import {
  Select,
  MenuItem,
  FormControl,
  Popover,
  IconButton,
  Box,
} from '@mui/material';

import {
  addColumnToTraitManager,
  addLinkToMenu,
  deleteColumnFromTraitManager,
  changeColumnWidth,
  deleteLinkFromMenu,
  restartCountdown,
  addRowTableTop,
  addRowTableBottom,
  addColTableLeft,
  addColTableRight,
  deleteTableColumn,
  deleteTableRow,
  onImageLinkInputChange,
  onDeleteImageLinkClick,
  addImageLinkToSocialBlock,
  updateSocialImagesWidth,
  updateImageLinkGap,
  updateIconStyle,
  setBorderTraitVisibility,
  updateLinkDetails,
  appendToDiv,
  addColumnToTable,
  updateUIWithImage,
  resetUI,
  renderImageBlockTraitManager,
  wrapImageWithLink,
  unwrapImageFromLink,
} from '../utils';
import CommonToggler from '../../../../../components/Toggler/CommonToggler.js';
import { toggleBrandStyle } from '../../../../../store/store.js';

export const registerTraits = (
  traitManager,
  grapesjsEditor,
  brandColors,
  dispatch,
) => {
  traitManager.addType('asset-manager', {
    createInput({ trait }) {
      const parentEl = document.createElement('div');
      parentEl.className = 'asset-manager-wrapper';

      const el = document.createElement('div');
      el.className = 'open-asset-manager';

      const dropIcon = document.createElement('img');
      dropIcon.src = iconUpload; // Ensure this points to a correct upload icon
      dropIcon.className = 'drop-icon';

      const label = document.createElement('span');
      label.className = 'image-upload-label';
      label.textContent = 'Click to Upload Image';

      const infoText = document.createElement('span');
      infoText.className = 'image-upload-info-text';
      infoText.textContent = 'Allow only PNG, JPG, JPEG, and GIF';

      const helperText = document.createElement('span');
      helperText.className = 'image-upload-helper-text';
      helperText.textContent = 'Max file size 3 MB and 2000 px in width';

      const editButtonContainer = document.createElement('span');
      editButtonContainer.className = 'edit-button-container';
      editButtonContainer.style.display = 'none'; // Initially hidden

      const editIcon = document.createElement('img');
      editIcon.src = iconEdit;
      editIcon.className = 'edit-icon';

      const editButton = document.createElement('button');
      editButton.textContent = 'Edit';
      editButton.className = 'edit-image-btn';

      const helperAndEdit = document.createElement('div');
      helperAndEdit.className = 'helper-and-edit';

      el.appendChild(dropIcon);
      el.appendChild(label);
      el.appendChild(infoText);
      parentEl.appendChild(el);
      helperAndEdit.appendChild(helperText);
      editButtonContainer.appendChild(editIcon);
      editButtonContainer.appendChild(editButton);
      helperAndEdit.appendChild(editButtonContainer);
      parentEl.appendChild(helperAndEdit);

      const elements = {
        dropIcon,
        label,
        infoText,
        helperText,
        editButtonContainer,
        el,
        iconUpload,
      };
      // Add event listener to the edit button
      editButtonContainer.addEventListener('click', function () {
        const containerWidth = getContainerWidth();
        openAssetManager(elements, containerWidth);
      });

      el.addEventListener('click', function () {
        const containerWidth = getContainerWidth();
        openAssetManager(elements, containerWidth);
      });

      function getContainerWidth() {
        const component = grapesjsEditor.getSelected();
        const el = grapesjsEditor.Canvas.getDocument().getElementById(
          component.getId(),
        );
        const renderedWidth = getComputedStyle(el).width;
        console.log(renderedWidth, 'selected');
        return renderedWidth;
      }

      function openAssetManager(elements, containerWidth) {
        console.log('Opening assetManager');
        grapesjsEditor.runCommand('open-assets', {
          target: grapesjsEditor.getSelected().find('img')[0],
          onSelect: (asset) => {
            updateUIWithImage(asset, elements, grapesjsEditor, containerWidth);
            renderImageBlockTraitManager(
              grapesjsEditor.getSelected(),
              grapesjsEditor,
            );
            grapesjsEditor.Modal.close();
            return false;
          },
        });
      }

      // Initialize UI based on existing image or lack thereof
      const existingImage = grapesjsEditor.getSelected().find('img')[0];
      if (existingImage && existingImage.getAttributes().src) {
        const src = existingImage.getAttributes().src;
        if (src && !src.startsWith('data:image/svg+xml;base64')) {
          const assets = grapesjsEditor.AssetManager.getAll().find(
            (asset) => asset.get('src') === src,
          );
          const assetName = assets ? assets.get('name') : 'Unknown';
          const asset = {
            get: (key) => {
              if (key === 'src') return src;
              if (key === 'name') return assetName;
              if (key === 'attributes')
                return { alt: existingImage.getAttributes().alt || '' };
            },
          };
          updateUIWithImage(asset, elements, grapesjsEditor);
        } else {
          resetUI(elements, grapesjsEditor);
        }
      } else {
        resetUI(elements, grapesjsEditor);
      }

      return parentEl;
    },
  });

  traitManager.addType('htmlTrait', {
    createInput({ trait }) {
      const el = document.createElement('textarea');
      el.value = trait.target.get('html') || '';
      el.className = 'input-trait';

      el.addEventListener('change', (e) => {
        trait.target.set('html', e.target.value);
        trait.target.set('content', e.target.value);
      });
      return el;
    },
  });

  traitManager.addType('child-width', {
    createInput({ trait }) {
      // Create the input element
      const el = document.createElement('input');
      el.type = 'number';
      el.min = 0; // Minimum width
      el.step = 1; // Step size
      el.max = 100;

      // Get the first child component
      const firstChild = trait.target.components().at(0);

      // Calculate the container width and padding
      const containerElement = trait.target.view.el;
      const containerStyles = window.getComputedStyle(containerElement);
      const containerWidth = parseFloat(
        containerStyles.getPropertyValue('width'),
      );
      const paddingLeft = parseFloat(
        containerStyles.getPropertyValue('padding-left'),
      );
      const paddingRight = parseFloat(
        containerStyles.getPropertyValue('padding-right'),
      );

      // Subtract the padding from the container width
      const contentWidth = containerWidth - paddingLeft - paddingRight;

      // Calculate the width of the first child as a percentage of the content width
      const dividerWidth = parseFloat(
        window.getComputedStyle(firstChild.view.el).getPropertyValue('width'),
      );
      const widthInPercentage = Math.round((dividerWidth / contentWidth) * 100);

      // Set the initial value of the input to the calculated percentage
      el.value = widthInPercentage;

      el.className = 'input-trait';

      // Update the child component style when the trait value changes
      el.addEventListener('input', (event) => {
        const width = event.target.value;
        if (firstChild) {
          firstChild.addStyle({
            width: width ? `${width}%` : '', // Handle zero and empty values
          });
        }
      });

      return el;
    },
  });

  traitManager.addType('table-width', {
    createInput({ trait }) {
      // Create the input element
      const getTableComponent = () => {
        const target = trait.target;
        const targetComp = target.components().at(0);
        return targetComp?.is('table') ? targetComp : target.closest('table');
      };

      const table = getTableComponent();

      const el = document.createElement('input');
      el.type = 'number';
      el.min = 0; // Minimum width
      el.step = 1; // Step size
      el.max = 100;
      el.value = table.getStyle('width')?.replace('%', '') || '100';
      el.className = 'input-trait';

      // Update the child component style when the trait value changes
      el.addEventListener('input', (event) => {
        const width = event.target.value;
        table.addStyle({
          width: width ? `${width}%` : '', // Handle zero and empty values
        });
      });

      return el;
    },
  });

  traitManager.addType('child-width-control', {
    createInput({ trait }) {
      const traitContainer = document.createElement('div');
      traitContainer.className = 'traitContainer';
      const el = document.createElement('div');
      el.className = 'auto-width-div';

      // Label for Auto Width
      const labelAutoWidth = document.createElement('label');
      labelAutoWidth.className = 'auto-width-label';
      labelAutoWidth.innerText = 'Auto Width';
      labelAutoWidth.style.display = 'block';
      labelAutoWidth.style.marginBottom = '5px';

      // Toggler Container
      const togglerContainer = document.createElement('label');
      togglerContainer.className = 'switch';
      togglerContainer.style.marginBottom = '15px';

      // Get the element width and the container width
      const widthAuto = window
        .getComputedStyle(
          trait.target.components().at(0).components().at(0).view.el,
        )
        .getPropertyValue('width');
      const containerWidth = window
        .getComputedStyle(trait.target.view.el)
        .getPropertyValue('width');

      const widthInPercentage = Math.round(
        (parseFloat(widthAuto) / parseFloat(containerWidth)) * 100,
      );

      // Toggler Input
      const toggler = document.createElement('input');
      toggler.type = 'checkbox';
      toggler.checked = widthAuto === 'auto';
      toggler.className = 'checkbox';
      toggler.style.display = 'none';

      // Toggler Styling Span (The Slider)
      const sliderSpan = document.createElement('span');
      sliderSpan.className = 'slider round';

      // Append toggler and styling span to togglerContainer
      togglerContainer.appendChild(toggler);
      togglerContainer.appendChild(sliderSpan);

      // Width Input Container
      const widthInputContainer = document.createElement('div');
      widthInputContainer.className = 'auto-width-div';
      widthInputContainer.style.display = toggler.checked ? 'none' : 'flex';

      // Label for Width Input
      const labelWidthInput = document.createElement('label');
      labelWidthInput.className = 'auto-width-label';
      labelWidthInput.innerText = 'Width (%)';
      labelWidthInput.style.display = 'block';

      // Create Width Input
      const widthInput = document.createElement('input');
      widthInput.type = 'number';
      widthInput.min = 1;
      widthInput.max = 100;
      widthInput.value = toggler.checked ? '' : widthInPercentage;
      widthInput.step = 1;
      widthInput.className = 'child-width-input input-trait';
      widthInput.style.marginTop = '5px';
      widthInput.style.width = '100%';
      widthInput.style.boxSizing = 'border-box';

      // Append label and input to widthInputContainer
      widthInputContainer.appendChild(labelWidthInput);
      widthInputContainer.appendChild(widthInput);

      // Toggler Event Listener
      toggler.addEventListener('change', () => {
        if (toggler.checked) {
          widthInputContainer.style.display = 'none';
          updateChildWidth('auto');
        } else {
          widthInputContainer.style.display = 'flex';
          widthInput.value = widthInPercentage;
          updateChildWidth(`${widthInput.value}%`);
        }
      });

      // Width Input Event Listener
      widthInput.addEventListener('input', () => {
        updateChildWidth(`${widthInput.value}%`);
      });

      // Function to update child width
      function updateChildWidth(widthValue) {
        const firstChild = trait.target.components().at(0);
        const secondChild = firstChild.components().at(0);
        if (secondChild) {
          secondChild.addStyle({ width: widthValue });
        }
      }

      // Append elements to the main container
      el.appendChild(labelAutoWidth);
      el.appendChild(togglerContainer);
      traitContainer.appendChild(el);
      traitContainer.appendChild(widthInputContainer);

      return traitContainer;
    },
  });

  traitManager.addType('image-width-control', {
    createInput({ trait }) {
      const imageElement = trait.target.getEl().querySelector('img');
      const traitContainer = document.createElement('div');
      traitContainer.className = 'traitContainer';
      const el = document.createElement('div');
      el.className = 'auto-width-div';

      // Label for Auto Width
      const labelAutoWidth = document.createElement('label');
      labelAutoWidth.className = 'auto-width-label';
      labelAutoWidth.innerText = 'Auto Width';
      labelAutoWidth.style.display = 'block';
      labelAutoWidth.style.marginBottom = '5px';

      // Toggler Container
      const togglerContainer = document.createElement('label');
      togglerContainer.className = 'switch';
      togglerContainer.style.marginBottom = '15px';

      const widthAuto = window
        .getComputedStyle(trait.target.components().at(0).view.el)
        .getPropertyValue('width');
      const containerWidth = window
        .getComputedStyle(trait.target.view.el)
        .getPropertyValue('width');

      // Toggler Input
      const toggler = document.createElement('input');
      toggler.type = 'checkbox';
      toggler.checked = widthAuto === containerWidth; // Toggler initially off
      toggler.className = 'checkbox';
      toggler.style.display = 'none';

      // Toggler Styling Span (The Slider)
      const sliderSpan = document.createElement('span');
      sliderSpan.className = 'slider round';

      // Append toggler and styling span to togglerContainer
      togglerContainer.appendChild(toggler);
      togglerContainer.appendChild(sliderSpan);

      // Width Input Container
      const widthInputContainer = document.createElement('div');
      widthInputContainer.className = 'auto-width-div';
      widthInputContainer.style.display = toggler.checked ? 'none' : 'flex'; // Initially visible

      // Label for Width Input
      const labelWidthInput = document.createElement('label');
      labelWidthInput.className = 'auto-width-label';
      labelWidthInput.innerText = 'Width (%)';
      labelWidthInput.style.display = 'block';

      // Create Width Input
      const widthInput = document.createElement('input');
      widthInput.type = 'number';
      widthInput.min = 1;
      widthInput.max = 100;
      widthInput.step = 1;
      widthInput.value = Math.round(
        (parseFloat(widthAuto.replace('px', ''), 10) /
          imageElement.naturalWidth) *
          100,
      );
      widthInput.className = 'image-width-input input-trait';
      widthInput.style.marginTop = '5px';
      widthInput.style.width = '100%';
      widthInput.style.boxSizing = 'border-box';

      // Append label and input to widthInputContainer
      widthInputContainer.appendChild(labelWidthInput);
      widthInputContainer.appendChild(widthInput);

      // Toggler Event Listener
      toggler.addEventListener('change', () => {
        if (toggler.checked) {
          widthInputContainer.style.display = 'none';
          // updateImageWidth("100%");  // Take full width of the container
          const imageElement = trait.target.find('img')[0];
          imageElement.addStyle({
            width: '100%',
            height: 'auto',
          });
        } else {
          widthInputContainer.style.display = 'flex';
          updateImageWidth(`${widthInput.value}%`); // Manual width control
        }
      });

      // Width Input Event Listener
      widthInput.addEventListener('input', () => {
        if (!toggler.checked) {
          updateImageWidth(`${widthInput.value}%`);
        }
      });

      // Function to update image width// Function to update image width
      function updateImageWidth(widthValue) {
        const imageElement = trait.target.getEl().querySelector('img');

        if (imageElement) {
          if (imageElement.complete) {
            // Ensure the image is loaded
            applyWidthAdjustment(imageElement, widthValue);
          } else {
            // If the image is not yet loaded, set up a listener to apply changes once it is
            imageElement.addEventListener('load', () =>
              applyWidthAdjustment(imageElement, widthValue),
            );
          }
        }
      }

      function applyWidthAdjustment(imageElement, widthValue) {
        const originalWidth = imageElement.naturalWidth; // Get the original width of the image
        const originalHeight = imageElement.naturalHeight; // Get the original height of the image
        const percentage = parseInt(widthValue) / 100;

        const container = grapesjsEditor.getSelected();
        const containerWidth = parseFloat(
          getComputedStyle(container.getEl()).width,
        );

        const newWidth = originalWidth * percentage;
        const newHeight = originalHeight * percentage;

        // Update the GrapesJS component style
        const imageComponent = trait.target.find('img')[0];
        if (originalWidth < containerWidth) {
          imageComponent.addStyle({
            width: `${newWidth}px`,
            height: `${newHeight}px`,
          });
        } else {
          imageComponent.addStyle({
            width: `${parseInt(widthValue)}%`,
            height: 'auto',
          });
        }
      }

      // Append elements to the main container
      el.appendChild(labelAutoWidth);
      el.appendChild(togglerContainer);
      traitContainer.appendChild(el);
      traitContainer.appendChild(widthInputContainer);

      return traitContainer;
    },
  });

  traitManager.addType('image-link-input', {
    createInput({ trait }) {
      const el = document.createElement('div');

      // Create Input Field
      const input = document.createElement('input');
      input.type = 'text';
      input.placeholder = 'http://';
      input.value = trait.target.find('a')[0]?.getAttributes().href || '';
      input.className = 'input-link input-trait';
      input.style.width = '100%';
      input.style.boxSizing = 'border-box';

      // Event Listener for input changes
      input.addEventListener('input', () => {
        const component = trait.target;
        const url = input.value.trim();

        if (url) {
          wrapImageWithLink(component, url);
        } else {
          unwrapImageFromLink(component);
        }

        // Ensure the canvas updates to reflect the changes
        grapesjsEditor.getModel().trigger('component:update', component);
      });

      el.appendChild(input);
      return el;
    },
  });

  traitManager.addType('divider-height', {
    // The createInput function will be responsible for creating the input element
    createInput({ trait }) {
      const el = document.createElement('div');
      const input = document.createElement('input');
      input.className = 'input-trait';
      input.type = 'number';
      input.min = 0; // Set minimum to 0 or any other value you see fit
      input.step = 1; // Change the step if you want more precision
      // Get the first child of the selected component
      const componentFirstChild = trait.target.components().at(0);

      // Set the input value to the border-top-width of the first child, or to 0 if not set
      const borderTopWidth = componentFirstChild.getStyle()['border-top-width'];
      input.value = parseInt(borderTopWidth, 10) || '2';

      input.addEventListener('change', (event) => {
        // When the value changes, apply it as the border-top-width of the child
        const componentFirstChild = trait.target.components().at(0);
        if (componentFirstChild) {
          // Set the border-top-width style
          componentFirstChild.addStyle({
            'border-top-width': `${event.target.value}px`,
          });
        }
      });

      // Append the input to the container element and return it
      el.appendChild(input);
      return el;
    },
  });

  traitManager.addType('divider-color', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'my-color-picker-trait';

      // Get the initial color of the border-top of the first child
      const firstChild = trait.target.components().at(0);
      const initialColor =
        firstChild.getStyle()['border-top-color'] || '#000000';

      const handleColorChange = (color) => {
        // Here, instead of setting the 'border-color', we set 'border-top-color' of the first child
        if (firstChild) {
          firstChild.addStyle({ 'border-top-color': color });
        }
      };

      // Use ReactDOM.render to ensure the component is within the React tree
      ReactDOM.render(
        <ColorPicker
          id="divider-color"
          initialColor={initialColor || '#ffffff'} // Fallback to white if no color is set
          onChange={handleColorChange}
          isGradient={true}
          brandColors={brandColors}
        />,
        el,
      );

      return el;
    },
  });

  traitManager.addType('divider-style', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'mui-select-wrapper';

      // React component for MUI Select
      const MUISelect = () => {
        // Assuming the first child exists and retrieving its current border-top-style
        const initialBorderStyle =
          trait.target.components().at(0)?.getStyle()['border-top-style'] ||
          'solid';
        const [value, setValue] = React.useState(initialBorderStyle);

        const handleChange = (event) => {
          const styleValue = event.target.value;
          setValue(styleValue);
          const firstChild = trait.target.components().at(0);
          if (firstChild) {
            // Apply the style to the first child
            firstChild.addStyle({
              'border-top-style': styleValue,
            });
          }
        };

        return (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              labelId="divider-style-select-label"
              value={value}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="none">
                <em>None</em>
              </MenuItem>
              <MenuItem value="solid">Solid</MenuItem>
              <MenuItem value="dotted">Dotted</MenuItem>
              <MenuItem value="dashed">Dashed</MenuItem>
            </Select>
          </FormControl>
        );
      };

      // Render the React component inside the GrapesJS environment
      ReactDOM.render(<MUISelect />, el);

      return el;
    },
  });

  traitManager.addType('alt-text', {
    createInput({ trait }) {
      // Create the input element
      const el = document.createElement('input');
      el.type = 'text';
      el.className = 'input-trait';
      el.placeholder = 'Enter alt text...';
      el.value = trait.target.find('img')[0]?.getAttributes().alt || '';

      // Update the component's alt attribute when the trait value changes
      el.addEventListener('input', (event) => {
        const altText = event.target.value;
        trait.target.find('img')[0].addAttributes({
          alt: altText,
        });
      });

      return el;
    },
  });

  traitManager.addType('color-picker', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'my-color-picker-trait';
      const initialColor = trait.target.getStyle()['background'] || '#000000';
      // const initialColor = rgbToHex(window.getComputedStyle(trait.target.view.el).getPropertyValue("background-color")) || "#000000";

      const handleColorChange = (color) => {
        trait.target.addStyle({ background: color });
      };

      ReactDOM.render(
        <ColorPicker
          id="color-picker"
          initialColor={initialColor || 'No Color'}
          onChange={handleColorChange}
          brandColors={brandColors}
        />,
        el,
      );

      return el;
    },
  });

  traitManager.addType('child-padding-control', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'child-paddingContainer lock-trait-container';

      // Create padding inputs for each side
      ['top', 'right', 'bottom', 'left'].forEach((side) => {
        const div = document.createElement('div');
        div.className = 'child-padding-box lock-input-box';

        const label = document.createElement('div');
        label.className = 'child-padding-label lock-input-label';
        label.textContent = side;

        const input = document.createElement('input');
        input.className = 'child-padding-input lock-input';
        input.type = 'number';
        input.id = `child-padding-${side}`;
        input.setAttribute('data-side', `padding-${side}`);
        input.min = 0;
        // Set initial value for padding
        const firstChild = trait.target.components().at(0);

        input.value =
          window
            .getComputedStyle(firstChild.components().at(0).view.el)
            .getPropertyValue(`padding-${side}`)
            ?.slice(0, -2) || 0;
        div.appendChild(input);
        div.appendChild(label);
        el.appendChild(div);
      });

      // Create the lock button
      const lockButton = document.createElement('button');
      lockButton.className = 'child-padding-lock-btn lock-btn locked';
      lockButton.id = 'child-toggle-lock';
      lockButton.textContent = ''; // Set initial text for the button
      el.appendChild(lockButton);

      // Initial state
      let isLocked = true;

      // Function to update padding values for child
      function updateChildPadding(id, value) {
        const selected = grapesjsEditor.getSelected();
        const firstChild = selected ? selected.components().at(0) : null;
        const secondChild = firstChild ? firstChild.components().at(0) : null;
        if (secondChild) {
          let style = {};
          style[id] = `${value}px`;
          secondChild.addStyle(style);
        }
      }

      // Function to handle input changes
      function handleInputChange(event) {
        const paddingValue = event.target.value;
        const paddingId = event.target.getAttribute('data-side');

        if (isLocked) {
          ['top', 'right', 'bottom', 'left'].forEach((side) => {
            const id = `padding-${side}`;
            el.querySelector(`#child-padding-${side}`).value = paddingValue;
            updateChildPadding(id, paddingValue);
          });
        } else {
          updateChildPadding(paddingId, paddingValue);
        }
      }

      // Attach the event listener to each input
      el.querySelectorAll('.child-padding-input').forEach((input) => {
        input.addEventListener('input', handleInputChange);
      });

      // Toggle button functionality
      lockButton.addEventListener('click', function () {
        isLocked = !isLocked;
        this.textContent = isLocked ? '' : '';
        this.classList.toggle('locked');
        this.classList.toggle('unlocked');
      });

      return el;
    },
  });

  traitManager.addType('border-radius-control', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'borderRadiusContainer lock-trait-container';

      // Define labels for each corner
      const cornerLabels = {
        'top-left': 'top',
        'top-right': 'right',
        'bottom-right': 'bottom',
        'bottom-left': 'left',
      };

      // Create border-radius inputs for each corner
      Object.entries(cornerLabels).forEach(([corner, labelName]) => {
        const div = document.createElement('div');
        div.className = 'borderRadius-box lock-input-box';

        const label = document.createElement('div');
        label.className = 'borderRadius-label lock-input-label';
        label.textContent = labelName;

        const input = document.createElement('input');
        input.className = 'borderRadius-input lock-input';
        input.type = 'number';
        input.id = `border-${corner}-radius`;
        input.setAttribute('data-corner', `border-${corner}-radius`);
        input.min = 0;
        input.value =
          trait.target.getStyle(`border-${corner}-radius`)?.slice(0, -2) || 0;

        div.appendChild(input);
        div.appendChild(label);
        el.appendChild(div);
      });

      // Create the lock button
      const lockButton = document.createElement('button');
      lockButton.className = 'borderRadius-lock-btn lock-btn locked';
      lockButton.id = 'toggle-lock';
      lockButton.textContent = ''; // Set initial text for the button
      el.appendChild(lockButton);

      // Initial state
      let isLocked = true;

      // Function to update border-radius values
      function updateBorderRadius(id, value) {
        const selected = grapesjsEditor.getSelected();
        if (selected) {
          let style = {};
          style[id] = `${value}px`;
          selected.addStyle(style);
        }
      }

      // Function to handle input changes
      function handleInputChange(event) {
        const borderRadiusValue = event.target.value;
        const borderRadiusId = event.target.getAttribute('data-corner');

        if (isLocked) {
          ['top-left', 'top-right', 'bottom-left', 'bottom-right'].forEach(
            (corner) => {
              const id = `border-${corner}-radius`;
              el.querySelector(`#${id}`).value = borderRadiusValue;
              updateBorderRadius(id, borderRadiusValue);
            },
          );
        } else {
          updateBorderRadius(borderRadiusId, borderRadiusValue);
        }
      }

      // Attach the event listener to each input
      el.querySelectorAll('.borderRadius-input').forEach((input) => {
        input.addEventListener('input', handleInputChange);
      });

      // Toggle button functionality
      lockButton.addEventListener('click', function () {
        isLocked = !isLocked;
        this.textContent = isLocked ? '' : '';
        this.classList.toggle('locked');
        this.classList.toggle('unlocked');
      });

      return el;
    },
  });

  traitManager.addType('child-border-radius-control', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'child-borderRadiusContainer lock-trait-container';

      // Define labels for each corner
      const cornerLabels = {
        'top-left': 'top',
        'top-right': 'right',
        'bottom-right': 'bottom',
        'bottom-left': 'left',
      };

      // Create border-radius inputs for each corner
      Object.entries(cornerLabels).forEach(([corner, labelName]) => {
        const div = document.createElement('div');
        div.className = 'child-borderRadius-box lock-input-box';

        const label = document.createElement('div');
        label.className = 'child-borderRadius-label lock-input-label';
        label.textContent = labelName;

        const input = document.createElement('input');
        input.className = 'child-borderRadius-input lock-input';
        input.type = 'number';
        input.id = `child-border-${corner}-radius`;
        input.setAttribute('data-corner', `border-${corner}-radius`);
        input.min = 0;

        // Set initial value for border-radius of the first child
        const firstChild = trait.target.components().at(0);
        input.value =
          window
            .getComputedStyle(firstChild.view.el)
            .getPropertyValue(`border-${corner}-radius`)
            .replace('px', '') || 0;

        div.appendChild(input);
        div.appendChild(label);
        el.appendChild(div);
      });

      // Create the lock button
      const lockButton = document.createElement('button');
      lockButton.className = 'child-borderRadius-lock-btn lock-btn locked';
      lockButton.id = 'child-toggle-lock';
      lockButton.textContent = '';
      el.appendChild(lockButton);

      // Initial state
      let isLocked = true;

      // Function to update border-radius values for the child
      function updateChildBorderRadius(id, value) {
        const selected = grapesjsEditor.getSelected();
        const firstChild = selected ? selected.components().at(0) : null;
        if (firstChild) {
          let style = {};
          style[id] = `${value}px`;
          firstChild.addStyle(style);
        }
      }

      // Function to handle input changes
      function handleInputChange(event) {
        const borderRadiusValue = event.target.value;
        const borderRadiusId = event.target.getAttribute('data-corner');

        if (isLocked) {
          ['top-left', 'top-right', 'bottom-left', 'bottom-right'].forEach(
            (corner) => {
              const id = `border-${corner}-radius`;
              el.querySelector(`#child-border-${corner}-radius`).value =
                borderRadiusValue;
              updateChildBorderRadius(id, borderRadiusValue);
            },
          );
        } else {
          updateChildBorderRadius(borderRadiusId, borderRadiusValue);
        }
      }

      // Attach the event listener to each input
      el.querySelectorAll('.child-borderRadius-input').forEach((input) => {
        input.addEventListener('input', handleInputChange);
      });

      // Toggle button functionality
      lockButton.addEventListener('click', function () {
        isLocked = !isLocked;
        this.textContent = isLocked ? '' : '';
        this.classList.toggle('locked');
        this.classList.toggle('unlocked');
      });

      return el;
    },
  });

  traitManager.addType('second-child-border-radius-control', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'child-borderRadiusContainer lock-trait-container';

      // Define labels for each corner
      const cornerLabels = {
        'top-left': 'top',
        'top-right': 'right',
        'bottom-right': 'bottom',
        'bottom-left': 'left',
      };

      // Create border-radius inputs for each corner
      Object.entries(cornerLabels).forEach(([corner, labelName]) => {
        const div = document.createElement('div');
        div.className = 'child-borderRadius-box lock-input-box';

        const label = document.createElement('div');
        label.className = 'child-borderRadius-label lock-input-label';
        label.textContent = labelName;

        const input = document.createElement('input');
        input.className = 'child-borderRadius-input lock-input';
        input.type = 'number';
        input.id = `child-border-${corner}-radius`;
        input.setAttribute('data-corner', `border-${corner}-radius`);
        input.min = 0;

        // Set initial value for border-radius of the first child
        const firstChild = trait.target.components().at(0);
        const secondChild = firstChild.components().at(0);
        input.value =
          window
            .getComputedStyle(secondChild.view.el)
            .getPropertyValue(`border-${corner}-radius`)
            .replace('px', '') || 0;

        div.appendChild(input);
        div.appendChild(label);
        el.appendChild(div);
      });

      // Create the lock button
      const lockButton = document.createElement('button');
      lockButton.className = 'child-borderRadius-lock-btn lock-btn locked';
      lockButton.id = 'child-toggle-lock';
      lockButton.textContent = '';
      el.appendChild(lockButton);

      // Initial state
      let isLocked = true;

      // Function to update border-radius values for the child
      function updateChildBorderRadius(id, value) {
        const selected = grapesjsEditor.getSelected();
        const firstChild = selected ? selected.components().at(0) : null;
        const secondChild = firstChild ? firstChild.components().at(0) : null;
        if (secondChild) {
          let style = {};
          style[id] = `${value}px`;
          secondChild.addStyle(style);
        }
      }

      // Function to handle input changes
      function handleInputChange(event) {
        const borderRadiusValue = event.target.value;
        const borderRadiusId = event.target.getAttribute('data-corner');

        if (isLocked) {
          ['top-left', 'top-right', 'bottom-left', 'bottom-right'].forEach(
            (corner) => {
              const id = `border-${corner}-radius`;
              el.querySelector(`#child-border-${corner}-radius`).value =
                borderRadiusValue;
              updateChildBorderRadius(id, borderRadiusValue);
            },
          );
        } else {
          updateChildBorderRadius(borderRadiusId, borderRadiusValue);
        }
      }

      // Attach the event listener to each input
      el.querySelectorAll('.child-borderRadius-input').forEach((input) => {
        input.addEventListener('input', handleInputChange);
      });

      // Toggle button functionality
      lockButton.addEventListener('click', function () {
        isLocked = !isLocked;
        this.textContent = isLocked ? '' : '';
        this.classList.toggle('locked');
        this.classList.toggle('unlocked');
      });

      return el;
    },
  });

  traitManager.addType('column-gap', {
    // Create the input field for the trait
    createInput({ trait }) {
      const el = document.createElement('input');
      el.type = 'number';
      el.min = 0;
      const val = trait.target.getStyle()['border-spacing'];
      el.value = val?.slice(0, -2) || 16;
      el.className = ' input-trait';

      // Update the component style when the trait value changes
      el.addEventListener('input', (event) => {
        const value = event.target.value;
        trait.target.addStyle({
          'border-spacing': `${value}px`,
        });
      });

      return el;
    },
  });

  traitManager.addType('border-settings', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'border-settings-trait';

      const setBorderTraitVisibility = () => {
        const styleValue = trait.target.getStyle('border-style') || 'none';
        const shouldShow = styleValue !== 'none';
        const containers = el.querySelectorAll('.border-settings-container');
        containers.forEach((container) => {
          container.style.marginTop = shouldShow ? '8px' : '0';
        });

        const borderWidthEl = document.querySelector('.border-width-trait');
        const borderColorEl = document.querySelector('.border-color-trait');
        if (borderWidthEl)
          borderWidthEl.style.display = shouldShow ? 'block' : 'none';
        if (borderColorEl)
          borderColorEl.style.display = shouldShow ? 'block' : 'none';
        const borderWidthLabel = document.querySelector('.border-width-label');
        const borderColorLabel = document.querySelector('.border-color-label');
        if (borderWidthLabel)
          borderWidthLabel.style.display = shouldShow ? 'block' : 'none';
        if (borderColorLabel)
          borderColorLabel.style.display = shouldShow ? 'block' : 'none';
      };

      // React component for MUI Select (Border Style)
      const MUISelect = () => {
        const [value, setValue] = React.useState(
          trait.target.getStyle('border-style') || 'none',
        );

        const handleChange = (event) => {
          const newValue = event.target.value;
          setValue(newValue);
          trait.target.addStyle({ 'border-style': newValue });
          trait.target.addStyle({ 'border-width': '1px' });
          trait.target.addStyle({ 'border-color': '#000000' });
          setBorderTraitVisibility();
        };

        return (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={value}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="none">
                <em>None</em>
              </MenuItem>
              <MenuItem value="solid">Solid</MenuItem>
              <MenuItem value="dotted">Dotted</MenuItem>
              <MenuItem value="dashed">Dashed</MenuItem>
            </Select>
          </FormControl>
        );
      };

      // Border Style trait with label
      const borderStyleContainer = document.createElement('div');
      borderStyleContainer.className =
        'border-settings-container border-style-container';
      const borderStyleLabel = document.createElement('div');
      borderStyleLabel.className = 'border-settings-label border-style-label';
      borderStyleLabel.textContent = 'Border Style';
      const borderStyleEl = document.createElement('div');
      borderStyleEl.className = 'mui-select-wrapper';
      ReactDOM.render(<MUISelect />, borderStyleEl);
      borderStyleContainer.appendChild(borderStyleLabel);
      borderStyleContainer.appendChild(borderStyleEl);
      el.appendChild(borderStyleContainer);

      const styleValue = trait.target.getStyle('border-style') || 'none';
      const shouldShow = styleValue !== 'none';

      // Border Width Input
      const borderWidthContainer = document.createElement('div');
      borderWidthContainer.className =
        'border-settings-container border-width-container';
      const borderWidthLabel = document.createElement('div');
      borderWidthLabel.className = 'border-settings-label border-width-label';
      borderWidthLabel.textContent = 'Border Width';
      borderWidthLabel.style.display = shouldShow ? 'block' : 'none';
      const borderWidthEl = document.createElement('input');
      borderWidthEl.type = 'number';
      borderWidthEl.min = 0;
      borderWidthEl.value =
        trait.target.getStyle('border-width')?.replace('px', '') || 1;
      borderWidthEl.className = 'input-trait border-width-trait';
      borderWidthEl.style.display = shouldShow ? 'block' : 'none';
      borderWidthEl.addEventListener('input', (event) => {
        const value = event.target.value;
        trait.target.addStyle({ 'border-width': `${value}px` });
      });
      borderWidthContainer.appendChild(borderWidthLabel);
      borderWidthContainer.appendChild(borderWidthEl);
      el.appendChild(borderWidthContainer);

      // Border Color Picker
      const borderColorContainer = document.createElement('div');
      borderColorContainer.className =
        'border-settings-container border-color-container';
      const borderColorLabel = document.createElement('div');
      borderColorLabel.className = 'border-settings-label border-color-label';
      borderColorLabel.textContent = 'Border Color';
      borderColorLabel.style.display = shouldShow ? 'block' : 'none';
      const borderColorEl = document.createElement('div');
      borderColorEl.className = 'my-color-picker-trait border-color-trait';
      borderColorEl.style.display = shouldShow ? 'block' : 'none';
      const initialColor = trait.target.getStyle()['border-color'] || '#000000';
      const handleColorChange = (color) => {
        trait.target.addStyle({ 'border-color': color });
      };
      ReactDOM.render(
        <ColorPicker
          id="border-settings"
          initialColor={initialColor || 'No Color'}
          onChange={handleColorChange}
          isGradient={true}
          brandColors={brandColors}
        />,
        borderColorEl,
      );
      borderColorContainer.appendChild(borderColorLabel);
      borderColorContainer.appendChild(borderColorEl);
      el.appendChild(borderColorContainer);

      // Initial visibility setting
      setBorderTraitVisibility();

      return el;
    },
  });

  traitManager.addType('child-border-settings', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'child-border-settings-trait';

      const setBorderTraitVisibility = () => {
        const firstChild = trait.target.components().at(0);
        const styleValue =
          window
            .getComputedStyle(firstChild.view.el)
            .getPropertyValue('border-style') || 'none';
        const shouldShow = styleValue !== 'none';
        const containers = el.querySelectorAll('.border-settings-container');
        containers.forEach((container) => {
          container.style.marginTop = shouldShow ? '8px' : '0';
        });

        const widthEl = document.querySelector('.border-width-trait');
        const colorEl = document.querySelector('.border-color-trait');
        if (widthEl) {
          widthEl.style.display = shouldShow ? 'block' : 'none';
        }
        if (colorEl) {
          colorEl.style.display = shouldShow ? 'block' : 'none';
        }
        const widthLabel = document.querySelector('.border-width-label');
        const colorLabel = document.querySelector('.border-color-label');
        if (widthLabel)
          widthLabel.style.display = shouldShow ? 'block' : 'none';
        if (colorLabel)
          colorLabel.style.display = shouldShow ? 'block' : 'none';
      };

      const applyChildStyle = (property, value) => {
        const firstChild = trait.target.components().at(0);
        if (firstChild) {
          let style = {};
          style[property] = value;
          firstChild.addStyle(style);
        }
      };

      // React component for MUI Select (Border Style)
      const MUISelect = () => {
        const firstChild = trait.target.components().at(0);
        const initialValue =
          window
            .getComputedStyle(firstChild.view.el)
            .getPropertyValue('border-style') || 'none';
        const [value, setValue] = React.useState(initialValue);

        const handleChange = (event) => {
          const newValue = event.target.value;
          const currentBorderWidth = window
            .getComputedStyle(firstChild.view.el)
            .getPropertyValue('border-width');
          const currentBorderColor = window
            .getComputedStyle(firstChild.view.el)
            .getPropertyValue('border-color');

          setValue(newValue);
          applyChildStyle('border-style', newValue);
          applyChildStyle('border-width', currentBorderWidth);
          applyChildStyle('border-color', currentBorderColor);
          setBorderTraitVisibility();
        };

        return (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={value}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="none">
                <em>None</em>
              </MenuItem>
              <MenuItem value="solid">Solid</MenuItem>
              <MenuItem value="dotted">Dotted</MenuItem>
              <MenuItem value="dashed">Dashed</MenuItem>
            </Select>
          </FormControl>
        );
      };

      const borderStyleContainer = document.createElement('div');
      borderStyleContainer.className =
        'border-settings-container border-style-container';
      const borderStyleLabel = document.createElement('div');
      borderStyleLabel.className = 'border-settings-label border-style-label';
      borderStyleLabel.textContent = 'Border Style';
      const borderStyleEl = document.createElement('div');
      borderStyleEl.className = 'mui-select-wrapper';
      ReactDOM.render(<MUISelect />, borderStyleEl);
      borderStyleContainer.appendChild(borderStyleLabel);
      borderStyleContainer.appendChild(borderStyleEl);
      el.appendChild(borderStyleContainer);

      const firstChild = trait.target.components().at(0);
      const styleValue =
        window
          .getComputedStyle(firstChild.view.el)
          .getPropertyValue('border-style') || 'none';
      const shouldShow = styleValue !== 'none';

      // Border Width Input
      const borderWidthContainer = document.createElement('div');
      borderWidthContainer.className =
        'border-settings-container border-width-container';
      const borderWidthLabel = document.createElement('div');
      borderWidthLabel.className = 'border-settings-label border-width-label';
      borderWidthLabel.textContent = 'Border Width';
      borderWidthLabel.style.display = shouldShow ? 'block' : 'none';
      const borderWidthEl = document.createElement('input');
      borderWidthEl.type = 'number';
      borderWidthEl.min = 0;
      borderWidthEl.value =
        window
          .getComputedStyle(firstChild.view.el)
          .getPropertyValue('border-width')
          ?.replace('px', '') || 1;
      borderWidthEl.className = 'input-trait border-width-trait';
      borderWidthEl.style.display = shouldShow ? 'block' : 'none';
      borderWidthEl.addEventListener('input', (event) => {
        const value = event.target.value;
        applyChildStyle('border-width', `${value}px`);
      });
      borderWidthContainer.appendChild(borderWidthLabel);
      borderWidthContainer.appendChild(borderWidthEl);
      el.appendChild(borderWidthContainer);

      // Border Color Picker
      const borderColorContainer = document.createElement('div');
      borderColorContainer.className =
        'border-settings-container border-color-container';
      const borderColorLabel = document.createElement('div');
      borderColorLabel.className = 'border-settings-label border-color-label';
      borderColorLabel.textContent = 'Border Color';
      borderColorLabel.style.display = shouldShow ? 'block' : 'none';
      const borderColorEl = document.createElement('div');
      borderColorEl.className = 'my-color-picker-trait border-color-trait';
      borderColorEl.style.display = shouldShow ? 'block' : 'none';
      const initialColor =
        window
          .getComputedStyle(firstChild.view.el)
          .getPropertyValue('border-color') || '#000000';
      const handleColorChange = (color) => {
        applyChildStyle('border-color', color);
      };
      ReactDOM.render(
        <ColorPicker
          id="child-border-settings"
          initialColor={initialColor || 'No Color'}
          onChange={handleColorChange}
          isGradient={true}
          brandColors={brandColors}
        />,
        borderColorEl,
      );
      borderColorContainer.appendChild(borderColorLabel);
      borderColorContainer.appendChild(borderColorEl);
      el.appendChild(borderColorContainer);

      setBorderTraitVisibility();

      return el;
    },
  });

  traitManager.addType('second-child-border-settings', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'child-border-settings-trait';

      const setBorderTraitVisibility = () => {
        const firstChild = trait.target.components().at(0);
        const secondChild = firstChild.components().at(0);
        const styleValue =
          window
            .getComputedStyle(secondChild.view.el)
            .getPropertyValue('border-style') || 'none';
        const shouldShow = styleValue !== 'none';
        const containers = el.querySelectorAll('.border-settings-container');
        containers.forEach((container) => {
          container.style.marginTop = shouldShow ? '8px' : '0';
        });

        const widthEl = document.querySelector('.border-width-trait');
        const colorEl = document.querySelector('.border-color-trait');
        if (widthEl) {
          widthEl.style.display = shouldShow ? 'block' : 'none';
        }
        if (colorEl) {
          colorEl.style.display = shouldShow ? 'block' : 'none';
        }
        const widthLabel = document.querySelector('.border-width-label');
        const colorLabel = document.querySelector('.border-color-label');
        if (widthLabel)
          widthLabel.style.display = shouldShow ? 'block' : 'none';
        if (colorLabel)
          colorLabel.style.display = shouldShow ? 'block' : 'none';
      };

      const applyChildStyle = (property, value) => {
        const firstChild = trait.target.components().at(0);
        const secondChild = firstChild.components().at(0);
        if (secondChild) {
          let style = {};
          style[property] = value;
          secondChild.addStyle(style);
        }
      };

      // React component for MUI Select (Border Style)
      const MUISelect = () => {
        const firstChild = trait.target.components().at(0);
        const secondChild = firstChild.components().at(0);
        const initialValue =
          window
            .getComputedStyle(secondChild.view.el)
            .getPropertyValue('border-style') || 'none';
        const [value, setValue] = React.useState(initialValue);

        const handleChange = (event) => {
          const newValue = event.target.value;
          const currentBorderWidth = window
            .getComputedStyle(secondChild.view.el)
            .getPropertyValue('border-width');
          const currentBorderColor = window
            .getComputedStyle(secondChild.view.el)
            .getPropertyValue('border-color');

          setValue(newValue);
          applyChildStyle('border-style', newValue);
          applyChildStyle('border-width', currentBorderWidth);
          applyChildStyle('border-color', currentBorderColor);
          setBorderTraitVisibility();
        };

        return (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={value}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="none">
                <em>None</em>
              </MenuItem>
              <MenuItem value="solid">Solid</MenuItem>
              <MenuItem value="dotted">Dotted</MenuItem>
              <MenuItem value="dashed">Dashed</MenuItem>
            </Select>
          </FormControl>
        );
      };

      const borderStyleContainer = document.createElement('div');
      borderStyleContainer.className =
        'border-settings-container border-style-container';
      const borderStyleLabel = document.createElement('div');
      borderStyleLabel.className = 'border-settings-label border-style-label';
      borderStyleLabel.textContent = 'Border Style';
      const borderStyleEl = document.createElement('div');
      borderStyleEl.className = 'mui-select-wrapper';
      ReactDOM.render(<MUISelect />, borderStyleEl);
      borderStyleContainer.appendChild(borderStyleLabel);
      borderStyleContainer.appendChild(borderStyleEl);
      el.appendChild(borderStyleContainer);

      const firstChild = trait.target.components().at(0);
      const secondChild = firstChild.components().at(0);
      const styleValue =
        window
          .getComputedStyle(secondChild.view.el)
          .getPropertyValue('border-style') || 'none';

      const shouldShow = styleValue !== 'none';

      // Border Width Input
      const borderWidthContainer = document.createElement('div');
      borderWidthContainer.className =
        'border-settings-container border-width-container';
      const borderWidthLabel = document.createElement('div');
      borderWidthLabel.className = 'border-settings-label border-width-label';
      borderWidthLabel.textContent = 'Border Width';
      borderWidthLabel.style.display = shouldShow ? 'block' : 'none';
      const borderWidthEl = document.createElement('input');
      borderWidthEl.type = 'number';
      borderWidthEl.min = 0;
      borderWidthEl.value =
        window
          .getComputedStyle(secondChild.view.el)
          .getPropertyValue('border-width')
          ?.replace('px', '') || 1;
      borderWidthEl.className = 'input-trait border-width-trait';
      borderWidthEl.style.display = shouldShow ? 'block' : 'none';
      borderWidthEl.addEventListener('input', (event) => {
        const value = event.target.value;
        applyChildStyle('border-width', `${value}px`);
      });
      borderWidthContainer.appendChild(borderWidthLabel);
      borderWidthContainer.appendChild(borderWidthEl);
      el.appendChild(borderWidthContainer);

      // Border Color Picker
      const borderColorContainer = document.createElement('div');
      borderColorContainer.className =
        'border-settings-container border-color-container';
      const borderColorLabel = document.createElement('div');
      borderColorLabel.className = 'border-settings-label border-color-label';
      borderColorLabel.textContent = 'Border Color';
      borderColorLabel.style.display = shouldShow ? 'block' : 'none';
      const borderColorEl = document.createElement('div');
      borderColorEl.className = 'my-color-picker-trait border-color-trait';
      borderColorEl.style.display = shouldShow ? 'block' : 'none';
      const initialColor =
        window
          .getComputedStyle(secondChild.view.el)
          .getPropertyValue('border-color') || '#000000';
      const handleColorChange = (color) => {
        applyChildStyle('border-color', color);
      };
      ReactDOM.render(
        <ColorPicker
          id="second-child-border-settings"
          initialColor={initialColor || 'No Color'}
          onChange={handleColorChange}
          isGradient={true}
          brandColors={brandColors}
        />,
        borderColorEl,
      );
      borderColorContainer.appendChild(borderColorLabel);
      borderColorContainer.appendChild(borderColorEl);
      el.appendChild(borderColorContainer);

      setBorderTraitVisibility();

      return el;
    },
  });

  traitManager.addType('table-border-settings', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'table-border-settings-trait';

      const getTableComponent = () => {
        const target = trait.target;
        const targetComp = target.components().at(0);
        return targetComp?.is('table') ? targetComp : target.closest('table');
      };

      const setBorderTraitVisibility = () => {
        const table = getTableComponent();
        const firstCell = table.find('th, td').at(0);
        const styleValue = firstCell.getStyle('border-style') || 'none';
        const shouldShow = styleValue !== 'none';
        const borderWidthContainer = document.querySelector(
          '.border-width-container',
        );
        const borderColorContainer = document.querySelector(
          '.border-color-container',
        );

        if (borderWidthContainer) {
          borderWidthContainer.style.display = shouldShow ? 'flex' : 'none';
        }
        if (borderColorContainer) {
          borderColorContainer.style.display = shouldShow ? 'flex' : 'none';
        }
      };

      const applyChildStyle = (property, value) => {
        const table = getTableComponent();
        const childs = table.find('th,td');
        childs.forEach((child) => {
          child.addStyle(property, value);
        });
      };

      const MUISelect = () => {
        const table = getTableComponent();
        const firstCell = table?.find('th, td').at(0);
        const initialValue = firstCell?.getStyle('border-style') || 'dashed';
        const [value, setValue] = React.useState(initialValue);

        const handleChange = (event) => {
          const newValue = event.target.value;
          setValue(newValue);
          applyChildStyle('border-style', newValue);
          applyChildStyle(
            'border-width',
            firstCell?.getStyle('border-width') || '1px',
          );
          applyChildStyle(
            'border-color',
            firstCell?.getStyle('border-color') || '#dedfea',
          );
          setBorderTraitVisibility();
        };

        return (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={value}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="none">
                <em>None</em>
              </MenuItem>
              <MenuItem value="solid">Solid</MenuItem>
              <MenuItem value="dotted">Dotted</MenuItem>
              <MenuItem value="dashed">Dashed</MenuItem>
            </Select>
          </FormControl>
        );
      };

      const borderStyleContainer = document.createElement('div');
      borderStyleContainer.className =
        'border-settings-container border-style-container';
      const borderStyleLabel = document.createElement('div');
      borderStyleLabel.className = 'border-settings-label border-style-label';
      borderStyleLabel.textContent = 'Border Style';
      const borderStyleEl = document.createElement('div');
      borderStyleEl.className = 'mui-select-wrapper';
      ReactDOM.render(<MUISelect />, borderStyleEl);
      borderStyleContainer.appendChild(borderStyleLabel);
      borderStyleContainer.appendChild(borderStyleEl);
      el.appendChild(borderStyleContainer);

      const table = getTableComponent();
      const firstCell = table.find('th, td').at(0);
      const styleValue = firstCell?.getStyle('border-style') || 'dashed';
      const shouldShow = styleValue !== 'none';

      const borderWidthContainer = document.createElement('div');
      borderWidthContainer.className =
        'border-settings-container border-width-container';
      const borderWidthLabel = document.createElement('div');
      borderWidthLabel.className = 'border-settings-label border-width-label';
      borderWidthLabel.textContent = 'Border Width';
      borderWidthLabel.style.display = shouldShow ? 'block' : 'none';
      const borderWidthEl = document.createElement('input');
      borderWidthEl.type = 'number';
      borderWidthEl.min = 0;
      borderWidthEl.value =
        firstCell?.getStyle('border-width')?.replace('px', '') || 1;
      borderWidthEl.className = 'input-trait border-width-trait';
      borderWidthEl.style.display = shouldShow ? 'block' : 'none';
      borderWidthEl.addEventListener('input', (event) => {
        const value = event.target.value;
        applyChildStyle('border-width', `${value}px`);
      });
      borderWidthContainer.appendChild(borderWidthLabel);
      borderWidthContainer.appendChild(borderWidthEl);
      el.appendChild(borderWidthContainer);

      const borderColorContainer = document.createElement('div');
      borderColorContainer.className =
        'border-settings-container border-color-container';
      const borderColorLabel = document.createElement('div');
      borderColorLabel.className = 'border-settings-label border-color-label';
      borderColorLabel.textContent = 'Border Color';
      borderColorLabel.style.display = shouldShow ? 'block' : 'none';
      const borderColorEl = document.createElement('div');
      borderColorEl.className = 'my-color-picker-trait border-color-trait';
      borderColorEl.style.display = shouldShow ? 'block' : 'none';
      const initialColor = firstCell?.getStyle()['border-color'] || '#dedfea';
      const handleColorChange = (color) => {
        applyChildStyle('border-color', color);
      };
      ReactDOM.render(
        <ColorPicker
          id="table-border-settings"
          initialColor={initialColor || 'No Color'}
          onChange={handleColorChange}
          isGradient={true}
          brandColors={brandColors}
        />,
        borderColorEl,
      );
      borderColorContainer.appendChild(borderColorLabel);
      borderColorContainer.appendChild(borderColorEl);
      el.appendChild(borderColorContainer);

      setBorderTraitVisibility();

      return el;
    },
  });

  traitManager.addType('border-style', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'mui-select-wrapper';

      // React component for MUI Select
      const MUISelect = () => {
        const [value, setValue] = React.useState(
          trait.target.getStyle('border-style') || 'none',
        );

        const handleChange = (event) => {
          setValue(event.target.value);
          trait.target.addStyle({
            'border-style': event.target.value,
          });

          setBorderTraitVisibility(
            grapesjsEditor,
            event.target.value !== 'none',
          );
        };

        return (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={value}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="none">
                <em>None</em>
              </MenuItem>
              <MenuItem value="solid">Solid</MenuItem>
              <MenuItem value="dotted">Dotted</MenuItem>
              <MenuItem value="dashed">Dashed</MenuItem>
            </Select>
          </FormControl>
        );
      };

      // Render the React component inside the GrapesJS environment
      ReactDOM.render(<MUISelect />, el);

      return el;
    },
  });

  traitManager.addType('border-width', {
    // Create the input field for the trait
    createInput({ trait }) {
      const el = document.createElement('input');
      el.type = 'number';
      el.min = 0; // Minimum border width
      el.value = trait.target.getStyle('border-width')?.replace('px', '') || 0;
      el.className = 'input-trait';

      // Update the component style when the trait value changes
      el.addEventListener('input', (event) => {
        const value = event.target.value;
        trait.target.addStyle({
          'border-width': `${value}px`,
        });
      });

      return el;
    },
  });

  traitManager.addType('border-color', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'my-color-picker-trait';
      const initialColor = trait.target.getStyle()[trait.name];

      const handleColorChange = (color) => {
        trait.target.addStyle({ 'border-color': color });
      };

      ReactDOM.render(
        <ColorPicker
          id="border-color"
          initialColor={initialColor || 'No Color'}
          onChange={handleColorChange}
          isGradient={true}
          brandColors={brandColors}
        />,
        el,
      );

      return el;
    },
  });

  traitManager.addType('typography-settings', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'typography-settings-container ';

      // Function to create a label
      const createLabel = (text) => {
        const label = document.createElement('div');
        label.className = 'typography-settings-label';
        label.textContent = text;
        return label;
      };

      const setFontFamily = window
        .getComputedStyle(trait.target.view.el)
        .getPropertyValue('font-family');

      // Check if the font-family is enclosed in quotes
      const extractedFontFamily =
        setFontFamily.startsWith('"') || setFontFamily.startsWith("'")
          ? setFontFamily.split(',')[0].trim().replace(/['"]/g, '')
          : setFontFamily.split(',')[0].trim();
      // React component for Font Family Select
      const MUISelect = () => {
        const [value, setValue] = React.useState(
          extractedFontFamily || 'Arial',
        );

        const handleChange = (event) => {
          setValue(event.target.value);
          trait.target.addStyle({
            'font-family': `'${event.target.value}'`,
          });
        };

        const fonts = [
          'Arial',
          'Arial Black',
          'Brush Script MT',
          'Comic Sans MS',
          'Courier New',
          'Georgia',
          'Helvetica',
          'Impact',
          'Lucida Sans Unicode',
          'Tahoma',
          'Times New Roman',
          'Trebuchet MS',
          'Verdana',
        ];

        return (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={value}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {fonts.map((font) => (
                <MenuItem key={font} value={font}>
                  {font}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      };

      // Font Family Select container
      const fontFamilyContainer = document.createElement('div');
      fontFamilyContainer.className =
        'typography-settings-select-container typography-container';
      fontFamilyContainer.appendChild(createLabel('Font Family'));

      const fontFamilyEl = document.createElement('div');
      fontFamilyEl.className = 'mui-select-wrapper';
      ReactDOM.render(<MUISelect />, fontFamilyEl);

      fontFamilyContainer.appendChild(fontFamilyEl);
      el.appendChild(fontFamilyContainer);

      // Line Height Input container
      const lineHeightContainer = document.createElement('div');
      lineHeightContainer.className =
        'typography-settings-input-container typography-container';
      lineHeightContainer.appendChild(createLabel('Line Height'));

      const setLineHeight = window
        .getComputedStyle(trait.target.view.el)
        .getPropertyValue('line-height');

      const setFontSize = window
        .getComputedStyle(trait.target.view.el)
        .getPropertyValue('font-size');

      // Convert line-height to 'em' if it's in px
      let lineHeightInEm;
      if (setLineHeight.endsWith('px')) {
        const fontSizeInPx = parseFloat(setFontSize);
        const lineHeightInPx = parseFloat(setLineHeight);
        lineHeightInEm = (lineHeightInPx / fontSizeInPx).toFixed(1);
      } else {
        lineHeightInEm = parseFloat(setLineHeight); // If already in 'em', use as-is
      }

      const lineHeightEl = document.createElement('input');
      lineHeightEl.type = 'number';
      lineHeightEl.min = 0.5;
      lineHeightEl.step = 0.1;
      lineHeightEl.value = lineHeightInEm || '1';
      lineHeightEl.className = 'input-trait';
      lineHeightEl.addEventListener('input', (event) => {
        const lineHeight = event.target.value;
        trait.target.addStyle({
          'line-height': `${lineHeight}`,
        });
      });

      lineHeightContainer.appendChild(lineHeightEl);
      el.appendChild(lineHeightContainer);

      // Text Alignment Buttons container
      const textAlignContainer = document.createElement('div');
      textAlignContainer.className =
        'typography-settings-button-container typography-container';
      textAlignContainer.appendChild(createLabel('Text Alignment'));

      const setAlignment = window
        .getComputedStyle(trait.target.view.el)
        .getPropertyValue('text-align');

      const textAlignEl = document.createElement('div');
      textAlignEl.className = 'text-alignment-container';
      const alignments = [
        { value: 'left', name: 'Left' },
        { value: 'center', name: 'Center' },
        { value: 'right', name: 'Right' },
        { value: 'justify', name: 'Justify' },
      ];

      alignments.forEach((align) => {
        const button = document.createElement('button');
        button.className =
          align.value === setAlignment
            ? `alignment-button alignment-${align.value} alignment-selected`
            : `alignment-button alignment-${align.value}`;
        button.textContent = '';
        button.addEventListener('click', () => {
          trait.target.addStyle({ 'text-align': align.value });
          document.querySelectorAll('.alignment-button').forEach((btn) => {
            btn.classList.remove('alignment-selected');
          });
          button.classList.add('alignment-selected');
        });

        textAlignEl.appendChild(button);
      });

      textAlignContainer.appendChild(textAlignEl);
      el.appendChild(textAlignContainer);

      return el;
    },
  });

  traitManager.addType('font-family', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'mui-select-wrapper';

      const setFontFamily = window
        .getComputedStyle(trait.target.view.el)
        .getPropertyValue('font-family');

      // Check if the font-family is enclosed in quotes
      const extractedFontFamily =
        setFontFamily.startsWith('"') || setFontFamily.startsWith("'")
          ? setFontFamily.split(',')[0].trim().replace(/['"]/g, '')
          : setFontFamily.split(',')[0].trim();

      // React component for Font Family Select
      const MUISelect = () => {
        const [value, setValue] = React.useState(
          extractedFontFamily || 'Arial',
        );

        const handleChange = (event) => {
          setValue(event.target.value);
          trait.target.addStyle({
            'font-family': `'${event.target.value}'`,
          });
        };

        const fonts = [
          'Arial',
          'Arial Black',
          'Brush Script MT',
          'Comic Sans MS',
          'Courier New',
          'Georgia',
          'Helvetica',
          'Impact',
          'Lucida Sans Unicode',
          'Tahoma',
          'Times New Roman',
          'Trebuchet MS',
          'Verdana',
        ];

        return (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={value}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {fonts.map((font) => (
                <MenuItem key={font} value={font}>
                  {font}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      };

      // Render the React component inside the GrapesJS environment
      ReactDOM.render(<MUISelect />, el);

      return el;
    },
  });

  traitManager.addType('table-font-family', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'mui-select-wrapper';

      const getTableComponent = () => {
        const target = trait.target;
        const targetComp = target.components().at(0);
        return targetComp?.is('table') ? targetComp : target.closest('table');
      };

      const table = getTableComponent();
      // React component for MUI Select
      const MUISelect = () => {
        const [value, setValue] = React.useState(
          table.getStyle('font-family') || `Arial`,
        );

        const handleChange = (event) => {
          setValue(event.target.value);
          table.addStyle({
            'font-family': `${event.target.value}`,
          });
        };

        const fonts = [
          'Arial',
          'Arial Black',
          'Brush Script MT',
          'Comic Sans MS',
          'Courier New',
          'Georgia',
          'Helvetica',
          'Impact',
          'Lucida Sans Unicode',
          'Tahoma',
          'Times New Roman',
          'Trebuchet MS',
          'Verdana',
        ];

        return (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={value}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {fonts.map((font) => (
                <MenuItem key={font} value={font}>
                  {font}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      };

      // Render the React component inside the GrapesJS environment
      ReactDOM.render(<MUISelect />, el);

      return el;
    },
  });

  traitManager.addType('line-height', {
    createInput({ trait }) {
      const setLineHeight = window
        .getComputedStyle(trait.target.view.el)
        .getPropertyValue('line-height');
      // Create the input element
      const el = document.createElement('input');
      el.type = 'number';
      el.min = 0; // Minimum line height
      el.value = setLineHeight.replace('px', '') || '20';
      el.className = 'input-trait';

      // Update the component style when the trait value changes
      el.addEventListener('input', (event) => {
        const lineHeight = event.target.value;
        trait.target.addStyle({
          'line-height': lineHeight + 'px',
        });
      });

      return el;
    },
  });

  traitManager.addType('table-line-height', {
    createInput({ trait }) {
      // Create the input element
      const getTableComponent = () => {
        const target = trait.target;
        const targetComp = target.components().at(0);
        return targetComp?.is('table') ? targetComp : target.closest('table');
      };

      const table = getTableComponent();
      const el = document.createElement('input');
      el.type = 'number';
      el.min = 0; // Minimum line height
      el.value = table.getStyle('line-height')?.slice(0, -2) || '16';
      el.className = 'input-trait';

      // Update the component style when the trait value changes
      el.addEventListener('input', (event) => {
        const lineHeight = event.target.value;
        table.addStyle({
          'line-height': lineHeight + 'px',
        });
      });

      return el;
    },
  });

  traitManager.addType('element-float', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'element-float-container float-container';

      // Define the float options
      const floats = [
        { value: 'left', name: 'Left' },
        { value: 'center', name: 'Center' }, // For center, we use 'none'
        { value: 'right', name: 'Right' },
      ];

      const floatSelected = window
        .getComputedStyle(trait.target.view.el)
        .getPropertyValue('text-align');

      floats.forEach((floatOption) => {
        const button = document.createElement('button');
        button.className =
          floatOption.value === floatSelected
            ? `float-button float-${floatOption.value} float-selected`
            : `float-button float-${floatOption.value}`;
        button.textContent = '';
        button.addEventListener('click', () => {
          document.querySelectorAll('.float-button').forEach((btn) => {
            btn.classList.remove('float-selected');
          });
          button.classList.add('float-selected');

          trait.target.addStyle({
            'text-align': floatOption.value,
            // display: "block", // Ensuring the element is a block-level element
          });
        });

        el.appendChild(button);
      });

      return el;
    },
  });

  traitManager.addType('info-link', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'info-link-container';

      // Create the message text
      const message = document.createElement('p');
      message.textContent =
        'This is a global block. If you want to make any changes. Go to ';

      // Create the link element
      const link = document.createElement('a');
      link.textContent = 'Brand Style';
      link.href = '#';
      link.style.color = '#ff385c';

      // Event listener to open the brandStyles overlay
      link.addEventListener('click', (e) => {
        e.preventDefault();
        dispatch(toggleBrandStyle());
      });

      // Append the message and link to the container
      el.appendChild(message);
      message.appendChild(link);

      return el;
    },
  });

  traitManager.addType('table-position', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'element-float-container float-container';

      const getTableComponent = () => {
        const target = trait.target;
        const targetComp = target.components().at(0);
        return targetComp?.is('table') ? targetComp : target.closest('table');
      };

      const table = getTableComponent();
      const marginLeft = table.getStyle('margin-left');
      const marginRight = table.getStyle('margin-right');
      let floatValue;

      if (marginLeft === 'auto' && marginRight === 'auto') {
        floatValue = 'center';
      } else if (marginRight === '0' && marginLeft === 'auto') {
        floatValue = 'right';
      } else {
        floatValue = 'left';
      }

      // Define the float options
      const floats = [
        {
          value: 'left',
          name: 'Left',
          styles: { 'margin-left': '0', 'margin-right': 'auto' },
        },
        {
          value: 'center',
          name: 'Center',
          styles: { 'margin-left': 'auto', 'margin-right': 'auto' },
        }, // For center, we use 'none'
        {
          value: 'right',
          name: 'Right',
          styles: { 'margin-left': 'auto', 'margin-right': '0' },
        },
      ];

      floats.forEach((floatOption) => {
        const button = document.createElement('button');
        button.className =
          floatOption.value === floatValue
            ? `float-button float-${floatOption.value} float-selected`
            : `float-button float-${floatOption.value}`;
        button.textContent = '';
        button.addEventListener('click', () => {
          document.querySelectorAll('.float-button').forEach((btn) => {
            btn.classList.remove('float-selected');
          });
          button.classList.add('float-selected');

          const table = getTableComponent();
          table.addStyle({
            'margin-left': floatOption.styles['margin-left'],
            'margin-right': floatOption.styles['margin-right'],
          });
        });

        el.appendChild(button);
      });

      return el;
    },
  });

  /* Traits for Table Block */
  traitManager.addType('table-actions', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'table-actions-container';

      // Helper function to create buttons
      function createButton(text, onClickFunction, icon) {
        const btn = document.createElement('button');
        btn.type = 'button';
        btn.className = 'btn-action'; // Add a class for potential styling
        btn.onclick = onClickFunction;
        // Create an image element for the SVG icon
        const img = document.createElement('img');
        img.src = icon; // Path to your SVG icon
        img.alt = text; // Alternative text for the icon
        img.className = 'btn-svg-icon'; // Add a class for potential styling

        btn.appendChild(img);
        // Append the text node to the button
        const textNode = document.createTextNode(' ' + text);
        btn.appendChild(textNode);

        return btn;
      }

      // Create buttons for each action
      const btnAddRowAbove = createButton(
        'Add Row Above',
        () => {
          const selectedComponent = grapesjsEditor.getSelected();
          addRowTableTop(selectedComponent, grapesjsEditor);
        },
        addRowTop,
      );

      const btnAddRowBelow = createButton(
        'Add Row Bottom',
        () => {
          const selectedComponent = grapesjsEditor.getSelected();
          addRowTableBottom(selectedComponent, grapesjsEditor);
        },
        addRowBottom,
      );

      const btnAddColLeft = createButton(
        'Add Column Left',
        () => {
          const selectedComponent = grapesjsEditor.getSelected();
          addColTableLeft(selectedComponent, grapesjsEditor);
        },
        addColTop,
      );

      const btnAddColRight = createButton(
        'Add Column Right',
        () => {
          const selectedComponent = grapesjsEditor.getSelected();
          addColTableRight(selectedComponent, grapesjsEditor);
        },
        addColBottom,
      );

      const btnDeleteColumn = createButton(
        'Delete Column',
        () => {
          const selectedComponent = grapesjsEditor.getSelected();
          deleteTableColumn(selectedComponent, grapesjsEditor);
        },
        removeCol,
      );

      const btnDeleteRow = createButton(
        'Delete Row',
        () => {
          const selectedComponent = grapesjsEditor.getSelected();
          deleteTableRow(selectedComponent, grapesjsEditor);
        },
        removeRow,
      );

      // Append all buttons to the main container
      el.appendChild(btnAddRowAbove);
      el.appendChild(btnAddRowBelow);
      el.appendChild(btnAddColLeft);
      el.appendChild(btnAddColRight);
      el.appendChild(btnDeleteColumn);
      el.appendChild(btnDeleteRow);

      return el;
    },
  });

  traitManager.addType('table-heading-text-color', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'my-color-picker-trait';

      const getTableComponent = () => {
        const target = trait.target;
        const targetComp = target.components().at(0);
        return targetComp?.is('table') ? targetComp : target.closest('table');
      };

      const table = getTableComponent();
      // Get the selected component and find all <th> elements
      const thElements = table.find('th');
      const initialColor = thElements[0]?.getStyle('color')
        ? thElements[0].getStyle('color') // Get the color of the first <th>
        : '#000000'; // Default color if no <th> found or no color set

      const handleColorChange = (color) => {
        // Apply the chosen color to all <th> elements
        thElements.forEach((th) => {
          th.addStyle('color', color);
        });
      };

      // Assuming you have a ColorPicker React component
      ReactDOM.render(
        <ColorPicker
          id="table-heading-text-color"
          initialColor={initialColor}
          onChange={handleColorChange}
          isGradient={true}
          brandColors={brandColors}
        />,
        el,
      );

      return el;
    },
  });

  traitManager.addType('table-heading-background-color', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'my-color-picker-trait';

      const getTableComponent = () => {
        const target = trait.target;
        const targetComp = target.components().at(0);
        return targetComp?.is('table') ? targetComp : target.closest('table');
      };

      const table = getTableComponent();
      // Get the selected component and find all <th> elements
      const thElements = table.find('th');
      const initialColor = thElements[0]?.getStyle('background')
        ? thElements[0].getStyle('background')
        : '#f6f6f6';
      const handleColorChange = (color) => {
        // Apply the chosen color to all <th> elements
        thElements.forEach((th) => {
          // th.style.backgroundColor = color;
          th.addStyle('background', color);
        });
      };

      // Assuming you have a ColorPicker React component
      ReactDOM.render(
        <ColorPicker
          id="table-heading-background-color"
          initialColor={initialColor}
          onChange={handleColorChange}
          brandColors={brandColors}
        />,
        el,
      );

      return el;
    },
  });

  traitManager.addType('column-padding', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'paddingContainer lock-trait-container';

      // Create padding inputs for each side
      ['top', 'right', 'bottom', 'left'].forEach((side) => {
        const div = document.createElement('div');
        div.className = 'padding-box lock-input-box';

        const label = document.createElement('div');
        label.className = 'padding-label lock-input-label';
        label.textContent = side;

        const input = document.createElement('input');
        input.className = 'padding-input lock-input';
        input.type = 'number';
        input.id = `column-padding-${side}`;
        input.setAttribute('data-side', side); // Simplified data attribute
        input.min = 0;
        if (side === 'top' || side === 'bottom') input.value = '8';
        if (side === 'left' || side === 'right') input.value = '16';
        div.appendChild(input);
        div.appendChild(label);
        el.appendChild(div);
      });

      // Create the lock button
      const lockButton = document.createElement('button');
      lockButton.className = 'padding-lock-btn lock-btn unlocked'; // Initial state as unlocked
      lockButton.id = 'toggle-lock';
      lockButton.textContent = ''; // Set initial text for the button
      el.appendChild(lockButton);

      // Initial state
      let isLocked = false; // Start as unlocked

      // Function to update padding values
      function updateColumnPadding(side, value) {
        const getTableComponent = () => {
          const target = trait.target;
          const targetComp = target.components().at(0);
          return targetComp?.is('table') ? targetComp : target.closest('table');
        };

        const table = getTableComponent();

        if (table) {
          const query = `td, th`; // Target all td and th elements
          table.find(query).forEach((cell) => {
            let style = {};
            style[`padding-${side}`] = `${value}px`;
            cell.addStyle(style);
          });
        }
      }

      // Function to handle input changes
      function handleInputChange(event) {
        const paddingValue = event.target.value;
        const paddingSide = event.target.getAttribute('data-side');

        if (isLocked) {
          ['top', 'right', 'bottom', 'left'].forEach((side) => {
            const id = `column-padding-${side}`;
            el.querySelector(`#${id}`).value = paddingValue;
            updateColumnPadding(side, paddingValue);
          });
        } else {
          updateColumnPadding(paddingSide, paddingValue);
        }
      }

      // Attach the event listener to each input
      el.querySelectorAll('.padding-input').forEach((input) => {
        input.addEventListener('input', handleInputChange);
      });

      // Toggle button functionality
      lockButton.addEventListener('click', function () {
        isLocked = !isLocked;
        this.classList.toggle('locked');
        this.classList.toggle('unlocked');
      });

      return el;
    },
  });

  traitManager.addType('menu-link-padding', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'paddingContainer lock-trait-container';

      // Create padding inputs for each side
      ['top', 'right', 'bottom', 'left'].forEach((side) => {
        const div = document.createElement('div');
        div.className = 'padding-box lock-input-box';

        const label = document.createElement('div');
        label.className = 'padding-label lock-input-label';
        label.textContent = side;

        const input = document.createElement('input');
        input.className = 'padding-input lock-input';
        input.type = 'number';
        input.id = `menu-link-padding-${side}`;
        input.setAttribute('data-side', side); // Simplified data attribute
        input.min = 0;
        if (side === 'top' || side === 'bottom') input.value = '8';
        if (side === 'left' || side === 'right') input.value = '16';
        div.appendChild(input);
        div.appendChild(label);
        el.appendChild(div);
      });

      // Create the lock button
      const lockButton = document.createElement('button');
      lockButton.className = 'padding-lock-btn lock-btn unlocked'; // Initial state as unlocked
      lockButton.id = 'toggle-lock';
      lockButton.textContent = ''; // Set initial text for the button
      el.appendChild(lockButton);

      // Initial state
      let isLocked = false; // Start as unlocked

      // Function to update padding values for <a> tags
      function updateMenuLinkPadding(side, value) {
        const links = trait.target.find('a');
        links.forEach((link) => {
          const child = link.components().at(0);
          let style = {};
          style[`padding-${side}`] = `${value}px`;
          child.addStyle(style);
        });
      }

      // Function to handle input changes
      function handleInputChange(event) {
        const paddingValue = event.target.value;
        const paddingSide = event.target.getAttribute('data-side');

        if (isLocked) {
          ['top', 'right', 'bottom', 'left'].forEach((side) => {
            const id = `menu-link-padding-${side}`;
            el.querySelector(`#${id}`).value = paddingValue;
            updateMenuLinkPadding(side, paddingValue);
          });
        } else {
          updateMenuLinkPadding(paddingSide, paddingValue);
        }
      }

      // Attach the event listener to each input
      el.querySelectorAll('.padding-input').forEach((input) => {
        input.addEventListener('input', handleInputChange);
      });

      // Toggle button functionality
      lockButton.addEventListener('click', function () {
        isLocked = !isLocked;
        this.classList.toggle('locked');
        this.classList.toggle('unlocked');
      });

      return el;
    },
  });

  traitManager.addType('enable-seperator', {
    createInput({ trait }) {
      const traitContainer = document.createElement('div');
      traitContainer.className = 'traitContainer';

      const switcher = document.createElement('div');
      switcher.className = 'switcher';
      // Label for Enable Separator Toggle
      const labelEnableSeparator = document.createElement('label');
      labelEnableSeparator.className = 'enable-separator-label';
      labelEnableSeparator.innerText = 'Enable Separator';

      // Toggle Container
      const togglerContainer = document.createElement('label');
      togglerContainer.className = 'switch';

      // Toggle Input
      const toggler = document.createElement('input');
      toggler.type = 'checkbox';
      toggler.className = 'checkbox';
      toggler.style.display = 'none';

      // Toggler Styling Span (The Slider)
      const sliderSpan = document.createElement('span');
      sliderSpan.className = 'slider round';

      togglerContainer.appendChild(toggler);
      togglerContainer.appendChild(sliderSpan);

      // Separator Settings Container
      const separatorSettingsContainer = document.createElement('div');
      separatorSettingsContainer.className = 'separator-settings-container';
      separatorSettingsContainer.style.display = 'none';

      const separatorStyle = document.createElement('div');
      separatorStyle.className = 'separator-style';

      // Label for Separator Value Input
      const labelSeparatorInput = document.createElement('label');
      labelSeparatorInput.innerText = 'Separator';
      labelSeparatorInput.className = 'enable-separator-label';

      // Separator Value Input
      const separatorInput = document.createElement('input');
      separatorInput.type = 'text';
      separatorInput.className = 'separator-input input-trait';
      separatorInput.placeholder = "Separator Value (e.g., '|')";
      separatorInput.value = separatorInput.value || '|';

      const separatorColor = document.createElement('div');
      separatorColor.className = 'separator-style';

      // Label for Separator Value Input
      const labelColorPicker = document.createElement('label');
      labelColorPicker.innerText = 'Separator Color';
      labelColorPicker.className = 'enable-separator-label';

      const colorPicker = document.createElement('div');
      colorPicker.className = 'my-color-picker-trait';

      // Append inputs and labels to their container
      separatorStyle.appendChild(labelSeparatorInput);
      separatorStyle.appendChild(separatorInput);
      separatorSettingsContainer.appendChild(separatorStyle);
      separatorColor.appendChild(labelColorPicker);
      separatorColor.appendChild(colorPicker);
      separatorSettingsContainer.appendChild(separatorColor);

      toggler.addEventListener('change', () => {
        separatorSettingsContainer.style.display = toggler.checked
          ? 'block'
          : 'none';
        updateSeparator(separatorInput.value, colorPicker.color);
      });

      separatorInput.addEventListener('input', () => {
        updateSeparator(separatorInput.value, colorPicker.color);
      });

      function updateSeparator(value, color) {
        const links = trait.target.find('a');
        links.forEach((link, index) => {
          let nextSibling = link.view.el.nextSibling;
          if (index !== links.length - 1) {
            // Check if not the last link
            if (!nextSibling || nextSibling.className !== 'separator-span') {
              nextSibling = document.createElement('span');
              nextSibling.className = 'separator-span';
              link.view.el.parentNode.insertBefore(
                nextSibling,
                link.view.el.nextSibling,
              );
            }
            nextSibling.textContent = value;
            nextSibling.style.color = color;
            nextSibling.style.display = toggler.checked ? 'inline' : 'none';
          } else if (
            nextSibling &&
            nextSibling.className === 'separator-span'
          ) {
            // If it is the last link and a separator exists, hide it
            nextSibling.style.display = 'none';
          }
        });
      }

      // React component integration for color picker
      const handleColorChange = (color) => {
        updateSeparator(separatorInput.value, color);
      };

      ReactDOM.render(
        <ColorPicker
          id="enable-seperator"
          initialColor="#000000"
          onChange={handleColorChange}
          isGradient={true}
          brandColors={brandColors}
        />,
        colorPicker,
      );

      switcher.appendChild(labelEnableSeparator);
      switcher.appendChild(togglerContainer);
      traitContainer.appendChild(switcher);
      traitContainer.appendChild(separatorSettingsContainer);

      return traitContainer;
    },
  });

  traitManager.addType('table-padding-control', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'paddingContainer lock-trait-container';

      const getTableComponent = () => {
        const target = trait.target;
        const targetComp = target.components().at(0);
        return targetComp?.is('table') ? targetComp : target.closest('table');
      };

      // Create padding inputs for each side
      ['top', 'right', 'bottom', 'left'].forEach((side) => {
        const div = document.createElement('div');
        div.className = 'padding-box lock-input-box';

        const label = document.createElement('div');
        label.className = 'padding-label lock-input-label';
        label.textContent = side;

        const input = document.createElement('input');
        input.className = 'padding-input lock-input';
        input.type = 'number';
        input.id = `padding-${side}`;
        input.setAttribute('data-side', `padding-${side}`);
        input.min = 0;
        input.value =
          trait.target.getStyle(`padding-${side}`)?.slice(0, -2) || '16';

        div.appendChild(input);
        div.appendChild(label);
        el.appendChild(div);
      });

      // Create the lock button
      const lockButton = document.createElement('button');
      lockButton.className = 'padding-lock-btn lock-btn locked';
      lockButton.id = 'toggle-lock';
      lockButton.textContent = ''; // Set initial text for the button
      el.appendChild(lockButton);

      // Initial state
      let isLocked = true;

      // Function to update padding values
      function updatePadding(id, value) {
        const table = getTableComponent();
        if (table) {
          let style = {};
          style[id] = `${value}px`;
          table.parent().addStyle(style);
        }
      }

      // Function to handle input changes
      function handleInputChange(event) {
        const paddingValue = event.target.value;
        const paddingId = event.target.getAttribute('data-side');

        if (isLocked) {
          ['top', 'right', 'bottom', 'left'].forEach((side) => {
            const id = `padding-${side}`;
            el.querySelector(`#${id}`).value = paddingValue;
            updatePadding(id, paddingValue);
          });
        } else {
          updatePadding(paddingId, paddingValue);
        }
      }

      // Attach the event listener to each input
      el.querySelectorAll('.padding-input').forEach((input) => {
        input.addEventListener('input', handleInputChange);
      });

      // Toggle button functionality
      lockButton.addEventListener('click', function () {
        isLocked = !isLocked;
        this.textContent = isLocked ? '' : '';
        this.classList.toggle('locked');
        this.classList.toggle('unlocked');
      });

      return el;
    },
  });
  /* Traits for Social Block */

  traitManager.addType('padding-control', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'paddingContainer lock-trait-container';

      const selectedComponent = grapesjsEditor.getSelected();
      ['top', 'right', 'bottom', 'left'].forEach((side) => {
        const div = document.createElement('div');
        div.className = 'padding-box lock-input-box';

        const label = document.createElement('div');
        label.className = 'padding-label lock-input-label';
        label.textContent = side;

        const input = document.createElement('input');
        input.className = 'padding-input lock-input';
        input.type = 'number';
        input.id = `padding-${side}`;
        input.setAttribute('data-side', `padding-${side}`);
        input.min = 0;
        input.value =
          window
            .getComputedStyle(trait.target.view.el)
            .getPropertyValue(`padding-${side}`)
            ?.slice(0, -2) || 0;

        div.appendChild(input);
        div.appendChild(label);
        el.appendChild(div);
      });

      const lockButton = document.createElement('button');
      lockButton.className = 'padding-lock-btn lock-btn locked';
      lockButton.id = 'toggle-lock';
      lockButton.textContent = '';
      el.appendChild(lockButton);

      let isLocked = true;
      // if (selectedComponent.views[0]?.attr.type === "row") {
      //   isLocked = rowPaddingLock;
      // } else {
      //   isLocked = true;
      // }

      function updatePadding(id, value) {
        const selected = grapesjsEditor.getSelected();
        if (selected) {
          let style = {};
          style[id] = `${value}px`;
          selected.addStyle(style);
        }
      }

      function handleInputChange(event) {
        const paddingValue = event.target.value;
        const paddingId = event.target.getAttribute('data-side');

        if (isLocked) {
          ['top', 'right', 'bottom', 'left'].forEach((side) => {
            const id = `padding-${side}`;
            el.querySelector(`#${id}`).value = paddingValue;
            updatePadding(id, paddingValue);
          });
        } else {
          updatePadding(paddingId, paddingValue);
        }
      }

      el.querySelectorAll('.padding-input').forEach((input) => {
        input.addEventListener('input', handleInputChange);
      });

      lockButton.addEventListener('click', function () {
        isLocked = !isLocked;
        this.textContent = isLocked ? '' : '';
        this.classList.toggle('locked');
        this.classList.toggle('unlocked');
        // if (selectedComponent.views[0]?.attr.type === "row") {
        //   dispatch(toggleRowPaddingLock());
        // }
      });

      return el;
    },
  });

  traitManager.addType('trait-container', {
    noLabel: true,

    createInput({ trait }) {
      const el = document.createElement('div');
      el.id = trait.get('id') || 'traits-container';
      el.className = 'traits-container';
      return el;
    },
  });

  traitManager.addType('imageLink-combined', {
    createInput({ trait }) {
      // Create a container element to hold both the input and the button
      const container = document.createElement('div');
      container.className = 'imageLink-container';
      const index = trait.get('index');

      // Create the input element
      const input = document.createElement('input');
      input.type = 'text';
      input.value = trait.get('value');
      input.className = 'input-trait';
      input.style.flex = '1'; // Allows the input to expand to fill space
      input.placeholder = 'Enter URL';

      const outerDiv = document.createElement('div');
      outerDiv.className = 'delete-label-button';

      // Create a label for the delete button
      const label = document.createElement('span');

      // Create the delete button
      const btn = document.createElement('button');
      btn.type = 'button';
      // Append elements to the container
      outerDiv.appendChild(label);
      outerDiv.appendChild(btn);
      container.appendChild(outerDiv);
      container.appendChild(input);
      // Function to update button text based on the alt text of the linked image
      const updateButtonLabel = () => {
        const selectedComponent = grapesjsEditor.getSelected();
        const imgIcon = selectedComponent.find('a')[index].components().at(0)
          .attributes.attributes.src;
        const labelText = selectedComponent.find('a')[index].components().at(0)
          .attributes.attributes.alt;
        const rearrangeImg = document.createElement('img');

        // Create a container element to hold both the image and label
        const container = document.createElement('div');
        container.style.cursor = 'move';
        rearrangeImg.src = rearrageIcon;
        rearrangeImg.style.width = '16px';
        rearrangeImg.style.background = 'none';
        rearrangeImg.style.marginRight = '4px';
        container.appendChild(rearrangeImg);
        // Create the image element
        const image = document.createElement('img');
        image.src = imgIcon;
        image.style.width = '16px';
        image.style.borderRadius = '4px';
        image.style.marginRight = '4px';

        // Append the image and label to the container
        container.appendChild(image);
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.justifyContent = 'center';

        // Create a text node for the label
        const labelTextNode = document.createTextNode(labelText);

        // Append the label text node to the container
        container.appendChild(labelTextNode);
        label.appendChild(container);
        // label.textContent = labelText;
      };

      // Initial update of the button label
      updateButtonLabel();
      // Add event listener to input for changes
      input.addEventListener('change', (e) => {
        const value = e.target.value;
        onImageLinkInputChange(grapesjsEditor, trait, value);
      });

      // Add event listener to button for click action
      btn.onclick = () => {
        onDeleteImageLinkClick(grapesjsEditor, trait);
        container.parentNode.removeChild(container);
      };

      appendToDiv(trait, container);

      return container;
    },
  });

  traitManager.addType('add-imageLink', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'mui-select-wrapper';

      // Extract social links from the selected component
      const currentSocialLinks = [];
      const links = trait.target.find('a');

      links.forEach((link) => {
        const img = link
          .get('components')
          .models.find((component) => component.is('image'));
        if (img) {
          currentSocialLinks.push({
            icon: img.get('src'),
            altText: img.get('alt') || 'Social Icon',
          });
        }
      });

      // Render the React component inside the GrapesJS environment
      ReactDOM.render(
        <MUISelect currentSocialLinks={currentSocialLinks} />,
        el,
      );

      return el;
    },
  });

  const SocialPalette = ({ onSelect, currentSocialLinks }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const renderIcon = (link, index) => {
      const selectedComponent = grapesjsEditor.getSelected();
      const imgIcon = selectedComponent.find('a')[index].components().at(0)
        .attributes.attributes.src;
      const imgIconBorRad = selectedComponent.find('a')[0].components().at(0)
        .view.el.style.borderRadius;

      return (
        <img
          key={index}
          src={imgIcon}
          alt={link.altText}
          style={{ borderRadius: imgIconBorRad ? imgIconBorRad : '50%' }}
        />
      );
    };

    return (
      <>
        <div className="social-trait">
          <span
            className="social-input-trait"
            style={{ display: currentSocialLinks.length > 4 ? 'grid' : 'flex' }}
          >
            {currentSocialLinks.map((link, index) => renderIcon(link, index))}
          </span>
          <button
            className="social-input-button"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <img src={iconAdd} alt="" />
          </button>
        </div>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(5, 1fr)',
              gap: 1,
              p: 1,
            }}
          >
            {socialTypes.map((social) => (
              <IconButton
                key={social.id}
                onClick={() => {
                  onSelect(social.id);
                  setAnchorEl(null);
                }}
                sx={{
                  width: '100% !important',
                  height: '100% !important',
                }}
              >
                <img src={social.icon} height={30} width={30} alt="" />
              </IconButton>
            ))}
          </Box>
        </Popover>
      </>
    );
  };

  const MUISelect = ({ currentSocialLinks }) => {
    const handleSelect = (id) => {
      const selectedSocial = socialTypes.find((s) => s.id === id);
      if (selectedSocial) {
        addImageLinkToSocialBlock(grapesjsEditor, selectedSocial);
      }
    };

    return (
      <SocialPalette
        onSelect={handleSelect}
        currentSocialLinks={currentSocialLinks}
      />
    );
  };

  traitManager.addType('image-width', {
    createInput({ trait }) {
      const el = document.createElement('input');
      el.type = 'number';
      el.min = '1';
      el.max = '56';
      el.className = 'input-trait';
      el.value =
        trait.target
          ?.find('a')[0]
          .find('img')[0]
          .getStyle('width')
          ?.slice(0, -2) || '24';
      el.addEventListener('change', (e) => {
        const width = Math.max(el.min, Math.min(e.target.value, el.max));
        updateSocialImagesWidth(grapesjsEditor, width);
      });
      return el;
    },
  });

  traitManager.addType('image-link-gap', {
    createInput({ trait }) {
      const el = document.createElement('input');
      el.type = 'number';
      el.className = 'input-trait';
      el.value =
        trait.target.find('a')[0].getStyle('margin-right')?.slice(0, -2) || '4';
      el.min = 0; // Minimum gap
      el.max = 48; // Maximum gap, adjust as needed

      el.addEventListener('change', (e) => {
        const gap = Math.max(el.min, Math.min(e.target.value, el.max));
        updateImageLinkGap(grapesjsEditor, gap);
      });
      return el;
    },
  });

  traitManager.addType('icon-style', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'mui-select-wrapper';

      // React component for MUI Select
      const MUISelect = () => {
        const [value, setValue] = React.useState(
          window.currentIconStyle || 'circle', // Adjust this if you need an initial value based on target style
        );

        const handleChange = (event) => {
          const newValue = event.target.value;
          setValue(newValue);
          updateIconStyle(grapesjsEditor, newValue);
        };

        return (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={value}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Select Icon Style' }}
            >
              <MenuItem value="circle">Circle</MenuItem>
              <MenuItem value="circle-black">Circle Black</MenuItem>
              <MenuItem value="round">Round</MenuItem>
              <MenuItem value="round-black">Round Black</MenuItem>
              <MenuItem value="square">Square</MenuItem>
              <MenuItem value="square-black">Square Black</MenuItem>
            </Select>
          </FormControl>
        );
      };

      // Render the React component inside the GrapesJS environment
      ReactDOM.render(<MUISelect />, el);

      return el;
    },
  });

  /* Traits for Timer Block */

  traitManager.addType('card-bgcolor', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'my-color-picker-trait';
      const allChild =
        trait.target.view.el.querySelectorAll('.countdown-digit');
      const firstChild = allChild[0];
      const initialColor = firstChild?.style.backgroundColor || '#666666';

      const handleColorChange = (color) => {
        allChild.forEach((child) => {
          child.style.backgroundColor = color;
        });
      };

      ReactDOM.render(
        <ColorPicker
          id="card-bgcolor"
          initialColor={initialColor || '#ffffff'}
          onChange={handleColorChange}
          isGradient={true}
          brandColors={brandColors}
        />,
        el,
      );

      return el;
    },
  });

  //Custom trait for date selection
  traitManager.addType('datetime-local', {
    createInput({ trait }) {
      const el = document.createElement('input');
      el.type = 'datetime-local';
      el.value = trait.get('value');

      // Attach event listener for change
      el.addEventListener('change', (event) => {
        const selectedComponent = grapesjsEditor.getSelected();
        selectedComponent.addAttributes({
          'data-datetime': event.target.value,
        });
        restartCountdown(selectedComponent);
      });

      return el;
    },
  });

  //Custom trait for language selection
  traitManager.addType('select-language', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'mui-select-wrapper';

      // Access the data-timezone attribute value
      const languageAttribute =
        trait.target.view.el.attributes['data-language'];
      const initialLanguage = languageAttribute
        ? languageAttribute.value
        : 'english';

      // React component for MUI Select
      const MUISelect = () => {
        const [value, setValue] = React.useState(initialLanguage);

        const handleChange = (event) => {
          setValue(event.target.value);
          const selectedComponent = grapesjsEditor.getSelected();
          selectedComponent.addAttributes({
            'data-language': event.target.value,
          });
          // restartCountdown function should be defined somewhere in your script
          restartCountdown(selectedComponent);
        };

        // List of languages
        const languages = [
          'Arabic',
          'Chinese',
          'Czech',
          'Danish',
          'Dutch',
          'English',
          'Estonian',
          'Farsi',
          'Finnish',
          'French',
          'German',
          'Italian',
          'Korean',
          'Polish',
          'Portuguese',
          'Russian',
          'Spanish',
          'Swedish',
          'Turkish',
          'Hindi',
        ].map((lang) => ({ value: lang.toLowerCase(), name: lang }));

        return (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={value}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Select language' }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang.value} value={lang.value}>
                  {lang.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      };

      // Render the React component inside the GrapesJS environment
      ReactDOM.render(<MUISelect />, el);

      return el;
    },
  });

  //Custom trait for timezone selection
  traitManager.addType('select-timezone', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'mui-select-wrapper';

      // Access the data-timezone attribute value
      const timezoneAttribute =
        trait.target.view.el.attributes['data-timezone'];
      const initialTimezone = timezoneAttribute ? timezoneAttribute.value : '';

      // React component for MUI Select
      const MUISelect = () => {
        const [value, setValue] = React.useState(initialTimezone);

        const handleChange = (event) => {
          setValue(event.target.value);
          const selectedComponent = grapesjsEditor.getSelected();
          selectedComponent.addAttributes({
            'data-timezone': event.target.value,
          });
          // restartCountdown function should be defined somewhere in your script
          restartCountdown(selectedComponent);
        };

        // Get list of timezones using Intl API
        const timezones = Intl.supportedValuesOf('timeZone');

        return (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={value}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Select timezone' }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {timezones.map((tz) => (
                <MenuItem key={tz} value={tz}>
                  {tz}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      };

      // Render the React component inside the GrapesJS environment
      ReactDOM.render(<MUISelect />, el);

      return el;
    },
  });

  traitManager.addType('timer-digit-color', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'my-color-picker-trait';

      // Retrieve the initial color of the first "countdown-digit" element
      const firstCountdownDigit =
        trait.target.view.el.querySelector('.countdown-digit');
      const initialColor = firstCountdownDigit.style.color || '#ffffff';

      const handleColorChange = (color) => {
        // Get all children elements with the class "countdown-digit"
        const countdownDigits =
          trait.target.view.el.querySelectorAll('.countdown-digit');

        // Apply the selected color to each of these elements
        countdownDigits.forEach((digitElement) => {
          digitElement.style.color = color;
        });
      };

      ReactDOM.render(
        <ColorPicker
          id="timer-digit-color"
          initialColor={initialColor}
          onChange={handleColorChange}
          isGradient={true}
          brandColors={brandColors}
        />,
        el,
      );

      return el;
    },
  });

  traitManager.addType('timer-label-color', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'my-color-picker-trait';

      // Retrieve the initial color of the first "countdown-digit" element
      const firstCountdownLabel =
        trait.target.view.el.querySelector('.countdown-label');
      const initialColor = firstCountdownLabel.style.color || '#ffffff';

      const handleColorChange = (color) => {
        // Get all children elements with the class "countdown-digit"
        const countdownLabels =
          trait.target.view.el.querySelectorAll('.countdown-label');

        // Apply the selected color to each of these elements
        countdownLabels.forEach((label) => {
          label.style.color = color;
        });
      };

      ReactDOM.render(
        <ColorPicker
          id="timer-label-color"
          initialColor={initialColor}
          onChange={handleColorChange}
          isGradient={true}
          brandColors={brandColors}
        />,
        el,
      );

      return el;
    },
  });

  traitManager.addType('digit-font-size', {
    createInput({ trait }) {
      const el = document.createElement('div');
      const input = document.createElement('input');
      input.type = 'number';
      input.min = 1;
      input.max = 200;
      input.step = 1;
      input.className = 'input-trait';
      const firstCountdownDigit =
        trait.target.view.el.querySelector('.countdown-digit');
      if (firstCountdownDigit) {
        input.value = parseInt(
          window.getComputedStyle(firstCountdownDigit).fontSize,
          10,
        );
      }

      // Update font size on input change
      input.addEventListener('input', (event) => {
        const fontSizeValue = `${event.target.value}px`;
        const countdownDigits =
          trait.target.view.el.querySelectorAll('.countdown-digit');
        countdownDigits.forEach((digitElement) => {
          digitElement.style.fontSize = fontSizeValue;
        });
      });

      el.appendChild(input);
      return el;
    },
  });

  traitManager.addType('label-font-size', {
    createInput({ trait }) {
      const el = document.createElement('div');
      const input = document.createElement('input');
      input.type = 'number';
      input.min = 1;
      input.max = 200;
      input.step = 1;
      input.className = 'input-trait';
      const firstCountdownLabel =
        trait.target.view.el.querySelector('.countdown-label');
      if (firstCountdownLabel) {
        input.value = parseInt(
          window.getComputedStyle(firstCountdownLabel).fontSize,
          10,
        );
      }

      // Update font size on input change
      input.addEventListener('input', (event) => {
        const fontSizeValue = `${event.target.value}px`;
        const countdownLabels =
          trait.target.view.el.querySelectorAll('.countdown-label');
        countdownLabels.forEach((label) => {
          label.style.fontSize = fontSizeValue;
        });
      });

      el.appendChild(input);
      return el;
    },
  });

  traitManager.addType('view-mode-toggler', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'view-mode-toggler';
      const handleToggle = (event) => {
        const selectedComponent = grapesjsEditor.getSelected();
        const isOn = event.target.checked;
        const viewMode = isOn ? 'card' : 'text';
        selectedComponent.addAttributes({ 'data-view-mode': viewMode });
        selectedComponent.updateViewMode(viewMode);
        console.log('Update the timer to ', viewMode, 'after toggle');
      };

      const initialViewMode = trait.get('value') || 'text';
      const isOn = initialViewMode === 'card';

      ReactDOM.render(
        <CommonToggler
          isOn={isOn}
          onToggle={handleToggle}
          icons={{ on: 'Card', off: 'Text' }}
          type="view-mode-toggler"
          id="button-12"
        />,
        el,
      );

      return el;
    },
  });

  /* Traits for Menu Block */

  //Custom trait for add link button
  traitManager.addType('add-link-button', {
    createInput({ trait }) {
      const btn = document.createElement('button');
      btn.type = 'button';
      btn.textContent = 'Add Link';
      btn.onclick = () => {
        // Logic to add a new link goes here
        const selectedComponent = grapesjsEditor.getSelected();

        addLinkToMenu(selectedComponent, grapesjsEditor);
      };
      return btn;
    },
  });

  traitManager.addType('link-manager', {
    createInput({ trait }) {
      const index = trait.get('index');
      const wrapper = document.createElement('div');
      wrapper.className = 'link-manager-wrapper';

      const component = trait.target;
      const link = component.find('a')[index];
      const linkElement = link.getEl();

      // Button for deleting the link
      const labelBtn = document.createElement('div');
      labelBtn.className = 'label-button';

      const labelWithImg = document.createElement('div');
      labelWithImg.className = 'label-button-with-img';
      const rearrangeImg = document.createElement('img');
      rearrangeImg.src = rearrageIcon;
      // Label for the delete button
      const label = document.createElement('label');
      label.textContent = linkElement.textContent;
      label.htmlFor = `delete-button-${index}`;

      const btn = document.createElement('button');
      btn.type = 'button';
      btn.textContent = '';
      btn.id = `delete-button-${index}`;
      // btn.appendChild(label);

      btn.onclick = () => {
        deleteLinkFromMenu(grapesjsEditor, trait);
      };
      labelWithImg.appendChild(rearrangeImg);
      labelWithImg.appendChild(label);
      labelBtn.appendChild(labelWithImg);
      labelBtn.appendChild(btn);
      wrapper.appendChild(labelBtn);

      // Container for the select component
      const selectInputContainer = document.createElement('div');
      selectInputContainer.className = 'select-input-container';
      const selectContainer = document.createElement('div');
      selectContainer.className = 'mui-select-wrapper';
      selectInputContainer.appendChild(selectContainer);

      // Input for link details
      const input = document.createElement('input');
      input.type = 'text';
      input.className = 'input-trait';
      input.value = link?.getAttributes().href || '';
      input.placeholder = 'Enter URL';
      selectInputContainer.appendChild(input);

      wrapper.appendChild(selectInputContainer);
      // Containers for email subject and email content
      const emailSubject = document.createElement('input');
      emailSubject.type = 'text';
      emailSubject.className = 'input-trait';
      emailSubject.placeholder = 'Email Subject';
      emailSubject.style.display = 'none';
      // Initially hidden

      const emailContent = document.createElement('textarea');
      emailContent.className = 'input-trait';
      emailContent.placeholder = 'Email Content';
      emailContent.style.display = 'none'; // Initially hidden

      // React component for selecting link type
      const MUISelect = () => {
        const [type, setType] = React.useState(trait.get('value') || 'web');
        const [previousType, setPreviousType] = React.useState(
          trait.get('value') || 'web',
        );

        const handleTypeChange = (event) => {
          const newType = event.target.value;
          if (previousType === 'email' && newType !== 'email') {
            emailSubject.value = '';
            emailContent.value = '';
          }
          setType(newType);
          emailSubject.style.display = newType === 'email' ? '' : 'none';
          emailContent.style.display = newType === 'email' ? '' : 'none';
          input.placeholder = getPlaceholderByType(newType);
          trait.set('value', newType);
          setPreviousType(newType);
          const selectedComponent = grapesjsEditor.getSelected();
          updateLinkDetails(
            selectedComponent,
            parseInt(trait.get('name').split('-').pop()),
            newType,
            input.value,
            emailSubject.value,
            emailContent.value,
            grapesjsEditor,
          );
        };

        return (
          <FormControl fullWidth>
            <Select
              value={type}
              onChange={handleTypeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="web">Web</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="phone">Phone</MenuItem>
            </Select>
          </FormControl>
        );
      };

      ReactDOM.render(<MUISelect />, selectContainer);

      // Handle changes in the input fields
      const handleInputChange = () => {
        updateLinkDetails(
          component,
          parseInt(trait.get('name').split('-').pop()),
          trait.get('value') || 'web',
          input.value,
          emailSubject.value,
          emailContent.value,
          grapesjsEditor,
        );
        grapesjsEditor.trigger('component:update', component);
      };

      input.addEventListener('change', handleInputChange);
      emailSubject.addEventListener('input', handleInputChange);
      emailContent.addEventListener('input', handleInputChange);

      // Append email-specific fields
      wrapper.appendChild(emailSubject);
      wrapper.appendChild(emailContent);

      // Function to determine placeholder based on link type
      function getPlaceholderByType(type) {
        switch (type) {
          case 'web':
            return 'Enter URL';
          case 'phone':
            return 'Enter Phone Number';
          case 'email':
            return 'Enter Email ID';
          default:
            return 'Enter Value';
        }
      }

      appendToDiv(trait, wrapper);

      // MutationObserver to watch for changes in link text
      if (linkElement) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (
              mutation.type === 'characterData' ||
              mutation.type === 'childList'
            ) {
              label.textContent = linkElement.textContent; // Update the label text
            }
          });
        });

        observer.observe(linkElement, {
          childList: true,
          subtree: true,
          characterData: true,
        });
      }
      return wrapper;
    },
  });

  traitManager.addType('button-link-manager', {
    createInput({ trait }) {
      const wrapper = document.createElement('div');
      wrapper.className = 'link-manager-wrapper';

      const component = trait.target;
      const link = component.find('a')[0];

      // Container for the select component
      const selectInputContainer = document.createElement('div');
      selectInputContainer.className = 'select-input-container';
      const selectContainer = document.createElement('div');
      selectContainer.className = 'mui-select-wrapper';
      selectInputContainer.appendChild(selectContainer);

      // Input for link details
      const input = document.createElement('input');
      input.type = 'text';
      input.className = 'input-trait';
      input.value = link?.getAttributes().href || '';
      input.placeholder = 'Enter URL';
      selectInputContainer.appendChild(input);

      wrapper.appendChild(selectInputContainer);

      // Email Subject and Content inputs
      const emailSubject = document.createElement('input');
      emailSubject.type = 'text';
      emailSubject.className = 'input-trait';
      emailSubject.placeholder = 'Email Subject';
      emailSubject.style.display = 'none'; // Initially hidden

      const emailContent = document.createElement('textarea');
      emailContent.className = 'input-trait';
      emailContent.placeholder = 'Email Content';
      emailContent.style.display = 'none'; // Initially hidden

      // Append email-specific fields
      wrapper.appendChild(emailSubject);
      wrapper.appendChild(emailContent);

      // React component for selecting link type
      const MUISelect = () => {
        const [type, setType] = React.useState(trait.get('value') || 'web');
        const [previousType, setPreviousType] = React.useState(
          trait.get('value') || 'web',
        );

        const handleTypeChange = (event) => {
          const newType = event.target.value;
          if (previousType === 'email' && newType !== 'email') {
            emailSubject.value = '';
            emailContent.value = '';
          }
          setType(newType);
          emailSubject.style.display = newType === 'email' ? '' : 'none';
          emailContent.style.display = newType === 'email' ? '' : 'none';
          input.placeholder = getPlaceholderByType(newType);
          trait.set('value', newType);
          setPreviousType(newType);
          const selectedComponent = grapesjsEditor.getSelected();
          updateLinkDetails(
            selectedComponent,
            0,
            newType,
            input.value,
            emailSubject.value,
            emailContent.value,
            grapesjsEditor,
          );
        };

        return (
          <FormControl fullWidth>
            <Select
              value={type}
              onChange={handleTypeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="web">Web</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="phone">Phone</MenuItem>
            </Select>
          </FormControl>
        );
      };

      ReactDOM.render(<MUISelect />, selectContainer);

      // Handle changes in the input fields
      const handleInputChange = () => {
        updateLinkDetails(
          component,
          0, // Always 0 since there's only one link
          trait.get('value') || 'web',
          input.value,
          emailSubject.value,
          emailContent.value,
          grapesjsEditor,
        );
        grapesjsEditor.trigger('component:update', component);
      };

      input.addEventListener('change', handleInputChange);
      emailSubject.addEventListener('input', handleInputChange);
      emailContent.addEventListener('input', handleInputChange);

      // Function to determine placeholder based on link type
      function getPlaceholderByType(type) {
        switch (type) {
          case 'web':
            return 'Enter URL';
          case 'phone':
            return 'Enter Phone Number';
          case 'email':
            return 'Enter Email ID';
          default:
            return 'Enter Value';
        }
      }

      return wrapper;
    },
  });

  /* Traits for Section Block */
  // Define a custom trait type 'section-width-delete'

  traitManager.addType('section-width-delete', {
    createInput({ trait }) {
      const el = document.createElement('div');
      el.className = 'section-trait-group';

      const selectedComponent = grapesjsEditor.getSelected();
      const tds = selectedComponent.find('td');

      // Create a container element to hold both the image ad label
      const container = document.createElement('div');
      container.className = 'section-label-container';

      const rearrangeImg = document.createElement('img');
      rearrangeImg.src = rearrageIcon;
      container.appendChild(rearrangeImg);

      // Create a label element to show column number
      const label = document.createElement('span');
      label.className = 'section-input-label';
      label.textContent = `Column ${trait.get('tdIndex') + 1}`;
      container.appendChild(label);

      const deleteButton = document.createElement('button');
      deleteButton.type = 'button';
      deleteButton.className = 'section-delete-btn';
      deleteButton.textContent = '';
      deleteButton.onclick = () =>
        deleteColumnFromTraitManager(grapesjsEditor, trait);
      if (tds.length > 1) {
        container.appendChild(deleteButton);
      }

      const lockAndInput = document.createElement('div');
      lockAndInput.className = 'lock-and-input';

      const lockButton = document.createElement('button');
      lockButton.type = 'button';
      lockButton.className = 'section-lock-btn lock-btn unlocked';
      lockButton.textContent = '';
      lockButton.onclick = () => {
        const isLocked = trait.get('locked');
        trait.set('locked', !isLocked);
        lockButton.textContent = !isLocked ? '' : '';
        lockButton.classList.toggle('locked');
        lockButton.classList.toggle('unlocked');
        widthInput.disabled = !isLocked;
      };

      const widthInput = document.createElement('input');
      widthInput.type = 'number';
      widthInput.className = 'section-input-trait input-trait';
      widthInput.value = trait.get('value');
      widthInput.disabled = trait.get('locked');
      widthInput.addEventListener('change', (event) =>
        changeColumnWidth(grapesjsEditor, trait, event),
      );

      lockAndInput.appendChild(lockButton);
      lockAndInput.appendChild(widthInput);
      el.appendChild(container);
      el.appendChild(lockAndInput);

      appendToDiv(trait, el);
      return el;
    },
  });

  //Custom trait for add column to section
  traitManager.addType('add-column-button', {
    createInput({ trait }) {
      // Create button element
      const btn = document.createElement('button');
      btn.type = 'button';
      btn.className = 'add-section-btn';
      btn.textContent = trait.get('text');

      // Add event listener to button
      btn.onclick = () => {
        addColumnToTable(grapesjsEditor, trait);
        addColumnToTraitManager(grapesjsEditor, trait);
      };

      return btn;
    },
  });
};
