import { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import Icon from '../Icon';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Progress from '../Progress/Progess';
import { useState } from 'react';
import iconMore from '../../assets/icons/icon-more.svg';
import ImageNoSearch from '../../assets/images/no-search-results.png';
import CustomMenu from '../Menu';
import classNames from 'classnames';
import './Table.css';
import { Chip, IconButton } from '@mui/material';
import SearchBar from '../SearchBar/SearchBar';
import ShimmerTable from './ShimmerTable';
const TableToolBar = ({
  selectedCount,
  actions = [],
  bulkActions = [],
  onClearAllClick,
}) => {
  const actionsToRender = selectedCount > 0 ? bulkActions : actions;
  return (
    <Stack direction="row" spacing={1} sx={{ mb: 2 }} alignItems="center">
      {selectedCount > 0 ? (
        <>
          <Typography variant="subtitle2" component={'span'}>
            {selectedCount} items selected in the list
          </Typography>
          <Button
            className={'btn'}
            sx={{ mr: '12px' }}
            onClick={onClearAllClick}
          >
            <Icon type="cross" sx={{ mr: '5px' }} />
            <span>Clear All</span>
          </Button>
        </>
      ) : null}
      {actionsToRender.map((action) => {
        const {
          label,
          onClick,
          endIcon,
          startIcon,
          classes,
          isSearch,
          onSearch,
          onClear,
        } = action;
        return (
          <>
            {isSearch && (
              <>
                <SearchBar onSearch={onSearch} onClear={onClear} />
              </>
            )}
            {!isSearch && (
              <>
                <Button
                  className={classes}
                  onClick={(event) => onClick(event)}
                  endIcon={endIcon}
                  startIcon={startIcon}
                  sx={{ textTransform: 'none !important' }}
                >
                  {label}
                </Button>
              </>
            )}
          </>
        );
      })}
    </Stack>
  );
};

const EnhancedTableHead = ({
  headers = [],
  onSelectAllClick,
  numSelected = 0,
  rowCount = 0,
  noCheckBox,
  moreOptions,
}) => {
  return (
    <TableHead>
      <TableRow>
        {!noCheckBox && (
          <EnhancedTableCell sx={{ paddingLeft: '0px !important' }}>
            <Checkbox
              color="primary"
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </EnhancedTableCell>
        )}
        {moreOptions && <EnhancedTableCell />}

        {headers.map((header) => (
          <EnhancedTableCell
            key={header.key}
            className={classNames('TableHeader', header.customClass)}
          >
            {header.label}
          </EnhancedTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableCell = ({ children, onClick, ...rest }) => {
  let tableCellProps = {
    ...rest,
    sx: {
      borderRight: 1,
      borderColor: ({ palette }) => palette.grey[200],
      cursor: onClick ? 'pointer' : 'default',
    },
  };
  if (onClick) {
    tableCellProps = { ...tableCellProps, onClick };
  }
  return <TableCell {...tableCellProps}>{children}</TableCell>;
};

const FilterChips = ({ filters }) => (
  <>
    {filters.map(({ attribute, condition, value }, index) => (
      <Fragment key={index}>
        <Chip
          label={`${attribute}, ${condition}, ${value}`}
          size="small"
          sx={{ border: '1px solid #DFDDED', backgroundColor: '#F9FAFB' }}
        />
        {index < filters.length - 1 && (
          <Chip
            label="AND"
            sx={{ backgroundColor: (theme) => theme.palette.yellow?.light }}
            size="small"
          />
        )}
      </Fragment>
    ))}
  </>
);

const AppliedFiltersDisplay = ({ appliedFilters }) => {
  // Filter out blocks that have at least one valid sub-filter
  const validAppliedFilters = appliedFilters.filter((filterBlock) =>
    filterBlock.some(({ attribute, condition }) => attribute && condition),
  );

  return (
    <>
      {validAppliedFilters.map((filterBlock, blockIndex) => (
        <Fragment key={blockIndex}>
          <FilterChips filters={filterBlock} />
          {blockIndex < validAppliedFilters.length - 1 && (
            <Chip
              label="OR"
              sx={{ backgroundColor: (theme) => theme.palette.yellow?.light }}
              size="small"
            />
          )}
        </Fragment>
      ))}
    </>
  );
};

const TableComp = ({
  headers = [],
  isLoading = false,
  rows = [],
  noCheckBox,
  moreOptions,
  actions = [],
  bulkActions = [],
  rowActions = [],
  appliedFilters = [],
  onFilterEdit,
  ifEmpty,
}) => {
  const [selected, setSelected] = useState([]);
  const [rowSelected, setRowSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }

    setSelected([]);
  };

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClearAllClick = () => {
    setSelected([]);
  };

  const onSelectRow = (id) => {
    let newSelected = [];
    const isSelected = selected.some((selectedId) => selectedId === id);
    if (isSelected) {
      newSelected = selected.filter((selectedId) => selectedId !== id);
    } else {
      newSelected = selected.concat(id);
    }
    setSelected(newSelected);
  };

  if (isLoading) {
    return <ShimmerTable />;
  }

  return (
    <>
      {!noCheckBox && (
        <TableToolBar
          selectedCount={selected.length}
          onClearAllClick={onClearAllClick}
          actions={actions}
          bulkActions={bulkActions.map((bulkAction) => {
            return {
              ...bulkAction,
              onClick: (event) => {
                bulkAction.onClick(selected, event);
              },
            };
          })}
        />
      )}

      {appliedFilters.length > 0 && (
        <Stack
          mb={2}
          bgcolor="#F9FAFB"
          minHeight="48px"
          border="1px solid #DFDDED"
          borderRadius="12px"
          padding={1.5}
          alignItems="center"
          flexDirection="row"
          flexWrap="wrap"
          gap={1.5}
          position="relative"
        >
          Filter
          {<AppliedFiltersDisplay appliedFilters={appliedFilters} />}
          <IconButton
            onClick={onFilterEdit}
            sx={{ position: 'absolute', right: 12 }}
          >
            <Icon type="icon-edit" />
          </IconButton>
        </Stack>
      )}
      <Box
        sx={{
          borderRadius: 2,
          border: 1,
          borderColor: ({ palette }) => palette.grey[200],
        }}
      >
        <TableContainer>
          <Table>
            <EnhancedTableHead
              headers={headers}
              onSelectAllClick={onSelectAllClick}
              numSelected={selected.length}
              rowCount={rows.length}
              noCheckBox={noCheckBox}
              moreOptions={moreOptions}
            />
            <TableBody>
              {ifEmpty ? (
                <TableRow>
                  <EnhancedTableCell
                    colSpan={headers.length + 1}
                    align="center"
                  >
                    <div className="empty-table-state">
                      <img src={ImageNoSearch} alt="No result" />
                      <span>No data available</span>
                    </div>
                  </EnhancedTableCell>
                </TableRow>
              ) : (
                rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor: () =>
                          index % 2 === 0 ? '#F9FAFB' : '',
                      }}
                    >
                      {!noCheckBox && (
                        <EnhancedTableCell className="firstCol">
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Checkbox
                              color="primary"
                              onClick={() => onSelectRow(row.id)}
                              checked={selected.indexOf(row.id) !== -1}
                            />
                            <img
                              src={iconMore}
                              alt="icon-more"
                              onClick={(event) => {
                                handleMoreClick(event);
                                setRowSelected(row);
                              }}
                            />
                          </div>
                        </EnhancedTableCell>
                      )}

                      {moreOptions && (
                        <EnhancedTableCell className="firstCol">
                          {row?.type !== 'DEFAULT' ? (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <img
                                src={iconMore}
                                alt="icon-more"
                                onClick={(event) => {
                                  handleMoreClick(event);
                                  setRowSelected(row);
                                }}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </EnhancedTableCell>
                      )}

                      {headers.map(({ numeric, key, render, onClick }) => {
                        const RenderComp = render;
                        return (
                          <EnhancedTableCell
                            onClick={onClick ? () => onClick(row) : ''}
                            align={numeric ? 'right' : 'left'}
                            key={key}
                          >
                            {RenderComp ? (
                              <RenderComp {...row} />
                            ) : (
                              row[key] || '_'
                            )}
                          </EnhancedTableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <CustomMenu
          onSelect={(value) => {
            const { onClick } =
              rowActions.find((rowAction) => rowAction.value === value) || {};
            onClick?.(rowSelected);
            setAnchorEl(null);
          }}
          options={rowActions}
          open={!!anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          horizontalAlignment="bottom"
          verticalAlighnment="bottom"
          anchorEl={anchorEl}
        />
      </Box>
    </>
  );
};

export default TableComp;
