import React from 'react';
import PropTypes from 'prop-types';

const Toggler = ({ isOn, onToggle, icons, type }) => {
  return (
    <div className="toggle-button-cover">
      <div
        className={`${type === 'container-toggler' ? 'container-button-cover' : 'button-cover'}`}
      >
        <div
          className={`${type === 'container-toggler' ? 'container-button' : 'button'} b2 ${isOn ? 'active' : ''}`}
          id="button-10"
        >
          <input
            type="checkbox"
            className={`${type === 'container-toggler' ? 'container-checkbox' : 'checkbox'}`}
            checked={isOn}
            onChange={onToggle}
          />
          <div
            className={`${type === 'container-toggler' ? 'container-knobs' : 'knobs'}`}
          >
            {/* <span>{isOn ? icons.on : icons.off}</span> */}
            <span>{icons.on}</span>
          </div>
          <div className="layer"></div>
        </div>
      </div>
    </div>
  );
};

Toggler.propTypes = {
  isOn: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  icons: PropTypes.shape({
    on: PropTypes.node,
    off: PropTypes.node,
  }),
};

export default Toggler;
