import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import appIcon from '../assets/icons/app-icon.svg';
import verificationIcon from '../assets/icons/icon-comparison-tick.svg';
import loadingIcon from '../assets/icons/icon-refresh.svg';
import errorIcon from '../assets/icons/icon-error.svg';
import { validateShopifyCredentials } from '../utils/shopify.validator';

const shopifyQueryParams = {
  from: 'from',
  hmac: 'hmac',
  host: 'host',
  id_token: 'id_token',
  locale: 'locale',
  session: 'session',
  shop: 'shop',
  timestamp: 'timestamp',
  embedded: 'embedded',
};

const VerificationSuccess = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [redirectFunction, setRedirectFunction] = useState(
    () => () => navigate('/dashboard'),
  );

  const openDashboard = useCallback(() => navigate('/dashboard'), [navigate]);
  const openShopifyLogin = useCallback(() => {
    window.location.href = 'https://connect.adflipr.com/auth/login';
  }, []);

  useEffect(() => {
    const validateAndSetContent = async () => {
      let params = '';
      let isValid = true;

      let shopify = localStorage.getItem('from') === 'shopify';

      if (shopify) {
        for (const [key, localStorageKey] of Object.entries(shopifyQueryParams)) {
          const value = localStorage.getItem(localStorageKey);
          if (value) {
            params += `${key}=${value}&`;
          } else {
            console.error(`Missing required query parameter: ${key}`);
            isValid = false;
            break;
          }
        }
      }

      if (isValid && shopify) {
        isValid = await validateShopifyCredentials(params);
        if (!isValid) {
          console.error('Shopify validation failed');
        }
      }

      if (isValid || !shopify) {
        setContent(
          <>
            <img
              className="verification-icon"
              src={verificationIcon}
              alt="Verification Icon"
            />
            <h1 className="verification-heading">Successfully Verified</h1>
            <p className="verification-content">
              Now we are redirecting you to the main page...
            </p>
            <button className="verification-link" onClick={openDashboard}>
              Dashboard
            </button>
          </>,
        );
        setRedirectFunction(() => openDashboard);
      } else {
        setContent(
          <>
            <img
              className="verification-icon"
              src={errorIcon}
              alt="Error Icon"
            />
            <h1 className="verification-heading">
              Shopify App Verification Failed
            </h1>
            <p className="verification-content">
              Redirecting to the Shopify Login Page...
            </p>
            <button className="verification-link" onClick={openShopifyLogin}>
              Shopify Login
            </button>
          </>,
        );
        setRedirectFunction(() => openShopifyLogin);
      }

      setIsLoading(false);
    };

    validateAndSetContent();
  }, [navigate, openDashboard, openShopifyLogin]);

  useEffect(() => {
    if (!isLoading) {
      const redirectTimer = setTimeout(redirectFunction, 500);
      return () => clearTimeout(redirectTimer);
    }
  }, [isLoading, redirectFunction]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <img src={loadingIcon} alt="Loading" className="loading-spinner" />
        <p>Verifying credentials...</p>
      </div>
    );
  }

  return (
    <>
      <div className="header auth">
        <div className="header-left">
          <img className="app-icon" src={appIcon} alt="App Icon" />
          <span className="template-name heading">adflipr</span>
        </div>
        <div className="header-center"></div>

        <div className="header-right"></div>
      </div>
      <div className="verification-container">{content}</div>
    </>
  );
};

export default VerificationSuccess;
