import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Header from '../../../components/Header/Header';
import MyDrawer from './component/MyDrawer';
import CustomMenu from '../../../components/Menu';
import Icon from '../../../components/Icon/Icon';
import CloseBtn from '../../../assets/icons/icon-back.svg';
import uploadFileImg from '../../../assets/icons/icon-file-upload.svg';
import iconDelete from '../../../assets/icons/icon-delete.svg';
import iconAddCircleWhite from '../../../assets/icons/icon-add-white.svg';
import iconAddCircle from '../../../assets/icons/icon-add.svg';
import iconUpload from '../../../assets/icons/icon-upload.svg';
import iconWaiting from '../../../assets/icons/icon-waiting.svg';
import importBanner from '../../../assets/images/template_blank.png';
import iconCross from '../../../assets/icons/icon-cross-white.svg';
import createBanner from '../../../assets/images/use_prebuilt.png';
import EmptyState from '../../../components/EmptyState';
import CustomDialog from '../../../components/Dialog';
import FormRenderer from '../../../components/FormRenderer';
import ResourceApi from '../../../api/resourceapi';
import bulkApi from '../../../api/bulkapi';
import Message from '../../../components/Message';
import Table from '../../../components/Table';
import formatDate from '../../../utils/date-utils';
import Autocomplete from '../../../components/AutoComplete';
import styles from './AllContact.module.scss';
import Filter from '../../../components/Filter/Filter';
import { apiRequest } from '../../../api/http';
import useFilter from '../../../hooks/useFilter';
import { useDropzone } from 'react-dropzone';
import MapImport from './ImportContacts/MapImport';
import ImportProgress from './ImportContacts/ImportProgress';
import downloadCSV from '../../../utils/downloadCSV';
import {
  Button,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import TablePagination from '../../../components/Table/TablePagination';

const bulkActionOptions = [
  {
    label: 'Add Tags',
    value: 'add_tags',
  },
  {
    label: 'Add to List',
    value: 'add_to_list',
  },
  {
    label: 'Remove Tags',
    value: 'remove_tags',
  },
  {
    label: 'Remove from List',
    value: 'remove_from_list',
  },
  // {
  //   label: 'Change Contact Status',
  //   value: 'change_contact_status',
  // },
];

const bulkActionFormLabels = {
  add_tags: {
    title: 'Add Tags',
    action: 'Add',
    field: 'Tags',
  },
  add_to_list: {
    title: 'Add to List',
    action: 'Add',
    field: 'List',
  },
  remove_tags: {
    title: 'Remove Tags',
    action: 'Remove',
    field: 'Tags',
  },
  remove_from_list: {
    title: 'Remove from List',
    action: 'Remove',
    field: 'List',
  },
  change_contact_status: {
    title: 'Change Contact Status',
    action: 'Update',
    field: 'Status',
  },
};

const AllContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [totalContacts, setTotalContacts] = useState(0);
  const filterAttrs = useFilter();
  const [appliedFilters, setAppliedFilters] = useState(filterAttrs.filters);
  const [isContactLoading, setIsContactLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [bulkActionAnchorElement, setBulkActionAnchorElement] = useState(null);
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const bulkSelectedAction = useRef(null);
  const bulkSelectedIds = useRef(null);
  const fieldUpdated = useSelector((state) => state.fieldUpdated);
  const [fieldVisibility, setFieldVisibility] = useState({});
  const [alert, setAlert] = useState({
    type: '',
    show: false,
    message: '',
  });
  const [dialogConfig, setDialogConfig] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onBlur' });

  const [ifEmpty, setIfEmpty] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isImportContacts, setIsImportContacts] = useState(false);
  const [isImportContactsDialog, setIsImportContactsDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const [isImportProgress, setIsImportProgress] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [importFailed, setImportFailed] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleClearSearch = () => {
    console.log('clear search clicked');
    onContactFetch();
    setSearchTerm('');
  };

  useEffect(() => {
    fetchSearchedContacts();
  }, [searchTerm]);

  const filterFields = (config, obj) => {
    const filteredObj = {};

    for (const key in obj) {
      // If the field is in the config and set to true, retain it
      if (config[key] !== false) {
        filteredObj[key] = obj[key];
      }
    }

    return filteredObj;
  };

  const handleContactResponse = (Data) => {
    console.log(Data);
    Data.sort((contact1, contact2) => {
      const date1 = new Date(contact1.updated);
      const date2 = new Date(contact2.updated);
      return date2.getTime() - date1.getTime();
    });
    //setContacts([...Data, ...testContact]);
    Data = [...Data];
    ResourceApi.contact.getFieldsVisibility().then(({ data }) => {
      //console.log(data);
      filteredContacts(Data, data);
    });
  };

  const filteredContacts = (data, fieldVisibility) => {
    console.log(data);
    const array = data;
    const updatedArray = array.map((item) => {
      const filteredItem = filterFields(fieldVisibility, item);
      // console.log(filteredItem);
      //If the address is an object, apply the same filtering logic to address fields
      if (
        filteredItem.dynamicField &&
        typeof filteredItem.dynamicField === 'object'
      ) {
        filteredItem.dynamicField = filterFields(
          fieldVisibility,
          filteredItem.dynamicField,
        );
      }
      return filteredItem;
    });

    console.log(updatedArray);
    setIsContactLoading(false);
    setContacts(updatedArray);
  };

  const onCloseDialog = () => {
    setDialogConfig((prevConfig) => ({ ...prevConfig, open: false }));
  };

  const fetchSearchedContacts = () => {
    if (searchTerm !== '') {
      ResourceApi.contact
        .search(searchTerm)
        .then(({ data }) => {
          console.log(searchTerm, data, data.length, 'after search');
          // setIsContactLoading(false);
          if (data.length === 0) {
            setIfEmpty(true);
          } else {
            setIfEmpty(false);
            handleContactResponse(data);
            setTotalContacts(data.length);
          }
        })
        .catch((error) => {
          setIsContactLoading(false);
        });
    } else {
      onContactFetch();
    }
  };

  const onContactFetch = () => {
    console.log('onContactFetch');
    setIsContactLoading(true);
    ResourceApi.contact
      .getContact(pageNumber, pageSize, '')
      .then(({ data }) => {
        //setIsContactLoading(false);
        handleContactResponse(data.data);
        if (data?.data?.length > 0) {
          setIfEmpty(false);
        }
        setTotalContacts(data.totalElements);
        console.log(data, 'Fetched contact list till that moment');
      })
      .catch((error) => {
        setIsContactLoading(false);
      });
  };

  const onContactFormClose = () => {
    onCloseDialog();
    reset();
  };

  useEffect(() => {
    onContactFetch();
  }, [isImportContacts, fieldUpdated, pageNumber, pageSize]);

  const handlePageChange = (event, newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const totalPages = Math.ceil(totalContacts / pageSize);

  const onContactCreate = (data) => {
    //  console.log(data);
    if (!data.isSubscribed) {
      data.status = 'UNSUBSCRIBED';
    }
    //   console.log(data);
    const { tagResources = [] } = data;
    const apiPayload = {
      ...data,
      tagResources: tagResources.map(({ id }) => ({ id })),
    };

    ResourceApi.contact
      .create(apiPayload)
      .then(() => {
        onContactFormClose();
        setAlert({
          type: 'success',
          show: true,
          message: 'Contact created successfully',
        });
        onContactFetch();
      })
      .catch((error) => {
        setError('email', {
          type: 'manual',
          message: error.response?.data?.message || '',
        });
      });
  };

  const onContactDelete = (ids) => {
    onCloseDialog();
    ResourceApi.contact
      .delete({
        ids,
      })
      .then(() => {
        setAlert({
          type: 'success',
          show: true,
          message: `${ids.length} contact(s) deleted successfully`,
        });
        onContactFetch();
      });
  };

  const onDeleteAction = (ids) => {
    setDialogConfig({
      open: true,
      title: 'Delete Contacts',
      onClose: onCloseDialog,
      content:
        ids.length > 1
          ? `Are you sure you want to delete ${ids.length} contacts?`
          : 'Are you sure you want to delete the contact?',
      actions: [
        {
          label: 'Cancel',
          onClick: onCloseDialog,
          classes: 'btn-outline dark-border',
        },
        {
          label: 'Delete',
          onClick: () => onContactDelete(ids),
          classes: 'btn-primary',
        },
      ],
    });
  };

  const ContactCreateForm = () => {
    return (
      <FormRenderer
        register={register}
        watch={watch}
        errors={errors}
        fields={[
          {
            type: 'text',
            name: 'email',
            placeHolder: 'Type here',
            label: 'Email',

            validations: {
              required: 'Email is required',
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Invalid email address',
              },
            },
          },
          {
            type: 'text',
            name: 'firstName',
            placeHolder: 'Type here',
            label: 'First name',
            validations: { required: 'First name is required' },
          },
          {
            type: 'text',
            name: 'lastName',
            placeHolder: 'Type here',
            label: 'Last name',
            validations: {
              required: 'Last name is required',
            },
          },
          {
            type: 'text',
            name: 'phone',
            placeHolder: 'Type here',
            label: 'Phone',
            validations: {
              pattern: {
                value: /^\+?\d+$/,
                message: 'Phone number should be in correct format',
              },
            },
          },
          {
            type: 'dropdown',
            name: 'tagResources',
            placeHolder: 'Search to select',
            label: 'Tags',
            fetchUrl: '/tag/search',
            createUrl: '/tag',
          },
          {
            type: 'checkbox',
            name: 'isSubscribed',
            label: 'Mark contact as subscribed',
          },
        ]}
      />
    );
  };

  function isDataEmptyTemplate(data) {
    // Check if data is an array and has the correct structure
    if (!Array.isArray(data) || data.length === 0 || !Array.isArray(data[0])) {
      return false; // The structure itself is invalid, so it doesn't match the empty template
    }

    // Check if the first object in the nested array matches the template's empty values
    return data[0].every((item) => {
      return (
        item.attribute === '' &&
        item.value === '' &&
        item.valueType === '' &&
        Array.isArray(item.valueOptions) &&
        item.valueOptions.length === 0 &&
        item.condition === '' &&
        Array.isArray(item.conditionOptions) &&
        item.conditionOptions.length === 0
      );
    });
  }

  const transformFilterData = (data) => {
    const result = { filters: [] };

    data.forEach((subArray) => {
      const subFilters = subArray.map((item) => ({
        attribute: item.attribute,
        value: item.value,
        condition: item.condition,
      }));
      result.filters.push({ subFilters });
    });
    return result;
  };

  const openImportContactsDialog = () => {
    setIsImportContactsDialog(true);
  };

  const closeImportContactsDialog = () => {
    setIsImportContactsDialog(false);
    setSelectedFile(null);
  };

  const onDrop = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  };

  const ImportContactsContent = () => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: '.csv',
      maxFiles: 1,
    });
    // Function to remove the selected file
    const removeFile = () => {
      setSelectedFile(null);
    };
    return (
      <>
        <div
          {...getRootProps()}
          className={`dropzone ${isDragActive ? 'active-dropzone' : ''}`}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the file here...</p>
          ) : (
            <>
              <img src={uploadFileImg} alt="upload file here" />
              <p>Drag or browse a CSV file to import contacts.</p>
              <span>(Maximum file size allowed 10MB)</span>
            </>
          )}
        </div>

        {selectedFile && (
          <div className="file-selected">
            <span>{selectedFile.name}</span>
            <button onClick={removeFile}>
              <img
                style={{ cursor: 'pointer' }}
                src={iconDelete}
                alt="Remove File"
              />
            </button>
          </div>
        )}
      </>
    );
  };

  const onContactFormOpen = () => {
    setDialogConfig({
      title: 'Create Contact',
      open: true,
      onClose: onContactFormClose,
      type: 'contact',
      actions: [
        {
          label: 'Cancel',
          onClick: onContactFormClose,
          classes: 'btn-outline dark-border',
        },
        {
          label: 'Create',
          onClick: () => handleSubmit(onContactCreate)(),
          classes: 'btn-primary',
        },
      ],
    });
  };

  // const downloadCSV = (data) => {
  //   if (!data) return;

  //   // Create a Blob from the byte data (CSV content)
  //   const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });

  //   // Create a download link and click it
  //   const link = document.createElement('a');
  //   const url = URL.createObjectURL(blob);
  //   link.setAttribute('href', url);
  //   link.setAttribute('download', 'data.csv');
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const handleViewDetails = () => {
    setIsImportContacts(true);
  };

  const dialogActions = {
    filter: [
      {
        label: 'Apply Filters',
        classes: 'btn-primary',
        onClick: () => {
          onCloseDialog();
          let data = filterAttrs.filters;
          const result = transformFilterData(data);
          ResourceApi.contact
            .createFilter(result)
            .then(({ data }) => {
              handleContactResponse(data);
              setAppliedFilters(filterAttrs.filters);
            })
            .catch((err) => {
              console.log(err);
            });
        },
      },
    ],
  };

  const dialogContent = {
    contact: <ContactCreateForm />,
    bulk: (
      <Autocomplete
        label={bulkSelectedAction.current}
        classes={{ container: styles.autoCompleteContainer }}
        autoCompleteValue={autoCompleteValue}
        onChange={setAutoCompleteValue}
        createParams={{
          tagOrList: bulkSelectedAction.current === 'List' ? 'LIST' : 'TAG',
        }}
        fetchUrl={`/tag/search?tagOrList=${bulkSelectedAction.current === 'List' ? 'LIST' : 'TAG'}`}
        createUrl="/tag"
      />
    ),
    filter: (
      <Box sx={{ width: '50vw', height: '50vh' }}>
        <Filter {...filterAttrs} />
      </Box>
    ),
  };

  const validAppliedFilters = appliedFilters.filter((filterBlock) =>
    filterBlock.some(({ attribute, condition }) => attribute && condition),
  );

  const actions = [
    {
      isSearch: true,
      onSearch: handleSearch,
      onClear: handleClearSearch,
    },
    {
      label: <Icon type="icon-preference" />,
      classes: classNames(styles.actionIconBtn),
      onClick: handleDrawerOpen,
    },
    {
      label: <Icon type="icon-export" />,
      classes: classNames(styles.actionIconBtn),
      onClick: () => {
        let data = filterAttrs.filters;
        console.log(data);
        let result;
        if (isDataEmptyTemplate(data)) {
          result = {};
        } else {
          result = transformFilterData(data);
        }
        ResourceApi.contact.exportContact(result).then(({ data }) => {
          downloadCSV(data);
          console.log(data);
          setAlert({
            type: 'success',
            show: true,
            message: 'Contact exported successfully',
          });
        });
      },
    },
  ];

  if (validAppliedFilters.length === 0) {
    actions.splice(0, 0, {
      label: 'Add Filters',
      classes: 'btn btn-outline',
      onClick: (event) => {
        setDialogConfig({
          open: true,
          title: 'Add Filters',
          type: 'filter',
          classes: 'large-dialog',
          onClose: () => {
            onCloseDialog();
            filterAttrs.resetFilters();
          },
        });
      },
      endIcon: <Icon type="icon-arrow" />,
    });
  } else {
    actions.push({
      label: 'Clear Filters',
      classes: classNames('btn', styles.clearBtn),
      startIcon: <Icon type="icon-refresh" />,
      onClick: (event) => {
        filterAttrs.resetFilters();
        onContactFetch();
        setAppliedFilters(filterAttrs.filterDefaultState);
      },
    });
  }

  const generateTableColumns = (contacts) => {
    const columns = [];

    // Existing logic to add predefined fields like Name, Email, Phone
    if (
      contacts.some(
        (contact) => 'firstName' in contact && 'lastName' in contact,
      )
    ) {
      columns.push({
        numeric: false,
        key: 'name',
        disablePadding: false,
        onClick: (row) => navigate(`/contacts/allContacts/${row.id}`),
        label: 'Name',
        render: (row) => (
          <div className="contact-name">
            {row.firstName && row.lastName
              ? `${row.firstName} ${row.lastName}`
              : '-'}
          </div>
        ),
      });
    }

    if (contacts.some((contact) => 'email' in contact)) {
      columns.push({
        numeric: false,
        key: 'email',
        disablePadding: false,
        label: 'Email',
        render: (row) => <div>{row.email ? row.email : '-'}</div>,
      });
    }

    if (contacts.some((contact) => 'phone' in contact)) {
      columns.push({
        numeric: false,
        key: 'phone',
        disablePadding: false,
        label: 'Phone',
        render: (row) => <div>{row.phone ? row.phone : '-'}</div>,
      });
    }
    if (contacts.some((contact) => 'address' in contact)) {
      columns.push({
        numeric: false,
        key: 'address',
        disablePadding: false,
        label: 'Address',
        render: (row) => (
          <div>
            {row.address
              ? `${row.address?.line1},
                ${row.address?.line2},
                ${row?.address?.state},
                ${row.address?.country},
                ${row.address?.zipcode}`
              : '-'}
          </div>
        ),
      });
    }

    if (contacts.some((contact) => 'gender' in contact)) {
      columns.push({
        numeric: false,
        key: 'gender',
        disablePadding: false,
        label: 'Gender',
        render: (row) => <div>{row.gender ? row.gender : '-'}</div>,
      });
    }

    if (contacts.some((contact) => 'birthDate' in contact)) {
      columns.push({
        numeric: false,
        key: 'birthDate',
        disablePadding: false,
        label: 'Birth Date',
        render: (row) => (
          <div>{row.birthDate ? formatDate(row.birthDate) : '-'}</div>
        ),
      });
    }

    if (contacts.some((contact) => 'company' in contact)) {
      columns.push({
        numeric: false,
        key: 'company',
        disablePadding: false,
        label: 'Company',
        render: (row) => <div>{row.company ? row.company : '-'}</div>,
      });
    }

    if (contacts.some((contact) => 'timezone' in contact)) {
      columns.push({
        numeric: false,
        key: 'timezone',
        disablePadding: false,
        label: 'Timezone',
        render: (row) => <div>{row.timezone ? row.timezone : '-'}</div>,
      });
    }

    if (contacts.some((contact) => 'created' in contact)) {
      columns.push({
        numeric: false,
        key: 'created',
        disablePadding: false,
        label: 'Created On',
        render: (row) => (
          <div>{row.created ? formatDate(row.created, true) : '-'}</div>
        ),
      });
    }

    if (contacts.some((contact) => 'tagResources' in contact)) {
      columns.push({
        numeric: false,
        key: 'tagResources',
        disablePadding: false,
        label: 'Tags',
        render: (row) => (
          <Stack direction="row" spacing={1}>
            {row.tagResources && row.tagResources.length > 0
              ? row.tagResources.map(({ name, id }) => (
                  <Chip size="small" label={name} key={id} />
                ))
              : '-'}
          </Stack>
        ),
      });
    }

    if (contacts.some((contact) => 'listResources' in contact)) {
      columns.push({
        numeric: false,
        key: 'listResources',
        disablePadding: false,
        label: 'Lists',
        render: (row) => (
          <Stack direction="row" spacing={1}>
            {row.listResources && row.listResources.length > 0
              ? row.listResources.map(({ name, id }) => (
                  <Chip size="small" label={name} key={id} />
                ))
              : '-'}
          </Stack>
        ),
      });
    }

    // Dynamically add dynamicField as columns
    const dynamicKeys = new Set(); // To track all dynamic keys across contacts
    contacts.forEach((contact) => {
      if (contact.dynamicField) {
        Object.keys(contact.dynamicField).forEach((key) =>
          dynamicKeys.add(key),
        );
      }
    });

    // Add a column for each dynamic field key
    dynamicKeys.forEach((key) => {
      columns.push({
        numeric: false,
        key: key,
        disablePadding: false,
        label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the key for label
        render: (row) => <div>{row.dynamicField?.[key] || '-'}</div>,
      });
    });

    // Finally add the "Status" column if status is present
    if (contacts.some((contact) => contact.status)) {
      columns.push({
        numeric: false,
        key: 'status',
        disablePadding: false,
        label: 'Status',
        render: ({ status }) => (
          <div>
            {status ? (
              <Chip
                size="small"
                label={status}
                sx={{ backgroundColor: '#DCF2ED', color: '#09B29C' }}
              />
            ) : (
              '_'
            )}
          </div>
        ),
      });
    }
    //  console.log(columns);
    return columns;
  };

  const pageSizes = [25, 50, 75, 100];
  const showEmptyState = !isContactLoading && contacts.length === 0;

  return (
    <>
      <CustomDialog
        {...dialogConfig}
        actions={dialogActions[dialogConfig.type] ?? dialogConfig.actions}
        content={dialogContent[dialogConfig.type] ?? dialogConfig.content}
      />
      <CustomDialog
        open={isImportContactsDialog}
        title={'Import Contact'}
        content={<ImportContactsContent />}
        actions={[
          {
            label: 'Upload File',
            loader: isFileUploaded,
            onClick: async () => {
              if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);

                try {
                  const response =
                    await ResourceApi.contact.uploadCSVFile(formData);
                  console.log(response.data, 'After uploading CSV');
                  closeImportContactsDialog();
                  setIsImportContacts(true);
                  setCsvData(response.data);
                  if (response.data) {
                    setIsFileUploaded(true);
                  }
                } catch (err) {
                  console.log(err);
                } finally {
                  setIsFileUploaded(false);
                }
              }
            },
            classes: `btn btn-primary ${!selectedFile ? 'blurred' : ''}`,
            disabled: !selectedFile,
          },
        ]}
        onClose={closeImportContactsDialog}
      />

      {isImportContacts ? (
        <div className="header" style={{ zIndex: 1 }}>
          <div className="header-left">
            <div>
              <button
                className="back-icon common-btn"
                onClick={() => setIsImportContacts(false)}
                style={{ border: 'none' }}
              >
                <img
                  src={CloseBtn}
                  alt="Back button"
                  style={{ transform: 'rotate(-90deg)' }}
                />
              </button>
            </div>
            <span className="template-name"> Import Contacts </span>
          </div>
        </div>
      ) : isImportProgress ? (
        <div className="header">
          <div className="header-left">
            <div>
              <button
                className="back-icon common-btn"
                onClick={() => setIsImportContacts(false)}
                style={{ border: 'none' }}
              >
                <img
                  src={CloseBtn}
                  alt="Back button"
                  style={{ transform: 'rotate(-90deg)' }}
                />
              </button>
            </div>
            <span className="template-name"> Import Contacts </span>
          </div>
          <div className="header-right">
            {completed ? (
              <div className="banner success">
                <div className="left">
                  <img src={iconWaiting} alt="importing" />
                  <span>Import Completed</span>
                </div>
                <div className="right"></div>
              </div>
            ) : importFailed ? (
              <div className="banner failed">
                <div className="left">
                  <img src={iconWaiting} alt="importing" />
                  <span>Import Failed</span>
                </div>
                <div className="right">
                  <button onClick={handleViewDetails}>View Details</button>
                </div>
              </div>
            ) : (
              <div className="banner black">
                <div className="left">
                  <img src={iconWaiting} alt="importing" />
                  <span>Importing in Progress</span>
                </div>
                <div className="right">
                  <button onClick={handleViewDetails}>View Details</button>
                </div>
              </div>
            )}

            <Button
              className="btn btn-primary"
              variant="contained"
              onClick={onContactFormOpen}
            >
              Create Contact
            </Button>
          </div>
        </div>
      ) : (
        <Header
          title={`All Contacts ${totalContacts > 0 ? ` (${totalContacts})` : ''}`}
          actions={[
            {
              label: 'Import Contacts',
              isPrimary: false,
              icon: iconUpload,
              onClick: openImportContactsDialog,
            },
            {
              label: 'Create Contact',
              isPrimary: true,
              icon: iconAddCircleWhite,
              onClick: onContactFormOpen,
            },
          ]}
        />
      )}

      {isImportContacts ? (
        isImportProgress ? (
          <ImportProgress
            importId={csvData.id}
            setIsImportContacts={setIsImportContacts}
            completed={completed}
            setCompleted={setCompleted}
            importFailed={importFailed}
            setImportFailed={setImportFailed}
            setIsImportProgress={setIsImportProgress}
          />
        ) : (
          <MapImport
            csvData={csvData}
            setIsImportProgress={setIsImportProgress}
          />
        )
      ) : showEmptyState ? (
        <EmptyState
          title="Manage Your Contacts"
          description="View, organize, and handle them individually or in groups to engage customers"
          options={[
            {
              title: 'Import Contacts',
              description:
                'Easily Import Multiple Contacts with a Single Click to add',
              action: {
                label: 'Import Contacts',
                icon: iconUpload,
                onClick: openImportContactsDialog,
              },
              banner: importBanner,
              bannerBackground: 'yellow',
            },
            {
              title: 'Create Contact Manually',
              description:
                'Enter Contact Details to Build Your Network One Person at a Time',
              action: {
                label: 'Create Contact',
                icon: iconAddCircle,
                onClick: onContactFormOpen,
              },
              banner: createBanner,
              bannerBackground: 'green',
            },
          ]}
        />
      ) : (
        <div className="main-content-container">
          <Table
            appliedFilters={validAppliedFilters}
            onFilterEdit={() => {
              setDialogConfig({
                open: true,
                title: 'Add Filters',
                type: 'filter',
                classes: 'large-dialog',
                onClose: () => {
                  filterAttrs.setFilters(appliedFilters);
                  onCloseDialog();
                },
              });
            }}
            headers={generateTableColumns(contacts)}
            rows={contacts}
            isLoading={isContactLoading}
            actions={actions}
            rowActions={[
              {
                label: 'View',
                icon: 'icon-preview',
                value: 'view',
                onClick: (row) => {
                  navigate(`/contacts/allContacts/${row.id}`);
                },
              },
              {
                label: 'Delete',
                icon: 'icon-delete',
                value: 'delete',
                onClick: (row) => {
                  onDeleteAction([row.id]);
                },
              },
            ]}
            bulkActions={[
              {
                label: 'Export',
                classes: classNames('btn', styles.bulkActionBtn),
                onClick: (ids) => {
                  // handle this
                  const data = { ids: ids };
                  //    console.log(ids);
                  ResourceApi.contact.exportContact(data).then((data) => {
                    console.log(data);
                    setAlert({
                      type: 'success',
                      show: true,
                      message: 'Contact exported successfully',
                    });
                  });
                },
              },
              {
                label: 'Delete',
                classes: classNames('btn', styles.bulkActionBtn),
                onClick: (ids) => {
                  onDeleteAction(ids);
                },
              },
              {
                label: 'Bulk Actions',
                classes: classNames('btn', styles.bulkActionBtn),
                onClick: (ids, event) => {
                  bulkSelectedIds.current = ids;
                  setBulkActionAnchorElement(event.currentTarget);
                },
                endIcon: (
                  <Icon
                    type="icon-arrow"
                    sx={{
                      transform: bulkActionAnchorElement
                        ? 'rotate(180deg)'
                        : 'none',
                    }}
                  />
                ),
              },
            ]}
            ifEmpty={ifEmpty}
          />
          <TablePagination
            isLoading={isContactLoading}
            pageNumber={pageNumber}
            totalPages={totalPages}
            pageSize={pageSize}
            setPageSize={setPageSize}
            handlePageChange={handlePageChange}
            pageSizes={pageSizes}
          />
        </div>
      )}
      <Message
        open={alert.show}
        type={alert.type}
        message={alert.message}
        onMessageClose={() => {
          setAlert({
            type: '',
            show: false,
            message: '',
          });
        }}
      />
      <CustomMenu
        onClose={() => {
          setBulkActionAnchorElement(null);
        }}
        onSelect={(bulkActionValue) => {
          const { title, action, field } =
            bulkActionFormLabels[bulkActionValue];
          setBulkActionAnchorElement(null);
          bulkSelectedAction.current = field;
          setDialogConfig({
            open: true,
            title: title,
            actions: [
              {
                label: 'Cancel',
                onClick: onCloseDialog,
                classes: 'btn-outline dark-border',
              },
              {
                label: action,
                onClick: () => {
                  setAutoCompleteValue((prevValue) => {
                    const bulkPayload = {
                      ids: bulkSelectedIds.current,
                      tagIds: prevValue.map((value) => value.id),
                    };

                    let bulkOperation = null;
                    if (bulkActionValue === 'add_tags') {
                      bulkOperation = bulkApi.contact.add('tag', bulkPayload);
                    } else if (bulkActionValue === 'remove_tags') {
                      bulkOperation = bulkApi.contact.remove(
                        'tag',
                        bulkPayload,
                      );
                    }

                    bulkOperation.then(() => {
                      onContactFetch();
                    });

                    return [];
                  });
                  onCloseDialog();
                },
                classes: 'btn-primary',
              },
            ],
            onClose: onCloseDialog,
            type: 'bulk',
          });
        }}
        options={bulkActionOptions}
        anchorEl={bulkActionAnchorElement}
      />
      <MyDrawer isOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} />
    </>
  );
};

export default AllContacts;
