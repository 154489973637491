import React, { useState } from 'react';

const UnsubscribeComponent = ({ success, reasons }) => {
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');

  const handleResubscribe = () => {
    alert('You have been resubscribed!');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (reason === 'Other' && otherReason.trim() === '') {
      alert('Please fill in a reason.');
      return;
    }
    alert(`You have unsubscribed for reason: ${reason === 'Other' ? otherReason : reason}`);
    setUnsubscribed(true);
  };

  return (
    <div className="unsubscribe-container">
      <img src="company-logo.png" alt="Company Logo" className="company-logo" />
      {unsubscribed || success ? (
        <div className="content-container">
          <h2 className="content-header">Success!</h2>
          <p className="content-text">You have been successfully unsubscribed from our mailing list.</p>
          <p className="content-text">To resubscribe click on the button below.</p>
          <button onClick={handleResubscribe} className="btn btn-primary">
            Resubscribe
          </button>
        </div>
      ) : (
        <div className="content-container">
          <h2 className="content-header">{"You have been unsubscribed from {{ company_name }}!"}</h2>
          <p className="content-text">You won’t receive any more emails from us.</p>
          <form onSubmit={handleSubmit}>
            <h3>Please tell us why you unsubscribed:</h3>
            {reasons.map((reasonOption, index) => (
              <div key={index} className="reason-option">
                <input
                  type="radio"
                  name="reason"
                  value={reasonOption}
                  onChange={() => setReason(reasonOption)}
                />
                <label>{reasonOption}</label>
              </div>
            ))}
            {reason === 'Other' && (
              <textarea
                placeholder="Please fill in your reason"
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
                className="reason-textarea"
              />
            )}
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
          <p>If this was a mistake, you can <span onClick={handleResubscribe} className="resubscribe-link">resubscribe</span>.</p>
        </div>
      )}
    </div>
  );
};

export default UnsubscribeComponent;
