import React, { useEffect, useState } from 'react';
import AppCard from '../../components/AppCard/AppCard';
import AppConnect from '../../components/AppCard/AppConnect';
import CloseBtn from '../../assets/icons/icon-back.svg';
import { Button } from '@mui/material';
import AppConnectSuccess from '../../components/AppCard/AppConnectSuccess';
import ResourceApi from '../../api/resourceapi';

const AppIntegration = () => {
  const [isConnectOpen, setIsConnectOpen] = useState(false);
  const [openedApp, setOpenedApp] = useState('');
  const [isShopifyConnected, setIsShopifyConnected] = useState(false);
  const [isWooCommerceConnected, setIsWooCommerceConnected] = useState(false);

  useEffect(() => {
    const getShopifyConnectionStatus = async () => {
      try {
        const response = await ResourceApi.shopify.getConnectionStatus();
        console.log(response, 'After checking for connection status');
        setIsShopifyConnected(response.data.connected);
      } catch (err) {
        console.error(err);
      }
    };
    getShopifyConnectionStatus();
  }, []);

  const removeIntegration = async () => {
    console.log("Remove Integration clicked");
    try{
        const response = await ResourceApi.shopify.disconnectShopify();
        setIsConnectOpen(false)
        console.log(response.data);
    }catch(e){
        console.log(e);
    }
  }

  return (
    <>
      <div className="header">
        <div className="header-left">
          {isConnectOpen ? (
            <div>
              <button
                className="back-icon common-btn"
                onClick={() => setIsConnectOpen(false)}
                style={{ border: 'none' }}
              >
                <img
                  src={CloseBtn}
                  alt="Back button"
                  style={{ transform: 'rotate(-90deg)' }}
                />
              </button>
            </div>
          ) : null}
          <span className="template-name">
            
            {isConnectOpen
              ? `App Integrations - ${openedApp}`
              : 'App Integrations'}
          </span>
        </div>

        <div className="header-center"></div>

        <div className="header-right">
          {isConnectOpen &&
          ((isShopifyConnected && openedApp === 'Shopify') ||
            (isWooCommerceConnected && openedApp === 'WooCommerce')) ? (
            <Button className="btn btn-light" onClick={removeIntegration}>Remove Integrations</Button>
          ) : null}
        </div>
      </div>
      {isConnectOpen ? (
        (isShopifyConnected && openedApp === 'Shopify') ||
        (isWooCommerceConnected && openedApp === 'WooCommerce') ? (
          <AppConnectSuccess name={openedApp} />
        ) : (
          <AppConnect name={openedApp} />
        )
      ) : (
        <div className="app-integration-body">
          <AppCard
            setIsConnectOpen={setIsConnectOpen}
            setOpenedApp={setOpenedApp}
            name="Shopify"
            isShopifyConnected={isShopifyConnected}
          />
          <AppCard
            setIsConnectOpen={setIsConnectOpen}
            setOpenedApp={setOpenedApp}
            name="WooCommerce"
          />
        </div>
      )}
    </>
  );
};

export default AppIntegration;
