import React, { useEffect } from 'react';
import Header from '../../components/Header/Header';
import EmptyState from '../../components/EmptyState';
import iconAddCircleWhite from '../../assets/icons/icon-add-white.svg';
import createAuto from '../../assets/images/undraw_preferences_re_49in 1.png';
import iconAddCircle from '../../assets/icons/icon-add-circle.svg';
import iconSettings from '../../assets/icons/icon-settings.svg';
import followingUser from '../../assets/images/undraw_innovative_re_rr5i 1.png';
import CustomDialog from '../../components/Dialog/Dialog';
import { useState } from 'react';
import FormRenderer from '../../components/FormRenderer';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ResourceApi from '../../api/resourceapi';
import DynamicTabs from '../../components/Tab/Tab';
import Table from '../../components/Table';
import formatDate from '../../utils/date-utils';
import Status from '../../components/Status/Status';
import Message from '../../components/Message';
const Automation = () => {
  const [automationData, setAutomationData] = useState([]);
  const [isAutomationLoading, setIsAutomationLoading] = useState(true);
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    type: '',
    show: false,
    message: '',
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onBlur' });
  const [createDialog, setCreateDialog] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const [deleteDialog, setDeleteDialog] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const onCloseDialog = () => {
    setCreateDialog((prevConfig) => ({ ...prevConfig, open: false }));
  };
  const CloseDeleteDialog = () => {
    setDeleteDialog((prevConfig) => ({ ...prevConfig, open: false }));
  };

  const deleteAutomation = (id) => {
    ResourceApi.automation.deleteById(id).then(() => {
      console.log('Automation deleted successfully');
      getAutomationData();
      CloseDeleteDialog();
      setAlert({
        type: 'success',
        show: true,
        message: 'Automation deleted successfully',
      });
    });
  };

  const getAutomationData = () => {
    ResourceApi.automation.get().then(({ data }) => {
      console.log(data);
      if (data.length > 0) {
        setAutomationData(data);
        setIsAutomationLoading(false);
      }
    });
  };
  useEffect(() => {
    getAutomationData();
  }, []);
  const createAutomationDialog = () => {
    setCreateDialog({
      title: 'Create Automation',
      open: true,
      onClose: onCloseDialog,
      type: 'createAutomation',
      actions: [
        {
          label: 'Cancel',
          onClick: onCloseDialog,
          classes: 'btn-outline dark-border',
        },
        {
          label: 'Create',
          onClick: () => {
            handleSubmit(createAutomation)();
          },
          classes: 'btn-primary',
        },
      ],
    });
  };

  const createAutomation = (data) => {
    console.log(data);
    ResourceApi.automation.create(data).then(({ data }) => {
      console.log(data);
      onCloseDialog();
      getAutomationData();
      setAlert({
        type: 'success',
        show: true,
        message: 'Automation created successfully',
      });
      navigate(`/automations/${data.id}`);
    });
    // navigate('/automations/flow');
  };

  const showEmptyState = !isAutomationLoading && automationData.length === 0;

  const tabs = ['All', 'Active', 'Inactive'];
  const panels = [
    <div className="main-content-container">
      <Table
        headers={[
          {
            numeric: false,
            key: 'name',
            disablePadding: false,
            label: 'Name',
            onClick: (row) => navigate(`/automations/${row.id}`),
            render: (row) => `${row.name}`,
          },
          {
            numeric: false,
            key: 'description',
            disablePadding: false,
            label: 'Description',
            render: (row) =>
              `${row.description === null ? '_' : row.description}`,
          },
          {
            numeric: false,
            key: 'created',
            disablePadding: false,
            label: 'Created On',
            render: (row) => {
              return <div>{formatDate(row.created, true)}</div>;
            },
          },

          {
            numeric: false,
            key: 'Contacts Activities',
            disablePadding: false,
            label: 'Contacts Activities',
          },
          {
            numeric: false,
            key: 'status',
            disablePadding: false,
            label: 'Status',
            render: ({ status }) => (status ? <Status status={status} /> : '_'),
          },
        ]}
        rows={automationData}
        isLoading={isAutomationLoading}
        rowActions={[
          {
            label: 'Delete',
            icon: 'icon-delete',
            value: 'delete',
            onClick: (row) => {
              setDeleteDialog({
                open: true,
                title: 'Delete Automations',
                onClose: CloseDeleteDialog,
                type: 'deleteAutomation',
                content: `Are you sure you want to delete ${[row.id].length} automation(s)?`,
                actions: [
                  {
                    label: 'Cancel',
                    onClick: CloseDeleteDialog,
                    classes: 'btn-outline dark-border',
                  },
                  {
                    label: 'Delete',
                    onClick: () => deleteAutomation([row.id]),
                    classes: 'btn-primary',
                  },
                ],
              });
            },
          },
        ]}
      />
    </div>,
    <div></div>,
    <div></div>,
  ];

  return (
    <>
      <Message
        open={alert.show}
        type={alert.type}
        message={alert.message}
        onMessageClose={() => {
          setAlert({
            type: '',
            show: false,
            message: '',
          });
        }}
      />
      <CustomDialog
        {...deleteDialog}
        content={
          deleteDialog.type === 'deleteAutomation' ? deleteDialog.content : ''
        }
      />
      <CustomDialog
        {...createDialog}
        content={
          createDialog.type === 'createAutomation' ? (
            <FormRenderer
              register={register}
              watch={watch}
              errors={errors}
              fields={[
                {
                  type: 'text',
                  name: 'name',
                  placeHolder: 'type here',
                  label: 'Name',
                  validations: { required: 'Name is required' },
                },
                {
                  type: 'text',
                  name: 'description',
                  placeHolder: 'type here',
                  label: 'Description',
                  validations: {},
                },
              ]}
            />
          ) : (
            createDialog.content
          )
        }
      />
      <Header
        title="Automations"
        actions={[
          {
            label: 'Create Automations',
            isPrimary: true,
            icon: iconAddCircleWhite,
            onClick: () => {
              createAutomationDialog();
            },
          },
        ]}
      />

      {showEmptyState && (
        <EmptyState
          title="Create Automation"
          description="Connect with customers via automated welcome emails, product promotions, greetings, and special offers."
          options={[
            {
              title: 'Setup your email deliverability',
              description:
                'Launch and personalize a site using our easy-to-learn website builder.',
              action: {
                label: 'Setup email',
                icon: iconSettings,
                onClick: () => {},
              },
              banner: createAuto,
              bannerBackground: 'yellow',
            },
            {
              title: 'Start designing email',
              description:
                'Launch and personalize a site using our easy-to-learn website builder.',
              action: {
                label: 'Create email',
                icon: iconAddCircle,
                onClick: () => {},
              },
              banner: followingUser,
              bannerBackground: 'green',
            },
          ]}
        />
      )}
      {!showEmptyState && (
        <>
          <DynamicTabs tabs={tabs} panels={panels} />
        </>
      )}
    </>
  );
};

export default Automation;
