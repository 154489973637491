import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/Header/Header';
import { Stack, IconButton, Icon, Typography, Card } from '@mui/material';
import iconBack from '../../assets/icons/icon-back.svg';
import iconEdit from '../../assets/icons/icon-edit.svg';
import iconError from '../../assets/icons/icon-error.svg';
import preview from '../../assets/images/Group 1171275255.png';
import FormRenderer from '../../components/FormRenderer';
import { useForm } from 'react-hook-form';
import ListItem from './Components/List';
import CustomDialog from '../../components/Dialog/Dialog';
import CustomMenu from '../../components/Menu/Menu';
import OTP from './Components/OTP';
import ResourceApi from '../../api/resourceapi';
import { useNavigate, useParams } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Switcher from './Components/Switcher';
import CommonOverlay from '../../components/Overlay/Overlay';
import iconClose from '../../assets/icons/icon-cross.svg';
import Sender from './Components/Sender';
import Progress from '../../components/Progress/Progess';
import { TextField } from '@mui/material';
import iconArrow from '../../assets/icons/icon-arrow.svg';
import appIcon from '../../assets/icons/app-icon.svg';
import CardContainer from '../../components/CardContainer/CardContainer';
import { useSelector } from 'react-redux';
import { usePrebuiltLayouts } from '../../hooks/usePrebuiltLayouts';
import CreateEmailDialog from '../templates/PrebuiltTemplates/components/CreateEmailDialog';
import { useLocation } from 'react-router-dom';
const CampaignCreate = () => {
  const { id } = useParams();
  //  const [otp, setOtp] = useState('');
  const [campaign, setCampaign] = useState({});
  const [isCampaignLoading, setIsCampaignLoading] = useState(true);
  const [addSender, setAddSender] = useState(false);
  const [showOverlay, setshowOverlay] = useState(false);
  const [istemplate, setIsTemplate] = useState(true);
  const [isSavedTemplate, setIsSavedTemplate] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onBlur' });
  const [dialogConfig, setDialogConfig] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });

  const [subjectDialog, setsubjectDialog] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const [timeDialog, setTimeDialog] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const campaignRef = useRef(campaign); // Use ref to store the latest campaign state

  useEffect(() => {
    // Whenever campaign state updates, also update the ref
    campaignRef.current = campaign;
  }, [campaign]);

  const templateId = useSelector((state) => state.campaignTemplateId);

  useEffect(() => {
    fetchCampaignData();
    console.log(templateId);
    if (templateId !== '') {
      addFieldToCampaign('emailTemplateId', templateId);
    }
  }, []);

  const { fetchPrebuiltLayout } = usePrebuiltLayouts();

  useEffect(() => {
    fetchPrebuiltLayout();
  }, [fetchPrebuiltLayout]);

  const isCreateTemplateDialog = useSelector(
    (state) => state.isCreateTemplateDialog,
  );

  const prebuiltLayoutData = useSelector((state) => state.prebuiltLayoutData);
  const prebuiltCards = prebuiltLayoutData.map((template, index) => {
    return {
      name: template.name,
      content: `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Preview</title>
      <style>
      </style>
    </head>
    <body>
      ${template.htmlContent}
    </body>
    </html>
  `,
      showPreviewButton: true,
      id: template.id,
    };
  });

  const fetchCampaignData = () => {
    ResourceApi.campaign.getById(id).then(({ data }) => {
      setIsCampaignLoading(false);
      setCampaign(data);
      console.log(data);
      updateSteps(data);
    });
  };

  const updateSteps = (data) => {
    setSteps((prevSteps) => {
      const updatedSteps = prevSteps.map((step) => {
        if (data.subject && step.text.title === 'Subject') {
          return { ...step, status: true };
        }
        if (data.from && step.text.title === 'From') {
          return { ...step, status: true };
        }
        if (data.contactResources && step.text.title === 'Recipients') {
          return { ...step, status: true };
        }
        if (data.emailTemplateId && step.text.title === 'Design') {
          return { ...step, status: true };
        }
        if (data.scheduledTime && step.text.title === 'Send Time') {
          return { ...step, status: true };
        }
        return step;
      });
      return updatedSteps;
    });
  };

  const updateCampaignData = (data) => {
    ResourceApi.campaign
      .update(id, data)
      .then(({ data }) => {
        console.log('Campaign updated');
        console.log(data);
        setCampaign(data);
        updateSteps(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dialogClose = () => {
    setDialogConfig((prevConfig) => ({ ...prevConfig, open: false }));
  };
  const subjectDialogClose = () => {
    setsubjectDialog((prevConfig) => ({ ...prevConfig, open: false }));
  };
  const timeDialogClose = () => {
    setTimeDialog((prevConfig) => ({ ...prevConfig, open: false }));
  };

  const initialSteps = [
    {
      text: {
        title: 'From',
        description: 'Set sender email details to send email',
      },
      button: {
        label: 'Add from',
        onClick: () => {
          setDialogConfig({
            title: 'From',
            open: true,
            onClose: dialogClose,
            type: 'campaign',
            actions: [],
          });
        },
      },
      status: false,
    },
    {
      text: {
        title: 'Recipients',
        description: 'The people who will receive this campaign email',
      },
      button: {
        label: 'Add recipients',
        onClick: () => {
          console.log(campaignRef.current);
        },
      },
      status: false,
    },
    {
      text: {
        title: 'Subject',
        description: 'Add a subject line for this campaign.',
      },
      button: {
        label: 'Add subject',
        onClick: () => {
          setsubjectDialog({
            title: 'Subject',
            open: true,
            onClose: subjectDialogClose,
            type: 'campaignSubject',
            actions: [
              {
                label: 'Save',
                onClick: () => handleSubmit(updateSubject)(),
                classes: 'btn-primary',
              },
            ],
          });
        },
      },
      status: false,
    },
    {
      text: {
        title: 'Design',
        description: 'Design the Content for your email.',
      },
      button: {
        label: 'Start design',
        onClick: () => {
          console.log(steps[3].status);
          console.log(campaignRef.current);
          if (campaignRef.current.emailTemplateId !== null) {
            navigate(`/email-builder/orange/66db3c1f1c8982547d7c0b43`, {
              state: {
                from: location,
              },
            });
          } else {
            setshowOverlay(true);
          }
        },
      },
      status: false,
    },
    {
      text: {
        title: 'Send Time',
        description: `When should we send this email - ${campaign.scheduledTime}`,
      },
      button: {
        label: 'Edit send time',
        onClick: () => {
          setTimeDialog({
            title: 'Send Time',
            open: true,
            onClose: timeDialogClose,
            type: 'campaignTime',
            actions: [],
          });
        },
      },
      status: false,
    },
  ];
  const [steps, setSteps] = useState(initialSteps);
  const updateStatus = (title) => {
    console.log('updateStatus');
    setSteps((prevSteps) =>
      prevSteps.map((step) =>
        step.text.title === title ? { ...step, status: true } : step,
      ),
    );
    console.log(steps);
  };

  const updateSubject = (data) => {
    addFieldToCampaign('subject', {
      subjectLine: data.subjectLine,
      previewText: data.previewText,
    });
    updateStatus('Subject');
    subjectDialogClose();
  };

  const Form = () => {
    const [statusAnchorElement, setStatusAnchorElement] = useState(null);
    const [email, setEmail] = useState('marketing@adflipr.com');
    const [name, setName] = useState('Adflipr');
    const [sender, setSender] = useState([
      {
        label: 'Adflipr - adflipr@gmail.com',
        value: 'adflipr@gmail.com',
      },
      {
        icon: 'icon-add-circle',
        label: 'Add a new sender',
        value: 'add sender',
      },
    ]);
    const getSender = () => {
      ResourceApi.sender.get({ name: name, email: email }).then((data) => {
        console.log(data.data);
        const arr = data.data;
        const transformedArray = arr.map((obj) => ({
          value: obj.email,
          label: `${obj.name} - ${obj.email}`,
        }));
        const newArray = sender;
        newArray.splice(newArray.length - 1, 0, ...transformedArray);
        setSender(newArray);
      });
    };
    useEffect(() => {
      getSender();
    }, []);

    const updateFrom = () => {
      addFieldToCampaign('from', {
        emailId: email,
        name: name,
      });
      updateStatus('From');
    };

    return (
      <>
        <div className="error-notice">
          <img src={iconError} alt="error icon" />
          <div>
            <div className="error-label">
              Email will be sent to contacts from sender@adflipr.com
            </div>
            <div className="error-description">
              Send your emails with your custom domain to increase conversions.
            </div>
            <div className="error-link">Connect your domain</div>
          </div>
        </div>
        <div className="input-container">
          <div className="email-dialog-label">Email address</div>
          <TextField
            variant="outlined"
            placeholder="Text"
            value={email}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <img
                  src={iconArrow}
                  style={{ width: '16px', height: '16px' }}
                />
              ),
            }}
            onClick={(event) => {
              setStatusAnchorElement(event.currentTarget);
            }}
          />
          <div className="input-description">
            Use sender email id to send email
          </div>
        </div>
        <div className="input-container">
          <div className="email-dialog-label">Name</div>
          <input
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            className="email-dialog-input"
          />
          <div className="input-description">
            Use something subscribers will instantly recognize, like your
            company name.
          </div>
        </div>
        <button
          className="btn btn-primary"
          onClick={() => {
            updateFrom();
            dialogClose();
          }}
        >
          Save
        </button>
        <CustomMenu
          onClose={() => {
            setStatusAnchorElement(null);
          }}
          onSelect={(value) => {
            setStatusAnchorElement(null);
            if (value === 'add sender') {
              dialogClose();

              setAddSender(true);
            } else {
              setEmail(value);
            }
          }}
          options={sender}
          anchorEl={statusAnchorElement}
          inputBox={statusAnchorElement}
        />
      </>
    );
  };

  const addFieldToCampaign = (fieldName, fieldValue) => {
    console.log(fieldName);
    console.log(fieldValue);
    let updatedCampaign = {
      ...campaign, // Keep existing properties
      [fieldName]: fieldValue,
    };
    console.log(updatedCampaign);
    updateCampaignData(updatedCampaign);
    setCampaign((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  return (
    <>
      {isCreateTemplateDialog ? <CreateEmailDialog /> : ''}
      {addSender && <Sender setAddSender={setAddSender} />}
      <CustomDialog
        {...dialogConfig}
        content={
          dialogConfig.type === 'campaign' ? <Form /> : dialogConfig.content
        }
      />

      <CustomDialog
        {...subjectDialog}
        content={
          subjectDialog.type === 'campaignSubject' ? (
            <FormRenderer
              width="408px"
              register={register}
              watch={watch}
              errors={errors}
              fields={[
                {
                  type: 'text',
                  name: 'subjectLine',
                  placeHolder: 'Type here',
                  label: 'Subject line',
                  description: `The subject line, the first thing recipients see, should clearly describe the email's content. Keep it under 50 characters for the best impact.`,
                  validations: { required: 'Subject line is required' },
                },
                {
                  type: 'text',
                  name: 'previewText',
                  placeHolder: 'Type here',
                  label: 'Preview Text',
                  description: `The preview text, offering more email details, should be under 100 characters for maximum impact`,
                  validations: {
                    required: 'Preview Text is required',
                  },
                },
              ]}
            />
          ) : (
            subjectDialog.content
          )
        }
      />
      <CustomDialog
        {...timeDialog}
        content={
          timeDialog.type === 'campaignTime' ? (
            <Switcher
              dateAndTime={(date) => {
                if (date !== 'sendNow') {
                  updateStatus('Send Time');
                  timeDialogClose();
                  addFieldToCampaign('scheduledTime', date);
                }
              }}
            ></Switcher>
          ) : (
            timeDialog.content
          )
        }
      />
      {showOverlay && (
        <CommonOverlay
          open={true}
          onClose={() => {
            setshowOverlay(false);
          }}
        >
          <div className="template-layout-wrapper">
            <Header
              title={
                <Stack direction="row" gap={2} alignItems="center">
                  <img src={appIcon} alt="icon-app" />

                  <Typography
                    variant="h1"
                    sx={{
                      lineHeight: 1.2,
                      fontWeight: 600,
                      fontSize: '1.25rem',
                      fontFamily: 'Lato',
                    }}
                  >
                    | Choose a template for your email
                  </Typography>
                </Stack>
              }
              actions={[
                {
                  icon: iconClose,
                  onClick: () => {
                    setshowOverlay(false);
                  },
                  classes: 'btn common-btn',
                  isPrimary: false,
                },
              ]}
            />
            <div className="campaign-design">
              <div className="campaign-sidebar">
                <div
                  onClick={() => {
                    setIsTemplate(true);
                    setIsSavedTemplate(false);
                  }}
                  className={` ${istemplate ? 'active' : ''}`}
                >
                  AdFlipr Templates
                </div>
                <div
                  onClick={() => {
                    setIsSavedTemplate(true);
                    setIsTemplate(false);
                  }}
                  className={` ${isSavedTemplate ? 'active' : ''}`}
                >
                  Saved Templates
                </div>
              </div>
              <div className="main-content">
                <div className="heading">AdFlipr Templates</div>
                <div className="prebuilt-template-wrapper ">
                  <div className="prebuilt-template-card-container">
                    <CardContainer
                      cards={prebuiltCards}
                      isStartFromBlank={true}
                      setTemplateLayout={setshowOverlay}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonOverlay>
      )}
      {!showOverlay && (
        <>
          
          <Header
            title={
              <Stack direction="row" gap={2} alignItems="center">
                <img
                  src={iconBack}
                  onClick={() => {
                    navigate('/campaigns');
                  }}
                  alt="iconBack"
                />

                <Typography
                  variant="h1"
                  sx={{ lineHeight: 1.2, fontWeight: 600, fontSize: '1.25rem' }}
                >
                  {campaign.name}
                </Typography>
                <img
                  src={iconEdit}
                  alt="iconEdit"
                  style={{ width: '20px', height: '20px' }}
                />
                <Chip
                  size="small"
                  label={campaign.status}
                  sx={{
                    backgroundColor: '#FCF6EB',
                    color: '#B68F40',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textTransform: 'none', // Prevents label from transforming to uppercase
                  }}
                />
              </Stack>
            }
            actions={[
              {
                label: 'Preview & Test',
                isPrimary: false,
              },
              {
                label: 'Publish',
                isPrimary: true,
                //onClick: onContactFormOpen
              },
            ]}
          />
          {isCampaignLoading && <Progress />}
          <div className="campaign-main">
            <div className="campaign-list">
              {steps.map((step) => {
                return (
                  <ListItem
                    Text={step.text}
                    button={step.button}
                    status={step.status}
                  />
                );
              })}
            </div>

            <div>
              <img src={preview} alt="preview" style={{ width: '100%' }} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CampaignCreate;
