import { DateTime } from 'luxon';
import { socialTypes } from './customBlocks/socialTypes';
import Sortable from 'sortablejs';
import iconBold from '../../../../assets/icons/icon-bold.svg';
import iconItalic from '../../../../assets/icons/icon-italic.svg';
import iconUnderline from '../../../../assets/icons/icon-underline.svg';
import iconStrikethrough from '../../../../assets/icons/icon-Strikethrough.svg';
import iconHilite from '../../../../assets/icons/icon-Highlight.svg';
import iconUL from '../../../../assets/icons/icon-bullet.svg';
import iconOL from '../../../../assets/icons/icon-bullet-number.svg';
import iconSuperscript from '../../../../assets/icons/icon-Superscript.svg';
import iconSubscript from '../../../../assets/icons/icon-Subscript.svg';
import iconFontcolor from '../../../../assets/icons/icon-Textcolor.svg';
import iconLink from '../../../../assets/icons/icon-link.svg';
import { imageApi } from '../../../../api/imageapi';

// Function to move blocks to divs
export const moveBlocksToDivs = (editor) => {
  if (!editor) {
    console.error('Editor is not initialized');
    return;
  }

  const blockManager = editor.BlockManager;
  if (!blockManager) {
    console.error('BlockManager is not initialized');
    return;
  }

  const allBlocks = blockManager.getAll();

  const filteredBlocks = allBlocks.filter(
    (block) => block.get('category').attributes.id === 'Blocks',
  );
  const filteredContainers = allBlocks.filter(
    (block) => block.get('category').attributes.id === 'Containers',
  );
  const filteredLayouts = allBlocks.filter(
    (block) => block.get('category').attributes.id === 'Layouts',
  );
  const filteredPrebuilts = allBlocks.filter(
    (block) => block.get('category').attributes.id === 'Prebuilt',
  );

  const newBlocksEl = blockManager.render(filteredBlocks, { external: true });
  const newContainersEl = blockManager.render(filteredContainers, {
    external: true,
  });
  const newLayoutsEl = blockManager.render(filteredLayouts, { external: true });
  const newPrebuiltEl = blockManager.render(filteredPrebuilts, {
    external: true,
  });

  const containerDivs = document.querySelector('#containers-block');
  const layoutDivs = document.querySelector('#layouts-block');
  const blockDivs = document.querySelector('#block-div');
  const prebuiltDivs = document.querySelector('#prebuilt-block');

  containerDivs.innerHTML = '';
  layoutDivs.innerHTML = '';
  blockDivs.innerHTML = '';
  prebuiltDivs.innerHTML = '';

  blockDivs.appendChild(newBlocksEl);
  containerDivs.appendChild(newContainersEl);
  layoutDivs.appendChild(newLayoutsEl);
  prebuiltDivs.appendChild(newPrebuiltEl);
};

export function setupAssetManagerButtonBehavior(editor) {
  const am = editor.AssetManager;
  const originalOpen = am.open;

  am.open = async function () {
    await syncAssetsWithServer(editor);
    originalOpen.apply(this, arguments);
    
    // Use setTimeout to ensure DOM is ready
    setTimeout(() => {
      addButtonsToAssets();
      initializeEventListeners();
    }, 0);
    
    // Use setTimeout to ensure DOM is ready
    setTimeout(() => {
      addButtonsToAssets();
      initializeEventListeners();
    }, 0);
  };

  function addButtonsToAssets() {
    document.querySelectorAll('.gjs-am-close').forEach((btn) => (btn.textContent = ''));
    const btnClose = document.querySelector('.gjs-mdl-btn-close');
    if(btnClose){
      btnClose.textContent = '';
    }
    const mdlTitle = document.querySelector('.gjs-mdl-title');
    if(mdlTitle){
      mdlTitle.textContent = 'Select Image to Upload';
    }
    
    document.querySelectorAll('.gjs-am-assets .gjs-am-asset .gjs-am-preview-cont:not(.button-added)').forEach((asset) => {
      const useButton = document.createElement('button');
      useButton.className = 'use-image-button';
      useButton.innerText = 'Use Image';
      asset.classList.add('button-added');
      asset.appendChild(useButton);
    });
  }

  function initializeEventListeners() {
    document.querySelectorAll('.gjs-am-assets .gjs-am-asset').forEach((asset) => {
      const useButton = asset.querySelector('.use-image-button');
      
      asset.addEventListener('mouseenter', () => {
        if (useButton) useButton.style.display = 'block';
      });
      
      asset.addEventListener('mouseleave', () => {
        if (useButton) useButton.style.display = 'none';
      });
      
      if (useButton) {
        useButton.addEventListener('click', (e) => handleUseImageClick(e, asset, am));
      }
    });
  }

  function handleUseImageClick(e, asset, am) {
    e.preventDefault();
    e.stopPropagation();
    
    const backgroundImageStyle = asset.style.backgroundImage || window.getComputedStyle(asset).backgroundImage;
    const match = backgroundImageStyle.match(/url\("?(.+?)"?\)/);
    
    if (match && match[1]) {
      const src = match[1];
      if (am.onSelect) {
        am.onSelect(src);
      } else {
        const target = am.getTarget();
        if (target) {
          target.set('src', src);
        }
      }
      am.close();
    } else {
      console.error('No background image found in the asset');
    }
  }

  // Start observing for dynamic content changes
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        addButtonsToAssets();
        initializeEventListeners();
      }
    });
  });

  // Start observing the document with the configured parameters
  observer.observe(document.body, { childList: true, subtree: true });
}

export const syncAssetsWithServer = async (editor) => {
  const am = editor.AssetManager;

  // Log current assets in AssetManager
  console.log('Current assets in AssetManager:', am.getAll().toArray());

  // Clear all assets from AssetManager
  // am.getAll().reset();

  try {
    // Fetch assets from the server
    const response = await imageApi.getImage();
    console.log('Images fetched from server:', response.data);

    // Add fetched assets to AssetManager
    response.data.forEach(item => {
      am.add({
        src: item.url,
        name: item.name,
        id: item.id,
      });
    });

    // Log updated assets in AssetManager
    console.log('Updated assets in AssetManager:', am.getAll().toArray());
  } catch (error) {
    console.error('Error fetching assets from server:', error);
  }
};

export const handleAssetUpload = async (e, editor) => {
  const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
  const am = editor.AssetManager;

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    
    try {
      const formData = new FormData();
      formData.append('image', file);
      const response = await imageApi.uploadImage(formData);
      const [uploadedFile] = response.data;

      const newAsset = {
        src: uploadedFile.url,
        name: uploadedFile.name,
        id: uploadedFile.id,
      };
      am.add(newAsset);

      console.log('Image Upload Successful');
      console.log('Updated assets in AssetManager:', am.getAll().toArray());
      console.log('Updated server images:', await imageApi.getImage());
      
      // Force a re-render of the asset manager
      am.render();
    } catch (error) {
      console.error('Error uploading image', error);
    }
  }
};

export const handleAssetRemove = async (asset, editor) => {
  const am = editor.AssetManager;

  try {
    const assetId = asset.get('id');
    console.log('Asset ID:', assetId);
    
    // Delete the asset from the server
    await imageApi.deleteImage({ ids: [assetId] });
    console.log('Asset successfully deleted from server');

    // Remove the asset from the UI
    am.remove(asset);

    console.log('Updated assets in AssetManager:', am.getAll().toArray());
    console.log('Updated server images:', await imageApi.getImage());
  } catch (error) {
    console.error('Error deleting image', error);
    // If server deletion fails, don't remove the asset from the UI
    alert('Failed to delete the image from the server. Please try again.');
  }
};

export function customizeRTEIcons() {
  const customIcons = {
    Bold: iconBold,
    Italic: iconItalic,
    Underline: iconUnderline,
    'Strike-through': iconStrikethrough,
    Hyperlink: iconLink,
    'Font Color': iconFontcolor,
    'Highlight Color': iconHilite,
    Subscript: iconSubscript,
    Superscript: iconSuperscript,
    'Ordered List': iconOL,
    'Unordered List': iconUL,
  };

  document.querySelectorAll('.gjs-rte-action').forEach((btn) => {
    const title = btn.getAttribute('title');
    const iconSrc = customIcons[title];

    // Only customize buttons that have matching titles
    if (iconSrc) {
      btn.innerHTML = ''; // Clear existing content
      const img = document.createElement('img');
      img.src = iconSrc;
      img.alt = title;
      img.title = title;
      img.width = 18;
      img.height = 18;
      btn.appendChild(img);
    }
  });
}
//Set Border Settings Trait Visibility on the basis of border-style value

export const setBorderTraitVisibility = (editor, value) => {
  const selectedComponent = editor.getSelected();

  if (value) {
    // Find the index of border-style trait
    const traits = selectedComponent.get('traits').models;
    const borderStyleTraitIndex = traits.findIndex(
      (trait) => trait.id === 'border-style',
    );

    // Add or show border-width and border-color traits
    selectedComponent.addTrait(
      {
        type: 'border-width',
        name: 'border-width',
        label: 'Border Width',
        changeProp: 1,
      },
      { at: borderStyleTraitIndex + 1 },
    );

    selectedComponent.addTrait(
      {
        type: 'border-color',
        name: 'border-color',
        label: 'Border Color',
      },
      { at: borderStyleTraitIndex + 2 },
    );
  } else {
    // Remove or hide border-width and border-color traits
    selectedComponent.removeTrait('border-width');
    selectedComponent.removeTrait('border-color');
  }
};

//Section Trait Manager
export const renderSectionTableTraitManager = (selectedComponent, editor) => {
  // Clear existing traits
  selectedComponent.set('traits', []);
  // Create a container trait for section-width-delete types
  selectedComponent.addTrait({
    type: 'trait-container',
    id: 'widths-container', // ID for the container
  });

  // Find all `td` elements
  const tds = selectedComponent.find('td.container');

  // Create a trait for each `td` to set its width, adding them to the container
  tds.forEach((td, index) => {
    selectedComponent.addTrait({
      type: 'section-width-delete',
      label: `Column ${index + 1}`,
      name: `width-${index}`,
      value: td.getEl().offsetWidth || '',
      tdIndex: index,
      parent: 'widths-container', // Parent ID set to the container
    });
  });

  // Add additional traits outside the container
  if (tds.length < 4) {
    selectedComponent.addTrait({
      type: 'add-column-button',
      text: 'Add Column',
      name: 'add-column',
    });
  }

  selectedComponent.addTrait({
    type: 'color-picker',
    label: 'Background',
    name: 'background-color',
  });

  selectedComponent.addTrait({
    type: 'column-gap',
    label: 'Column Gap',
    name: 'column-gap',
  });

  selectedComponent.addTrait({
    type: 'border-settings',
    label: '',
    name: 'border-settings',
  });

  selectedComponent.addTrait({
    type: 'border-radius-control',
    label: 'Border Radius',
    name: 'border-radius',
  });

  selectedComponent.addTrait({
    type: 'padding-control',
    label: 'Padding',
    name: 'padding',
  });


  initializeSorting(selectedComponent, editor, 'widths-container');

  
  // Re-render the traits panel
  editor.TraitManager.render();
};

export const addColumnToTable = (editor, trait) => {
  const selectedComponent = editor.getSelected();
  console.log(selectedComponent, 'when column added, should be table');
  if (selectedComponent && selectedComponent.attributes.tagName === 'table') {
    const tbody = selectedComponent.find('tbody')[0];

    if (tbody) {
      const sectionTableRow = selectedComponent.find('[type="section-table"] tr')[0];
      console.log(sectionTableRow, 'The tr where td is to be added');
      if (sectionTableRow) {
        sectionTableRow.append({
          type: 'cell',
          tagName: 'td',
          classes: 'container-td container',
          attributes: { type: 'column' },
          style: {
            width: '100%',
          },
          components: [],
        });
        editor.trigger('component:update', selectedComponent);
        
        // Re-render the trait manager to reflect the new column
        renderSectionTableTraitManager(selectedComponent, editor);
      }
    }
  }
};

export const deleteColumnFromTraitManager = (editor, trait) => {
  const selectedComponent = editor.getSelected();

  if (selectedComponent) {
    const tds = selectedComponent.find('td.container');
    const index = trait.get('tdIndex'); // Assuming 'tdIndex' is correctly set when the trait is added

    if (tds.length > 1 && tds[index]) {
      tds[index].remove(); // Delete the column directly here
      editor.trigger('component:update', selectedComponent); // Refresh the selected component
    }

    // Optional: Additional functionality after deleting a column
    if (selectedComponent.views[0].attr.type === 'section-table') {
      renderSectionTableTraitManager(selectedComponent, editor);
    }

    editor.TraitManager.render();
  }
};

export const changeColumnWidth = (editor, trait, event) => {
  const newValue = parseInt(event.target.value, 10);
  const selectedComponent = editor.getSelected();
  const index = parseInt(trait.get('name').split('-')[1], 10);
  const tds = selectedComponent.find('td');

  if (tds.length === 1) {
    alert('Cannot change width of single column');
    return;
  }

  if (tds[index]) {
    const oldValue = tds[index].getEl().offsetWidth;
    const difference = newValue - oldValue;
    let widths = tds.map((td) => td.getEl().offsetWidth);

    // Ensure the new value does not go below the minimum width
    if (newValue < 60) {
      alert('Minimum column width is 60px');
      return;
    }

    // Adjust the current column to the new value
    widths[index] = newValue;

    // Find the index of the largest column to adjust
    if (difference > 0) {
      let remainingDifference = difference;
      // Sort indexes by width descending, ignoring the current index
      const sortedIndexes = widths
        .map((width, i) => ({ index: i, width }))
        .filter((w) => w.index !== index)
        .sort((a, b) => b.width - a.width);

      for (let { index: i } of sortedIndexes) {
        if (remainingDifference <= 0) break;
        const maxDecrease = widths[i] - 60; // Minimum width is 60
        const decrease = Math.min(remainingDifference, maxDecrease);
        widths[i] -= decrease;
        remainingDifference -= decrease;
      }
    } else if (difference < 0) {
      const adjacentIndex = index === tds.length - 1 ? index - 1 : index + 1;
      widths[adjacentIndex] += Math.abs(difference);
    }

    // Apply the new styles and ensure the total width is maintained
    tds.forEach((td, idx) => {
      td.addStyle({ width: `${widths[idx]}px` });
    });

    // Trigger update and re-render the trait manager
    editor.trigger('component:update', selectedComponent);
    editor.TraitManager.render();
    renderSectionTableTraitManager(selectedComponent, editor);
  }
};

//Column Trait Manager
export const renderColumnTraitManager = (selectedComponent, editor) => {
  selectedComponent.set('traits', [
    {
      type: 'color-picker',
      label: 'Background Color',
      name: 'background-color',
    },
    {
      type: 'border-settings', // Use your custom trait
      label: 'Border Style',
      name: 'border-settings',
    },
    {
      type: 'border-radius-control',
      label: 'Border Radius',
      name: 'border-radius',
    },
    {
      type: 'padding-control',
      label: 'Padding',
      name: 'padding',
    },
  ]);

  editor.TraitManager.render();
};

//TextBlock Trait Manager
export const renderTextBlockTraitManager = (selectedComponent, editor) => {
  selectedComponent.set('traits', [
    {
      type: 'typography-settings',
      label: '',
      name: 'typography',
    },
    {
      type: 'padding-control',
      label: 'Padding',
      name: 'padding',
    },
  ]);

  editor.TraitManager.render();
};

//ImageBlock Trait Manager
export const renderImageBlockTraitManager = (selectedComponent, editor) => {
  selectedComponent.set('traits', []);

  const getImageComponent = (component) => {
    return component.find('img')[0] || component;
  };

  const imageSrc = getImageComponent(selectedComponent).get('src');
  if (imageSrc && !imageSrc.startsWith('<svg ')) {
    const imageComponent = getImageComponent(selectedComponent);
    if (imageComponent) {
      // Initialize image attributes and styles if not already set
      const initializeImage = () => {
        if (!imageComponent.get('originalWidth')) {
          const img = new Image();
          img.onload = function () {
            const containerEl = selectedComponent.getEl();
            const containerWidth = containerEl ? containerEl.offsetWidth : 0;
            
            imageComponent.set('originalWidth', this.width);
            imageComponent.set('originalHeight', this.height);

            if (this.width <= containerWidth) {
              imageComponent.addStyle({
                width: `${this.width}px`,
                height: `${this.height}px`,
                'max-width': '100%'
              });
            } else {
              imageComponent.addStyle({
                width: '100%',
                height: 'auto',
                'max-width': '100%'
              });
            }
            imageComponent.set('widthAuto', true);
            imageComponent.set('widthValue', 100);
          };
          img.src = imageSrc;
        } else {
          // If already initialized, just apply the stored state
          const isAuto = imageComponent.get('widthAuto') !== false;
          const savedWidth = imageComponent.get('widthValue');
          const originalWidth = imageComponent.get('originalWidth');
          const originalHeight = imageComponent.get('originalHeight');
          const containerEl = selectedComponent.getEl();
          const containerWidth = containerEl ? containerEl.offsetWidth : 0;

          if (isAuto) {
            if (originalWidth <= containerWidth) {
              imageComponent.addStyle({
                width: `${originalWidth}px`,
                height: `${originalHeight}px`,
                'max-width': '100%'
              });
            } else {
              imageComponent.addStyle({
                width: '100%',
                height: 'auto',
                'max-width': '100%'
              });
            }
          } else {
            const newWidth = (savedWidth / 100) * containerWidth;
            const newHeight = (newWidth / originalWidth) * originalHeight;
            imageComponent.addStyle({
              width: `${newWidth}px`,
              height: `${newHeight}px`,
              'max-width': '100%'
            });
          }
        }
      };

      initializeImage();

      selectedComponent.addTrait({
        type: 'asset-manager',
      });

      selectedComponent.addTrait({
        type: 'element-float',
        label: 'Position',
        name: 'element-float',
      });

      selectedComponent.addTrait({
        type: 'image-width-control',
        label: 'Width',
        name: 'width',
      });

      selectedComponent.addTrait({
        type: 'alt-text',
        label: 'Alt Text',
        name: 'alt',
      });

      selectedComponent.addTrait({
        type: 'image-link-input',
        label: 'Link',
        name: 'link',
        changeProp: 1,
      });

      selectedComponent.addTrait({
        type: 'image-border-settings',
        label: 'Border Style',
        name: 'border-settings',
      });

      selectedComponent.addTrait({
        type: 'image-border-radius-control',
        label: 'Border Radius',
        name: 'border-radius',
      });

      selectedComponent.addTrait({
        type: 'padding-control',
        label: 'Padding',
        name: 'padding',
      });
    }
  } else {
    selectedComponent.addTrait({
      type: 'asset-manager',
    });
  }

  editor.TraitManager.render();
};

export function wrapImageWithLink(component, url) {
  const imageElement = component.find('img')[0];

  if (imageElement) {
    const spanComponent = imageElement.closest('span.block-div');

    if (spanComponent) {
      const imageStyles = { ...imageElement.getStyle() };
      
      // Create the new anchor element as a GrapesJS component
      const anchorComponent = {
        tagName: 'a',
        attributes: {
          href: url,
          target: '_blank',
        },
        components: [imageElement],
      };

      // Replace the content of the span with the new anchor-wrapped image
      spanComponent.components().reset([anchorComponent]);

      // Reapply image styles
      const newImageElement = spanComponent.find('img')[0];
      if (newImageElement) {
        newImageElement.setStyle(imageStyles);
      }
    }
  }
}

export function unwrapImageFromLink(component) {
  const imageElement = component.find('img')[0];

  if (imageElement) {
    const spanComponent = imageElement.closest('span.block-div');

    if (spanComponent) {
      const imageStyles = { ...imageElement.getStyle() };
      
      // Replace the content of the span with the image element
      spanComponent.components().reset([imageElement]);

      // Reapply image styles
      imageElement.setStyle(imageStyles);
    }
  }
}

// assetManagerFunctions.js
function getTruncatedName(fullName, maxLength = 10) {
  if (fullName.length <= maxLength) {
    return fullName;
  }

  const extension = fullName.slice(fullName.lastIndexOf('.'));
  const baseName = fullName.slice(0, fullName.lastIndexOf('.'));

  if (baseName.length + extension.length <= maxLength) {
    // If the full name is too long, but the base name fits within the maxLength
    return fullName;
  }

  // Calculate the number of characters that can be taken from the base name
  const baseNameLength = maxLength - extension.length - 3; // 3 for the ellipsis
  return baseName.substring(0, baseNameLength) + '...' + extension;
}

export function updateUIWithImage(asset, elements, grapesjsEditor, containerWidth) {
  const { dropIcon, label, infoText, helperText, editButtonContainer, el } = elements;
  const src = asset.get('src');
  const name = asset.get('name') || 'Untitled';
  let imageWidth, imageHeight;
  const imageComponent = grapesjsEditor.getSelected().find('img')[0];

  const img = new Image();
  img.onload = () => {
    imageWidth = img.width;
    imageHeight = img.height;

    if (src && !src.startsWith('data:image/svg+xml;base64')) {
      imageComponent.addAttributes({ src: src });
      // Apply initial styles
      imageComponent.addStyle({
        width: 'auto',
        'max-width': '100%'
      });
      
      // Store the rendered width after a short delay
      setTimeout(() => {
        const renderedWidth = imageComponent.view.el.offsetWidth;
        imageComponent.set('renderedWidth', renderedWidth);
      }, 100);

      dropIcon.src = src;
      dropIcon.style.maxHeight = '100%';
      dropIcon.style.maxWidth = '100%';
      label.textContent = '';
      infoText.textContent = '';
      helperText.textContent = getTruncatedName(name, 36) || '';
      editButtonContainer.style.display = 'flex';
      el.style.cursor = 'default';
      el.style.padding = '0';
      el.style.height = '96px';
      el.style.display = 'flex';
      el.style.alignItems = 'center';
      el.style.justifyContent = 'center';
      el.style.overflow = 'hidden';
    } else {
      resetUI(elements, grapesjsEditor);
    }
  };
  img.src = src;
}

export function resetUI(elements, grapesjsEditor) {
  const {
    dropIcon,
    label,
    infoText,
    helperText,
    editButtonContainer,
    el,
    iconUpload,
  } = elements;
  dropIcon.src = iconUpload;
  label.textContent = 'Click to Upload Image';
  infoText.textContent = 'Allow only PNG, JPG, JPEG, and GIF';
  helperText.textContent = 'Max file size 3 MB and 2000 px in width';
  editButtonContainer.style.display = 'none';
  el.style.cursor = 'pointer';
  el.style.padding = '16px';
  el.style.maxHeight = 'auto';
}

//ButtonBlock Trait Manager
export const renderButtonBlockTraitManager = (selectedComponent, editor) => {
  selectedComponent.set('traits', [
    {
      type: 'element-float',
      label: 'Position',
      name: 'element-float',
    },
    {
      type: 'button-link-manager',
      label: `Link`,
      name: `link-manager`,
    },
    {
      type: 'font-family',
      label: 'Font Family',
      name: 'font-family',
    },
    {
      type: 'line-height',
      label: 'Line Height',
      name: 'line-height',
    },
    {
      type: 'child-width-control',
      label: 'Enable Child Width',
      name: 'enableChildWidth',
    },
    {
      type: 'child-padding-control',
      label: 'Padding',
      name: 'child-padding',
    },
    {
      type: 'padding-control',
      label: 'Container Spacing',
      name: 'padding',
    },
    {
      type: 'second-child-border-settings',
      label: 'Child Border Settings',
    },
    {
      type: 'second-child-border-radius-control',
      label: 'Border Radius',
      name: 'border-radius',
    },
  ]);

  editor.TraitManager.render();
};

//DividerBlock Trait Manager
export const renderDividerBlockTraitManager = (selectedComponent, editor) => {
  selectedComponent.set('traits', [
    {
      type: 'element-float',
      label: 'Position',
      name: 'element-float',
    },
    {
      type: 'child-width',
      label: 'Width',
      name: 'width',
    },
    {
      type: 'divider-height',
      label: 'Height',
      name: 'divider-height',
    },
    {
      type: 'divider-style',
      label: 'Style',
      name: 'divider-style', // This should match the CSS property name if you want it to reflect the style without extra mapping
    },
    {
      type: 'divider-color',
      label: 'Color',
      name: 'divider-color',
    },
    {
      type: 'padding-control',
      label: 'Padding',
      name: 'padding',
    },
  ]);

  editor.TraitManager.render();
};

//TimerBlock Trait Manager
export const renderTimerTraitManager = (selectedComponent, editor) => {
  const viewMode =
    selectedComponent.getAttributes()['data-view-mode'] || 'text';

  selectedComponent.set('traits', []);
  selectedComponent.addTrait({
    type: 'view-mode-toggler',
    label: 'Type',
    name: 'data-view-mode',
    value: viewMode,
  });

  selectedComponent.addTrait({
    type: 'datetime-local',
    label: 'End Date & Time',
    name: 'endTime',
  });

  selectedComponent.addTrait({
    type: 'select-timezone',
    label: 'Timezone',
    name: 'timezone',
  });

  selectedComponent.addTrait({
    type: 'select-language',
    label: 'Language',
    name: 'language',
  });

  selectedComponent.addTrait({
    type: 'color-picker',
    label: 'Background Color',
    name: 'background-color',
  });

  if (viewMode === 'card') {
    selectedComponent.addTrait({
      type: 'card-bgcolor',
      label: 'Card Background Color',
      name: 'card-bgcolor',
    });
  }

  selectedComponent.addTrait({
    type: 'timer-digit-color',
    label: 'Digit Color',
    name: 'digit-color',
  });

  selectedComponent.addTrait({
    type: 'timer-label-color',
    label: 'Label Color',
    name: 'label-color',
  });

  selectedComponent.addTrait({
    type: 'font-family',
    label: 'Font Family',
    name: 'font-family',
  });

  selectedComponent.addTrait({
    type: 'digit-font-size',
    label: 'Digit Font Size',
    name: 'digit-font-size',
  });

  selectedComponent.addTrait({
    type: 'label-font-size',
    label: 'Label Font Size',
    name: 'label-font-size',
  });

  selectedComponent.addTrait({
    type: 'padding-control',
    label: 'Padding',
    name: 'padding',
  });

  editor.TraitManager.render();
};

const countdownTranslations = {
  arabic: { days: 'أيام', hours: 'ساعات', minutes: 'دقائق', seconds: 'ثواني' },
  chinese: { days: '天', hours: '小时', minutes: '分钟', seconds: '秒' },
  czech: {
    days: 'Dny',
    hours: 'Hodiny',
    minutes: 'Minuty',
    seconds: 'Sekundy',
  },
  danish: {
    days: 'Dage',
    hours: 'Timer',
    minutes: 'Minutter',
    seconds: 'Sekunder',
  },
  dutch: {
    days: 'Dagen',
    hours: 'Uren',
    minutes: 'Minuten',
    seconds: 'Seconden',
  },
  english: {
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
  },
  estonian: {
    days: 'Päevad',
    hours: 'Tunnid',
    minutes: 'Minutid',
    seconds: 'Sekundid',
  },
  farsi: { days: 'روزها', hours: 'ساعت', minutes: 'دقیقه', seconds: 'ثانیه' },
  finnish: {
    days: 'Päivää',
    hours: 'Tuntia',
    minutes: 'Minuuttia',
    seconds: 'Sekuntia',
  },
  french: {
    days: 'Jours',
    hours: 'Heures',
    minutes: 'Minutes',
    seconds: 'Secondes',
  },
  german: {
    days: 'Tage',
    hours: 'Stunden',
    minutes: 'Minuten',
    seconds: 'Sekunden',
  },
  italian: {
    days: 'Giorni',
    hours: 'Ore',
    minutes: 'Minuti',
    seconds: 'Secondi',
  },
  korean: { days: '일', hours: '시간', minutes: '분', seconds: '초' },
  polish: {
    days: 'Dni',
    hours: 'Godziny',
    minutes: 'Minuty',
    seconds: 'Sekundy',
  },
  portuguese: {
    days: 'Dias',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  },
  russian: {
    days: 'Дни',
    hours: 'Часы',
    minutes: 'Минуты',
    seconds: 'Секунды',
  },
  spanish: {
    days: 'Días',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  },
  swedish: {
    days: 'Dagar',
    hours: 'Timmar',
    minutes: 'Minuter',
    seconds: 'Sekunder',
  },
  turkish: {
    days: 'Günler',
    hours: 'Saatler',
    minutes: 'Dakikalar',
    seconds: 'Saniyeler',
  },
  hindi: { days: 'दिन', hours: 'घंटे', minutes: 'मिनट', seconds: 'सेकंड' },
};

export const updateCountdownDisplay = (
  component,
  remainingTime,
  language = 'english',
) => {
  const labels = countdownTranslations[language];

  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

  const dayEl = component.find("[data-js='countdown-day']")[0];
  const hourEl = component.find("[data-js='countdown-hour']")[0];
  const minuteEl = component.find("[data-js='countdown-minute']")[0];
  const secondEl = component.find("[data-js='countdown-second']")[0];

  const dayBlock = component.find('.countdown-block')[0];

  const timerType = component.getAttributes()['data-view-mode'];

  const addSeparator = timerType !== 'card';

  if (dayEl) {
    const dayParent = dayEl.parent();
    if (remainingTime >= 1000 * 60 * 60 * 24) {
      dayBlock.addAttributes({ style: { display: 'block' } });
      dayEl.components(
        `${days < 10 ? '0' + days : days}${addSeparator ? ' :' : ''}`,
      );
      if (dayParent) {
        const dayLabel = dayParent.find('.countdown-label')[0];
        if (dayLabel) dayLabel.components(labels.days);
      }
    } else {
      // dayBlock.attributes.style.display = "none";
      dayBlock.addAttributes({ style: { display: 'none' } });
      // Remove the day countdown-block
      // if (dayParent) {
      //   // dayParent.remove();

      // }
    }
  }
  if (hourEl) {
    hourEl.components(
      `${hours < 10 ? '0' + hours : hours}${addSeparator ? ' :' : ''}`,
    );
    const hourParent = hourEl.parent();
    if (hourParent) {
      const hourLabel = hourParent.find('.countdown-label')[0];
      if (hourLabel) hourLabel.components(labels.hours);
    }
  }
  if (minuteEl) {
    minuteEl.components(
      `${minutes < 10 ? '0' + minutes : minutes}${addSeparator ? ' :' : ''}`,
    );
    const minuteParent = minuteEl.parent();
    if (minuteParent) {
      const minuteLabel = minuteParent.find('.countdown-label')[0];
      if (minuteLabel) minuteLabel.components(labels.minutes);
    }
  }
  if (secondEl) {
    secondEl.components(`${seconds < 10 ? '0' + seconds : seconds}`);
    const secondParent = secondEl.parent();
    if (secondParent) {
      const secondLabel = secondParent.find('.countdown-label')[0];
      if (secondLabel) secondLabel.components(labels.seconds);
    }
  }
};

export const restartCountdown = (selectedComponent) => {
  const endTime =
    selectedComponent?.getAttributes()['data-datetime'] ||
    DateTime.local().toISO();
  const timezone =
    selectedComponent?.getAttributes()['data-timezone'] || 'local';
  startCountdown(selectedComponent, endTime, timezone);
};

export const startCountdown = (component, endTime, timezone = 'local') => {
  // const countDownDate = new Date(endTime).getTime();
  const countDownDate = DateTime.fromISO(endTime, {
    zone: timezone,
  }).toMillis();

  // Clear any existing interval
  if (component.countdownInterval) {
    clearInterval(component.countdownInterval);
  }

  const interval = setInterval(() => {
    // const now = new Date().getTime();
    const now = DateTime.local().setZone(timezone).toMillis();
    const remainingTime = countDownDate - now;

    const language = component?.getAttributes()['data-language'] || 'english';

    if (remainingTime < 0) {
      clearInterval(interval);
      updateCountdownDisplay(component, 0, language);
    } else {
      updateCountdownDisplay(component, remainingTime, language);
    }
  }, 1000);

  // Store the interval ID on the component for later access
  component.countdownInterval = interval;

  return interval;
};

//TableBlock Trait Manager
export const renderTableTraitManager = (selectedComponent, editor) => {
  selectedComponent.set('traits', [
    {
      type: 'table-position',
      label: 'Position',
      name: 'table-position',
    },
    {
      type: 'table-actions',
      label: `Table Actions <br>
      (You need to select the column to add table element) `,
      name: 'table-actions',
    },
    {
      type: 'table-heading-background-color',
      label: 'Heading Background Color',
      name: 'heading-background-color',
    },
    {
      type: 'table-heading-text-color',
      label: 'Heading Text Color',
      name: 'heading-text-color',
    },
    {
      type: 'table-border-settings', // Use your custom trait
      label: 'Border Style',
      name: 'table-border-settings',
    },
    {
      type: 'table-font-family',
      label: 'Font Family',
      name: 'table-font-family',
    },
    {
      type: 'table-line-height',
      label: 'Line Height',
      name: 'table-line-height',
    },
    {
      type: 'table-width',
      label: 'Width',
      name: 'width',
    },
    {
      type: 'column-padding',
      label: 'Column Padding',
      name: 'column-padding',
    },
    {
      type: 'table-padding-control',
      label: 'Container Padding',
      name: 'table-padding',
    },
  ]);

  // Re-render the trait manager to show the new traits
  editor.TraitManager.render();
};

export const renderLogoBlockTraitManager = (selectedComponent, editor) => {
  // Clear existing traits
  selectedComponent.set('traits', []);
  // Add the info-link trait
  selectedComponent.addTrait({
    type: 'info-link',
    label: false, // No label needed for this trait
    name: 'info-link',
  });
  selectedComponent.addTrait({
    type: 'element-float',
    label: 'Position',
    name: 'element-float',
  });
  editor.TraitManager.render();
};

//MenuBlock Trait Manager
export const renderMenuTraitManager = (selectedComponent, editor) => {
  // Clear existing traits
  selectedComponent.set('traits', []);
  const separatorEnabled = selectedComponent.get('separatorEnabled') || false;
  const separatorValue = selectedComponent.get('separatorValue') || '|';
  const separatorColor = selectedComponent.get('separatorColor') || '#000000';

  if (separatorEnabled) {
    const td = selectedComponent.getEl()?.querySelector('td');
    if (td) {
      const separators = td.querySelectorAll('.separator-span');
      separators.forEach((separator) => {
        separator.textContent = ` ${separatorValue} `;
        separator.style.color = separatorColor;
        separator.style.display = 'inline';
      });
    }
  }

  // Get all 'a' elements within the menu component
  const links = selectedComponent.find('a');

  selectedComponent.addTrait({
    type: 'element-float',
    label: 'Position',
    name: 'element-float',
  });

  selectedComponent.addTrait({
    type: 'trait-container',
    id: 'sortable-menu', // ID for the container
  });
  // Create a trait for each link
  links.forEach((link, index) => {
    selectedComponent.addTrait({
      type: 'link-manager',
      label: `Menu Item ${index + 1}`,
      name: `link-manager-${index}`,
      index: index,
      parent: 'sortable-menu',
    });
  });

  // Add the 'add link' button trait
  selectedComponent.addTrait({
    type: 'add-link-button',
    label: 'Add Link',
    name: 'add-link',
  });

  // Add the enable-separator trait
  selectedComponent.addTrait({
    type: 'enable-separator',
    label: 'Enable Separator',
    name: 'separator',
  });

  selectedComponent.addTrait({
    type: 'font-family',
    label: 'Font Family',
    name: 'font-family',
  });

  selectedComponent.addTrait({
    type: 'line-height',
    label: 'Line Height',
    name: 'line-height',
  });

  selectedComponent.addTrait({
    type: 'menu-link-padding',
    label: 'Padding',
    name: 'menu-link-padding',
  });

  selectedComponent.addTrait({
    type: 'padding-control',
    label: 'Container Padding',
    name: 'padding',
  });

  initializeSorting(selectedComponent, editor, 'sortable-menu');
  // Re-render the trait manager
  editor.TraitManager.render();
};


//SocialBlock Trait Manager
export const renderSocialTraitManager = (selectedComponent, editor) => {
  //Clear existing traits
  selectedComponent.set('traits', []);

  //Find all 'img' elements
  const links = selectedComponent.find('a');

  selectedComponent.addTrait({
    type: 'element-float',
    label: 'Position',
    name: 'element-float',
  });

  selectedComponent.addTrait({
    type: 'add-imageLink',
    label: 'Icons',
    name: 'add-link',
  });

  selectedComponent.addTrait({
    type: 'icon-style',
    text: 'Style',
    name: 'style',
  });

  selectedComponent.addTrait({
    type: 'image-width',
    text: 'Size',
    name: 'size',
  });

  selectedComponent.addTrait({
    type: 'image-link-gap',
    text: 'Gap',
    name: 'gap',
  });

  selectedComponent.addTrait({
    type: 'trait-container',
    id: 'sortable-div', // ID for the container
  });

  links.forEach((link, index) => {
    selectedComponent.addTrait({
      type: 'imageLink-combined',
      text: '',
      name: `imageLink-${index}`,
      index: index,
      parent: 'sortable-div',
    });
  });

  selectedComponent.addTrait({
    type: 'padding-control',
    label: 'Padding',
    name: 'padding',
  });

  editor.TraitManager.render();
  // Initialize sorting
  initializeSorting(selectedComponent, editor, 'sortable-div');
};

function initializeSorting(selectedComponent, editor, id) {
  // Use setTimeout to push the execution to the end of the call stack,
  // ensuring all elements have been added to the DOM.
  setTimeout(() => {
    const el = document.getElementById(id);
    if (el && !el.dataset.initialized) {
      new Sortable(el, {
        animation: 150,
        onEnd: (evt) => {
          const { newIndex, oldIndex } = evt;
          if (newIndex !== oldIndex) {
            switch (id) {
              case 'sortable-div':
                updateLinkOrder(selectedComponent, editor, oldIndex, newIndex);
                break;
              case 'widths-container':
                updateColumnOrder(
                  selectedComponent,
                  editor,
                  oldIndex,
                  newIndex,
                );
                break;
              case 'sortable-menu':
                updateMenuOrder(selectedComponent, editor, oldIndex, newIndex);
                break;
              default:
                break;
            }
          }
        },
      });
      // Set a flag to indicate that sorting has been initialized
      el.dataset.initialized = 'true';
    }
  }, 0);
}

function updateMenuOrder(selectedComponent, editor, oldIndex, newIndex) {
  const tdComponent = selectedComponent.find('td')[0];

  if (tdComponent) {
    const elements = Array.from(tdComponent.getEl().childNodes);
    const links = elements.filter(el => el.tagName === 'A');
    const separators = elements.filter(el => el.classList && el.classList.contains('separator-span'));

    console.log("All the links, and the seperators found when function called for sorting", links, separators);

    if (oldIndex < links.length && newIndex < links.length) {
      const movedLink = links[oldIndex];
      links.splice(oldIndex, 1);
      links.splice(newIndex, 0, movedLink);

      // Reconstruct the td content
      tdComponent.empty();
      links.forEach((link, index) => {
        tdComponent.append(link);
        if (index < links.length - 1 && separators.length > 0) {
          tdComponent.append(separators[0].cloneNode(true));
        }
      });

      editor.TraitManager.render();
    } else {
      console.error('Invalid index for reordering links');
    }
  } else {
    console.error('TD container for links not found!');
  }

  selectedComponent.components().forEach((child) => {
    applyNonSelectability(child);
    removeNoPointerClass(child);
  });
  renderMenuTraitManager(selectedComponent, editor);
}


function updateLinkOrder(selectedComponent, editor, oldIndex, newIndex) {
  // Access the 'social-block' component directly
  const socialBlockComponent = selectedComponent.find('.social-block')[0];

  if (socialBlockComponent) {
    // Get the current array of link components
    let linksComponents = socialBlockComponent.components().models;

    // Save current styles and src of the links
    const linkStyles = linksComponents.map((linkComponent) => {
      const imgComponent = linkComponent.components().at(0);
      return {
        name: imgComponent.attributes.attributes.alt,
        src: imgComponent.attributes.attributes.src,
        borderRadius: imgComponent.view.el.style.borderRadius,
      };
    });

    console.log(linkStyles, 'style when sorted');
    // Create a new array with the moved component
    const movedLink = linksComponents.splice(oldIndex, 1)[0];
    linksComponents.splice(newIndex, 0, movedLink);

    // Reset the components array to the new order
    socialBlockComponent.components().reset(linksComponents);

    // Reapply saved styles and src to each link
    linksComponents.forEach((linkComponent, index) => {
      const imgComponent = linkComponent.components().at(0);
      const originalStyle = linkStyles.find(
        (style) => style.name === imgComponent.attributes.attributes.alt,
      );

      if (originalStyle) {
        imgComponent.attributes.src = originalStyle.src;
        imgComponent.addStyle({ borderRadius: originalStyle.borderRadius });
      }
      console.log(imgComponent, imgComponent.attributes);
    });
    // Re-render the component view to reflect changes
    socialBlockComponent.view.render();

    // Re-render traits to reflect the new order
    renderSocialTraitManager(selectedComponent, editor);
  } else {
    console.error('Social block component not found!');
  }
}

function updateColumnOrder(selectedComponent, editor, oldIndex, newIndex) {
  // Access the row that contains the 'td' elements directly
  const rowComponent = selectedComponent.find('tr')[0]; // Assuming there's only one row to simplify

  if (rowComponent) {
    // Get the current array of 'td' components
    let tdComponents = rowComponent.components().models;

    // Check if both oldIndex and newIndex are within the range of existing tdComponents
    if (oldIndex < tdComponents.length && newIndex < tdComponents.length) {
      // Create a new array with the moved 'td' component
      const movedTd = tdComponents.splice(oldIndex, 1)[0];
      tdComponents.splice(newIndex, 0, movedTd);

      // Reset the components array to the new order
      rowComponent.components().reset(tdComponents);

      // Re-render the component view to reflect changes
      rowComponent.view.render();

      // Optionally, update traits or trigger some other updates in the editor
      editor.TraitManager.render();
    } else {
      console.error('Invalid index for reordering');
    }
  } else {
    console.error('Row component not found!');
  }
  renderSectionTableTraitManager(selectedComponent, editor);
}

export function appendToDiv(trait, container) {
  setTimeout(() => {
    if (trait.get('parent')) {
      const parentEl = document.getElementById(trait.get('parent'));
      parentEl.appendChild(container);
    }
  }, 0);
}

export const onImageLinkInputChange = (editor, trait, value) => {
  const selectedComponent = editor.getSelected();
  const linkIndex = trait.get('index');
  const links = selectedComponent.find('a');

  if (links[linkIndex]) {
    links[linkIndex].addAttributes({ href: value });
    editor.trigger('component:update', selectedComponent);
  }
};

export const onDeleteImageLinkClick = (editor, trait) => {
  const selectedComponent = editor.getSelected();
  const linkIndex = trait.get('index');
  const links = selectedComponent.find('a');

  if (links.length > 1 && links[linkIndex]) {
    links[linkIndex].remove();
    editor.trigger('component:update', selectedComponent);
  } else {
    alert('Cannot delete all the imageLinks');
  }

  renderSocialTraitManager(selectedComponent, editor);
};

export const addImageLinkToSocialBlock = (editor, social) => {
  const selectedComponent = editor.getSelected();
  const socialBlock =
    selectedComponent.find('.social-block')[0] ||
    editor.getWrapper().find('.social-block')[0];
  if (socialBlock) {
    const existingLinks = socialBlock.find('a');
    if (
      existingLinks.some(
        (link) => link.find(`img[alt="${social.name}"]`).length > 0,
      )
    ) {
      alert(`${social.name} link already exists in the social block.`);
      return;
    }

    const currentStyle = window.currentIconStyle || 'circle';
    console.log(currentStyle, 'when adding link to social block');
    const useBlackVersion = currentStyle?.includes('black');

    // Default properties if no existing links are found
    let width = '24px'; // default width
    let gap = '4px'; // default gap
    let borRad = '50%'; // default border-radius

    // Check if there are any existing links to copy styles from
    if (existingLinks.length > 0) {
      const existingLink = existingLinks[0];
      const existingImg = existingLink.find('img')[0];

      if (existingImg) {
        width = existingImg.getStyle('width') || width;
        borRad = existingImg.getStyle('border-radius') || borRad;
      }

      gap = existingLink.getStyle('margin-right') || gap; // Assuming margin-left and margin-right are the same
    }

    // Determine the icon source based on style
    const iconSrc = useBlackVersion ? social.iconDark : social.icon;

    // Define the new link component with an image inside
    const linkComponent = {
      type: 'link',
      tagName: 'a',
      attributes: { href: social.url, target: '_blank' },
      style: { 'margin-left': gap, 'margin-right': gap },
      components: [
        {
          type: 'image',
          tagName: 'img',
          attributes: { src: iconSrc, alt: social.name },
          style: { 'border-radius': borRad, width: width },
        },
      ],
    };

    // Append the new link component to the social block
    socialBlock.append(linkComponent);
    editor.trigger('component:update', selectedComponent);
    renderSocialTraitManager(selectedComponent, editor);
  }
};

export const updateSocialImagesWidth = (editor, width) => {
  const selectedComponent = editor.getSelected();
  const socialBlock =
    selectedComponent.find('.social-block')[0] ||
    editor.getWrapper().find('.social-block')[0];
  if (socialBlock) {
    const images = socialBlock.find('img');
    images.forEach((img) => {
      img.addStyle({ width: `${width}px` });
    });
  }
};

export const updateImageLinkGap = (editor, gap) => {
  const selectedComponent = editor.getSelected();
  const socialBlock =
    selectedComponent.find('.social-block')[0] ||
    editor.getWrapper().find('.social-block')[0];
  if (socialBlock) {
    const links = socialBlock.find('a');
    links.forEach((link) => {
      link.addStyle({
        'margin-left': `${gap}px`,
        'margin-right': `${gap}px`,
      });
    });
  }
};

export const updateIconStyle = (editor, style) => {
  const selectedComponent = editor.getSelected();
  const socialBlock =
    selectedComponent.find('.social-block')[0] ||
    editor.getWrapper().find('.social-block')[0];
  if (socialBlock) {
    const links = socialBlock.find('img');
    const useBlackVersion = style.includes('black');
    links.forEach((link) => {
      // Reset styles
      link.addStyle({
        'border-radius': '',
        'background-color': '',
        padding: '',
      });

      const socialTypeName = link?.getAttributes()['alt'];
      const socialType = socialTypes.find(
        (social) => social.name === socialTypeName,
      );

      if (socialType) {
        // Set the source based on the selected style
        const newSrc = useBlackVersion ? socialType.iconDark : socialType.icon;
        link.setAttributes({ src: newSrc, alt: socialTypeName, width: '24px' });
      }
      switch (style) {
        case 'circle':
        case 'circle-black':
          link.addStyle({ 'border-radius': '50%' });
          break;
        case 'round':
        case 'round-black':
          link.addStyle({ 'border-radius': '4px' });
          break;
        case 'square':
        case 'square-black':
          link.addStyle({ 'border-radius': '0' });
          break;
        default:
          break;
      }
    });
  }
  window.currentIconStyle = style;
  renderSocialTraitManager(selectedComponent, editor);
};

//FooterBlock Trait Manager
export const renderFooterBlockTraitManager = (selectedComponent, editor) => {
  selectedComponent.set('traits', [
    {
      type: 'font-family',
      label: 'Font Family',
      name: 'font-family',
    },
    {
      type: 'line-height',
      label: 'Line Height',
      name: 'line-height',
    },
    {
      type: 'padding-control',
      label: 'Padding',
      name: 'padding',
    },
  ]);
};

//HTMLBlock Trait Manager
export const renderHTMLBlockTraitManager = (selectedComponent, editor) => {
  selectedComponent.set('traits', [
    {
      type: 'htmlTrait',
      label: 'HTML',
      name: 'html',
      changeProp: 1,
      default: '<strong>Hello World</strong>',
    },
    {
      type: 'padding-control',
      label: 'Padding',
      name: 'padding',
    },
  ]);

  editor.TraitManager.render();
};

// Function to update link details
export function updateLinkDetails(
  selectedComponent,
  linkIndex,
  type,
  input,
  subject,
  content,
  editor,
) {
  const links = selectedComponent.find('a');
  if (links.length > linkIndex) {
    const link = links.at(linkIndex);
    let href = '';
    let error = null;

    switch (type) {
      case 'web':
        const urlPattern = /^(http:\/\/|https:\/\/)?(.*)$/i;
        const match = input.match(urlPattern);
        input = match[2];
        href = `https://${input}`;
        break;
      case 'email':
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(input)) {
          error = 'Please enter a valid email address.';
        } else {
          href = `mailto:${input}?subject=${encodeURIComponent(
            subject,
          )}&body=${encodeURIComponent(content)}`;
        }
        break;
      case 'phone':
        input = input.replace(/[^0-9]/g, '');
        if (input.length > 10) {
          error = 'Phone number must be up to 10 digits.';
          input = input.substring(0, 10);
        }
        href = `tel:${input}`;
        break;
      default:
        href = input;
        break;
    }

    if (error) {
      console.error(error);
      return;
    }

    link.addAttributes({ href });
    editor.trigger('component:update', selectedComponent);
  }
}

export const addLinkToMenu = (selectedComponent, editor) => {
  const tdComponent = selectedComponent.find('td')[0];

  console.log("---- When link added to the menu ----" , tdComponent, ' should contain each <a>');
  if (tdComponent) {
    const existingLinks = tdComponent.find('a');
    console.log(existingLinks, " All the <a> present in the menuBlock");
    const lastLink = existingLinks.length > 0 ? existingLinks.at(existingLinks.length - 1) : null;
    console.log(lastLink, 'Second last menu-item after added ', lastLink.components().at(0), ' styles for the new list item');
    
    // Add separator after the new link
    const separatorSpan = {
      tagName: 'span',
      type: 'text',
      content: selectedComponent.get('separatorValue') || '|',
      attributes: { class: 'separator-span' },
      style: {
        display: selectedComponent.get('separatorEnabled') ? 'inline' : 'none',
        color: selectedComponent.get('separatorColor') || '#000000',
      },
    };

    console.log("Added a seperator along with the list");
    tdComponent.append(separatorSpan);
    
    const newLink = {
      type: 'link',
      tagName: 'a',
      style: {
        display: "inline-block",
      },
      attributes: { href: '', target: '_blank' },
      components: [
        {
          tagName: 'span',
          type: 'text',
          content: 'New Link',
          style: lastLink ? { ...lastLink.components().at(0).getStyle() } : {
            display: 'inline-block',
            padding: '8px 16px',
            'font-size': '14px',
            'font-weight': '600',
            'text-decoration': 'none',
            color: '#000000',
          },
        },
      ],
    };

    console.log("Added new link in the tdComponent")
    tdComponent.append(newLink);

    
  }

  selectedComponent.components().forEach((child) => {
    applyNonSelectability(child);
    removeNoPointerClass(child);
  });

  renderMenuTraitManager(selectedComponent, editor);
};

export const deleteLinkFromMenu = (editor, trait) => {
  const selectedComponent = editor.getSelected();
  const td = selectedComponent.find('td')[0];
  const links = td.find('a');
  if (links.length > 1) {
    const linkIndex = trait.get('index');
    const linkToDelete = links.at(linkIndex);
    if (linkToDelete) {
      // Remove the separator after the link (if it exists)
      const nextSeparator = linkToDelete.getEl().nextSibling;
      console.log(nextSeparator, 'seperator after the deleted link');
      if (nextSeparator && nextSeparator.classList.contains('separator-span')) {
        nextSeparator.remove();
        console.log("Remove the seperator as well")
      }
      linkToDelete.remove();
      
      renderMenuTraitManager(selectedComponent, editor);
    }
  } else {
    alert('There should be at least 1 link');
  }
};

//Table Trait Functions
export const addRowTableTop = (selectedComponent, editor) => {
  if (selectedComponent.components().at(0)?.attributes.tagName === 'table') {
    alert('Select a cell to add rows');
  }

  if (selectedComponent.attributes.tagName === 'td') {
    const parentRow = selectedComponent.parent();
    const tableBody = parentRow.parent();
    const rowIndex = tableBody.components().indexOf(parentRow);
    const newRow = parentRow.clone();

    // Clear content of the new row's cells
    newRow.components().forEach((cell) => {
      cell.set('content', 'New Cell');
      cell.components().reset();
    });

    // Add the new row at the correct index
    tableBody.components().add(newRow, { at: rowIndex });

    editor.trigger('component:update', tableBody);
  }
};

export const addRowTableBottom = (selectedComponent, editor) => {
  if (selectedComponent.components().at(0)?.attributes.tagName === 'table') {
    alert('Select a cell to add rows');
  }

  if (selectedComponent.attributes.tagName === 'td') {
    const parentRow = selectedComponent.parent();
    const tableBody = parentRow.parent();
    const rowIndex = tableBody.components().indexOf(parentRow);
    const newRow = parentRow.clone();

    // Clear content of the new row's cells
    newRow.components().forEach((cell) => {
      cell.set('content', 'New Cell');
      cell.components().reset();
    });

    // Add the new row at the correct index
    tableBody.components().add(newRow, { at: rowIndex + 1 });

    editor.trigger('component:update', tableBody);
  }
};

export const addColTableLeft = (selectedComponent, editor) => {
  if (selectedComponent.components().at(0)?.attributes.tagName === 'table') {
    alert('Select a cell to add columne');
    return;
  }
  if (selectedComponent.attributes.tagName === 'td') {
    // Get the current cell and its parent row
    const currentCell = selectedComponent;
    const parentRow = currentCell.parent();

    // Get the table body by accessing the parent of the row
    const tableBody = parentRow.parent();

    // Find the index of the current cell within the row
    const cellIndex = parentRow.components().indexOf(currentCell);

    // Get the first row's th cell
    const firstRow = tableBody.components().at(0);
    const thCell = firstRow
      .components()
      .find((cell) => cell.attributes.tagName === 'th');

    // Iterate over each row to add a new cell at the correct index
    tableBody.components().forEach((row, idx) => {
      let newCell;
      if (idx === 0 && thCell) {
        newCell = thCell.clone();
      } else {
        newCell = currentCell.clone();
      }
      newCell.set('content', 'New Cell');
      newCell.components().reset();
      row.components().add(newCell, { at: cellIndex });
    });

    // Update the view to reflect the changes
    editor.trigger('component:update', tableBody);
  }
};

export const addColTableRight = (selectedComponent, editor) => {
  if (selectedComponent.components().at(0)?.attributes.tagName === 'table') {
    alert('Select a cell to add columns');
    return;
  }
  if (selectedComponent.attributes.tagName === 'td') {
    // Get the current cell and its parent row
    const currentCell = selectedComponent;
    const parentRow = currentCell.parent();

    // Get the table body by accessing the parent of the row
    const tableBody = parentRow.parent();

    // Find the index of the current cell within the row
    const cellIndex = parentRow.components().indexOf(currentCell);

    // Get the first row's th cell
    const firstRow = tableBody.components().at(0);
    const thCell = firstRow
      .components()
      .find((cell) => cell.attributes.tagName === 'th');

    // Iterate over each row to add a new cell at the correct index
    tableBody.components().forEach((row, idx) => {
      let newCell;
      if (idx === 0 && thCell) {
        // Clone the th cell for the first row
        newCell = thCell.clone();
      } else {
        // Clone the selected cell for other rows
        newCell = currentCell.clone();
      }
      newCell.set('content', 'New Cell');
      newCell.components().reset();
      row.components().add(newCell, { at: cellIndex + 1 });
    });

    // Update the view to reflect the changes
    editor.trigger('component:update', tableBody);
  }
};

export const deleteTableColumn = (selectedComponent, editor) => {
  if (selectedComponent.components().at(0)?.attributes.tagName === 'table') {
    alert('Select a cell to delete its column');
    return;
  }

  if (selectedComponent.attributes.tagName === 'td') {
    // Get the current cell
    const currentCell = selectedComponent;

    // Get the index of the current cell in its parent row
    const cellIndex = currentCell.parent()?.components().indexOf(currentCell);

    // Get the table body by going up two levels in the hierarchy
    const tableBody = currentCell.parent().parent();

    // Iterate over each row and remove the cell at the same index
    tableBody?.components().forEach((row) => {
      const cell = row.components().at(cellIndex);
      if (cell) {
        cell.remove();
      }
    });

    // Update the view to reflect the changes
    editor.trigger('component:update', tableBody);
  }
};

export const deleteTableRow = (selectedComponent, editor) => {
  if (selectedComponent.components().at(0)?.attributes.tagName === 'table') {
    alert('Select a cell to delete');
    return;
  }
  if (selectedComponent.attributes.tagName === 'td') {
    // Get the current cell and its parent row
    const currentCell = selectedComponent;
    const parentRow = selectedComponent.parent();

    if (parentRow) {
      parentRow.remove();
    }
    // Update the view to reflect the changes
    editor.trigger('component:update');
  }
};

// utils.js

const removeNoPointerClass = (component) => {
  const el = component.getEl();
  if (el && el.classList?.contains('gjs-no-pointer')) {
    el.classList.remove('gjs-no-pointer');
  }
  component.components().forEach((child) => {
    removeNoPointerClass(child);
  });
};

export const applyNonSelectability = (component) => {
  // Define the selectability conditions
  const isSelectable = (comp) => {
    const el = comp.getEl();

    if (!el) {
      return false; // Return false if el is null or undefined
    }

    // Condition 1: Body (div with data-gjs-type="wrapper")
    if (el.dataset && el.dataset.gjsType === 'wrapper') {
      return true;
    }

    // Condition 2: Section (table with type="section-table" and class="content-table")
    if (
      el.tagName === 'TABLE' &&
      el.getAttribute('type') === 'section-table' &&
      el.classList.contains('content-table')
    ) {
      return true;
    }

    // Condition 3: Column (td with type="column" and class="container")
    if (
      el.tagName === 'TD' &&
      el.getAttribute('type') === 'column' &&
      el.classList.contains('container')
    ) {
      return true;
    }

    // Condition 4: All blocks (span with class="block-div")
    if (el.tagName === 'SPAN' && el.classList.contains('block-div')) {
      return true;
    }

    // Condition 5: If span with class="block-div" has data-gjs-type="Text" or "Footer", then its first child (div with class="text-block" or "footer-block")
    if (
      el.dataset &&
      (el.dataset.gjsType === 'Text' || el.dataset.gjsType === 'Footer') &&
      el.firstChild
    ) {
      if (
        el.firstChild.classList.contains('text-block') ||
        el.firstChild.classList.contains('footer-block')
      ) {
        return true;
      }
    }

    return false;
  };

  // Apply non-selectability if the component doesn't meet the selectability conditions

  if (component?.parent()?.attributes.type === 'Table') {
    component.find('th, td').forEach((cell) => {
      cell.addAttributes({ 'data-gjs-type': 'text' });
      cell.removeAttributes('type');
      cell.set({
        selectable: true,
        hoverable: true,
        editable: true,
      });

      // Ensure RTE settings are applied
      cell.components().forEach((child) => {
        child.set({
          editable: true,
        });
      });
    });
    return;
  } else {
    if (!isSelectable(component)) {
      component.addAttributes({ 'data-non-selectable': 'true' });
      component.addClass('non-selectable');
      component.set('selectable', false);
      component.set('hoverable', false);
    }
    component.components().forEach((child) => {
      applyNonSelectability(child);
      removeNoPointerClass(child);
    });
  }
};

// Function to set margin between section-tables when dragging
export const setSectionTableSpacing = (editor, addSpacing) => {
  const canvasDoc = editor.Canvas.getDocument(); // Get the document inside the GrapesJS canvas
  const sectionTables = canvasDoc.querySelectorAll('.content-table'); // Select elements within the canvas
  sectionTables.forEach((table) => {
    if (addSpacing) {
      table.style.marginBottom = '5px';
    } else {
      table.style.marginBottom = '0';
    }
  });
};

//ProductBlock Trait Manager
export const renderProductBlockTraitManager = (selectedComponent, editor) => {
  const viewType =
    selectedComponent.getAttributes()['data-view-type'] || 'column';
  selectedComponent.set('traits', [
    {
      type: 'product-alignment',
      label: 'Alignment',
    },
    {
      type: 'product-view-type',
      label: 'Style',
      name: 'data-view-type',
      value: viewType,
    },
    {
      type: 'product-columns',
      label: 'No of Columns',
      name: 'data-columns',
    },
    {
      type: 'product-rows',
      label: 'No of Rows',
      name: 'data-rows',
    },
    {
      type: 'preview-content',
      label: 'Preview Content',
      name: 'previewContent',
    },
    {
      type: 'product-column-gap',
      label: 'Column Gap',
      name: 'product-column-gap',
    },
    {
      type: 'product-font-family',
      label: 'Font Family',
      name: 'product-font-family'
    },
    {
      type: 'product-line-height',
      label: 'Line Height',
      name: 'product-line-height',
    },
    {
      type: 'padding-control',
      label: "Padding",
      name: 'product-padding'
    }
  ]);
};

export function updateProductBlockLayout(component) {
  const viewType = component.getAttributes()['data-view-type'] || 'column';
  const columns = parseInt(component.getAttributes()['data-columns'], 10) || 2;
  const rows = parseInt(component.getAttributes()['data-rows'], 10) || 1;

  const table = component.find('table')[0];
  if (!table) return;

  const tbody = table.find('tbody')[0];
  if (!tbody) return;

  const totalCells = rows * columns;

  // Get current gap value
  const currentGap = table.getStyle()['border-spacing'] || '16px 16px';
  const [horizontalGap] = currentGap.split(' ');
  const gapValue = parseInt(horizontalGap, 10) || 16;

  // Function to create a new product cell
  const createProductCell = () => ({
    tagName: 'td',
    type: 'cell',
    droppable: false,
    selectable: false,
    hoverable: false,
    draggable: false,
    style: {
      width: viewType === 'row' ? '100%' : `${100 / columns}%`,
      height: '100%',
      'vertical-align': 'middle',
      'text-align': 'center',
      border: '1px dashed #82838e',
      'border-radius': '8px',
      padding: '50px 0',
      background: '#f6f6f6',
      'font-family': "Lato, Arial",
    },
    components: [
      {
        tagName: 'button',
        type: 'button',
        content: 'Add Product',
        droppable: false,
        draggable: false,
        selectable: false,
        hoverable: false,
        editable: true,
        style: {
          padding: '10px 20px',
          'font-size': '14px',
          'font-weight': '600',
          'text-decoration': 'none',
          color: '#ffffff',
          'font-family': "Lato, Arial",
          background: '#ff385c',
          'border-radius': '4px',
          border: 'none',
          cursor: 'pointer',
        },
        attributes: {
          'data-gjs-clickable': 'true',
          'data-gjs-propagate': ['click'],
        },
      },
    ],
  });

  // Create new rows and cells
  const newRows = [];
  const existingCells = tbody.find('td');

  for (let i = 0; i < (viewType === 'row' ? totalCells : rows); i++) {
    const row = { tagName: 'tr', components: [],
      droppable: false,
      draggable: false,
      selectable: false,
      hoverable: false, };
    
    for (let j = 0; j < (viewType === 'row' ? 1 : columns); j++) {
      const cellIndex = viewType === 'row' ? i : i * columns + j;
      let cell;
      
      if (cellIndex < existingCells.length) {
        cell = existingCells[cellIndex].clone();
        cell.addStyle({ width: viewType === 'row' ? '100%' : `${100 / columns}%` });
      } else {
        cell = createProductCell();
      }
      
      row.components.push(cell);
    }
    
    newRows.push(row);
  }

  // Update the table layout
  tbody.empty();
  tbody.append(newRows);
  
    table.setStyle({ 
      'table-layout': 'fixed',
      width: '100%',
      border: 'none',
      'border-spacing': viewType === 'column' ? `${gapValue}px ${gapValue}px` : `0 ${gapValue}px`,
      'border-collapse': 'separate',
    });

  // Ensure all cells have the correct width
  tbody.find('td').forEach(cell => {
    cell.addStyle({ width: viewType === 'row' ? '100%' : `${100 / columns}%` });
  });
}

export const handleProductSelect = (product, selectedProductBlock, setProductsOverlay, editor) => {

  console.log(product, 'Added Product');

  if (selectedProductBlock) {
    const shopDomain = localStorage.getItem('shopDomain');
    const productComponent = selectedProductBlock.closest('[data-gjs-type="Product"]');
    
    if (productComponent) {
      const enabledElements = productComponent.get('enabledElements') || ['image', 'title', 'description', 'price', 'regularPrice', 'button'];
      const fontFamily = productComponent.get('productFontFamily') || 'Arial';
      const lineHeight = productComponent.get('productLineHeight') || 1.5;
      
      const productContent = createProductContent(product, shopDomain, enabledElements, fontFamily, lineHeight);
    
      // Preserve the original td styles
      const originalStyles = { ...selectedProductBlock.getStyle() };
    
      // Set the new content
      selectedProductBlock.set('components', [productContent]);
    
      // Reapply the original styles
      selectedProductBlock.setStyle(originalStyles);

      updateProductBlockLayout(productComponent);
    }

    setProductsOverlay(false);
  }
};

export const formatPrice = (minPrice, maxPrice, currency) => {
  if (minPrice === maxPrice) {
    return `${currency} ${minPrice}`;
  }
  return `${currency} ${minPrice} - ${maxPrice}`;
};

function createProductContent(product, shopDomain, enabledElements, fontFamily, lineHeight) {
  const components = [
    {
      tagName: 'div',
      droppable: false,
      selectable: false,
      hoverable: false,
      draggable: false,
      attributes: { 'data-element': 'image' },
      style: { display: enabledElements.includes('image') ? 'block' : 'none' },
      components: [{
        tagName: 'img',
        droppable: false,
        selectable: true,
        hoverable: true,
        draggable: false,
        attributes: {
          src: product.image.url,
          alt: product.image.altText,
          style: 'width: 100%; max-width: 200px; height: auto; border-radius: 8px;',
        },
      }],
    },
    {
      tagName: 'div',
      droppable: false,
      selectable: false,
      hoverable: false,
      draggable: false,
      attributes: { 'data-element': 'title' },
      style: { 
        display: enabledElements.includes('title') ? 'block' : 'none',
        'font-family': `'${fontFamily}', sans-serif`,
        'line-height': lineHeight,
      },
      components: [{
        tagName: 'h3',
        type: 'text',
        editable: true,
        selectable: true,
        hoverable: true,
        content: product.title,
        style: {
          'font-weight': '600',
          'font-size': '24px',
          'color': '#241c15',
          margin: '0',
          padding: '0',
        }
      }],
    },
    {
      tagName: 'div',
      droppable: false,
      selectable: false,
      hoverable: false,
      draggable: false,
      attributes: { 'data-element': 'description' },
      style: { 
        display: enabledElements.includes('description') ? 'block' : 'none',
        'font-family': `'${fontFamily}', sans-serif`,
        'line-height': lineHeight,
      },
      components: [{
        tagName: 'p',
        type: 'text',
        editable: true,
        selectable: true,
        hoverable: true,
        content: product.descriptionHtml || 'No description available',
        style: {
          'font-weight': '500',
          'font-size': '24px',
          'color': '#241c15',
          margin: '0',
          padding: '0',
        }
      }],
    },
    {
      tagName: 'div',
      droppable: false,
      selectable: false,
      hoverable: false,
      draggable: false,
      attributes: { 'data-element': 'price' },
      style: { 
        display: enabledElements.includes('price') ? 'block' : 'none',
        'font-family': `'${fontFamily}', sans-serif`,
        'line-height': lineHeight,
      },
      components: [
        {
          tagName: 'span',
          type: 'text',
          editable: true,
          selectable: true,
          hoverable: true,
          content: product.priceRangeV2 ? formatPrice(
            product.priceRangeV2.minVariantPrice.amount,
            product.priceRangeV2.maxVariantPrice.amount,
            product.priceRangeV2.minVariantPrice.currencyCode
          ) : product.price ,
          style: {
            'font-weight': '400',
            'font-size': '24px',
            'color': '#241c15',
            'width': '100%',
          }
        },
      ]
    },
    {
      tagName: 'div',
      droppable: false,
      selectable: false,
      hoverable: false,
      draggable: false,
      attributes: { 'data-element': 'regularPrice' },
      style: { 
        display: enabledElements.includes('regularPrice') ? 'block' : 'none',
        'font-family': `'${fontFamily}', sans-serif`,
        'line-height': lineHeight,
      },
      components: [
        {
          tagName: 'span',
          type: 'text',
          editable: true,
          selectable: true,
          hoverable: true,
          content: product.compareAtPrice,
          style: {
            'font-weight': '400',
            'font-size': '24px',
            'color': '#241c15',
            'width': '100%',
            'text-decoration': 'line-through',
          }
        },
      ]
    },
    {
      tagName: 'div',
      droppable: false,
      selectable: false,
      hoverable: false,
      draggable: false,
      attributes: { 'data-element': 'button' },
      style: { 
        display: enabledElements.includes('button') ? 'block' : 'none',
        'font-family': `'${fontFamily}', sans-serif`,
        'line-height': lineHeight,
      },
      components: [{
        tagName: 'a',
        type: 'text',
        editable: true,
        selectable: true,
        hoverable: true,
        content: "Buy Now",
        attributes: {
          href: `https://${shopDomain}/products/${product.handle}`,
          target: '_blank',
          rel: 'noopener noreferrer'
        },
        style: {
          display: 'inline-block',
          padding: '10px 20px',
          'font-size': '14px',
          'font-weight': '600',
          'text-decoration': 'none',
          color: '#ffffff',
          background: '#ff385c',
          'border-radius': '4px',
          border: 'none',
          cursor: 'pointer',
        }
      }],
    }
  ];

  return {
    tagName: 'div',
    droppable: false,
    selectable: false,
    hoverable: false,
    draggable: false,
    attributes: { 'data-gjs-type': 'product-content' },
    style: {
      display: 'flex',
      'flex-direction': 'column',
      gap: '8px',
      height: '100%',
      width: '100%',
    },
    components: components,
  };
}

export function applyProductAlignment(component, alignment) {
  const productCells = component.find('td');
  productCells.forEach(cell => {
    const addProductButton = cell.find('button');
    if (addProductButton.length === 0) {
      // This cell contains a product, apply alignment
      const productContainer = cell.components().models[0]; // Get the main container
      if (productContainer) {
        
        const childDivs = productContainer.components().models;
        childDivs.forEach(div => {
          div.addStyle({ 'text-align': alignment });
        });
      }
    }
  });
}

// Function to update the product block based on selected elements
export function updateProductBlock(component, enabledElements) {
  // Update the component's attributes with the enabled elements
  component.set('enabledElements', enabledElements);
  
  // Update all product cells in the block
  const productCells = component.find('td');
  productCells.forEach(cell => {
    const productContent = cell.find('[data-gjs-type="product-content"]')[0];
    if (productContent) {
      updateProductContent(productContent, enabledElements);
    }
  });
  
  // Trigger component update
  component.em.trigger('component:update', component);
}

// Function to update a single product cell content
function updateProductContent(productContent, enabledElements) {
  const childComponents = productContent.components();
  childComponents.forEach(child => {
    const elementType = child.getAttributes()['data-element'];
    if (elementType) {
      child.addStyle({ display: enabledElements.includes(elementType) ? 'block' : 'none' });
    }
  });
}

export function updateProductGap(component, gapValue) {
  const table = component.find('table')[0];
  if (table) {
    const viewType = component.getAttributes()['data-view-type'] || 'column';
    
    if (viewType === 'column') {
      table.addStyle({
        'border-spacing': `${gapValue}px ${gapValue}px`,
      });
    } else { // row layout
      table.addStyle({
        'border-spacing': `0 ${gapValue}px`,
      });
    }
  }
}

export function updateProductFontFamily(component, fontFamily) {
  const productCells = component.find('td');
  productCells.forEach(cell => {
    const productContent = cell.find('[data-gjs-type="product-content"]')[0];
    if (productContent) {
      const elements = productContent.find('[data-element]');
      elements.forEach(el => {
        el.addStyle({ 'font-family': `'${fontFamily}', sans-serif` });
      });
    }
  });
}

export function updateProductLineHeight(component, lineHeight) {
  const productCells = component.find('td');
  productCells.forEach(cell => {
    const productContent = cell.find('[data-gjs-type="product-content"]')[0];
    if (productContent) {
      const elements = productContent.find('[data-element]');
      elements.forEach(el => {
        el.addStyle({ 'line-height': lineHeight });
      });
    }
  });
}

export const renderProductImageTraitManager = (selectedComponent, editor) => {
  selectedComponent.set('traits', [
    {
      type: 'product-image-width',
      label: 'Image Width',
      name: 'product-image-width',
    },
    {
      type: 'product-image-border-settings',
      label: 'Border',
      name: 'product-image-border',
    },
    {
      type: 'product-image-border-radius-control',
      label: 'Border Radius',
      name: 'product-image-border-radius',
    }
  ]);

  editor.TraitManager.render();
};

export const renderProductButtonTraitManager = (selectedComponent, editor) => {
  selectedComponent.set('traits', [
    {
      type: 'product-button-width',
      label: 'Button Width',
      name: 'product-button-width',
    },
    {
      type: 'product-button-border-settings',
      label: 'Border',
      name: 'product-button-border',
    },
    {
      type: 'product-button-border-radius-control',
      label: 'Border Radius',
      name: 'product-button-border-radius',
    }
  ]);

  editor.TraitManager.render();
};

export function updateAllProductImages(component, property, value) {
  const productBlock = component.closest('[data-gjs-type="Product"]');
  if (productBlock) {
    const images = productBlock.find('[data-element="image"] img');
    images.forEach(img => {
      img.addStyle({ [property]: value });
    });
  }
}

export function updateAllProductButtons(component, property, value) {
  const productBlock = component.closest('[data-gjs-type="Product"]');
  if (productBlock) {
    const buttons = productBlock.find('[data-element="button"] a');
    buttons.forEach(button => {
      button.addStyle({ [property]: value });
    });
  }
}

export const renderCartItemsBlockTraitManager = (selectedComponent, editor) => {
  selectedComponent.set('traits', [
    {
      type: 'cart-description',
      name: 'description',
    },
    {
      type: 'cartItem-font-family',
      name: 'Font Family',
      label: 'Font Family',
    },
    {
      type: 'cartItem-line-height',
      name: 'Line Height',
      label: 'Line Height',
    },
    {
      type: 'cart-items-content-preview',
      name: 'contentPreview',
      label: 'Cart Items Content Preview',
    },
    {
      type: 'cart-item-image-width',
      name: 'imageWidth',
      label: 'Image Width',
    },
    {
      type: 'separator-style',
      name: 'separatorStyle',
      label: 'Separator Style',
    },
    {
      type: 'separator-color',
      name: 'separatorColor',
      label: 'Separator Color',
    },
    {
      type: 'padding-control',
      label: 'Padding',
      name: 'padding',
    }
  ])
}

export const updateCartItemsLineHeight = (component, lineHeight) => {
  const updateComponentLineHeight = (comp) => {
    if (comp.is('text') || comp.get('type') === 'text' || comp.get('tagName') === 'span') {
      comp.addStyle({ 'line-height': lineHeight });
    }
    comp.components().forEach(child => updateComponentLineHeight(child));
  };

  updateComponentLineHeight(component);

  // Update the styles string
  let styles = component.get('styles');
  styles = styles.replace(/line-height:.*?;/g, `line-height: ${lineHeight};`);
  component.set('styles', styles);

  // Trigger a re-render of the component
  component.view.render();
};

export function updateCartItemsBlock(component, enabledElements) {
  // Update the component's attributes with the enabled elements
  component.set('enabledElements', enabledElements);
  
  // Update the cart items table
  const cartItemsTable = component.find('.cart-items-table')[0];
  if (cartItemsTable) {
    updateCartItemsContent(cartItemsTable, enabledElements);
  }
  
  // Trigger component update
  component.em.trigger('component:update', component);
}

// Function to update the cart items content
function updateCartItemsContent(cartItemsTable, enabledElements) {
  const productInfoDiv = cartItemsTable.find('.product-info')[0];
  if (productInfoDiv) {
    // Update Product Image visibility
    const productImage = productInfoDiv.find('.product-image')[0];
    if (productImage) {
      productImage.addStyle({ display: enabledElements.includes('productImage') ? 'block' : 'none' });
    }

    // Update Title visibility
    const productTitle = productInfoDiv.find('.product-title')[0];
    if (productTitle) {
      productTitle.addStyle({ display: enabledElements.includes('title') ? 'block' : 'none' });
    }

    // Update Attribute visibility
    const productVariant = productInfoDiv.find('.product-variant')[0];
    if (productVariant) {
      productVariant.addStyle({ display: enabledElements.includes('attribute') ? 'block' : 'none' });
    }
  }

  // Update Qty visibility
  const quantityCell = cartItemsTable.find('.quantity')[0];
  if (quantityCell) {
    quantityCell.addStyle({ display: enabledElements.includes('quantity') ? 'table-cell' : 'none' });
  }

  // Update Price visibility
  const priceCell = cartItemsTable.find('.price')[0];
  if (priceCell) {
    priceCell.addStyle({ display: enabledElements.includes('price') ? 'table-cell' : 'none' });
  }
}

export const renderCartLinkBlockTraitManager = ( selectedComponent, editor) => {
  selectedComponent.set('traits', [
    {
      type: 'element-float',
      label: 'Position',
      name: 'element-float',
    },
    {
      type: 'font-family',
      label: 'Font Family',
      name: 'font-family',
    },
    {
      type: 'child-width-control',
      label: 'Enable Child Width',
      name: 'enableChildWidth',
    },
    {
      type: 'second-child-border-settings',
      label: 'Child Border Settings',
    },
    {
      type: 'second-child-border-radius-control',
      label: 'Border Radius',
      name: 'border-radius',
    },
    {
      type: 'child-padding-control',
      label: 'Padding',
      name: 'child-padding',
    },
    {
      type: 'padding-control',
      label: 'Container Spacing',
      name: 'padding',
    },
  ])
}

export const renderCouponBlockTraitManager = (selectedComponent, editor) => {
  selectedComponent.set('traits', [
    {
      type: 'coupon-alignment',
      name: 'couponAlignment',
      label: 'Alignment',
    },
    {
      type: 'coupon-font-family',
      name: 'couponFontFamily',
      label: 'Font Family',
    },
    {
      type: 'coupon-content-preview',
      name: 'contentPreview',
      label: 'Content Preview',
    },
    {
      type: 'coupon-line-height',
      name: 'couponLineHeight',
      label: 'Line Height',
    },
    {
      type: 'coupon-list',
      name: 'couponList',
      label: 'Select Coupon',
    },
    {
      type: 'coupon-link-manager',
      name: 'shopNowLink',
      label: 'Link',
    },
    {
      type: 'coupon-style-toggler',
      name: 'styleToggler',
      label: 'Style Target',
    },
    {
      type: 'coupon-width-control',
      name: 'widthControl',
      label: 'Width Control',
    },
    {
      type: 'coupon-border-settings',
      name: 'borderSettings',
      label: 'Border Settings',
    },
    {
      type: 'coupon-padding-control',
      name: 'paddingControl',
      label: 'Padding',
    },
    {
      type: 'coupon-container-spacing',
      name: 'containerSpacing',
      label: 'Container Spacing',
    },
    {
      type: 'coupon-border-radius-control',
      name: 'borderRadiusControl',
      label: 'Border Radius',
    },
  ]);
}

export const renderCustomerAddressBlockTraitManager = (selectedComponent, editor) => {
  selectedComponent.set('traits', [
    {
      type: 'customer-description',
      name: 'description',
    },
    {
      type: 'customer-alignment',
      label: 'Alignment',
      name: 'customer-alignment',
    },
    {
      type: 'customer-font-family',
      label: 'Font Family',
      name: 'customer-font-family',
    },
    {
      type: 'customer-line-height',
      label: 'Line Height',
      name: 'customer-line-height',
    },
    {
      type: 'customer-billing-address',
      label: 'Billing Address',
      name: 'customer-billing-address',
    },
    {
      type: 'customer-shipping-address',
      label: 'Shipping Address',
      name: 'customer-shipping-address',
    },
    {
      type: 'padding-control',
      label: 'Padding',
      name: 'padding',
    }
  ]);
}

export const renderOrderSummaryBlockTraitManager = (selectedComponent, editor) => {
  selectedComponent.set('traits', [
    {
      type: 'order-description',
      name: 'description',
    },
    {
      type: 'order-alignment',
      label: 'Alignment',
      name: 'order-alignment'
    },
    {
      type: 'order-font-family',
      label: 'Font Family',
      name: 'order-font-family',
    },
    {
      type: 'order-line-height',
      label: 'Line Height',
      name: 'order-line-height',
    },
    {
      type: 'order-preview-content',
      label: 'Preview Content',
      name: 'order-preview-content',
    },
    {
      type: 'order-image-width',
      label: 'Image Width',
      name: 'order-image-width',
    },
    {
      type: 'order-separator-style',
      label: 'Separator Style',
      name: 'order-separator-style',
    },
    {
      type: 'order-separator-color',
      label: 'Separator Color',
      name: 'order-separator-color',
    },
    {
      type: 'padding-control',
      label: 'Padding',
      name: 'padding',
    }
  ]);
}

export function applyCouponAlignment(component, alignment) {
  const couponContainer = component.find('.coupon-container')[0];
  if (couponContainer) {
    couponContainer.addStyle({ 'text-align': alignment });
  }

  const elParent = component.find('.coupon-code-button-wrapper')
  
  elParent.forEach(element => {
    if (element) {
      if (alignment === 'left') {
        element.addStyle({ 
          'display': 'inline-block',
          'text-align': 'left'
        });
      } else if (alignment === 'right') {
        element.addStyle({ 
          'display': 'inline-block',
          'text-align': 'right'
        });
      } else {
        element.addStyle({ 
          'display': 'block',
          'text-align': 'center',
          'margin-left': 'auto',
          'margin-right': 'auto'
        });
      }
    }
  });
}

export function updateCouponFontFamily(component, fontFamily) {
  const couponContainer = component.find('.coupon-container')[0];
  if (couponContainer) {
    const elements = couponContainer.find('.coupon-header, .coupon-description, .coupon-code, .shop-now-button');
    elements.forEach(el => {
      el.addStyle({ 'font-family': `'${fontFamily}', sans-serif` });
    });
  }
}

export function updateCouponLineHeight(component, lineHeight) {
  const couponContainer = component.find('.coupon-container')[0];
  if (couponContainer) {
    const elements = couponContainer.find('.coupon-header, .coupon-description, .coupon-code, .shop-now-button');
    elements.forEach(el => {
      el.addStyle({ 'line-height': lineHeight });
    });
  }
}

export function updateCouponBlock(component, enabledElements) {
  component.set('enabledElements', enabledElements);
  
  const couponContainer = component.find('.coupon-container')[0];
  if (couponContainer) {
    const title = couponContainer.find('.coupon-header')[0];
    const description = couponContainer.find('.coupon-description')[0];
    const couponCode = couponContainer.find('.coupon-code')[0];
    const button = couponContainer.find('.shop-now-button')[0];

    console.log(enabledElements, 'enabled elements');
    if (title) {
      title.addStyle({ display: enabledElements.includes('title') ? 'block' : 'none' });
    }
    if (description) {
      description.addStyle({ display: enabledElements.includes('description') ? 'block' : 'none' });
    }
    if (couponCode) {
      couponCode.addStyle({ display: 'inline-block' }); // Always visible
    }
    if (button) {
      button.addStyle({ display: enabledElements.includes('button') ? 'inline-block' : 'none' });
    }

    // Adjust the layout based on visible elements
    const visibleElements = [title, description, couponCode, button].filter(el => el && el.getStyle('display') !== 'none');
    visibleElements.forEach((el, index) => {
      if (index === visibleElements.length - 1) {
        el.addStyle({ "margin-bottom": '0' });
      } else {
        el.addStyle({ "margin-bottom": '16px' });
      }
    });
  }
  
  component.em.trigger('component:update', component);
}

export const updateMenuSeparator = (editor, component, value, color, enabled) => {
  const separators = component.find('.separator-span');
  separators.forEach((separator) => {
    separator.set('content', value);
    separator.addStyle({ 
      color: color, 
      display: enabled ? 'table-cell' : 'none',
      padding: '0 5px'
    });
  });

  component.set('separatorEnabled', enabled);
  component.set('separatorValue', value);
  component.set('separatorColor', color);

  // Force update of inlined content
  editor.refresh();
  editor.trigger('component:update', component);
};

export function updateSeparators(component, style, color='#000000') {
  const table = component.find('.cart-items-table')[0];
  if (table) {
    const rows = table.find('tr');
    rows.forEach((row, index) => {
      const cells = row.find('td');
      cells.forEach(cell => {
        if (index === 0) { // First row (product details)
          cell.addStyle({
            'border-top': style !== 'none' ? `1px ${style} ${color}` : 'none',
            'border-bottom': style !== 'none' ? `1px ${style} ${color}` : 'none'
          });
        } else if (index === rows.length - 2) { // Second to last row (before Total)
          cell.addStyle({
            'border-bottom': style !== 'none' ? `1px ${style} ${color}` : 'none'
          });
        } else if (index === rows.length - 1) { // Last row (Total)
          cell.addStyle({
            'border-top': 'none',
            'border-bottom': style !== 'none' ? `1px ${style} ${color}` : 'none'
          });
        } else {
          cell.addStyle({
            'border-top': 'none',
            'border-bottom': 'none'
          });
        }
      });
    });
  }
}

export function applyCustomerAlignment(component, alignment) {
  const addressColumns = component.find('.address-column');
  addressColumns.forEach(column => {
    column.addStyle({ 'text-align': alignment });
  });
}

export function updateCustomerFontFamily(component, fontFamily) {
  const addressColumns = component.find('.address-column');
  addressColumns.forEach(column => {
    column.addStyle({ 'font-family': `'${fontFamily}', sans-serif` });
  });
}

export function updateCustomerLineHeight(component, lineHeight) {
  const addressColumns = component.find('.address-column');
  addressColumns.forEach(column => {
    column.addStyle({ 'line-height': lineHeight });
  });
}

export function updateOrderSummaryBlock(component, enabledElements) {
  if (component) {
    component.set('enabledElements', enabledElements);
    
    const elements = {
      orderdate: component.find('[data-element="orderdate"]')[0],
      orderid: component.find('[data-element="orderid"]')[0],
      payment: component.find('[data-element="payment"]')[0],
      productimage: component.find('[data-element="productimage"]')[0],
      qty: component.find('[data-element="qty"]')[0],
      subscription: component.find('[data-element="subscription"]')[0],
    };

    Object.keys(elements).forEach(key => {
      if (elements[key]) {
        if (enabledElements.includes(key)) {
          elements[key].addStyle({ display: '' });
        } else {
          elements[key].addStyle({ display: 'none' });
        }
      }
    });

    // Adjust the order header table
    const orderHeader = component.find('.order-header')[0];
    if (orderHeader) {
      const visibleHeaderCells = ['orderdate', 'orderid', 'payment'].filter(key => enabledElements.includes(key));
      orderHeader.find('td').forEach((cell, index) => {
        if (visibleHeaderCells.includes(['orderdate', 'orderid', 'payment'][index])) {
          cell.addStyle({ display: '', width: `${100 / visibleHeaderCells.length}%` });
        } else {
          cell.addStyle({ display: 'none' });
        }
      });
    }

    // Adjust the product info row
    const productInfoRow = component.find('.order-summary-table tr')[0];
    if (productInfoRow) {
      const productImageCell = productInfoRow.find('.product-info')[0];
      const qtyCell = productInfoRow.find('.quantity')[0];

      if (productImageCell && !enabledElements.includes('productimage')) {
        const productImage = productImageCell.find('.product-image')[0];
        if (productImage) {
          productImage.addStyle({ display: 'none' });
        }
      }
      if (qtyCell) {
        qtyCell.addStyle({ display: enabledElements.includes('qty') ? '' : 'none' });
      }
    }
  }
}

export function applyOrderAlignment(component, alignment) {
  const orderHeader = component.find('.order-header')[0];
  if (orderHeader) {
    orderHeader.addStyle({ 'text-align': alignment });
  }
}

export function updateOrderFontFamily(component, fontFamily) {
  if (component) {
    // Update the data attribute
    component.set('attributes', { ...component.get('attributes'), 'data-font-family': fontFamily });
    
    // Update the style of the main component
    component.setStyle({ 'font-family': fontFamily });
    
    // Update all child components recursively
    function updateChildrenFontFamily(parent) {
      parent.components().forEach(child => {
        child.setStyle({ 'font-family': fontFamily });
        if (child.components().length > 0) {
          updateChildrenFontFamily(child);
        }
      });
    }
    
    updateChildrenFontFamily(component);
  }
}

export function updateOrderLineHeight(component, lineHeight) {
  component.addStyle({ 'line-height': lineHeight });
}

export function updateOrderSeparators(component, style, color = '#e0e0e0') {
  const table = component.find('.order-summary-table')[0];
  if (table) {
    const rows = table.find('tr');
    rows.forEach((row, index) => {
      const cells = row.find('td');
      cells.forEach(cell => {
        if (index === rows.length - 1) { // Last row (Total)
          cell.addStyle({
            'border-top': style !== 'none' ? `1px ${style} ${color}` : 'none',
            'border-bottom': 'none'
          });
        } else if (index === 0) { // First row (product details)
          cell.addStyle({
            'border-top': 'none',
            'border-bottom': style !== 'none' ? `1px ${style} ${color}` : 'none'
          });
        } else {
          cell.addStyle({
            'border-top': 'none',
            'border-bottom': 'none'
          });
        }
      });
    });
  }
}

export const applyTemplateSettings = (editor, metadata) => {
  if (!editor || !metadata) return;

  const wrapper = editor.getWrapper();
  const contentTable = wrapper.find('.content-table')[0];

  if (contentTable) {
    contentTable.addStyle({ 'max-width': `${metadata.containerWidth || 640}px` });
    contentTable.addAttributes({ align: metadata.contentAlignment || 'center' });
  }

  wrapper.setStyle({ background: metadata.bodyBackgroundColor || '#f7f8fa' });
};