import MenuIcon from '../../../../../assets/icons/icon-menu.svg';

export const registerMenuBlock = (editor, brandStyles, opts = {}) => {
  editor.DomComponents.addType('Menu', {
    model: {
      defaults: {
        tagName: 'span',
        classes: ['block-div'],
        attributes: { 'data-gjs-type': 'Menu' },
        style: {
          display: "block !important",
          "font-family":  `${brandStyles?.typeGraphy?.fontFamily}, ${brandStyles?.typeGraphy?.backupFontFamily}` ||  "Arial",
          width: "100%",
          "text-align": "center",
        },
        components: [
          {
            tagName: 'table',
            type: 'table',
            style: {
              display: 'inline-block',
            },
            droppable: false,
            attributes: { cellspacing: '0', cellpadding: '0', border: '0' },
            components: [
              {
                tagName: 'tbody',
                type: 'tbody',
                droppable: false,
                components: [
                  {
                    tagName: 'tr',
                    type: 'row',
                    droppable: false,
                    components: [
                      {
                        tagName: 'td',
                        type: 'cell',
                        droppable: false,
                        components: [
                          {
                            tagName: 'a',
                            attributes: { href: '#', target: '_blank' },
                            style: { 
                              display: "inline-block", 'text-decoration': 'none' 
                            },
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: 'Item 1',
                                droppable: false,
                                editable: true,
                                style: {
                                  display: "inline-block",
                                  padding: "8px 16px",
                                  "font-size": brandStyles?.typeGraphy?.fontSize || "14px",
                                  "font-weight": "600",
                                  "text-decoration": "none",
                                  color: "#000000",
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        tagName: 'td',
                        attributes: { class: 'separator-span' },
                        style: { display: 'none', color:  '#000000', padding: '0 5px' },
                        content: '|',
                      },
                      {
                        tagName: 'td',
                        components: [
                          {
                            tagName: 'a',
                           attributes: { href: '#', target: '_blank' },
                            style: { 
                              display: "inline-block", 'text-decoration': 'none' 
                            },
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: 'Item 2',
                                droppable: false,
                                editable: true,
                                style: {
                                  display: "inline-block",
                                  padding: "8px 16px",
                                  "font-size": brandStyles?.typeGraphy?.fontSize || "14px",
                                  "font-weight": "600",
                                  "text-decoration": "none",
                                  color: "#000000",
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        tagName: 'td',
                        attributes: { class: 'separator-span' },
                        style: { 
                          display: 'none', color: '#000000', padding: '0 5px' 
                        },
                        content: '|',
                      },
                      {
                        tagName: 'td',
                        components: [
                          {
                            tagName: 'a',
                            attributes: { href: '#', target: '_blank' },
                            style: { 
                              display: "inline-block", 'text-decoration': 'none' 
                            },
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: 'Item 3',
                                droppable: false,
                                editable: true,
                                style: {
                                  display: "inline-block",
                                  padding: "8px 16px",
                                  "font-size": brandStyles?.typeGraphy?.fontSize || "14px",
                                  "font-weight": "600",
                                  "text-decoration": "none",
                                  color: "#000000",
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],   
        droppable: false,
        stylable: true,
        separatorEnabled: false,
        separatorValue: '|',
        separatorColor: '#000000',
      },
      init() {
        this.on('change:attributes', this.handleAttrChange);
      },
      handleAttrChange() {
        if (this.get('separatorEnabled')) {
          this.showSeparators();
        } else {
          this.hideSeparators();
        }
      },
      showSeparators() {
        const separators = this.find('.separator-span');
        separators.forEach(separator => {
          separator.addStyle({ display: 'table-cell' });
        });
      },
      hideSeparators() {
        const separators = this.find('.separator-span');
        separators.forEach(separator => {
          separator.addStyle({ display: 'none' });
        });
      },
    },
  });

  editor.BlockManager.add('menuBlock', {
    label: `<div class="gjs-block-label-container" data-category="Block"><img src=${MenuIcon} alt="Menu Icon" height="24px" width="24px"/></div>
    <div class="block-label-text">Menu</div>`,
    content: { type: 'Menu' },
    category: 'Blocks',
    draggable: 'td',
  });
};