import {
  BaseEdge,
  EdgeLabelRenderer,
  getSimpleBezierPath,
  useReactFlow,
} from 'reactflow';
import { FormGroup } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Checkbox } from '@mui/material';
import iconAdd from '../../../assets/icons/icon-plus.svg';
import iconAddCircle from '../../../assets/icons/icon-add-circle.svg';
import iconTemplates from '../../../assets/icons/icon-templates.svg';
import iconTag from '../../../assets/icons/icon-tag-automation.svg';
import iconClose from '../../../assets/icons/icon-cross.svg';
import iconEmail from '../../../assets/icons/icon-email.svg';
import iconTime from '../../../assets/icons/icon-time.svg';
import iconElse from '../../../assets/icons/icon-Condition.svg';
import iconSplit from '../../../assets/icons/icon-AB.svg';
import iconContact from '../../../assets/icons/icon-contact-status.svg';
import iconList from '../../../assets/icons/icon-order-listing.svg';
import iconSkip from '../../../assets/icons/icon-skip-step.svg';
import iconGoal from '../../../assets/icons/icon-target.svg';
import iconExit from '../../../assets/icons/icon-exit.svg';
import iconArrow from '../../../assets/icons/icon-arrow.svg';
import { useEffect, useState } from 'react';
import CustomDialog from '../../../components/Dialog/Dialog';
import { useForm } from 'react-hook-form';
import CustomMenu from '../../../components/Menu/Menu';
import { TextField } from '@mui/material';
import RadioButtonsGroup from '../../../components/RadioGroup/Radio';
import CustomCheckBox from '../../../components/CheckBox/CheckBox';
import CustomAutocomplete from '../../../components/AutoComplete/AutoComplete';
import DynamicTabs from '../../../components/Tab/Tab';
import iconForm from '../../../assets/icons/icon-form.svg';
import BasicDateTimePicker from '../../../components/Calendar/Calendar2';
import ResourceApi from '../../../api/resourceapi';
export default function CustomEdge({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  DATA,
}) {
  const { setEdges, getNode, getNodes } = useReactFlow();
  const { setNodes } = useReactFlow();
  //const [showOverlay, setshowOverlay] = useState(true);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [edgePath, labelX, labelY] = getSimpleBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });
  const sourceNode = getNode(source); // Get the source node
  const { onSHowOverlay } = sourceNode.data;
  const targetNode = getNode(target); // Get the target node
  console.log(sourceNode, targetNode, id);
  const { register, handleSubmit, setValue } = useForm();
  const [status, setStatus] = useState('');
  const [jump, setJump] = useState('');
  const [jumpAnchorElement, setJumpAnchorElement] = useState(null);
  const [senderAnchorElement, setSenderAnchorElement] = useState(null);
  const [statusAnchorElement, setStatusAnchorElement] = useState(null);
  const [delayAnchorElement, setDelayAnchorElement] = useState(null);
  const [delay, setDelay] = useState('');
  const [goal, setGoal] = useState('');
  const [sender, setSender] = useState('');
  const [allSenders, setAllSenders] = useState([]);
  const [senderEmail, setSenderEmail] = useState('');
  const [contactStatus, setContactStatus] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const [selectedValue, setSelectedValue] = useState('Add');
  const values = ['Add', 'Remove'];
  const goalOrder = ['Any Product', 'Specific Product'];
  const goalTag = ['Any Tag', 'Specific Tag'];
  const goalList = ['Any List', 'Specific List'];
  const goalWait = [
    'Wait until the goal is met',
    'Continue anyway',
    'End this automation',
  ];
  const [selectedGoalWait, setSelectedGoalWait] = useState(
    'Wait until the goal is met',
  );
  const [selectedGoalOrder, setSelectedGoalOrder] = useState('Any Product');
  const [selectedTag, setSelectedTag] = useState('Any Tag');
  const [selectedList, setSelectedList] = useState('Any List');
  const [selectedCheckValue, setSelectedCheckValue] = useState('');
  const [selectedGoalOrderStatus, setSelectedGoalOrderStatus] = useState('');
  const checkValues = [
    'Add UTM parameters to email links',
    'Override From Name, From Email & Reply To Email',
  ];
  const goalOrderStatus = ['Completed', 'Draft', 'onHold', 'Processing'];
  const [jumpDialog, setJumpDialog] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const [goalDialog, setGoalDialog] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });

  const setDimensions = (nodeId, dimensions) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: { ...node.data, ...dimensions },
          };
        }
        return node;
      }),
    );
  };
  const nextGoalDialog = (nextGoal) => {
    setGoalDialog({
      title: `Select Goal (${nextGoal})`,
      open: true,
      onClose: closeGoalDialog,
      type: 'nextStep',
      actions: [
        {
          label: 'Cancel',
          onClick: () => closeGoalDialog(),
          classes: 'btn-outline dark-border',
        },
        {
          label: 'Save',
          onClick: () => handleSubmit(addGoalNode)(),
          classes: 'btn-primary',
        },
      ],
    });
  };

  const tabs = ['Activities', 'Email'];
  const panels = [
    <div className="event-contacts">
      <div
        className="journey-card"
        onClick={() => {
          setValue('setGoal', 'Tag Added');
          setGoal('Tag is Added');
          nextGoalDialog('Tag is Added');
        }}
      >
        <div className="img-container">
          <img src={iconForm} alt="form" />
        </div>
        <div>Tag is Added</div>
      </div>
      <div
        className="journey-card"
        onClick={() => {
          setValue('setGoal', 'Tag Removed');
          setGoal('Tag is Removed');
          nextGoalDialog('Tag is Removed');
        }}
      >
        <div className="img-container">
          <img src={iconForm} alt="form" />
        </div>
        <div>Tag is Removed</div>
      </div>
      <div
        className="journey-card"
        onClick={() => {
          setValue('setGoal', 'List Added');
          setGoal('Added to a List');
          nextGoalDialog('Added to a List');
        }}
      >
        <div className="img-container">
          <img src={iconForm} alt="form" />
        </div>
        <div>Added to a List</div>
      </div>
      <div
        className="journey-card"
        onClick={() => {
          setValue('setGoal', 'List Removed');
          setGoal('Removed from a List');
          nextGoalDialog('Removed from a List');
        }}
      >
        <div className="img-container">
          <img src={iconForm} alt="form" />
        </div>
        <div>Removed from a List</div>
      </div>

      <div
        className="journey-card"
        onClick={() => {
          setValue('setGoal', 'Contact Subscribers');
          setGoal('Contact Subscribers');
          nextGoalDialog('Contact Subscribers');
        }}
      >
        <div className="img-container">
          <img src={iconForm} alt="form" />
        </div>
        <div>Contact Subscribers</div>
      </div>
    </div>,
    <div className="event-contacts">
      <div
        className="journey-card"
        onClick={() => {
          setValue('setGoal', 'Order Created');
          setGoal('Order Created');
          nextGoalDialog('Order Created');
        }}
      >
        <div className="img-container">
          <img src={iconForm} alt="form" />
        </div>
        <div>Order Created</div>
      </div>
    </div>,
  ];

  const [splitDialog, setSplitDialog] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const [tagDialog, setTagDialog] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [delayOption, setDelayoption] = useState('Delay for a specific period');
  const [emailDialog, setEmailDialog] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const [delayDialog, setDelayDialog] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const closestatusDialog = () => {
    setContactStatus((prevConfig) => ({ ...prevConfig, open: false }));
  };
  useEffect(() => {
    setValue('actionType', 'Add');
  }, []);
  const closeSplitDialog = () => {
    console.log('clicked close splite');
    setSplitDialog((prevConfig) => ({ ...prevConfig, open: false }));
  };
  const closeGoalDialog = () => {
    setGoalDialog((prevConfig) => ({ ...prevConfig, open: false }));
    setGoal('');
  };
  const closeJumpDialog = () => {
    setJumpDialog((prevConfig) => ({ ...prevConfig, open: false }));
  };
  const closeTagDialog = () => {
    setTagDialog((prevConfig) => ({ ...prevConfig, open: false }));
  };
  const closeEmailDialog = () => {
    setEmailDialog((prevConfig) => ({ ...prevConfig, open: false }));
    setSenderEmail('');
    setSender('');
    setSelectedCheckValue('');
  };
  const closeDelayDialog = () => {
    setDelayDialog((prevConfig) => ({ ...prevConfig, open: false }));
    //setDelayoption('Delay for a specific period');
  };
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    setValue('actionType', event.target.value);
  };
  const handleGoalOrder = (event) => {
    setSelectedGoalOrder(event.target.value);
    setValue('orderContains', event.target.value);
  };
  const handleGoalWait = (event) => {
    setSelectedGoalWait(event.target.value);
    setValue('waitDecision', event.target.value);
  };
  const handleCheckChange = (event) => {
    setSelectedCheckValue(event.target.value);
    setValue('checkOptions', event.target.value);
  };
  const handleGoalOrderStatus = (event) => {
    setSelectedGoalOrderStatus(event.target.value);
    setValue('orderStatus', event.target.value);
  };

  const handleDateTimeSelection = (dateTime) => {
    setValue('delayOption', 'Delay until a specific date and time');
    console.log('Selected Date and Time:', dateTime);
    const date = new Date(dateTime);

    // Format the date as MM/DD/YYYY
    const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });

    // Format the time as hh:mm AM/PM
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    setValue('specificDuration', `${formattedDate} ${formattedTime}`);
    console.log(`${formattedDate} ${formattedTime}`);
  };
  useEffect(() => {
    console.log(status);
  }, [setStatus]);

  const [jumpOptions, setJumpOptions] = useState([]);
  const delayOptions = [
    {
      label: 'Minutes',
      value: 'Minutes',
    },
    {
      label: 'Hours',
      value: 'Hours',
    },
    {
      label: 'Days',
      value: 'Days',
    },
    {
      label: 'Weeks',
      value: 'Weeks',
    },
  ];
  const contactStatusOptions = [
    {
      label: 'Subscribed',
      value: 'Subscribed',
    },
    {
      label: 'Unsubscribed',
      value: 'Unsubscribed',
    },
    {
      label: 'Blocked',
      value: 'Blocked',
    },
    {
      label: 'Bounced',
      value: 'Bounced',
    },
  ];

  const addSplitPath = (Data) => {
    console.log('adding split path');
    const nodes = getNodes();
    const currentNodeIndex = nodes.findIndex((node) => node.id === id);

    if (
      targetNode.type === 'endNode' &&
      !('flag' in targetNode.data) &&
      !('flag' in sourceNode.data)
    ) {
      // Current node is the last node
      addConditional(Data.description);
    } else {
      // Current node is not the last node
      addConditional2(Data.description);
    }
    setIsPopupVisible(!isPopupVisible);
  };

  const addDelayNode = (Data) => {
    console.log(Data);
    console.log(delay);
    let data;
    if (delayOption === 'Delay for a specific period') {
      data = {
        header: `Delay`,
        headerIcon: iconTag,
        headerDescription: delayOption,
        status: `${Data.delayDuration} ${Data.delay}`,
        bodyContent: [
          { label: 'Active', data: 20 },
          { label: 'Completed', data: 20 },
        ],
      };
    } else {
      data = {
        header: `Delay`,
        headerIcon: iconTag,
        headerDescription: Data.delayOption,
        status: Data.specificDuration,
        bodyContent: [
          { label: 'Active', data: 20 },
          { label: 'Completed', data: 20 },
        ],
      };
    }
    console.log(status);

    if (
      sourceNode.data &&
      'flag' in sourceNode.data &&
      'flag' in targetNode.data
    ) {
      console.log('addtagNode');
      handleClick3(data);
    } else {
      if (targetNode.type === 'endNode' && !('flag' in targetNode.data)) {
        // Current node is the last node
        handleClick(data);
      } else {
        // Current node is not the last node
        handleClick2(data);
      }
    }
  };

  const addTagNode = (Data) => {
    console.log(Data);
    const data = {
      header: `Add/Remove ${Data.type}`,
      headerIcon: iconTag,
      headerDescription: `${Data.actionType} ${Data.type}`,
      status: Data.tag,
      bodyContent: [
        { label: 'Active', data: 20 },
        { label: 'Completed', data: 20 },
      ],
    };

    if (
      sourceNode.data &&
      'flag' in sourceNode.data &&
      'flag' in targetNode.data
    ) {
      console.log('addtagNode');
      handleClick3(data);
    } else {
      if (targetNode.type === 'endNode' && !('flag' in targetNode.data)) {
        // Current node is the last node
        handleClick(data);
      } else {
        // Current node is not the last node
        handleClick2(data);
      }
    }
  };

  const addGoalNode = (Data) => {
    console.log(Data);
    let description;
    if (Data.setGoal === 'Order Created') {
      description = [
        { 'Set goal': Data.setGoal },
        { 'Order Status': Data.orderStatus },
        { Contains: Data.orderContains || 'Any product' },
      ];
    } else if (Data.setGoal === 'Tag Added') {
      description = [
        { 'Set goal': Data.setGoal },
        { Contains: Data.tagContains || 'Any Tag' },
      ];
    } else if (Data.setGoal === 'Tag Removed') {
      description = [{ 'Set goal': Data.setGoal }];
    } else if (Data.setGoal === 'List Added') {
      description = [
        { 'Set goal': Data.setGoal },
        { Contains: Data.listContains || 'Any List' },
      ];
    } else if (Data.setGoal === 'List Removed') {
      description = [{ 'Set goal': Data.setGoal }];
    } else if (Data.setGoal === 'Contact Subscribers') {
      description = [{ 'Set goal': Data.setGoal }];
    }
    console.log(description);
    const data = {
      header: `Goal`,
      headerIcon: iconGoal,
      headerDescription: description,
      status: Data.tag,
      bodyContent: [
        { label: 'Active', data: 20 },
        { label: 'Completed', data: 20 },
      ],
    };

    if (
      sourceNode.data &&
      'flag' in sourceNode.data &&
      'flag' in targetNode.data
    ) {
      console.log('addtagNode');
      handleClick3(data);
    } else {
      if (targetNode.type === 'endNode' && !('flag' in targetNode.data)) {
        // Current node is the last node
        handleClick(data);
      } else {
        // Current node is not the last node
        handleClick2(data);
      }
    }
  };

  const addExitNode = () => {
    console.log('exitNode', id);
    let newNodePosition = { x: 0, y: 0 };
    let Id = 1;
    Id = sourceNode.id;
    console.log(status);
    setNodes((nds) => {
      let updatedNodes = [...nds];
      const insertIndex = updatedNodes.findIndex((node) => node === targetNode);
      //updatedNodes = updatedNodes.filter(node => node.id !== targetNode.id);
      //    if (
      //     sourceNode.data.flag === 'conditional' ||
      //     sourceNode.data.flag === 'splitPath'
      //    ) {
      //     newNodePosition = {
      //      x: sourceNode.position.x - 120, // Keep the same x position
      //      y: sourceNode.position.y + 100 // Position 100px below the last node (adjust as needed)
      //     };
      //    } else if (sourceNode.data && 'flag' in sourceNode.data) {
      //     newNodePosition = {
      //      x: sourceNode.position.x - 120, // Keep the same x position
      //      y: sourceNode.position.y + 220 // Position 100px below the last node (adjust as needed)
      //     };
      //    } else {
      //     newNodePosition = {
      //      x: sourceNode.position.x, // Keep the same x position
      //      y: sourceNode.position.y + 220 // Position 100px below the last node (adjust as needed)
      //     };
      //    }
      let newNode;
      newNode = {
        id: `${Id}-end`,
        type: 'textUpdater',
        data: {
          header: 'Exit Automation',
          setDimensions,
          headerIcon: iconExit,
          type: 'exit',
          headerDescription: '',
          bodyContent: [{ label: 'Completed', data: 20 }],
        },
        //draggable: false,
        position: newNodePosition, // Use dynamically calculated position
      };

      console.log(insertIndex);
      // Push all nodes below the current node downward
      //    for (let i = insertIndex; i < updatedNodes.length; i++) {
      //     updatedNodes[i].position = {
      //      x: updatedNodes[i].position.x,
      //      y: updatedNodes[i].position.y + 220 // Push each node downward
      //     };
      //    }
      updatedNodes.splice(insertIndex, 0, newNode); // Insert new node
      return updatedNodes;
    });

    const newEdge2 = {
      id: `${Id}-end`,
      type: 'custom-edge',
      source: Id,
      target: `${Id}-end`,
      sourceHandle: 'bottom-handle',
      targetHandle: 'top-handle',
    };

    setEdges((eds) => {
      let updatedEdges = [...eds];
      updatedEdges = updatedEdges.filter((edge) => edge.id !== id);
      return [...updatedEdges, newEdge2];
    });
  };

  const addJump = (jumpData) => {
    const jumpTarget = jumpData.jump;
    console.log(jumpData);
    console.log('add Jump', id);
    let newNodePosition = { x: 0, y: 0 };
    let Id = 1;
    Id = sourceNode.id;
    console.log(status);
    setNodes((nds) => {
      let updatedNodes = [...nds];
      const insertIndex = updatedNodes.findIndex((node) => node === targetNode);
      //updatedNodes = updatedNodes.filter(node => node.id !== targetNode.id);
      //    if (
      //     sourceNode.data.flag === 'conditional' ||
      //     sourceNode.data.flag === 'splitPath'
      //    ) {
      //     newNodePosition = {
      //      x: sourceNode.position.x - 120, // Keep the same x position
      //      y: sourceNode.position.y + 100 // Position 100px below the last node (adjust as needed)
      //     };
      //    } else if (sourceNode.data && 'flag' in sourceNode.data) {
      //     newNodePosition = {
      //      x: sourceNode.position.x - 120, // Keep the same x position
      //      y: sourceNode.position.y + 220 // Position 100px below the last node (adjust as needed)
      //     };
      //    } else {
      //     newNodePosition = {
      //      x: sourceNode.position.x, // Keep the same x position
      //      y: sourceNode.position.y + 220 // Position 100px below the last node (adjust as needed)
      //     };
      //    }
      let newNode;
      newNode = {
        id: `${Id}-jump`,
        type: 'textUpdater',
        data: {
          header: 'Jump Step',
          setDimensions,
          headerIcon: iconSkip,
          type: 'jump',
          headerDescription: '',
          bodyContent: [{ label: 'Completed', data: 20 }],
        },
        //draggable: false,
        position: newNodePosition, // Use dynamically calculated position
      };

      console.log(insertIndex);
      // Push all nodes below the current node downward
      //    for (let i = insertIndex; i < updatedNodes.length; i++) {
      //     updatedNodes[i].position = {
      //      x: updatedNodes[i].position.x,
      //      y: updatedNodes[i].position.y + 220 // Push each node downward
      //     };
      //    }
      updatedNodes.splice(insertIndex, 0, newNode); // Insert new node
      return updatedNodes;
    });

    const newEdge2 = {
      id: `${sourceNode.id}-jump`,
      type: 'custom-edge',
      source: sourceNode.id,
      target: `${Id}-jump`,
      sourceHandle: 'bottom-handle',
      targetHandle: 'top-handle',
    };
    const newEdge = {
      id: `${sourceNode.id}-jump-${jumpTarget}`,
      type: 'jumpEdge',
      source: `${Id}-jump`,
      target: jumpTarget,
      sourceHandle: 'bottom-handle',
      targetHandle: 'top-handle',
    };
    setEdges((eds) => {
      let updatedEdges = [...eds];
      updatedEdges = updatedEdges.filter((edge) => edge.id !== id);
      return [...updatedEdges, newEdge2, newEdge];
    });
  };

  const addStatusNode = (Data) => {
    console.log(Data.status);
    const data = {
      header: 'Change Contact Status',
      headerIcon: iconContact,
      headerDescription: 'Status updated to',
      status: Data.status,
      bodyContent: [
        { label: 'Active', data: 20 },
        { label: 'Completed', data: 20 },
      ],
    };
    if (
      sourceNode.data &&
      'flag' in sourceNode.data &&
      'flag' in targetNode.data
    ) {
      handleClick3(data);
    } else {
      if (targetNode.type === 'endNode') {
        // Current node is the last node
        handleClick(data);
      } else {
        // Current node is not the last node
        handleClick2(data);
      }
    }
    setIsPopupVisible(!isPopupVisible);
  };

  const handleClick = (data) => {
    console.log('handleClick', id);
    let newNodePosition = { x: 0, y: 0 };
    let Id = 1;
    Id = sourceNode.id;
    console.log(status);
    setNodes((nds) => {
      let updatedNodes = [...nds];
      updatedNodes = updatedNodes.filter((node) => node.id !== targetNode.id);
      //    if (
      //     sourceNode.data.flag === 'conditional' ||
      //     sourceNode.data.flag === 'splitPath'
      //    ) {
      //     newNodePosition = {
      //      x: sourceNode.position.x - 120, // Keep the same x position
      //      y: sourceNode.position.y + 100 // Position 100px below the last node (adjust as needed)
      //     };
      //    } else if (sourceNode.data && 'flag' in sourceNode.data) {
      //     newNodePosition = {
      //      x: sourceNode.position.x - 120, // Keep the same x position
      //      y: sourceNode.position.y + 220 // Position 100px below the last node (adjust as needed)
      //     };
      //    } else {
      //     newNodePosition = {
      //      x: sourceNode.position.x, // Keep the same x position
      //      y: sourceNode.position.y + 220 // Position 100px below the last node (adjust as needed)
      //     };
      //    }
      let newNode;
      if (data) {
        console.log('data');
        newNode = {
          id: `${parseInt(Id) + 1}`,
          type: 'textUpdater',
          data: {
            header: data.header,
            setDimensions,
            headerIcon: data.headerIcon,
            headerDescription: data.headerDescription,
            status: data.status,
            bodyContent: data.bodyContent,
          },
          //draggable: false,
          position: newNodePosition, // Use dynamically calculated position
        };
      } else {
        console.log('data not');
        newNode = {
          id: `${parseInt(Id) + 1}`,
          type: 'textUpdater',
          data: {
            header: 'Tag is added',
            setDimensions,
            headerIcon: iconTag,
            headerDescription: 'Has included',
            bodyContent: [
              { label: 'Active', data: 20 },
              { label: 'Active', data: 20 },
              { label: 'Active', data: 20 },
            ],
          },
          //draggable: false,
          position: newNodePosition, // Use dynamically calculated position
        };
      }

      //    newNodePosition = {
      //     x: newNodePosition.x + 98,
      //     y: newNodePosition.y + 200
      //    };

      const newNode2 = {
        id: `${parseInt(Id) + 1 + 0.5}`,
        type: 'endNode',
        data: { label: 'End', setDimensions },
        position: newNodePosition,
        //draggable: false
      };
      return [...updatedNodes, newNode, newNode2];
    });

    const newEdge2 = {
      id: `${parseInt(Id)}-${parseInt(Id) + 1}`,
      type: 'custom-edge',
      source: Id,
      target: `${parseInt(Id) + 1}`,
      sourceHandle: 'bottom-handle',
      targetHandle: 'top-handle',
    };

    const newEdge = {
      id: `${parseInt(Id) + 1}-${parseInt(Id) + 1 + 0.5}`,
      type: 'custom-edge',
      source: `${parseInt(Id) + 1}`,
      target: `${parseInt(Id) + 1 + 0.5}`,
      sourceHandle: 'bottom-handle',
      targetHandle: 'top-handle',
    };
    setEdges((eds) => {
      let updatedEdges = [...eds];
      updatedEdges = updatedEdges.filter((edge) => edge.id !== id);
      return [...updatedEdges, newEdge2, newEdge];
    });
  };

  const handleClick3 = (data) => {
    console.log('handleClick3');
    let previousNode;
    let nextNode;

    setNodes((nds) => {
      const updatedNodes = [...nds];
      const insertIndex = updatedNodes.findIndex(
        (node) => node.id === sourceNode.id,
      );

      // Get the previous and next nodes
      previousNode = sourceNode;
      nextNode = targetNode;
      console.log(previousNode.id, nextNode.id);

      // Insert the new node between previousNode and nextNode
      let newNodePosition = { x: 0, y: 0 };
      //    newNodePosition = {
      //     x: sourceNode.position.x, // Keep the same x position
      //     y: sourceNode.position.y + 220 // Position 100px below the last node (adjust as needed)
      //    };
      //    if (sourceNode.data && sourceNode.data.flag === true) {
      //     if (sourceNode.type === 'endNode') {
      //      newNodePosition = {
      //       x: sourceNode.position.x - 240, // Keep the same x position
      //       y: sourceNode.position.y + 100 // Position 100px below the last node (adjust as needed)
      //      };
      //     } else {
      //      newNodePosition = {
      //       x: sourceNode.position.x - 60, // Keep the same x position
      //       y: sourceNode.position.y + 200 // Position 100px below the last node (adjust as needed)
      //      };
      //     }
      //    } else if (sourceNode.data && sourceNode.data.flag === false) {
      //     if (sourceNode.type === 'endNode') {
      //      newNodePosition = {
      //       x: sourceNode.position.x + 60, // Keep the same x position
      //       y: sourceNode.position.y + 100 // Position 100px below the last node (adjust as needed)
      //      };
      //     } else {
      //      newNodePosition = {
      //       x: sourceNode.position.x + 60, // Keep the same x position
      //       y: sourceNode.position.y + 200 // Position 100px below the last node (adjust as needed)
      //      };
      //     }
      //    }

      //console.log(ID);
      let newNode;
      if (data) {
        console.log('data');
        newNode = {
          id: `${previousNode.id}.${nextNode.id}`,
          type: 'textUpdater',
          data: {
            header: data.header,
            setDimensions,
            headerIcon: data.headerIcon,
            flag: 'normal',
            label: sourceNode.data.label,
            headerDescription: data.headerDescription,
            status: data.status,
            bodyContent: data.bodyContent,
          },
          //draggable: false,
          position: newNodePosition, // Use dynamically calculated position
        };
      } else {
        console.log('data not');
        newNode = {
          id: `${previousNode.id}.${nextNode.id}`,
          type: 'textUpdater',
          data: {
            header: 'Tag is added',
            setDimensions,
            headerIcon: iconTag,
            flag: 'normal',
            headerDescription: 'Has included',
            bodyContent: [
              { label: 'Active', data: 20 },
              { label: 'Active', data: 20 },
              { label: 'Active', data: 20 },
            ],
          },
          //draggable: false,
          position: newNodePosition, // Use dynamically calculated position
        };
      }

      console.log(insertIndex, previousNode.id, nextNode.id);
      // Push all nodes below the current node downward
      //    for (let i = insertIndex + 1; i < updatedNodes.length; i++) {
      //     updatedNodes[i].position = {
      //      x: updatedNodes[i].position.x,
      //      y: updatedNodes[i].position.y + 250 // Push each node downward
      //     };
      //    }
      updatedNodes.splice(insertIndex + 1, 0, newNode); // Insert new node
      return updatedNodes;
    });

    setEdges((eds) => {
      const updatedEdges = [...eds];

      const edgeBetweenPrevAndNext = updatedEdges.find(
        (edge) =>
          edge.source === previousNode.id && edge.target === nextNode.id,
      );
      if (edgeBetweenPrevAndNext) {
        const index = updatedEdges.indexOf(edgeBetweenPrevAndNext);
        if (index !== -1) {
          updatedEdges.splice(index, 1);
        }
      }

      const newEdge1 = {
        id: `${previousNode.id}-${previousNode.id}.${nextNode.id}`,
        type: 'custom-edge',
        source: previousNode.id,
        target: `${previousNode.id}.${nextNode.id}`,
        sourceHandle: 'bottom-handle',
        targetHandle: 'top-handle',
      };

      const newEdge2 = {
        id: `${previousNode.id}.${nextNode.id}-${nextNode.id}`,
        type: 'custom-edge',
        source: `${previousNode.id}.${nextNode.id}`,
        target: nextNode.id,
        sourceHandle: 'bottom-handle',
        targetHandle: 'top-handle',
      };

      return [...updatedEdges, newEdge1, newEdge2]; // Add new edges
    });
  };

  const handleClick2 = (data) => {
    console.log('handleClick2');
    let previousNode;
    let nextNode;
    console.log(id);
    setNodes((nds) => {
      const updatedNodes = [...nds];

      // Get the previous and next nodes
      previousNode = sourceNode;
      nextNode = targetNode;
      console.log(previousNode.id, nextNode.id);

      // Remove the current node

      // Insert the new node between previousNode and nextNode
      let newNodePosition = { x: 0, y: 0 };
      //    if (
      //     sourceNode.data.flag === 'conditional' ||
      //     sourceNode.data.flag === 'splitPath'
      //    ) {
      //     newNodePosition = {
      //      x: sourceNode.position.x - 120, // Keep the same x position
      //      y: sourceNode.position.y + 100 // Position 100px below the last node (adjust as needed)
      //     };
      //    } else if (sourceNode.data && 'flag' in sourceNode.data) {
      //     newNodePosition = {
      //      x: sourceNode.position.x - 120, // Keep the same x position
      //      y: sourceNode.position.y + 220 // Position 100px below the last node (adjust as needed)
      //     };
      //    } else {
      //     newNodePosition = {
      //      x: sourceNode.position.x, // Keep the same x position
      //      y: sourceNode.position.y + 220 // Position 100px below the last node (adjust as needed)
      //     };
      //    }

      //console.log(ID);
      let newNode;
      if (data) {
        console.log('data');
        newNode = {
          id: `${previousNode.id}.${nextNode.id}`,
          type: 'textUpdater',
          data: {
            header: data.header,
            setDimensions,
            headerIcon: data.headerIcon,
            headerDescription: data.headerDescription,
            status: data.status,
            bodyContent: data.bodyContent,
          },
          //draggable: false,
          position: newNodePosition, // Use dynamically calculated position
        };
      } else {
        console.log('data not');
        newNode = {
          id: `${previousNode.id}.${nextNode.id}`,
          type: 'textUpdater',
          data: {
            header: 'Tag is added',
            setDimensions,
            headerIcon: iconTag,
            headerDescription: 'Has included',
            bodyContent: [
              { label: 'Active', data: 20 },
              { label: 'Active', data: 20 },
              { label: 'Active', data: 20 },
            ],
          },
          //draggable: false,
          position: newNodePosition, // Use dynamically calculated position
        };
      }
      //    const newNode = {
      //     id: `${previousNode.id}.${nextNode.id}`, // Generate a unique ID
      //     type: 'textUpdater',
      //     data: {
      //      header: 'Tag is inserted',
      //      headerIcon: 'tag',
      //      headerDescription: 'Has included',
      //      bodyContent: [
      //       {label: 'Active', data: 20},
      //       {label: 'Active', data: 20},
      //       {label: 'Active', data: 20}
      //      ]
      //     },
      //     //draggable: false,
      //     position: newNodePosition // Use dynamically calculated position
      //    };
      const insertIndex = updatedNodes.findIndex((node) => node === targetNode);

      console.log(insertIndex, previousNode.id, nextNode.id);
      // Push all nodes below the current node downward
      //    for (let i = insertIndex; i < updatedNodes.length; i++) {
      //     updatedNodes[i].position = {
      //      x: updatedNodes[i].position.x,
      //      y: updatedNodes[i].position.y + 220 // Push each node downward
      //     };
      //    }
      updatedNodes.splice(insertIndex, 0, newNode); // Insert new node
      return updatedNodes;
    });

    setEdges((eds) => {
      const updatedEdges = [...eds];
      const edgeBetweenPrevAndNext = updatedEdges.find(
        (edge) =>
          edge.source === previousNode.id && edge.target === nextNode.id,
      );
      if (edgeBetweenPrevAndNext) {
        const index = updatedEdges.indexOf(edgeBetweenPrevAndNext);
        if (index !== -1) {
          updatedEdges.splice(index, 1);
        }
      }

      const newEdge1 = {
        id: `${previousNode.id}-${previousNode.id}.${nextNode.id}`,
        type: 'custom-edge',
        source: previousNode.id,
        target: `${previousNode.id}.${nextNode.id}`,
        sourceHandle: 'bottom-handle',
        targetHandle: 'top-handle',
      };

      const newEdge2 = {
        id: `${previousNode.id}.${nextNode.id}-${nextNode.id}`,
        type: 'custom-edge',
        source: `${previousNode.id}.${nextNode.id}`,
        target: nextNode.id,
        sourceHandle: 'bottom-handle',
        targetHandle: 'top-handle',
      };

      return [...updatedEdges, newEdge1, newEdge2]; // Add new edges
    });
  };

  const addConditional2 = (Data) => {
    let newNodePosition = { x: 0, y: 0 };
    let Id;
    console.log(id);
    console.log('Conditional2');
    let nodeId = id;
    const node = new Array(2).fill(null);
    console.log(nodeId);

    node[0] = sourceNode.id;
    node[1] = targetNode.id;

    console.log(node);

    setNodes((nds) => {
      let updatedNodes = [...nds];

      // Determine position based on the last node in the array

      Id = sourceNode.id;
      //    newNodePosition = {
      //     x: sourceNode.position.x, // Keep the same x position
      //     y: sourceNode.position.y // Position 100px below the last node (adjust as needed)
      //    };

      let xPosition = 0,
        yPosition = 0;

      if ('flag' in sourceNode.data) {
        if (sourceNode.data.flag === 'conditional') {
          xPosition = 120;
          yPosition = 100;
        }
      }
      let newNodes;
      if (Data) {
        newNodes = [
          {
            id: `${node[0]}.${node[1]}`,
            type: 'textUpdater',
            data: {
              header: 'A/B Split Path',
              setDimensions,
              headerIcon: iconSplit,
              flag: 'splitPathStart',
              headerDescription: Data,

              bodyContent: [{ label: 'Active', data: 20 }],
            },
            //draggable: false,
            position: {
              x: newNodePosition.x - xPosition,
              y: newNodePosition.y + 220 - yPosition,
            }, // Use dynamically calculated position
          },
          {
            id: `${node[0]}.${node[1]}y`,
            type: 'endNode',
            data: {
              label: 'Path A',
              type: 'splitPath',
              flag: true,
              setDimensions,
            },
            position: {
              x: newNodePosition.x - 10 - xPosition,
              y: newNodePosition.y + 420 - yPosition,
            },
            //draggable: false
          },
          {
            id: `${node[0]}.${node[1]}n`,
            type: 'endNode',
            data: {
              label: 'Path B',
              type: 'splitPath',
              flag: false,
              setDimensions,
            },
            position: {
              x: newNodePosition.x + 200 - xPosition,
              y: newNodePosition.y + 420 - yPosition,
            },
            //draggable: false
          },
          {
            id: `${node[0]}.${node[1]}e`,
            type: 'endNode',
            data: {
              label: 'End Node',
              type: 'dot',
              flag: 'splitPath',
              setDimensions,
            },
            position: {
              x: newNodePosition.x + 120 - xPosition,
              y: newNodePosition.y + 520 - yPosition,
            },
            //draggable: false
          },
        ];
      } else {
        newNodes = [
          {
            id: `${node[0]}.${node[1]}`,
            type: 'textUpdater',
            data: {
              header: 'If/Else Condition',
              setDimensions,
              headerIcon: iconElse,
              flag: 'conditionalStart',
              headerDescription: 'Status Purchase is',
              status: 'yes',
              bodyContent: [{ label: 'Active', data: 20 }],
            },
            //draggable: false,
            position: {
              x: newNodePosition.x - xPosition,
              y: newNodePosition.y + 220 - yPosition,
            }, // Use dynamically calculated position
          },
          {
            id: `${node[0]}.${node[1]}y`,
            type: 'endNode',
            data: {
              label: 'Yes',
              type: 'conditional',
              flag: true,
              setDimensions,
            },
            position: {
              x: newNodePosition.x - 10 - xPosition,
              y: newNodePosition.y + 420 - yPosition,
            },
            //draggable: false
          },
          {
            id: `${node[0]}.${node[1]}n`,
            type: 'endNode',
            data: {
              label: 'No',
              type: 'conditional',
              flag: false,
              setDimensions,
            },
            position: {
              x: newNodePosition.x + 200 - xPosition,
              y: newNodePosition.y + 420 - yPosition,
            },
            //draggable: false
          },
          {
            id: `${node[0]}.${node[1]}e`,
            type: 'endNode',
            data: {
              label: 'End Node',
              type: 'dot',
              flag: 'conditional',
              setDimensions,
            },
            position: {
              x: newNodePosition.x + 120 - xPosition,
              y: newNodePosition.y + 520 - yPosition,
            },
            //draggable: false
          },
        ];
      }

      const insertIndex = updatedNodes.findIndex(
        (node) => node.id === sourceNode.id,
      );
      console.log(insertIndex);
      //    for (let i = insertIndex + 1; i < updatedNodes.length; i++) {
      //     updatedNodes[i].position = {
      //      x: updatedNodes[i].position.x,
      //      y: updatedNodes[i].position.y + 420 // Push each node downward
      //     };
      //    }
      const beforeInsert = updatedNodes.slice(0, insertIndex + 1); // Nodes before the insertion point
      const afterInsert = updatedNodes.slice(insertIndex + 1); // Nodes after the insertion point
      return [...beforeInsert, ...newNodes, ...afterInsert];
    });

    console.log(Id);

    console.log(node[1]);
    const newEdges = [
      {
        id: `${node[0]}-${node[0]}.${node[1]}`,
        source: `${node[0]}`,
        target: `${node[0]}.${node[1]}`,
        type: 'custom-edge',
      },
      {
        id: `${node[0]}.${node[1]}-${node[0]}.${node[1]}y`,
        source: `${node[0]}.${node[1]}`,
        target: `${node[0]}.${node[1]}y`,
        animated: false,
      },
      {
        id: `${node[0]}.${node[1]}-${node[0]}.${node[1]}n`,
        source: `${node[0]}.${node[1]}`,
        target: `${node[0]}.${node[1]}n`,
        animated: false,
      },
      {
        id: `${node[0]}.${node[1]}y-${node[0]}.${node[1]}e`,
        source: `${node[0]}.${node[1]}y`,
        target: `${node[0]}.${node[1]}e`,
        type: 'custom-edge',
      },
      {
        id: `${node[0]}.${node[1]}n-${node[0]}.${node[1]}e`,
        source: `${node[0]}.${node[1]}n`,
        target: `${node[0]}.${node[1]}e`,
        type: 'custom-edge',
      },
      {
        id: `${node[0]}.${node[1]}e-${node[1]}`,
        source: `${node[0]}.${node[1]}e`,
        target: node[1],
        type: 'custom-edge',
      },
    ];

    setEdges((eds) => {
      let updatedEdges = [...eds];
      const edgeIndex = updatedEdges.findIndex((edge) => edge === id);
      updatedEdges.splice(edgeIndex, 1);
      updatedEdges = eds.filter(
        (edge) => edge.source !== node[0], // Second edge to delete
      );
      return [...updatedEdges, ...newEdges];
    });
  };

  const addConditional = (Data) => {
    let newNodePosition = { x: 0, y: 0 };
    console.log(id);
    let Id;
    setNodes((nds) => {
      let updatedNodes = [...nds];
      Id = sourceNode.id;
      // Determine position based on the last node in the array
      if (updatedNodes.length > 0) {
        //    Id = sourceNode.id;
        //    newNodePosition = {
        //     x: sourceNode.position.x, // Keep the same x position
        //     y: sourceNode.position.y // Position 100px below the last node (adjust as needed)
        //    };
        updatedNodes.pop();
        console.log(updatedNodes);
        // Remove the last node
      }
      let newNodes;
      if (Data) {
        newNodes = [
          {
            id: `${parseInt(Id) + 1}`,
            type: 'textUpdater',
            data: {
              header: 'A/B Split Path',
              setDimensions,
              headerIcon: iconSplit,
              flag: 'splitPathStart',
              headerDescription: Data,
              bodyContent: [{ label: 'Active', data: 20 }],
            },
            //draggable: false,
            position: { x: newNodePosition.x, y: newNodePosition.y + 220 }, // Use dynamically calculated position
          },
          {
            id: `${parseInt(Id) + 1}y`,
            type: 'endNode',
            data: {
              label: 'Path A',
              type: 'splitPath',
              flag: true,
              setDimensions,
            },
            position: { x: newNodePosition.x - 10, y: newNodePosition.y + 420 },
            //draggable: false
          },
          {
            id: `${parseInt(Id) + 1}n`,
            type: 'endNode',
            data: {
              label: 'Path B',
              type: 'splitPath',
              flag: false,
              setDimensions,
            },
            position: {
              x: newNodePosition.x + 200,
              y: newNodePosition.y + 420,
            },
            // draggable: false
          },
          {
            id: `${parseInt(Id) + 1}e`,
            type: 'endNode',
            data: {
              label: 'End Node',
              type: 'dot',
              flag: 'splitPath',
              setDimensions,
            },
            position: {
              x: newNodePosition.x + 120,
              y: newNodePosition.y + 520,
            },
            // draggable: false
          },
          {
            id: `${parseInt(Id) + 2}`,
            type: 'endNode',
            data: { label: 'End Node', setDimensions },
            position: { x: newNodePosition.x + 80, y: newNodePosition.y + 570 },
            // draggable: false
          },
          //   {id: '3', data: {label: 'Condition 1'}, position: {x: 150, y: 200}},
          //   {id: '4', data: {label: 'Condition 2'}, position: {x: 450, y: 200}}
        ];
      } else {
        newNodes = [
          {
            id: `${parseInt(Id) + 1}`,
            type: 'textUpdater',
            data: {
              header: 'If/Else Condition',
              setDimensions,
              headerIcon: iconElse,
              flag: 'conditionalStart',
              headerDescription: 'Status Purchase is',
              status: 'yes',
              bodyContent: [{ label: 'Active', data: 20 }],
            },
            //draggable: false,
            position: { x: newNodePosition.x, y: newNodePosition.y + 220 }, // Use dynamically calculated position
          },
          {
            id: `${parseInt(Id) + 1}y`,
            type: 'endNode',
            data: {
              label: 'Yes',
              type: 'conditional',
              flag: true,
              setDimensions,
            },
            position: { x: newNodePosition.x - 10, y: newNodePosition.y + 420 },
            //draggable: false
          },
          {
            id: `${parseInt(Id) + 1}n`,
            type: 'endNode',
            data: {
              label: 'No',
              type: 'conditional',
              flag: false,
              setDimensions,
            },
            position: {
              x: newNodePosition.x + 200,
              y: newNodePosition.y + 420,
            },
            // draggable: false
          },
          {
            id: `${parseInt(Id) + 1}e`,
            type: 'endNode',
            data: {
              label: 'End Node',
              type: 'dot',
              flag: 'conditional',
              setDimensions,
            },
            position: {
              x: newNodePosition.x + 120,
              y: newNodePosition.y + 520,
            },
            // draggable: false
          },
          {
            id: `${parseInt(Id) + 2}`,
            type: 'endNode',
            data: { label: 'End Node', setDimensions },
            position: { x: newNodePosition.x + 80, y: newNodePosition.y + 570 },
            // draggable: false
          },
          //   {id: '3', data: {label: 'Condition 1'}, position: {x: 150, y: 200}},
          //   {id: '4', data: {label: 'Condition 2'}, position: {x: 450, y: 200}}
        ];
      }
      return [...updatedNodes, ...newNodes];
    });

    console.log(Id);
    // Add two new nodes
    // deleteNodeAndEdges(id);

    // Add edges from the previous node to the new nodes
    const newEdges = [
      {
        id: `${parseInt(Id)}-${parseInt(Id) + 1}`,
        source: `${parseInt(Id)}`,
        target: `${parseInt(Id) + 1}`,
        type: 'custom-edge',
      },
      {
        id: `${parseInt(Id) + 1}-${parseInt(Id) + 1}y`,
        source: `${parseInt(Id) + 1}`,
        target: `${parseInt(Id) + 1}y`,
        animated: false,
      },
      {
        id: `${parseInt(Id) + 1}-${parseInt(Id) + 1}n`,
        source: `${parseInt(Id) + 1}`,
        target: `${parseInt(Id) + 1}n`,
        animated: false,
      },
      {
        id: `${parseInt(Id) + 1}y-${parseInt(Id) + 1}e`,
        source: `${parseInt(Id) + 1}y`,
        target: `${parseInt(Id) + 1}e`,
        type: 'custom-edge',
      },
      {
        id: `${parseInt(Id) + 1}n-${parseInt(Id) + 1}e`,
        source: `${parseInt(Id) + 1}n`,
        target: `${parseInt(Id) + 1}e`,
        type: 'custom-edge',
      },
      {
        id: `${parseInt(Id) + 1}e-${parseInt(Id) + 2}`,
        source: `${parseInt(Id) + 1}e`,
        target: `${parseInt(Id) + 2}`,
        type: 'custom-edge',
      },
    ];

    // Update state
    // setNodes(nds => [...nds, ...newNodes]);
    console.log(id);
    setEdges((eds) => {
      const updatedEdges = [...eds];
      const edgeIndex = updatedEdges.findIndex((edge) => edge.id === id);
      updatedEdges.splice(edgeIndex, 1);
      return [...updatedEdges, ...newEdges];
    });
  };

  const handleIconClick = () => {
    setIsPopupVisible(!isPopupVisible); // Toggle the popup visibility
  };

  const tagStart = (label) => {
    setTagDialog({
      title: `Add/Remove ${label}`,
      open: true,
      onClose: closeTagDialog,
      type: { label },
      actions: [
        {
          label: 'Cancel',
          onClick: () => closeTagDialog(),
          classes: 'btn-outline dark-border',
        },
        {
          label: 'Save',
          onClick: () => handleSubmit(addTagNode)(),
          classes: 'btn-primary',
        },
      ],
    });
  };

  //  const prebuiltLayoutData = useSelector(state => state.prebuiltLayoutData);
  //  const prebuiltCards = prebuiltLayoutData.map((template, index) => {
  //   return {
  //    name: template.name,
  //    content: `
  //     <!DOCTYPE html>
  //     <html lang="en">
  //     <head>
  //       <meta charset="UTF-8">
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0">
  //       <title>Preview</title>
  //       <style>
  //       @media only screen and (max-width:768px){
  //         .container{
  //           float: left !important;
  //           width: 100% !important;
  //         }
  //       }
  //       </style>
  //     </head>
  //     <body>
  //       ${template.htmlContent}
  //     </body>
  //     </html>
  //   `,
  //    showPreviewButton: true,
  //    id: template.id
  //   };
  //  });
  //  const [isSavedTemplate, setIsSavedTemplate] = useState(false);
  //  const [istemplate, setIsTemplate] = useState(true);

  const [senderMenu, setSenderMenu] = useState([]);
  const fetchSender = () => {
    ResourceApi.sender.get().then(({ data }) => {
      console.log(data);
      const extracteddata = data.map(({ name }) => ({
        label: name,
        value: name,
      }));
      setSenderMenu(extracteddata);
      const extracteddata2 = data.map(({ name, email }) => ({
        name: name,
        email: email,
      }));
      setAllSenders(extracteddata2);
      console.log(senderMenu);
    });
  };

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        {(sourceNode.type === 'endNode' || targetNode.type === 'endNode') && (
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              pointerEvents: 'all',
              border: '3px solid #FFFFFF',
              borderRadius: '50%',
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 4px 24px 0px #0000001F',
            }}
            className="nodrag nopan"
            onClick={handleIconClick}
          >
            <img src={iconAdd} alt="add" />
          </div>
        )}
        {!(sourceNode.type === 'endNode' || targetNode.type === 'endNode') && (
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -80%) translate(${labelX}px,${labelY}px)`,
              pointerEvents: 'all',
              border: '3px solid #FFFFFF',
              borderRadius: '50%',
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 4px 24px 0px #0000001F',
            }}
            className="nodrag nopan"
            onClick={handleIconClick}
          >
            <img src={iconAdd} alt="add" />
          </div>
        )}

        {isPopupVisible && (
          <div
            style={{
              position: 'fixed', // Use fixed position to ensure it appears above other elements
              top: `${labelY + 20}px`,
              left: `${labelX}px`,
              transform: 'translate(-50%, 0)',
              zIndex: 100, // Higher z-index to ensure it appears above all elements
              pointerEvents: 'all',
              backgroundColor: '#FFF',
              boxShadow: '0px 4px 24px 0px #0000001F',
              minWidth: '240px',
              padding: '12px 0px 8px 0px',
              borderRadius: '12px',
              border: '0.5px solid #dfdded',
            }}
            className="popup"
          >
            <div className="node-addStep">
              <div className="addStep-header">
                <div>Add Step</div>
                <img
                  src={iconClose}
                  alt="close"
                  style={{ width: '20px', height: '20px' }}
                  onClick={() => {
                    setIsPopupVisible(!isPopupVisible);
                  }}
                />
              </div>
              <div className="horizontal-line"></div>
              <div className="addStep-body">
                <div
                  className="body-element"
                  onClick={() => {
                    fetchSender();
                    setEmailDialog({
                      title: `Send Email`,
                      open: true,
                      onClose: closeEmailDialog,
                      type: 'email',
                      actions: [
                        {
                          label: 'Cancel',
                          onClick: () => closeEmailDialog(),
                          classes: 'btn-outline dark-border',
                        },
                        {
                          label: 'Save',
                          onClick: () => handleSubmit()(),
                          classes: 'btn-primary',
                        },
                      ],
                    });
                    //   if (
                    //    targetNode.data &&
                    //    'flag' in targetNode.data &&
                    //    targetNode.data.flag !== 'conditionalStart'
                    //   ) {
                    //    handleClick3();
                    //   } else {
                    //    if (targetNode.type === 'endNode') {
                    //     // Current node is the last node
                    //     handleClick();
                    //    } else {
                    //     // Current node is not the last node
                    //     handleClick2();
                    //    }
                    //   }
                    //   setIsPopupVisible(!isPopupVisible);
                  }}
                >
                  <img src={iconEmail} alt="email" />
                  <div>Send Email</div>
                </div>
                <div
                  className="body-element"
                  onClick={() => {
                    setDelayDialog({
                      title: `Delay`,
                      open: true,
                      onClose: closeDelayDialog,
                      type: 'delay',
                      actions: [
                        {
                          label: 'Cancel',
                          onClick: () => closeDelayDialog(),
                          classes: 'btn-outline dark-border',
                        },
                        {
                          label: 'Save',
                          onClick: () => handleSubmit(addDelayNode)(),
                          classes: 'btn-primary',
                        },
                      ],
                    });
                  }}
                >
                  <img src={iconTime} alt="email" />
                  <div>Wait</div>
                </div>
                <div
                  className="body-element"
                  onClick={() => {
                    const nodes = getNodes();
                    const currentNodeIndex = nodes.findIndex(
                      (node) => node.id === id,
                    );

                    if (
                      targetNode.type === 'endNode' &&
                      !('flag' in targetNode.data) &&
                      !('flag' in sourceNode.data)
                    ) {
                      // Current node is the last node
                      addConditional();
                    } else {
                      // Current node is not the last node
                      addConditional2();
                    }
                    setIsPopupVisible(!isPopupVisible);
                  }}
                >
                  <img src={iconElse} alt="email" />
                  <div>If/Else Conditions</div>
                </div>
                <div
                  className="body-element"
                  onClick={() => {
                    setSplitDialog({
                      title: `A/B Split Path`,
                      open: true,
                      onClose: closeSplitDialog,
                      type: 'splitPath',
                      actions: [
                        {
                          label: 'Cancel',
                          onClick: () => closeSplitDialog(),
                          classes: 'btn-outline dark-border',
                        },
                        {
                          label: 'Save',
                          onClick: () => handleSubmit(addSplitPath)(),
                          classes: 'btn-primary',
                        },
                      ],
                    });
                  }}
                >
                  <img src={iconSplit} alt="email" />
                  <div>A/B Split Path</div>
                </div>
                <div
                  className="body-element"
                  onClick={() => {
                    setContactStatus({
                      title: 'Change Contact Status',
                      open: true,
                      onClose: closestatusDialog,
                      type: 'changeContactStatus',
                      actions: [
                        {
                          label: 'Cancel',
                          onClick: () => closestatusDialog(),
                          classes: 'btn-outline dark-border',
                        },
                        {
                          label: 'Save',
                          onClick: () => handleSubmit(addStatusNode)(),
                          classes: 'btn-primary',
                        },
                      ],
                    });
                  }}
                >
                  <img src={iconContact} alt="email" />
                  <div>Change Contact status</div>
                </div>
                <div
                  className="body-element"
                  onClick={() => {
                    setValue('type', 'Tag');
                    tagStart('Tag');
                  }}
                >
                  <img src={iconTag} alt="email" />
                  <div>Add/Remove Tag</div>
                </div>
                <div
                  className="body-element"
                  onClick={() => {
                    setValue('type', 'List');
                    tagStart('List');
                  }}
                >
                  <img src={iconList} alt="email" />
                  <div>Add/Remove to List</div>
                </div>
                <div
                  className="body-element"
                  onClick={() => {
                    const nodes = getNodes();
                    const options = nodes
                      .filter((n) => n.position.y > sourceNode.position.y)
                      .map((n) => ({
                        label: n.data?.label || n.data?.header,
                        value: n.id,
                      }));
                    console.log(options);
                    setJumpOptions(options);
                    setJumpDialog({
                      title: `Jump`,
                      open: true,
                      onClose: closeJumpDialog,
                      type: 'jump',
                      actions: [
                        {
                          label: 'Cancel',
                          onClick: () => closeJumpDialog(),
                          classes: 'btn-outline dark-border',
                        },
                        {
                          label: 'Next',
                          onClick: () => handleSubmit(addJump)(),
                          classes: 'btn-primary',
                        },
                      ],
                    });
                  }}
                >
                  <img src={iconSkip} alt="email" />
                  <div>Jump Step</div>
                </div>
                <div
                  className="body-element"
                  onClick={() => {
                    setGoalDialog({
                      title: `Select Goal`,
                      open: true,
                      onClose: closeGoalDialog,
                      type: 'goal',
                      actions: [
                        {
                          label: 'Cancel',
                          onClick: () => closeGoalDialog(),
                          classes: 'btn-outline dark-border',
                        },
                        {
                          label: 'Next',
                          onClick: () => handleSubmit()(),
                          classes: 'btn-primary',
                        },
                      ],
                    });
                  }}
                >
                  <img src={iconGoal} alt="email" />
                  <div>Goal</div>
                </div>
                <div className="body-element" onClick={addExitNode}>
                  <img src={iconExit} alt="email" />
                  <div>Exit</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </EdgeLabelRenderer>
      <CustomDialog
        {...goalDialog}
        content={
          goal === '' ? (
            <>
              <DynamicTabs panels={panels} tabs={tabs} />
            </>
          ) : (
            <>
              <form>
                {goal === 'Order Created' && (
                  <>
                    <div className="input-container" style={{ gap: '8px' }}>
                      <label className="email-dialog-label">Order Status</label>
                      <CustomCheckBox
                        values={goalOrderStatus}
                        onChange={handleGoalOrderStatus}
                        checked={selectedGoalOrderStatus}
                        direction="row"
                      />
                      <div className="input-description">
                        The goal will be met if the new order has the selected
                        order status.
                      </div>
                    </div>
                    <div className="input-container" style={{ gap: '4px' }}>
                      <label className="email-dialog-label">
                        Order Contains
                      </label>
                      <RadioButtonsGroup
                        values={goalOrder}
                        onChange={handleGoalOrder}
                        checked={selectedGoalOrder}
                        direction="row"
                      />
                      {selectedGoalOrder === 'Specific Product' && (
                        <CustomAutocomplete
                          placeholder="Search to select"
                          fetchUrl="/tag/search"
                          createUrl="/tag/"
                          onChange={setAutoCompleteValue}
                          autoCompleteValue={autoCompleteValue}
                        />
                      )}
                    </div>
                  </>
                )}
                {goal === 'Tag is Added' && (
                  <>
                    <div className="input-container" style={{ gap: '4px' }}>
                      <label className="email-dialog-label">Tag Contains</label>
                      <RadioButtonsGroup
                        values={goalTag}
                        onChange={(event) => {
                          setSelectedTag(event.target.value);
                          setValue('tagContains', event.target.value);
                        }}
                        checked={selectedTag}
                        direction="row"
                      />
                      {selectedGoalOrder === 'Specific Product' && (
                        <CustomAutocomplete
                          placeholder="Search to select"
                          fetchUrl="/tag/search"
                          createUrl="/tag/"
                          onChange={setAutoCompleteValue}
                          autoCompleteValue={autoCompleteValue}
                        />
                      )}
                    </div>
                  </>
                )}
                {goal === 'Added to a List' && (
                  <>
                    <div className="input-container" style={{ gap: '4px' }}>
                      <label className="email-dialog-label">
                        List Contains
                      </label>
                      <RadioButtonsGroup
                        values={goalList}
                        onChange={(event) => {
                          setSelectedList(event.target.value);
                          setValue('listContains', event.target.value);
                        }}
                        checked={selectedList}
                        direction="row"
                      />
                      {selectedGoalOrder === 'Specific Product' && (
                        <CustomAutocomplete
                          placeholder="Search to select"
                          fetchUrl="/tag/search"
                          createUrl="/tag/"
                          onChange={setAutoCompleteValue}
                          autoCompleteValue={autoCompleteValue}
                        />
                      )}
                    </div>
                  </>
                )}
                <div
                  className="input-container"
                  style={{ marginBottom: '0px' }}
                >
                  <label className="email-dialog-label">
                    If the contact doesn't meet the specified goal
                  </label>
                  <RadioButtonsGroup
                    values={goalWait}
                    onChange={handleGoalWait}
                    checked={selectedGoalWait}
                    direction="row"
                  />
                </div>
              </form>
            </>
          )
        }
      />
      <CustomDialog
        {...delayDialog}
        content={
          delayDialog.type === 'delay' ? (
            <>
              <form>
                <div className="input-container">
                  <label className="email-dialog-label">
                    Choose how long a contact will be delayed from moving
                    forward.
                  </label>
                </div>
                <div className="input-container">
                  <FormControl>
                    <RadioGroup
                      column
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(event) => {
                        console.log(event.target.value);
                        setDelayoption(event.target.value);
                      }}
                      defaultValue="Delay for a specific period"
                      sx={{ gap: '8px' }}
                    >
                      <FormControlLabel
                        value="Delay for a specific period"
                        control={
                          <Radio
                            sx={{
                              color: '#ff385c',
                              '&.Mui-checked': {
                                color: '#ff385c',
                              },
                              marginRight: '4px',
                            }}
                          />
                        }
                        label="Delay for a specific period"
                        sx={{
                          marginLeft: '4px !important',
                          justifyContent: 'flex-start !important',
                        }}
                      />
                      {delayOption === 'Delay for a specific period' && (
                        <>
                          <div
                            className="input-container"
                            style={{ marginBottom: '8px' }}
                          >
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '75% 25%',
                              }}
                            >
                              <input
                                className="email-dialog-input"
                                type="number"
                                placeholder="type here"
                                {...register('delayDuration')}
                              />
                              <TextField
                                variant="outlined"
                                placeholder="Select"
                                value={delay}
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <img
                                      src={iconArrow}
                                      style={{ width: '16px', height: '16px' }}
                                      alt="arrow"
                                    />
                                  ),
                                }}
                                onClick={(event) => {
                                  setDelayAnchorElement(event.currentTarget);
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <FormControlLabel
                        value="Delay until a specific date and time"
                        control={
                          <Radio
                            onChange={(event) => {
                              console.log(event.target.value);
                              setDelayoption(event.target.value);
                            }}
                            sx={{
                              color: '#ff385c',
                              '&.Mui-checked': {
                                color: '#ff385c',
                              },
                              marginRight: '4px',
                            }}
                          />
                        }
                        label="Delay until a specific date and time"
                        sx={{
                          marginLeft: '4px !important',
                          justifyContent: 'flex-start !important',
                        }}
                      />
                      {delayOption ===
                        'Delay until a specific date and time' && (
                        <>
                          <div
                            className="input-container"
                            style={{ marginBottom: '0px' }}
                          >
                            <BasicDateTimePicker
                              onDateTimeChange={handleDateTimeSelection}
                            />
                          </div>
                        </>
                      )}
                    </RadioGroup>
                  </FormControl>
                </div>
              </form>
              {/* <DateTimePickerComponent show={showCalendar} Date={selectDate} /> */}
            </>
          ) : (
            delayDialog.content
          )
        }
      />

      <CustomDialog
        {...jumpDialog}
        content={
          jumpDialog.type === 'jump' ? (
            <>
              <form>
                <div
                  className="input-container"
                  style={{ marginBottom: '0px' }}
                >
                  <label className="email-dialog-label">Jump to</label>
                  <TextField
                    variant="outlined"
                    placeholder="Select"
                    value={jump}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <img
                          src={iconArrow}
                          style={{ width: '16px', height: '16px' }}
                        />
                      ),
                    }}
                    onClick={(event) => {
                      setJumpAnchorElement(event.currentTarget);
                    }}
                  />
                </div>
              </form>
            </>
          ) : (
            jumpDialog.content
          )
        }
      />
      <CustomDialog
        {...splitDialog}
        content={
          splitDialog.type === 'splitPath' ? (
            <>
              <form>
                <div className="input-container">
                  <label className="email-dialog-label">Description</label>
                  <textarea
                    type="text"
                    className="email-dialog-input"
                    placeholder="Type here..."
                    {...register('description', { required: 'required field' })}
                  />
                </div>
              </form>
            </>
          ) : (
            splitDialog.content
          )
        }
      />
      <CustomDialog
        {...contactStatus}
        content={
          contactStatus.type === 'changeContactStatus' ? (
            <>
              <form>
                <div
                  className="input-container"
                  style={{ marginBottom: '0px' }}
                >
                  <label className="email-dialog-label">Status</label>
                  <TextField
                    variant="outlined"
                    placeholder="Select"
                    value={status}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <img
                          src={iconArrow}
                          style={{ width: '16px', height: '16px' }}
                        />
                      ),
                    }}
                    onClick={(event) => {
                      setStatusAnchorElement(event.currentTarget);
                    }}
                  />
                </div>
              </form>
            </>
          ) : (
            contactStatus.content
          )
        }
      />
      <CustomDialog
        {...emailDialog}
        content={
          emailDialog.type === 'email' ? (
            <>
              <form>
                <div className="input-container">
                  <div className="email-dialog-label">Template</div>
                  <div className="email-dotted-input">
                    <div
                      className="dotted-input"
                      onClick={() => {
                        onSHowOverlay();
                        closeEmailDialog();
                      }}
                    >
                      <img src={iconTemplates} alt="template" />
                      <div>Choose Template</div>
                    </div>
                    <div className="dotted-input">
                      <img src={iconAddCircle} alt="circle" />
                      <div>Create New</div>
                    </div>
                  </div>
                </div>
                <div className="input-container">
                  <div className="email-dialog-label">To</div>
                  <input
                    type="text"
                    className="email-dialog-input"
                    {...register('to', { required: 'required field' })}
                  />
                </div>
                <div className="input-container">
                  <div className="email-dialog-label">Subject</div>
                  <textarea
                    className="email-dialog-input"
                    placeholder="Type here..."
                    {...register('subject', { required: 'required field' })}
                  />
                </div>
                <div className="input-container">
                  <div className="email-dialog-label">Preview Text</div>
                  <textarea
                    className="email-dialog-input"
                    placeholder="Type here..."
                    {...register('previewText', { required: 'required field' })}
                  />
                </div>
                <div
                  className="input-container"
                  style={{ marginBottom: '0px' }}
                >
                  {/* <CustomCheckBox
          values={checkValues}
          onChange={handleCheckChange}
          checked={selectedCheckValue}
          direction="column"
         /> */}
                  <FormControl>
                    <FormGroup
                      column
                      sx={{
                        gap: '8px',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCheckValue.includes(
                              'Add UTM parameters to email links',
                            )}
                            onChange={handleCheckChange}
                            value="Add UTM parameters to email links"
                            sx={{
                              marginRight: '4px',
                            }}
                          />
                        }
                        label="Add UTM parameters to email links"
                        sx={{
                          marginLeft: '4px !important',
                          justifyContent: 'flex-start !important',
                        }}
                      />
                      {selectedCheckValue ===
                        'Add UTM parameters to email links' && (
                        <>
                          <div className="input-container">
                            <div className="email-dialog-label">
                              Campaign Source
                            </div>
                            <input
                              type="text"
                              {...register('campaignSource', {
                                required: 'required field',
                              })}
                              className="email-dialog-input"
                            />
                          </div>
                          <div className="input-container">
                            <div className="email-dialog-label">
                              Campaign Medium
                            </div>
                            <input
                              type="text"
                              {...register('campaignMedium', {
                                required: 'required field',
                              })}
                              className="email-dialog-input"
                            />
                          </div>
                          <div className="input-container">
                            <div className="email-dialog-label">
                              Campaign Name
                            </div>
                            <input
                              type="text"
                              {...register('campaignName', {
                                required: 'required field',
                              })}
                              className="email-dialog-input"
                            />
                          </div>
                          <div className="input-container">
                            <div className="email-dialog-label">
                              Campaign term
                            </div>
                            <input
                              type="text"
                              {...register('campaignTerm', {
                                required: 'required field',
                              })}
                              className="email-dialog-input"
                            />
                          </div>
                          <div className="input-container">
                            <div className="email-dialog-label">
                              Campaign content
                            </div>
                            <input
                              type="text"
                              {...register('campaignContent', {
                                required: 'required field',
                              })}
                              className="email-dialog-input"
                            />
                          </div>
                        </>
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCheckValue.includes(
                              'Override From Name, From Email & Reply To Email',
                            )}
                            onChange={handleCheckChange}
                            value="Override From Name, From Email & Reply To Email"
                            sx={{
                              marginRight: '4px',
                            }}
                          />
                        }
                        label="Override From Name, From Email & Reply To Email"
                        sx={{
                          marginLeft: '4px !important',
                          justifyContent: 'flex-start !important',
                        }}
                      />
                      {selectedCheckValue ===
                        'Override From Name, From Email & Reply To Email' && (
                        <>
                          <div className="input-container">
                            <div className="email-dialog-label">
                              From Name (Select Sender)
                            </div>
                            <TextField
                              variant="outlined"
                              placeholder="Select"
                              value={sender}
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <img
                                    src={iconArrow}
                                    style={{ width: '16px', height: '16px' }}
                                  />
                                ),
                              }}
                              onClick={(event) => {
                                setSenderAnchorElement(event.currentTarget);
                              }}
                            />
                          </div>
                          <div className="input-container">
                            <div className="email-dialog-label">From Email</div>
                            <input
                              type="email"
                              name="emailId"
                              className="email-dialog-input"
                              value={senderEmail}
                              disabled
                            ></input>
                          </div>
                        </>
                      )}
                    </FormGroup>
                  </FormControl>
                </div>
              </form>
            </>
          ) : (
            emailDialog.content
          )
        }
      />
      <CustomDialog
        {...tagDialog}
        content={
          tagDialog.type === 'Tag' || 'List' ? (
            <>
              <form>
                <div className="input-container">
                  <div className="email-dialog-label">
                    What action you want to apply ?
                  </div>
                  <div>
                    <RadioButtonsGroup
                      values={values}
                      onChange={handleRadioChange}
                      checked={selectedValue}
                      direction="row"
                    />
                  </div>
                </div>
                <div
                  className="input-container"
                  style={{ marginBottom: '0px' }}
                >
                  <CustomAutocomplete
                    //  label={tagDialog.type}
                    placeholder="Search to select"
                    fetchUrl="/tag/search"
                    createUrl="/tag/"
                    onChange={(value) => {
                      setAutoCompleteValue(value);
                      const tagNames = value.map((item) => item.name);
                      setValue('tag', tagNames);
                    }}
                    autoCompleteValue={autoCompleteValue}
                  />
                </div>
              </form>
            </>
          ) : (
            tagDialog.content
          )
        }
      />
      <CustomMenu
        onClose={() => {
          setJumpAnchorElement(null);
        }}
        onSelect={(value) => {
          setJumpAnchorElement(null);
          setJump(value);
          setValue('jump', value);
        }}
        options={jumpOptions}
        anchorEl={jumpAnchorElement}
        inputBox={jumpAnchorElement}
      />
      <CustomMenu
        onClose={() => {
          setSenderAnchorElement(null);
        }}
        onSelect={(value) => {
          setSenderAnchorElement(null);
          setSender(value);
          setValue('sender', value);
          const email = allSenders.filter((sender) => sender.name === value);
          console.log(email[0].email);
          setSenderEmail(email[0].email);
        }}
        options={senderMenu}
        anchorEl={senderAnchorElement}
        inputBox={senderAnchorElement}
      />
      <CustomMenu
        onClose={() => {
          setDelayAnchorElement(null);
        }}
        onSelect={(value) => {
          setDelayAnchorElement(null);
          setDelay(value);
          setValue('delay', value);
        }}
        options={delayOptions}
        anchorEl={delayAnchorElement}
        inputBox={true}
      />
      <CustomMenu
        onClose={() => {
          setStatusAnchorElement(null);
        }}
        onSelect={(value) => {
          setStatusAnchorElement(null);
          setStatus(value);
          setValue('status', value);
        }}
        options={contactStatusOptions}
        anchorEl={statusAnchorElement}
        inputBox={statusAnchorElement}
      />
    </>
  );
}
