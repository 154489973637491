import TableIcon from '../../../../../assets/icons/icon-table.svg';

export const registerTableBlock = (editor, brandStyles, opts = {}) => {
  // Define the custom component type for tableBlock
  editor.DomComponents.addType('Table', {
    model: {
      defaults: {
        tagName: "span",
        attributes: { "data-gjs-type": "Table" },
        classes: ["block-div"],
        style: {
          display: "inline-flex !important",
          width: "100%",
          padding: "16px",
        },
        components: [
          {
            tagName: 'table',
            type: 'table',
            attributes: { cellspacing: '0', cellpadding: '0', width: '100%' },
            style: {
              "border-collapse": "collapse",
              "font-family": `${brandStyles?.typeGraphy?.fontFamily}, ${brandStyles?.typeGraphy?.backupFontFamily}` ||  "Arial",
              padding: "8px 16px",
              width: "100%",
              "text-align": "left",
            },
            components: [
              {
                tagName: "tbody",
                type: "table-body",
                components: [
                  {
                    tagName: "tr",
                    type: "row",
                    components: [
                      {
                        tagName: "th",
                        type: "text",
                        content: "Row 1, Cell 1",
                        editable: true,
                        style: {
                          "border-style": "dashed",
                          "border-color": "#C6C7D6",
                          "border-width": "1px",
                          "background-color": "#f6f6f6",
                          "font-size":  brandStyles?.typeGraphy?.fontSize || "14px",
                          "font-weight": "600",
                          "line-height": "16px",
                          padding: "8px 16px",
                        },
                      },
                      {
                        tagName: "th",
                        type: "text",
                        content: "Row 1, Cell 2",
                        editable: true,
                        style: {
                          "border-style": "dashed",
                          "border-color": "#C6C7D6",
                          "border-width": "1px",
                          "background-color": "#f6f6f6",
                          "font-size": brandStyles?.typeGraphy?.fontSize ||  "14px",
                          "font-weight": "600",
                          "line-height": "16px",
                          padding: "8px 16px",
                        },
                      },
                      {
                        tagName: "th",
                        type: "text",
                        content: "Row 1, Cell 3",
                        editable: true,
                        style: {
                          "border-style": "dashed",
                          "border-color": "#C6C7D6",
                          "border-width": "1px",
                          "background-color": "#f6f6f6",
                          "font-size":  brandStyles?.typeGraphy?.fontSize || "14px",
                          "font-weight": "600",
                          "line-height": "16px",
                          padding: "8px 16px",
                        },
                      },
                    ],
                  },
                  {
                    tagName: "tr",
                    type: "row",
                    components: [
                      {
                        tagName: "td",
                        type: "text",
                        content: "Row 2, Cell 1",
                        editable: true,
                        style: {
                          "border-style": "dashed",
                          "border-color": "#C6C7D6",
                          "border-width": "1px",
                          "font-size":  brandStyles?.typeGraphy?.fontSize || "14px",
                          "line-height": "16px",
                          padding: "8px 16px",
                        },
                      },
                      {
                        tagName: "td",
                        type: "text",
                        content: "Row 2, Cell 2",
                        editable: true,
                        style: {
                          "border-style": "dashed",
                          "border-color": "#C6C7D6",
                          "border-width": "1px",
                          "font-size":  brandStyles?.typeGraphy?.fontSize || "14px",
                          "line-height": "16px",
                          padding: "8px 16px",
                        },
                      },
                      {
                        tagName: "td",
                        type: "text",
                        content: "Row 2, Cell 3",
                        editable: true,
                        style: {
                          "border-style": "dashed",
                          "border-color": "#C6C7D6",
                          "border-width": "1px",
                          "font-size": brandStyles?.typeGraphy?.fontSize ||  "14px",
                          "line-height": "16px",
                          padding: "8px 16px",
                        },
                      },
                    ],
                  },
                  {
                    tagName: "tr",
                    type: "row",
                    components: [
                      {
                        tagName: "td",
                        type: "text",
                        content: "Row 3, Cell 1",
                        editable: true,
                        style: {
                          "border-style": "dashed",
                          "border-color": "#C6C7D6",
                          "border-width": "1px",
                          "font-size": brandStyles?.typeGraphy?.fontSize ||  "14px",
                          "line-height": "16px",
                          padding: "8px 16px",
                        },
                      },
                      {
                        tagName: "td",
                        type: "text",
                        content: "Row 3, Cell 2",
                        editable: true,
                        style: {
                          "border-style": "dashed",
                          "border-color": "#C6C7D6",
                          "border-width": "1px",
                          "font-size": brandStyles?.typeGraphy?.fontSize ||  "14px",
                          "line-height": "16px",
                          padding: "8px 16px",
                        },
                      },
                      {
                        tagName: "td",
                        type: "text",
                        content: "Row 3, Cell 3",
                        editable: true,
                        style: {
                          "border-style": "dashed",
                          "border-color": "#C6C7D6",
                          "border-width": "1px",
                          "font-size": brandStyles?.typeGraphy?.fontSize ||  "14px",
                          "line-height": "16px",
                          padding: "8px 16px",
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        droppable: false,
        // Enable inline styling
        stylable: true,
      },
      init() {
        // Ensure styles are inline when the component is initialized
        this.set({ style: this.defaults.style });
      },
    },
  });

  // Add the block for tableBlock
  editor.BlockManager.add('tableBlock', {
    label: `<div class="gjs-block-label-container" data-category="Block"><img src=${TableIcon} alt="Table Icon" height="24px" width="24px"/></div>
    <div class="block-label-text">Table</div>`,
    content: { type: 'Table' },
    category: 'Blocks',
    draggable: 'td',
  });
};
