import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CustomAutocomplete from '../../components/AutoComplete';
import Filter from '../../components/Filter';
import useFilter from '../../hooks/useFilter';

const Dashboard = () => {
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const filterAttrs = useFilter()
  return (
    <Stack alignItems="center" justifyContent="center" gap={2} padding={4}>
      <h1>Filter Demo</h1>
      <Box sx={{ width: '868px' }}>
        <Filter {...filterAttrs} />
      </Box>
      {/* <h1>Auto Complete Demo</h1>
      <Box sx={{ width: '500px' }}>
        <CustomAutocomplete
          label="Tags"
          placeholder="Search to select"
          fetchUrl="/tag/search"
          createUrl="/tag/"
          onChange={setAutoCompleteValue}
          autoCompleteValue={autoCompleteValue}
        />
      </Box> */}
    </Stack>
  );
};

export default Dashboard;
