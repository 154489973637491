import React, {useEffect, useRef} from 'react';
import Card from '@mui/material/Card';
import {Handle, useReactFlow} from 'reactflow';
import {
 Avatar,
 CardHeader,
 Chip,
 Grid,
 IconButton,
 Typography
} from '@mui/material';
import {red} from '@mui/material/colors';
import iconMore from '../../../assets/icons/icon-more.svg';
import iconTag from '../../../assets/icons/icon-tag-automation.svg';
import iconClose from '../../../assets/icons/icon-cross.svg';
import iconEmail from '../../../assets/icons/icon-email.svg';
import iconTime from '../../../assets/icons/icon-time.svg';
import iconElse from '../../../assets/icons/icon-Condition.svg';
import iconSplit from '../../../assets/icons/icon-AB.svg';
import iconContact from '../../../assets/icons/icon-contact-status.svg';
import iconList from '../../../assets/icons/icon-order-listing.svg';
import iconSkip from '../../../assets/icons/icon-skip-step.svg';
import iconGoal from '../../../assets/icons/icon-target.svg';
import iconExit from '../../../assets/icons/icon-exit.svg';
import iconDoThis from '../../../assets/icons/icon-dothis.svg';
import iconLight from '../../../assets/icons/icon-light.svg';
import {useCallback, useState} from 'react';
import {type} from '@testing-library/user-event/dist/type';
import CustomMenu from '../../../components/Menu/Menu';
import {useSelector, useDispatch} from 'react-redux';
import {showFlowAlert, hideFlowAlert} from '../../../store/store';
import CustomDialog from '../../../components/Dialog/Dialog';
import {useForm} from 'react-hook-form';
const Node = ({id, data}) => {
 // Sample chip data array
 const {setNodes, getNodes} = useReactFlow();
 const {setEdges, getEdges} = useReactFlow();
 const [statusAnchorElement, setStatusAnchorElement] = useState(null);
 const nodes = getNodes();
 const dispatch = useDispatch();
 const currentNode = nodes.filter(node => node.id === id);
 console.log(currentNode);
 const currentNodeIndex = nodes.findIndex(node => node.id === id);
 const alert = useSelector(state => state.flowAlert);
 const [deletedNodeData, setDeletedNodeData] = useState(null);
 const [undoTimeoutId, setUndoTimeoutId] = useState(null);
 const {register, handleSubmit} = useForm();
 const [deleteDialog, setDeleteDialog] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });
 const [splitDialog, setSplitDialog] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });

 const closeDeleteDialog = () => {
  setDeleteDialog(prevConfig => ({...prevConfig, open: false}));
 };
 const closeSplitDialog = () => {
  setSplitDialog(prevConfig => ({...prevConfig, open: false}));
 };
 const onDelete = () => {
  if (currentNode[0].data?.type === 'exit') {
   deleteExit();
  } else if (currentNode[0].data?.type === 'jump') {
   deleteNode(1);
  } else {
   if (
    'flag' in currentNode[0].data &&
    (currentNode[0].data.flag === 'conditionalStart' ||
     currentNode[0].data.flag === 'splitPathStart')
   ) {
    const firstChar = currentNode[0].id[0];
    let count = 0;
    const ID = currentNode[0].id;

    // Check if the last character is 'y' and replace it with 'n'
    const newId = ID + 'e';
    console.log(newId, currentNodeIndex);
    for (let i = currentNodeIndex; i < nodes.length; i++) {
     const node = nodes[i];
     if (node.id[0] === firstChar) {
      count++;
     }
     if (node.id === newId) break;
    }
    deleteNode(count);
   } else {
    deleteNode(1);
   }
  }
 };
 useEffect(() => {
  console.log(alert);
 }, [alert]);

 let options;
 if ('type' in currentNode[0].data && currentNode[0].data.type === 'event') {
  options = [
   {
    icon: 'icon-refresh',
    label: 'Change event',
    value: 'change event'
   }
  ];
 } else {
  options = [
   {
    label: 'Delete',
    value: 'delete'
   },
   {
    label: 'Duplicate',
    value: 'duplicate'
   }
  ];
 }

 const undoDelete = () => {
  if (deletedNodeData) {
   const {node, index} = deletedNodeData;

   // Restore the node at its original index
   setNodes(prevNodes => [
    ...prevNodes.slice(0, index),
    node,
    ...prevNodes.slice(index)
   ]);

   // Clear the deleted node data and timeout
   setDeletedNodeData(null);
   if (undoTimeoutId) {
    clearTimeout(undoTimeoutId);
    setUndoTimeoutId(null);
   }
  }
 };

 const duplicateNode2 = () => {
  let nodeAfter, nodeBefore;
  nodeBefore = nodes[currentNodeIndex - 1];
  const edges = getEdges();
  const connectedEdges = edges.filter(
   edge => edge.source === currentNode[0].id
  )[0];
  nodeAfter = nodes.filter(nd => nd.id === connectedEdges.target)[0];
  const newNodes = [
   {
    ...currentNode[0],
    id: `${currentNode[0].id}.${nodeAfter.id}`, // New unique ID
    position: {x: currentNode[0].position.x, y: currentNode[0].position.y + 200} // Adjust position slightly
   }
  ];
  const remainingNodes = nodes.slice(currentNodeIndex + 1).map(node => ({
   ...node,
   position: {...node.position, y: node.position.y + 200}
  }));

  const updatedNodes = [
   ...nodes.slice(0, currentNodeIndex + 1),
   ...newNodes,
   ...remainingNodes
  ];

  setNodes(updatedNodes);

  const newEdges = [
   {
    id: `${currentNode[0].id}-${currentNode[0].id}.${nodeAfter.id}`,
    type: 'custom-edge',
    source: currentNode[0].id,
    target: `${currentNode[0].id}.${nodeAfter.id}`,
    sourceHandle: 'bottom-handle',
    targetHandle: 'top-handle'
   },
   {
    id: `${currentNode[0].id}.${nodeAfter.id}-${nodeAfter.id}`,
    type: 'custom-edge',
    source: `${currentNode[0].id}.${nodeAfter.id}`,
    target: nodeAfter.id,
    sourceHandle: 'bottom-handle',
    targetHandle: 'top-handle'
   }
  ];

  setEdges(eds => {
   let updatedEdges = [...eds];
   updatedEdges = updatedEdges.filter(
    edges => edges.source !== currentNode[0].id
   );
   return [...updatedEdges, ...newEdges];
  });
  dispatch(
   showFlowAlert({
    type: 'success',
    show: true,
    message: 'Node duplicated successfully!'
   })
  );
 };

 const duplicateNode = count => {
  let edges = getEdges();
  console.log(count);
  const nodesToDuplicate = nodes.slice(
   currentNodeIndex,
   currentNodeIndex + count
  );
  let n = nodesToDuplicate.length - 1;
  let yPosition =
   nodesToDuplicate[n].position.y - nodesToDuplicate[0].position.y + 100;
  // Create new nodes as duplicates with unique IDs
  console.log(yPosition);
  const newNode = nodesToDuplicate.map((node, index) => {
   return {
    ...node,
    id: `${node.id}-duplicate`, // Give new unique IDs
    position: {x: node.position.x, y: node.position.y + yPosition} // Adjust position slightly
   };
  });
  console.log(newNode);

  // Create a mapping from old node IDs to new node IDs
  let newEdges1 = [];
  // Duplicate the edges between the nodes
  edges.forEach(edge => {
   // Check if the edge connects two nodes that are being duplicated
   const sourceNodeIndex = nodesToDuplicate.findIndex(
    node => node.id === edge.source
   );
   const targetNodeIndex = nodesToDuplicate.findIndex(
    node => node.id === edge.target
   );

   if (sourceNodeIndex !== -1 && targetNodeIndex !== -1) {
    // Create a new edge that connects the duplicated nodes
    const newEdge = {
     ...edge,
     id: `${edge.id}-duplicate`,
     source: `${edge.source}-duplicate`,
     target: `${edge.target}-duplicate`
    };

    newEdges1.push(newEdge);
   }
  });

  if (newNode.length > 0) {
   const firstNode = newNode[0];
   const lastNode = newNode[newNode.length - 1];

   // Create an edge from the first duplicated node to the firstNodeTargetId
   const firstNodeEdge = {
    id: `${nodesToDuplicate[n].id}-to-${firstNode.id}`,
    source: nodesToDuplicate[n].id,
    target: firstNode.id,
    type: 'custom-edge'
   };

   // Create an edge from the last duplicated node to the lastNodeTargetId
   const lastNodeEdge = {
    id: `${lastNode.id}-to-${nodes[currentNodeIndex + count].id}`,
    source: lastNode.id,
    target: nodes[currentNodeIndex + count].id,
    type: 'custom-edge'
   };

   newEdges1.push(firstNodeEdge);
   newEdges1.push(lastNodeEdge);
  }
  console.log(newEdges1);

  const remainingNodes = nodes.slice(currentNodeIndex + count).map(node => ({
   ...node,
   position: {...node.position, y: node.position.y + yPosition}
  }));

  const updatedNodes = [
   ...nodes.slice(0, currentNodeIndex + count),
   ...newNode,
   ...remainingNodes
  ];

  setNodes(updatedNodes);
  let id1 = nodesToDuplicate[n].id;
  let id2 = nodes[currentNodeIndex + count].id;
  setEdges(eds => {
   let updatedEdges = [...eds];
   updatedEdges = updatedEdges.filter(
    ed => ed.source !== id1 && ed.target !== id2
   );
   return [...updatedEdges, ...newEdges1];
  });
  dispatch(
   showFlowAlert({
    type: 'success',
    show: true,
    message: 'Node duplicated successfully'
   })
  );
 };
 const deleteExit = () => {
  console.log('delete exit node');
  let previousNode, afterNode;
  previousNode = nodes[currentNodeIndex - 1];
  afterNode = nodes[currentNodeIndex + 1];
  console.log(currentNode[0].id);
  setNodes(nds => {
   // Delete the specified nodes
   let updatedNodes = [...nds];
   //  for (let i = currentNodeIndex; i < updatedNodes.length; i++) {
   //   updatedNodes[i].position = {
   //    ...updatedNodes[i].position,
   //    y: updatedNodes[i].position.y - 220
   //   };
   //  }
   updatedNodes = updatedNodes.filter(node => node.id !== currentNode[0].id);
   return updatedNodes;
  });
  console.log(previousNode, afterNode);
  setEdges(eds => {
   // Remove edges connected to the deleted nodes
   let updatedEdges = [...eds];
   updatedEdges = updatedEdges.filter(edge => edge.id !== currentNode[0].id);
   const newEdge = {
    id: `${previousNode.id}-${afterNode.id}`,
    source: previousNode.id,
    target: afterNode.id,
    type: 'custom-edge'
   };

   return [...updatedEdges, newEdge];
  });
  closeDeleteDialog();
 };

 const deleteNode = nodeNumber => {
  let nodeBefore, nodeAfter;
  const nodesToDelete = nodes.slice(
   currentNodeIndex,
   currentNodeIndex + nodeNumber
  );
  let n = nodesToDelete.length - 1;
  let YPosition =
   nodesToDelete[n].position.y - nodesToDelete[0].position.y + 100;
  // Create new nodes as duplicates with unique IDs
  console.log(YPosition);
  setNodes(nds => {
   // Delete the specified nodes
   const updatedNodes = [...nds];
   nodeBefore = updatedNodes[currentNodeIndex - 1];
   nodeAfter = updatedNodes[currentNodeIndex + nodeNumber];
   const nodeAfterIndex = currentNodeIndex + nodeNumber;
   const nodeAfter2 = updatedNodes[nodeAfterIndex];

   let xPosition = 0;
   let yPosition = 0;
   if (nodeNumber === 1) {
    xPosition = 4;
    yPosition = 100;
   }
   // Update the positions of the remaining nodes
   console.log(updatedNodes.length);
   if (nodeAfterIndex === updatedNodes.length - 1) {
    // Only one node is left after the deleted nodes
    if (nodeAfter2) {
     updatedNodes[updatedNodes.length - 1].position = {
      x: nodeAfter2.position.x - 4 + xPosition,
      y: nodeAfter2.position.y - YPosition - yPosition // Adjust the position of the last node
     };
    }
   } else {
    // Update the positions of the remaining nodes after the deleted range
    for (let i = currentNodeIndex; i < updatedNodes.length; i++) {
     updatedNodes[i].position = {
      ...updatedNodes[i].position,
      y: updatedNodes[i].position.y - YPosition - yPosition
     };
    }
   }
   updatedNodes.splice(currentNodeIndex, nodeNumber);
   return updatedNodes;
  });

  setEdges(eds => {
   // Remove edges connected to the deleted nodes
   const deletedNodeIds = nodes
    .slice(currentNodeIndex, currentNodeIndex + nodeNumber)
    .map(node => node.id);
   let updatedEdges = eds.filter(
    edge =>
     !deletedNodeIds.includes(edge.source) &&
     !deletedNodeIds.includes(edge.target)
   );

   // Add a new edge connecting the node before to the node after
   if (nodeBefore?.data?.label !== nodeAfter?.data?.label) {
    console.log('adding edge to end of conditional');
    const ID = nodeAfter.id;

    // Check if the last character is 'y' and replace it with 'n'
    const newId = ID.endsWith('n') ? ID.slice(0, -1) + 'e' : ID;

    const newEdge = {
     id: `${nodeBefore.id}-${newId}`,
     source: nodeBefore.id,
     target: newId,
     type: 'custom-edge'
    };
    updatedEdges = [...updatedEdges, newEdge];
   } else if (nodeBefore && nodeAfter) {
    const newEdge = {
     id: `${nodeBefore.id}-${nodeAfter.id}`,
     source: nodeBefore.id,
     target: nodeAfter.id,
     type: 'custom-edge'
    };
    updatedEdges = [...updatedEdges, newEdge];
   }

   return updatedEdges;
  });
  dispatch(
   showFlowAlert({
    type: 'success',
    show: true,
    message: 'Node deleted successfully'
   })
  );
 };

 return (
  <div>
   <Card
    sx={{
     minWidth: '240px',
     padding: '12px 0px 8px 0px',
     borderRadius: '12px',
     border: '0.5px solid #dfdded'
    }}
   >
    {!data.addStep && (
     <>
      <div
       style={{
        position: 'absolute',
        top: '-27px', // Position it 2px above the Card
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 10,
        display: 'flex',
        gap: '4px',
        alignItems: 'center'
       }}
       className={data?.type === 'event' ? 'status start' : 'status SUCCESS'}
      >
       {data?.type === 'event' && (
        <>
         <img src={iconLight} alt="dothis" /> <div>Start When</div>
        </>
       )}
       {!(data?.type === 'event') && (
        <>
         {data?.flag === 'conditionalStart' ||
         data?.flag === 'splitPathStart' ? (
          <>
           <img src={iconDoThis} alt="dothis" /> <div>Apply</div>
          </>
         ) : (
          <>
           <img src={iconDoThis} alt="dothis" /> <div>Do this</div>
          </>
         )}
        </>
       )}
      </div>

      <div className="node">
       <div className="node-header">
        <div className="node-label">
         <div className="icon-label">
          <div className="icon">
           <img
            src={data.headerIcon}
            alt="tag"
            style={{width: '21px', height: '21px'}}
           />
          </div>
          <div>{data.header}</div>
         </div>
         <div
          onClick={event => {
           console.log('iconMore is clicked');
           setStatusAnchorElement(event.currentTarget);
          }}
         >
          <img src={iconMore} alt="more" />
         </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
         {Array.isArray(data.headerDescription)
          ? data.headerDescription.map((item, index) => (
             <div key={index} className="header-description">
              <div className="text">{Object.keys(item)[0]}</div>
              <div className="status ERROR">{Object.values(item)[0]}</div>
             </div>
            ))
          : data.headerDescription && (
             <div className="header-description">
              <div className="text">{data.headerDescription}</div>
              {Array.isArray(data.status) ? (
               data.status.map((status, index) => (
                <div key={index} className="status ERROR">
                 {status}
                </div>
               ))
              ) : (
               <div className="status ERROR">{data.status}</div>
              )}
             </div>
            )}
        </div>
        {data?.flag === 'splitPathStart' && (
         <div className="header-button">
          <div
           className="btn btn-primary"
           onClick={() => {
            setSplitDialog({
             title: `Result of ${data.headerDescription}`,
             open: true,
             onClose: closeSplitDialog,
             content: 'Are you sure you want to delete the node?',
             type: 'split',
             actions: []
            });
           }}
          >
           stats
          </div>
         </div>
        )}
       </div>
       <div className="horizontal-line"></div>
       <div className="node-body">
        {data.bodyContent.map(data => {
         return (
          <>
           <div className="body-element">
            <div className="label">{data.label}</div>
            <div className="number">{data.data}</div>
           </div>
          </>
         );
        })}
       </div>
      </div>
      <Handle
       type="source"
       position="bottom"
       id="source-handle"
       style={{background: '#555'}}
      />
      <Handle
       type="target"
       position="top"
       id="target-handle"
       style={{background: '#555'}}
      />
     </>
    )}
   </Card>
   <CustomMenu
    onClose={() => {
     setStatusAnchorElement(null);
    }}
    onSelect={value => {
     setStatusAnchorElement(null);
     if (value === 'change event') {
      if (currentNode[0].data.perform) {
       currentNode[0].data.perform();
      }
     }
     if (value === 'delete') {
      setDeleteDialog({
       title: `Delete Node`,
       open: true,
       onClose: closeDeleteDialog,
       content: 'Are you sure you want to delete the node?',
       type: 'delete',
       actions: [
        {
         label: 'Cancel',
         onClick: () => closeDeleteDialog(),
         classes: 'btn-outline dark-border'
        },
        {
         label: 'Delete',
         onClick: () => handleSubmit(onDelete)(),
         classes: 'btn-primary'
        }
       ]
      });
     } else if (value === 'duplicate') {
      if (
       currentNode[0].data.flag === 'conditionalStart' ||
       currentNode[0].data.flag === 'splitPathStart'
      ) {
       const firstChar = currentNode[0].id[0];
       let count = 0;

       nodes.forEach(node => {
        if (node.id[0] === firstChar) {
         count++;
        }
       });
       duplicateNode(count);
      } else {
       duplicateNode2();
      }
     }
    }}
    options={options}
    anchorEl={statusAnchorElement}
   />
   <CustomDialog
    {...deleteDialog}
    content={deleteDialog.type === 'delete' ? deleteDialog.content : ''}
   />
   <CustomDialog
    {...splitDialog}
    content={
     splitDialog.type === 'split' ? (
      <>
       <div className="splitPathStats">
        <div className="path">
         <div className="status SUCCESS">path A</div>
         <div className="path-data">
          <div className="path-number">-</div>
          <div className="path-label">Contacts</div>
         </div>
         <div className="path-data">
          <div className="path-number">-</div>
          <div className="path-label">Sent</div>
         </div>
         <div className="path-data">
          <div className="path-number">-</div>
          <div className="path-label">Opened</div>
         </div>
         <div className="path-data">
          <div className="path-number">-</div>
          <div className="path-label">Opened</div>
         </div>
         <div className="path-data">
          <div className="path-number">-</div>
          <div className="path-label">Opened</div>
         </div>
         <div className="btn btn-primary">Declare Winner</div>
        </div>
        <div className="path">
         <div className="status SUCCESS">path B</div>
         <div className="path-data">
          <div className="path-number">-</div>
          <div className="path-label">Contacts</div>
         </div>
         <div className="path-data">
          <div className="path-number">-</div>
          <div className="path-label">Sent</div>
         </div>
         <div className="path-data">
          <div className="path-number">-</div>
          <div className="path-label">Opened</div>
         </div>
         <div className="path-data">
          <div className="path-number">-</div>
          <div className="path-label">Opened</div>
         </div>
         <div className="path-data">
          <div className="path-number">-</div>
          <div className="path-label">Opened</div>
         </div>
         <div className="btn btn-primary">Declare Winner</div>
        </div>
       </div>
      </>
     ) : (
      splitDialog.content
     )
    }
   />
  </div>
 );
};

export default Node;
