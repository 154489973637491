import ResourceApi from '../../../../../api/resourceapi';
import CouponIcon from '../../../../../assets/icons/icon-coupon.svg';

export const registerCouponBlock = (editor) => {

  // const getAllCoupons = async () => {
  //   try{
  //     const response = await ResourceApi.shopify.getAllCoupons();
  //     console.log(response.data, 'coupons fetched');
  //   }catch(e){
  //     console.log(e);
  //   }
  // }

  editor.DomComponents.addType('Coupon', {
    model: {
      defaults: {
        tagName: 'span',
        attributes: { 'data-gjs-type': 'Coupon' },
        classes: ['block-div'],
        style: {
          display: 'block !important',
          width: '100%',
        },
        components: [
          {
            tagName: 'div',
            classes: ['coupon-container'],
            components: [
              {
                tagName: 'div',
                classes: ['coupon-header'],
                content: '10% OFF DISCOUNT',
              },
              {
                tagName: 'div',
                classes: ['coupon-description'],
                content: "As thanks for shopping with us, we're giving you a discount coupon to use on your next purchase.",
              },
              {
                tagName: 'div',
                classes: ['coupon-code-button-wrapper'],
                components: [
                  {
                    tagName: 'div',
                    classes: ['coupon-code'],
                    content: 'TAKE10OFF',
                  },
                ],
              },
              {
                tagName: 'div',
                classes: ['coupon-code-button-wrapper'],
                components: [
                  {
                    tagName: 'a',
                    classes: ['shop-now-button'],
                    attributes: { href: '#' },
                    content: 'SHOP NOW',
                  },
                ],
              },
            ],
          },
        ],
        styles: `
          .coupon-container {
            width: 100%;
            padding: 24px;
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            font-family: Arial, sans-serif;
            text-align: center;
          }
          .coupon-header {
            font-size: 18px;
            font-family: Arial, sans-serif;
            font-weight: bold;
            margin-bottom: 12px;
            color: #ff5722;
          }
          .coupon-description {
            font-size: 14px;
            color: #717171;
            font-family: Arial, sans-serif;
            margin-bottom: 16px;
          }
          .coupon-code-button-wrapper {
            display: block;
            width: 100%;
          }
          .coupon-code {
            font-size: 16px;
            font-family: Arial, sans-serif;
            font-weight: 600;
            padding: 10px;
            border: 2px dashed #ff5722;
            margin-bottom: 16px;
            border-radius: 12px;
            width: auto;
            display: inline-block;
          }
          .shop-now-button {
            width: auto;
            display: inline-block;
            padding: 16px;
            background-color: #ff385c;
            color: white;
            text-align: center;
            text-decoration: none;
            font-size: 16px;
            font-family: Arial, sans-serif;
            font-weight: bold;
            border-radius: 5px;
            border: none;
            cursor: pointer;
          }
        `,
        droppable: false,
        selectable: true,
        hoverable: true,
        stylable: true,
      },
      init() {
        this.set({ style: this.get('style') });
        // getAllCoupons();
      },
    },
  });

  editor.BlockManager.add('couponBlock', {
    label: `
      <div class="gjs-block-label-container" data-category="Block">
        <img src=${CouponIcon} alt="Coupon Icon" height="24px" width="24px"/>
      </div>
      <div class="block-label-text">Coupon</div>
    `,
    content: { type: 'Coupon' },
    category: 'Blocks',
    draggable: 'td',
  });
};