import CartIcon from '../../../../../assets/icons/icon-cart.svg';
import { updateCartItemsBlock } from '../utils';

export const registerCartItemsBlock = (editor, opts = {}) => {
  editor.DomComponents.addType('CartItems', {
    model: {
      defaults: {
        tagName: 'span',
        attributes: { 'data-gjs-type': 'CartItems' },
        classes: ['block-div'],
        style: {
          display: 'block !important',
          width: '100%',
        },
        // Initialize all elements as visible
        enabledElements: ['productImage', 'title', 'attribute', 'quantity', 'price'],
        components: [
          {
            tagName: 'table',
            classes: ['cart-items-table'],
            components: [
              {
                tagName: 'tr',
                components: [
                  {
                    tagName: 'td',
                    attributes: { colspan: '3' },
                    components: [
                      {
                        tagName: 'div',
                        classes: ['product-info'],
                        components: [
                          {
                            tagName: 'div',
                            classes: ['product-image'],
                          },
                          {
                            tagName: 'div',
                            classes: ['product-details'],
                            components: [
                              {
                                tagName: 'span',
                                classes: [
                                  'product-title',
                                  'font-large',
                                  'black',
                                ],
                                content: 'Here is the product title',
                                type: 'text',
                                editable: 'true',
                              },
                              {
                                tagName: 'span',
                                classes: ['product-variant'],
                                content: 'Black, 32',
                                type: 'text',
                                editable: 'true',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    tagName: 'td',
                    classes: ['quantity', 'label', 'gray'],
                    content: 'Qty 1',
                    type: 'text',
                    editable: 'true',
                  },
                  {
                    tagName: 'td',
                    classes: ['price', 'black'],
                    content: '$50.00',
                    type: 'text',
                    editable: 'true',
                  },
                ],
              },
              {
                tagName: 'tr',
                classes: ['subtotal-row'],
                components: [
                  {
                    tagName: 'td',
                    attributes: { colspan: '4' },
                    content: 'Subtotal',
                    classes: ['label', 'gray'],
                    type: 'text',
                    editable: 'true',
                  },
                  {
                    tagName: 'td',
                    classes: ['price', 'black'],
                    content: '$50.00',
                    type: 'text',
                    editable: 'true',
                  },
                ],
              },
              {
                tagName: 'tr',
                components: [
                  {
                    tagName: 'td',
                    attributes: { colspan: '4' },
                    content: 'Shipping',
                    classes: ['label', 'gray'],
                    type: 'text',
                    editable: 'true',
                  },
                  {
                    tagName: 'td',
                    classes: ['price', 'black'],
                    content: '$7.00',
                    type: 'text',
                    editable: 'true',
                  },
                ],
              },
              {
                tagName: 'tr',
                components: [
                  {
                    tagName: 'td',
                    attributes: { colspan: '4' },
                    classes: ['label', 'gray'],
                    content: 'Taxes',
                    type: 'text',
                    editable: 'true',
                  },
                  {
                    tagName: 'td',
                    classes: ['price', 'black'],
                    content: '$5.00',
                    type: 'text',
                    editable: 'true',
                  },
                ],
              },
              {
                tagName: 'tr',
                components: [
                  {
                    tagName: 'td',
                    attributes: { colspan: '4' },
                    classes: ['label', 'green'],
                    content: 'Discount',
                    type: 'text',
                    editable: 'true',
                  },
                  {
                    tagName: 'td',
                    classes: ['price', 'discount', 'green'],
                    content: '-$10.00',
                    type: 'text',
                    editable: 'true',
                  },
                ],
              },
              {
                tagName: 'tr',
                classes: ['total-row'],
                components: [
                  {
                    tagName: 'td',
                    attributes: { colspan: '4' },
                    content: 'Total',
                    classes: ['label', 'black', 'font-large'],
                    type: 'text',
                    editable: 'true',
                  },
                  {
                    tagName: 'td',
                    classes: ['price', 'black', 'font-large'],
                    content: '$52.00',
                    type: 'text',
                    editable: 'true',
                  },
                ],
              },
            ],
          },
        ],
        styles: `
          .cart-items-table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            font-family: Arial, sans-serif;
            padding: 24px;
            padding-top: 36px;
          }
          .cart-items-table td {
            padding: 8px;
            font-size: 14px;
            font-family: Arial;
            font-weight: 500;
            color: #717171;
            line-height: 16px;
          }
          .product-info {
            display: flex;
            align-items: center;
          }
          .product-image {
            width: 50px;
            height: 50px;
            background-color: #f0f0f0;
            margin-right: 12px;
          }
          .product-details {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
          }
          .font-large {
            font-size: 16px !important;
            font-family: Arial;
            font-weight: 600 !important;
            line-height: 20px ;
        }
          .gray {
            color: #777777 !important;
          }
          .black {
            color: #241c15 !important;
          }
          .green{
            color: #ff6600 !important;
          }
        `,
        droppable: false,
        selectable: true,
        hoverable: true,
        stylable: true,
      },
      init() {
        this.set({ style: this.get('style') });
        // Ensure all elements are initially visible
        updateCartItemsBlock(this, this.get('enabledElements'));
      },
    },
  });

  editor.BlockManager.add('cartItemsBlock', {
    label: `
      <div class="gjs-block-label-container" data-category="Block">
        <img src=${CartIcon} alt="Cart Icon" height="24px" width="24px"/>
      </div>
      <div class="block-label-text">Cart Items</div>
    `,
    content: { type: 'CartItems' },
    category: 'Blocks',
    draggable: 'td',
  });
};
