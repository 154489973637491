import SvgIcon from '@mui/material/SvgIcon';
const Icon = ({ type, sx, size = 'small', onClick }) => {
  let svgContent = '';
  switch (type) {
    case 'cross':
      svgContent = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
        >
          <path
            d="M0.0758978 0.156794L0.113378 0.113378C0.250806 -0.0240499 0.465858 -0.0365433 0.6174 0.0758978L0.660816 0.113378L4 3.45239L7.33918 0.113378C7.49035 -0.0377927 7.73545 -0.0377927 7.88662 0.113378C8.03779 0.264549 8.03779 0.509645 7.88662 0.660816L4.54761 4L7.88662 7.33918C8.02405 7.47661 8.03654 7.69166 7.9241 7.84321L7.88662 7.88662C7.74919 8.02405 7.53414 8.03654 7.3826 7.9241L7.33918 7.88662L4 4.54761L0.660816 7.88662C0.509645 8.03779 0.264549 8.03779 0.113378 7.88662C-0.0377927 7.73545 -0.0377927 7.49035 0.113378 7.33918L3.45239 4L0.113378 0.660816C-0.0240499 0.523388 -0.0365433 0.308335 0.0758978 0.156794L0.113378 0.113378L0.0758978 0.156794Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-back':
      svgContent = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21 11.6154C21 11.9977 20.69 12.3077 20.3077 12.3077H5.36369L11.1049 18.0489C11.3753 18.3193 11.3753 18.7576 11.1049 19.028C10.8346 19.2984 10.3962 19.2984 10.1259 19.028L3.20277 12.1049C2.93241 11.8346 2.93241 11.3962 3.20277 11.1258L10.1259 4.20277C10.3962 3.93241 10.8346 3.93241 11.1049 4.20277C11.3753 4.47314 11.3753 4.91148 11.1049 5.18184L5.36369 10.9231H20.3077C20.69 10.9231 21 11.233 21 11.6154Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-edit':
      svgContent = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.0785 3.92063C18.8509 2.69309 16.8607 2.69313 15.6332 3.92071L4.51504 15.0397C4.19088 15.3639 3.96731 15.7749 3.87125 16.2232L3.01428 20.2224C2.96859 20.4356 3.03411 20.6575 3.18831 20.8117C3.34252 20.9659 3.56438 21.0314 3.77762 20.9857L7.77672 20.1288C8.22508 20.0327 8.63612 19.8091 8.96034 19.4848L20.0786 8.36573C21.306 7.13821 21.306 5.1481 20.0785 3.92063ZM16.5424 4.82988C17.2678 4.10447 18.4439 4.10445 19.1693 4.82983C19.8946 5.55518 19.8946 6.73119 19.1693 7.45656L18.5901 8.03588L15.9633 5.4091L16.5424 4.82988ZM15.0541 6.31834L17.6809 8.94512L8.0511 18.5756C7.90214 18.7246 7.7133 18.8274 7.50731 18.8715L4.47974 19.5203L5.12852 16.4926C5.17265 16.2866 5.27536 16.0978 5.42428 15.9489L15.0541 6.31834Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-tick':
      svgContent = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.7473 2.63884C12.7382 1.78705 11.2619 1.78705 10.2527 2.63884L9.33362 3.41457C8.90469 3.7766 8.37391 3.99648 7.81461 4.04378L6.6162 4.14512C5.30031 4.25641 4.25641 5.30031 4.14512 6.6162L4.04378 7.81461C3.99648 8.37391 3.7766 8.90469 3.41457 9.33364L2.63884 10.2527C1.78705 11.2619 1.78705 12.7382 2.63884 13.7473L3.41457 14.6664C3.7766 15.0953 3.99648 15.6261 4.04378 16.1854L4.14512 17.3839C4.25641 18.6998 5.30031 19.7437 6.6162 19.8549L7.81461 19.9562C8.37391 20.0036 8.90469 20.2234 9.33364 20.5855L10.2527 21.3612C11.2619 22.2129 12.7382 22.2129 13.7473 21.3612L14.6664 20.5855C15.0953 20.2234 15.6261 20.0036 16.1854 19.9562L17.3839 19.8549C18.6998 19.7437 19.7437 18.6998 19.8549 17.3839L19.9562 16.1854C20.0036 15.6261 20.2234 15.0953 20.5855 14.6664L21.3612 13.7473C22.2129 12.7382 22.2129 11.2619 21.3612 10.2527L20.5855 9.33362C20.2234 8.90469 20.0036 8.37391 19.9562 7.81461L19.8549 6.6162C19.7437 5.30031 18.6998 4.25641 17.3839 4.14512L16.1854 4.04378C15.6261 3.99648 15.0953 3.7766 14.6664 3.41457L13.7473 2.63884ZM16.926 10.1531C17.4022 9.677 17.4022 8.90514 16.926 8.42908C16.4501 7.953 15.6781 7.953 15.202 8.42908L10.6461 12.9851L8.7991 11.1381C8.32303 10.662 7.55117 10.662 7.07511 11.1381C6.59903 11.6141 6.59903 12.386 7.07511 12.8621L9.7841 15.571C10.2602 16.0471 11.032 16.0471 11.5081 15.571L16.926 10.1531Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-arrow':
      svgContent = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.6343 8.23431C18.9467 7.9219 19.4533 7.9219 19.7657 8.23431C20.0781 8.54673 20.0781 9.05327 19.7657 9.36569L12.5657 16.5657C12.2533 16.8781 11.7467 16.8781 11.4343 16.5657L4.23431 9.36569C3.92189 9.05327 3.92189 8.54673 4.23431 8.23431C4.54673 7.9219 5.05327 7.9219 5.36569 8.23431L12 14.8686L18.6343 8.23431Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-unsubscribe':
      svgContent = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2856 11.5694C18.8898 11.5694 21.0009 13.6805 21.0009 16.2847C21.0009 18.8889 18.8898 21 16.2856 21C13.6814 21 11.5703 18.8889 11.5703 16.2847C11.5703 13.6805 13.6814 11.5694 16.2856 11.5694ZM11.5894 13.2834C11.335 13.6806 11.1296 14.1122 10.9818 14.5697L4.929 14.5699C4.57388 14.5699 4.286 14.8578 4.286 15.2129V15.9908C4.286 16.5529 4.53119 17.0871 4.95743 17.4535C6.03603 18.3809 7.66154 18.8576 9.85562 18.8576C10.369 18.8576 10.8514 18.8315 11.3029 18.7796C11.5163 19.2084 11.7853 19.606 12.0993 19.9631C11.4116 20.0838 10.6632 20.1436 9.85562 20.1436C7.37836 20.1436 5.45895 19.5807 4.11905 18.4287C3.40865 17.8179 3 16.9277 3 15.9908V15.2129C3 14.1476 3.86364 13.2839 4.929 13.2839L11.5894 13.2834ZM14.222 14.1124L14.1627 14.162L14.1131 14.2214C14.0118 14.3676 14.0118 14.5627 14.1131 14.7089L14.1627 14.7683L15.6798 16.2853L14.1649 17.8L14.1153 17.8594C14.014 18.0056 14.014 18.2007 14.1153 18.3469L14.1649 18.4062L14.2243 18.4558C14.3705 18.5571 14.5656 18.5571 14.7118 18.4558L14.7711 18.4062L16.2859 16.8914L17.8026 18.4081L17.8619 18.4578C18.0081 18.559 18.2032 18.559 18.3494 18.4578L18.4088 18.4081L18.4584 18.3488C18.5597 18.2026 18.5597 18.0075 18.4584 17.8613L18.4088 17.8019L16.892 16.2853L18.4112 14.7683L18.4608 14.7089C18.562 14.5628 18.562 14.3676 18.4608 14.2214L18.4112 14.1621L18.3518 14.1125C18.2056 14.0112 18.0105 14.0112 17.8643 14.1125L17.8049 14.1621L16.2859 15.6791L14.7689 14.162L14.7095 14.1124C14.5842 14.0256 14.423 14.0132 14.2874 14.0752L14.222 14.1124ZM9.85562 3C12.2231 3 14.1423 4.9192 14.1423 7.28666C14.1423 9.65411 12.2231 11.5733 9.85562 11.5733C7.48816 11.5733 5.56896 9.65411 5.56896 7.28666C5.56896 4.9192 7.48816 3 9.85562 3ZM9.85562 4.286C8.1984 4.286 6.85496 5.62944 6.85496 7.28666C6.85496 8.94388 8.1984 10.2873 9.85562 10.2873C11.5128 10.2873 12.8563 8.94388 12.8563 7.28666C12.8563 5.62944 11.5128 4.286 9.85562 4.286Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-blocked':
      svgContent = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM19.875 12C19.875 10.0278 19.15 8.22497 17.9521 6.8434L6.8434 17.9521C8.22497 19.15 10.0278 19.875 12 19.875C16.3492 19.875 19.875 16.3492 19.875 12ZM6.04791 17.1566L17.1566 6.04791C15.775 4.84995 13.9722 4.125 12 4.125C7.65076 4.125 4.125 7.65076 4.125 12C4.125 13.9722 4.84995 15.775 6.04791 17.1566Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-bounced':
      svgContent = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 8.21053C22 11.0882 19.6548 13.4211 16.7619 13.4211C13.869 13.4211 11.5238 11.0882 11.5238 8.21053C11.5238 5.33283 13.869 3 16.7619 3C19.6548 3 22 5.33283 22 8.21053ZM12.9524 8.21053C12.9524 9.00018 13.1952 9.73343 13.6106 10.3404L18.9031 5.0758C18.2929 4.66258 17.5557 4.42105 16.7619 4.42105C14.658 4.42105 12.9524 6.11766 12.9524 8.21053ZM16.7619 12C18.8658 12 20.5714 10.3034 20.5714 8.21053C20.5714 7.42087 20.3286 6.68762 19.9132 6.08064L14.6207 11.3453C15.2309 11.7585 15.9681 12 16.7619 12ZM19.619 17.9211V13.6747C20.1438 13.4027 20.6245 13.058 21.0476 12.6541V17.9211C21.0476 19.5629 19.7558 20.9045 18.128 20.9951L17.9524 21H5.09524C3.44473 21 2.09596 19.7149 2.0049 18.0958L2 17.9211V8.92105C2 7.27923 3.29186 5.93756 4.9196 5.84698L5.09524 5.84211H11.0459C10.8575 6.29132 10.7212 6.76748 10.6442 7.26316H5.09524C4.22079 7.26316 3.50363 7.93305 3.4341 8.78508L3.42857 8.92105V9.32747L11.5238 13.5655L12.7668 12.9146C13.1845 13.2659 13.6495 13.5632 14.1511 13.7956L11.8565 14.9972C11.678 15.0906 11.4697 15.104 11.2826 15.0372L11.1911 14.9972L3.42857 10.9333V17.9211C3.42857 18.7909 4.10201 19.5043 4.95855 19.5735L5.09524 19.5789H17.9524C18.8268 19.5789 19.544 18.9091 19.6135 18.057L19.619 17.9211Z"
            fill="#242424"
          />
        </svg>
      );
      break;
    case 'icon-more':
      svgContent = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 7C10.8954 7 10 6.10457 10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7ZM12 13.8571C10.8954 13.8571 10 12.9617 10 11.8571C10 10.7526 10.8954 9.85714 12 9.85714C13.1046 9.85714 14 10.7526 14 11.8571C14 12.9617 13.1046 13.8571 12 13.8571ZM10 18.7143C10 19.8189 10.8954 20.7143 12 20.7143C13.1046 20.7143 14 19.8189 14 18.7143C14 17.6097 13.1046 16.7143 12 16.7143C10.8954 16.7143 10 17.6097 10 18.7143Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-add-circle':
      svgContent = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 7.5C12.4142 7.5 12.75 7.83579 12.75 8.25V11.25H15.75C16.1642 11.25 16.5 11.5858 16.5 12C16.5 12.4142 16.1642 12.75 15.75 12.75H12.75V15.75C12.75 16.1642 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1642 11.25 15.75V12.75H8.25C7.83579 12.75 7.5 12.4142 7.5 12C7.5 11.5858 7.83579 11.25 8.25 11.25H11.25V8.25C11.25 7.83579 11.5858 7.5 12 7.5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-delete':
      svgContent = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.4211 2C14.1088 2 15.4874 3.32409 15.5746 4.99018L15.5789 5.15789H20.2105C20.5593 5.15789 20.8421 5.44066 20.8421 5.78947C20.8421 6.10922 20.6045 6.37347 20.2962 6.41529L20.2105 6.42105H19.3187L18.2915 19.0973C18.1585 20.737 16.789 22 15.1439 22H9.69819C8.05309 22 6.68358 20.737 6.55063 19.0973L5.52253 6.42105H4.63158C4.31184 6.42105 4.04759 6.18345 4.00577 5.87517L4 5.78947C4 5.46973 4.2376 5.20548 4.54588 5.16366L4.63158 5.15789H9.26316C9.26316 3.41384 10.677 2 12.4211 2ZM18.052 6.42105H6.79012L7.80965 18.9952C7.88942 19.9791 8.71113 20.7368 9.69819 20.7368H15.1439C16.131 20.7368 16.9527 19.9791 17.0325 18.9952L18.052 6.42105ZM10.5263 9.57895C10.8461 9.57895 11.1103 9.81655 11.1521 10.1248L11.1579 10.2105V16.9474C11.1579 17.2962 10.8751 17.5789 10.5263 17.5789C10.2066 17.5789 9.94232 17.3413 9.9005 17.0331L9.89474 16.9474V10.2105C9.89474 9.86171 10.1775 9.57895 10.5263 9.57895ZM14.3158 9.57895C14.6355 9.57895 14.8998 9.81655 14.9416 10.1248L14.9474 10.2105V16.9474C14.9474 17.2962 14.6646 17.5789 14.3158 17.5789C13.996 17.5789 13.7318 17.3413 13.69 17.0331L13.6842 16.9474V10.2105C13.6842 9.86171 13.967 9.57895 14.3158 9.57895ZM12.4211 3.26316C11.4182 3.26316 10.5974 4.04224 10.5307 5.02817L10.5263 5.15789H14.3158L14.3114 5.02817C14.2448 4.04224 13.4239 3.26316 12.4211 3.26316Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-search':
      svgContent = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C11.8483 18 13.5503 17.3732 14.9048 16.3205L20.291 21.7067C20.6815 22.0972 21.3147 22.0972 21.7052 21.7067C22.0958 21.3162 22.0958 20.683 21.7052 20.2925L16.3192 14.9065C17.3727 13.5517 18 11.8491 18 10C18 5.58172 14.4183 2 10 2ZM4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-preview':
      svgContent = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21.8729 12.0154C21.6942 12.2598 17.4371 18 11.9999 18C6.56274 18 2.30539 12.2598 2.12691 12.0156C1.9577 11.7837 1.9577 11.4692 2.12691 11.2374C2.30539 10.9929 6.56274 5.25274 11.9999 5.25274C17.4371 5.25274 21.6942 10.993 21.8729 11.2372C22.0423 11.469 22.0423 11.7837 21.8729 12.0154ZM11.9999 6.57141C7.99486 6.57141 4.52606 10.3813 3.49922 11.6268C4.52473 12.8734 7.98626 16.6813 11.9999 16.6813C16.0048 16.6813 19.4733 12.8721 20.5006 11.6259C19.4751 10.3794 16.0135 6.57141 11.9999 6.57141Z"
            fill="#241C15"
          />
          <path
            d="M11.9999 15.5824C9.81858 15.5824 8.04385 13.8077 8.04385 11.6264C8.04385 9.44503 9.81858 7.6703 11.9999 7.6703C14.1812 7.6703 15.956 9.44503 15.956 11.6264C15.956 13.8077 14.1812 15.5824 11.9999 15.5824ZM11.9999 8.98902C10.5456 8.98902 9.36256 10.1721 9.36256 11.6264C9.36256 13.0806 10.5457 14.2637 11.9999 14.2637C13.4542 14.2637 14.6373 13.0806 14.6373 11.6264C14.6373 10.1721 13.4542 8.98902 11.9999 8.98902Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-preference':
      svgContent = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M6.84704 6.15042H3.52948C3.23702 6.15042 3 5.84801 3 5.47532C3 5.10264 3.23702 4.80023 3.52948 4.80023H6.84704C7.1395 4.80023 7.37652 5.10264 7.37652 5.47532C7.37652 5.84801 7.1395 6.15042 6.84704 6.15042Z"
            fill="#241C15"
          />
          <path
            d="M8.7428 7.94823C7.40546 7.94823 6.31752 6.83839 6.31752 5.47426C6.31752 4.10984 7.40546 3 8.7428 3C10.0801 3 11.1681 4.10984 11.1681 5.47426C11.1681 6.83843 10.0801 7.94823 8.7428 7.94823ZM8.7428 4.08031C7.98939 4.08031 7.37648 4.70568 7.37648 5.47426C7.37648 6.24283 7.98939 6.86792 8.7428 6.86792C9.49621 6.86792 10.1091 6.24283 10.1091 5.47426C10.1091 4.70568 9.49621 4.08031 8.7428 4.08031Z"
            fill="#241C15"
          />
          <path
            d="M20.473 6.15042H12.5594C12.2669 6.15042 12.0299 5.84801 12.0299 5.47532C12.0299 5.10264 12.2669 4.80023 12.5594 4.80023H20.4729C20.7654 4.80023 21.0024 5.10264 21.0024 5.47532C21.0024 5.84801 20.7654 6.15042 20.473 6.15042Z"
            fill="#241C15"
          />
          <path
            d="M15.852 14.2488C14.5146 14.2488 13.4267 13.139 13.4267 11.7748C13.4267 10.4107 14.5146 9.30087 15.852 9.30087C17.1893 9.30087 18.2773 10.4107 18.2773 11.7748C18.2773 13.139 17.1894 14.2488 15.852 14.2488ZM15.852 10.3812C15.0986 10.3812 14.4857 11.0063 14.4857 11.7748C14.4857 12.5434 15.0986 13.1685 15.852 13.1685C16.6054 13.1685 17.2183 12.5434 17.2183 11.7748C17.2183 11.0063 16.6054 10.3812 15.852 10.3812Z"
            fill="#241C15"
          />
          <path
            d="M12.0356 12.4513H3.52948C3.23702 12.4513 3 12.1489 3 11.7762C3 11.4035 3.23702 11.1011 3.52948 11.1011H12.0356C12.328 11.1011 12.565 11.4035 12.565 11.7762C12.565 12.1489 12.328 12.4513 12.0356 12.4513Z"
            fill="#241C15"
          />
          <path
            d="M20.4729 12.4513H17.7478C17.4553 12.4513 17.2183 12.1489 17.2183 11.7762C17.2183 11.4035 17.4553 11.1011 17.7478 11.1011H20.4729C20.7654 11.1011 21.0024 11.4035 21.0024 11.7762C21.0024 12.1489 20.7654 12.4513 20.4729 12.4513Z"
            fill="#241C15"
          />
          <path
            d="M8.15051 21C6.81317 21 5.72523 19.8901 5.72523 18.5257C5.72523 17.1616 6.81317 16.0518 8.15051 16.0518C9.48785 16.0518 10.5758 17.1616 10.5758 18.5257C10.5758 19.8901 9.48785 21 8.15051 21ZM8.15051 17.1321C7.39711 17.1321 6.78419 17.7572 6.78419 18.5257C6.78419 19.2943 7.39711 19.9197 8.15051 19.9197C8.90392 19.9197 9.51683 19.2943 9.51683 18.5257C9.51683 17.7572 8.90392 17.1321 8.15051 17.1321Z"
            fill="#241C15"
          />
          <path
            d="M20.473 19.2022H11.9669C11.6744 19.2022 11.4374 18.8998 11.4374 18.5271C11.4374 18.1544 11.6744 17.852 11.9669 17.852H20.473C20.7654 17.852 21.0025 18.1544 21.0025 18.5271C21.0025 18.8998 20.7654 19.2022 20.473 19.2022Z"
            fill="#241C15"
          />
          <path
            d="M6.25467 19.2022H3.52948C3.23702 19.2022 3 18.8998 3 18.5271C3 18.1544 3.23702 17.852 3.52948 17.852H6.25467C6.54713 17.852 6.78415 18.1544 6.78415 18.5271C6.78415 18.8998 6.54713 19.2022 6.25467 19.2022Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-close':
      svgContent = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0ZM6.94194 6.05806L6.89119 6.0125C6.6633 5.82917 6.3367 5.82917 6.10881 6.0125L6.05806 6.05806L6.0125 6.10881C5.82917 6.3367 5.82917 6.6633 6.0125 6.89119L6.05806 6.94194L9.1165 10L6.05806 13.0581L6.0125 13.1088C5.82917 13.3367 5.82917 13.6633 6.0125 13.8912L6.05806 13.9419L6.10881 13.9875C6.3367 14.1708 6.6633 14.1708 6.89119 13.9875L6.94194 13.9419L10 10.8835L13.0581 13.9419L13.1088 13.9875C13.3367 14.1708 13.6633 14.1708 13.8912 13.9875L13.9419 13.9419L13.9875 13.8912C14.1708 13.6633 14.1708 13.3367 13.9875 13.1088L13.9419 13.0581L10.8835 10L13.9419 6.94194L13.9875 6.89119C14.1708 6.6633 14.1708 6.3367 13.9875 6.10881L13.9419 6.05806L13.8912 6.0125C13.6633 5.82917 13.3367 5.82917 13.1088 6.0125L13.0581 6.05806L10 9.1165L6.94194 6.05806L6.89119 6.0125L6.94194 6.05806Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-export':
      svgContent = (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.7915 17.5C4.44633 17.5 4.1665 17.2202 4.1665 16.875C4.1665 16.5298 4.44633 16.25 4.7915 16.25H16.0415C16.3867 16.25 16.6665 16.5298 16.6665 16.875C16.6665 17.2202 16.3867 17.5 16.0415 17.5H4.7915ZM10.8584 14.8169C10.6144 15.061 10.2186 15.061 9.97456 14.8169L5.59956 10.4419C5.35548 10.1979 5.35548 9.80214 5.59956 9.55806C5.84364 9.31398 6.23937 9.31398 6.48345 9.55806L9.7915 12.8661V3.125C9.7915 2.77982 10.0713 2.5 10.4165 2.5C10.7617 2.5 11.0415 2.77982 11.0415 3.125V12.8661L14.3496 9.55806C14.5936 9.31398 14.9894 9.31398 15.2334 9.55806C15.4775 9.80214 15.4775 10.1979 15.2334 10.4419L10.8584 14.8169Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-refresh':
      svgContent = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 11.6236 19.4723 11.2538 19.4188 10.8923C19.3515 10.4382 19.6839 10 20.1429 10C20.5138 10 20.839 10.2562 20.8953 10.6228C20.9642 11.0718 21 11.5317 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C14.3051 3 16.4077 3.86656 18 5.29168V4.25C18 3.83579 18.3358 3.5 18.75 3.5C19.1642 3.5 19.5 3.83579 19.5 4.25V7.25C19.5 7.66421 19.1642 8 18.75 8H15.75C15.3358 8 15 7.66421 15 7.25C15 6.83579 15.3358 6.5 15.75 6.5H17.0991C15.7609 5.25883 13.9691 4.5 12 4.5Z"
            fill="#241C15"
          />
        </svg>
      );
      break;
    case 'icon-archive':
      svgContent = (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.77273 7.18182C6.54679 7.18182 6.36364 7.36497 6.36364 7.59091C6.36364 7.81684 6.54679 8 6.77273 8H9.22727C9.45321 8 9.63636 7.81684 9.63636 7.59091C9.63636 7.36497 9.45321 7.18182 9.22727 7.18182H6.77273ZM2 3.22727C2 2.54947 2.54947 2 3.22727 2H12.7727C13.4505 2 14 2.54947 14 3.22727V4.31818C14 4.74369 13.7835 5.11863 13.4545 5.33879V11.4091C13.4545 12.84 12.2946 14 10.8636 14H5.13636C3.70544 14 2.54545 12.84 2.54545 11.4091V5.33879C2.21655 5.11863 2 4.74369 2 4.31818V3.22727ZM3.36364 5.54545V11.4091C3.36364 12.3881 4.15731 13.1818 5.13636 13.1818H10.8636C11.8427 13.1818 12.6364 12.3881 12.6364 11.4091V5.54545H3.36364ZM3.22727 2.81818C3.00134 2.81818 2.81818 3.00134 2.81818 3.22727V4.31818C2.81818 4.54412 3.00134 4.72727 3.22727 4.72727H12.7727C12.9987 4.72727 13.1818 4.54412 13.1818 4.31818V3.22727C13.1818 3.00134 12.9987 2.81818 12.7727 2.81818H3.22727Z"
            fill="#353030"
          />
        </svg>
      );
      break;
    default:
  }
  return (
    <SvgIcon onClick={onClick} fontSize={size} sx={sx}>
      {svgContent}
    </SvgIcon>
  );
};

export default Icon;
