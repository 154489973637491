import React, { useState, useEffect } from 'react';
import GradientPicker from 'react-best-gradient-color-picker';

export function rgbToHex(color) {
  const parts = color?.match(
    /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d.]+)?\)$/,
  );
  if (!parts) {
    return color; // Return the original if not in the expected format
  }

  function hex(x) {
    return ('0' + parseInt(x).toString(16)).slice(-2);
  }

  return '#' + hex(parts[1]) + hex(parts[2]) + hex(parts[3]);
}

let zIndexCounter = 1000; // Global z-index counter

const ColorPicker = ({
  id,
  initialColor,
  onChange,
  isGradient = false,
  brandColors,
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState(initialColor || 'transparent');
  const [zIndex, setZIndex] = useState(zIndexCounter);

  useEffect(() => {
    setColor(initialColor);
  }, [initialColor]);

  const handleClick = () => {
    setZIndex(++zIndexCounter); // Increment global z-index and set it to this picker
    setShowColorPicker(!showColorPicker);

  };

  const handleClose = () => {
    setShowColorPicker(false);
    onChange(color);
  };

  const handleChange = (newColor) => {
    const hexColor = rgbToHex(newColor);
    setColor(hexColor);
    onChange(hexColor);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showColorPicker && event.target.closest('.color-picker') === null) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showColorPicker, color]);

  return (
    <div className="color-picker" style={{ zIndex: zIndex }}>
      <button
        className={`picker-button ${color === 'transparent' ? 'no-color' : ''}`}
        style={{
          background: color === 'transparent' ? 'none' : color,
        }}
        onClick={handleClick}
      ></button>
      <span>{color}</span>
      {showColorPicker && (
        <div className="color-palette" style={{ zIndex: zIndex }}>
          <GradientPicker
            value={color}
            onChange={handleChange}
            hideOpacity={true}
            hideAdvancedSliders={true}
            hideColorGuide={true}
            hideInputType={true}
            hideColorTypeBtns={isGradient}
          />
          <div className="palette" style={{ marginTop: '8px' }}>
            <span>Brand Colors</span>
            <div className="color-options">
              {brandColors.map((brandColor) => (
                <button
                  key={brandColor}
                  className="color-option"
                  style={{ background: brandColor }}
                  onClick={() => handleChange(brandColor)}
                ></button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
