import React, { useCallback, useEffect } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  ReactFlowProvider,
} from 'reactflow';
import dagre from 'dagre';
import { useSelector, useDispatch } from 'react-redux';
import { hideFlowAlert } from '../../store/store';
import { showFlowAlert } from '../../store/store';
import 'reactflow/dist/style.css';
import CustomCurvedEdge from './components/CurvedEdge';
import Node from './components/Node';
import CustomEdge from './components/CustomEdge';
import JumpEdge from './components/JumpEdge';
import End from './components/End';
import Message from '../../components/Message';
import { useForm } from 'react-hook-form';
import CustomDialog from '../../components/Dialog/Dialog';
import RadioButtonsGroup from '../../components/RadioGroup/Radio';
import CustomAutocomplete from '../../components/AutoComplete/AutoComplete';
import iconEmail from '../../assets/icons/icon-email.svg';
import iconTag from '../../assets/icons/icon-tag-automation.svg';
import CommonOverlay from '../../components/Overlay/Overlay';
import Header from '../../components/Header';
import { Stack, Typography } from '@mui/material';
import CardContainer from '../../components/CardContainer/CardContainer';
import appIcon from '../../assets/icons/app-icon.svg';
import { usePrebuiltLayouts } from '../../hooks/usePrebuiltLayouts';
import CreateEmailDialog from '../../pages/templates/PrebuiltTemplates/components/CreateEmailDialog';
import iconClose from '../../assets/icons/icon-cross.svg';
import { toggleAutomationOverlay } from '../../store/store';
const nodeTypes = {
  textUpdater: Node,
  endNode: End,
};

const edgeTypes = {
  'custom-edge': CustomEdge,
  curvedEdge: CustomCurvedEdge,
  jumpEdge: JumpEdge,
};

const initialEdges = [
  {
    id: '1-1.5',
    type: 'custom-edge',
    source: '1',
    target: '1.5',
    sourceHandle: 'bottom-handle',
    targetHandle: 'top-handle',
  },
];
const getNodeDimensions = (node) => {
  switch (node.type) {
    case 'textUpdater': // Replace with your actual node type
      return { width: 10, height: 140 };
    case 'endNode': // Replace with your actual node type
      return { width: 45, height: 25 };
    case 'nextStep': // Replace with your actual node type
      return { width: 240, height: 120 };
    default:
      return { width: 172, height: 250 }; // Default dimensions
  }
};
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes, edges) => {
  dagreGraph.setGraph({
    rankdir: 'TB', // Top to Bottom layout
    nodesep: 180, // Horizontal separation between nodes
    ranksep: 86, // Vertical separation between nodes
  });

  nodes.forEach((node) => {
    const { width, height } = getNodeDimensions(node);
    dagreGraph.setNode(node.id, { width, height });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    if (nodeWithPosition.data?.flag) {
      console.log('yes node');
      node.position = {
        x: nodeWithPosition.x + node.width / 2,
        y: nodeWithPosition.y - node.height / 2,
      };
    } else {
      node.position = {
        x: nodeWithPosition.x - node.width / 2,
        y: nodeWithPosition.y - node.height / 2,
      };
    }

    return node;
  });

  return { nodes: layoutedNodes, edges };
};
// Dagre layout function
// const nodeWidth = 172;
// const nodeHeight = 250;

export default function App() {
  const { register, handleSubmit, setValue } = useForm();

  const [selectedValue, setSelectedValue] = React.useState('Specific Tag');
  const values = ['Specific Tag', 'Any Tag'];
  const [autoCompleteValue, setAutoCompleteValue] = React.useState([]);
  const [selectedValue2, setSelectedValue2] = React.useState('Once');
  const values2 = ['Once', 'Multiple Times'];
  const [showOverlay, setshowOverlay] = React.useState(false);
  const [istemplate, setIsTemplate] = React.useState(true);
  const [isSavedTemplate, setIsSavedTemplate] = React.useState(false);
  const onSHowOverlay = () => {
    console.log('onSHowOverlay');
    setshowOverlay(true);
  };
  const initialNodes = [
    {
      id: '1',
      type: 'textUpdater',
      data: {
        id: '1',
        onSHowOverlay,
        perform: () => {
          setChangeEvent({
            title: `Tag is added`,
            open: true,
            onClose: closeChangeEvent,
            type: 'changeEvent',
            actions: [
              {
                label: 'Cancel',
                onClick: () => closeChangeEvent(),
                classes: 'btn-outline dark-border',
              },
              {
                label: 'Save',
                onClick: () => handleSubmit()(),
                classes: 'btn-primary',
              },
            ],
          });
        },
        header: 'Tag is added',
        type: 'event',
        headerIcon: iconTag,
        status: 'Any Tag',
        headerDescription: 'Has included',
        bodyContent: [
          { label: 'Active', data: 20 },
          { label: 'Active', data: 20 },
          { label: 'Active', data: 20 },
        ],
      },
      // draggable: false,
      position: { x: 250, y: 5 },
    },
    {
      id: '1.5',
      type: 'endNode',
      data: { label: 'End', onSHowOverlay },
      position: { x: 348, y: 200 },
      //draggable: false
    },
  ];
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);
  const [changeEvent, setChangeEvent] = React.useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const closeChangeEvent = () => {
    setChangeEvent((prevConfig) => ({ ...prevConfig, open: false }));
  };

  const alert = useSelector((state) => state.flowAlert);

  useEffect(() => {
    console.log(alert);
  }, [alert]);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log(edges);
  }, [edges]);

  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => {
        const updatedNodes = applyNodeChanges(changes, nds);
        const layoutedElements = getLayoutedElements(updatedNodes, edges);
        return layoutedElements.nodes;
      });
    },
    [setNodes, edges],
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges],
  );

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    setValue('radioOption', event.target.value);
  };
  const handleRadioChange2 = (event) => {
    setSelectedValue2(event.target.value);
    setValue('runContact', event.target.value);
  };

  const { fetchPrebuiltLayout } = usePrebuiltLayouts();

  useEffect(() => {
    fetchPrebuiltLayout();
  }, [fetchPrebuiltLayout]);

  const isCreateTemplateDialog = useSelector(
    (state) => state.isCreateTemplateDialog,
  );

  const prebuiltLayoutData = useSelector((state) => state.prebuiltLayoutData);
  const prebuiltCards = prebuiltLayoutData.map((template, index) => {
    return {
      name: template.name,
      content: `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Preview</title>
      <style>
      @media only screen and (max-width:768px){
        .container{
          float: left !important;
          width: 100% !important;
        }
      }
      </style>
    </head>
    <body>
      ${template.htmlContent}
    </body>
    </html>
  `,
      showPreviewButton: true,
      id: template.id,
    };
  });

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <button
        onClick={() => {
          setshowOverlay(true);
        }}
      >
        show
      </button>
      <CustomDialog
        {...changeEvent}
        content={
          changeEvent.type === 'changeEvent' ? (
            <>
              <form>
                <div
                  className="input-container"
                  style={{ marginBottom: '0px ', gap: '4px' }}
                >
                  <RadioButtonsGroup
                    values={values}
                    onChange={handleRadioChange}
                    checked={selectedValue}
                    direction="row"
                  />
                  <CustomAutocomplete
                    label="Tags"
                    placeholder="Search to select"
                    fetchUrl="/tag/search"
                    createUrl="/tag/"
                    onChange={setAutoCompleteValue}
                    autoCompleteValue={autoCompleteValue}
                  />
                </div>

                <div
                  className="input-container"
                  style={{ marginBottom: '0px' }}
                >
                  <label className="email-dialog-label">Runs of Contact</label>
                  <RadioButtonsGroup
                    values={values2}
                    onChange={handleRadioChange2}
                    checked={selectedValue2}
                    direction="row"
                  />
                </div>
              </form>
            </>
          ) : (
            changeEvent.content
          )
        }
      />
      {isCreateTemplateDialog ? <CreateEmailDialog /> : ''}

      {showOverlay && (
        <CommonOverlay
          open={true}
          onClose={() => {
            setshowOverlay(false);
          }}
        >
          <div className="template-layout-wrapper">
            <Header
              title={
                <Stack direction="row" gap={2} alignItems="center">
                  <img src={appIcon} alt="icon-app" />

                  <Typography
                    variant="h1"
                    sx={{
                      lineHeight: 1.2,
                      fontWeight: 600,
                      fontSize: '1.25rem',
                      fontFamily: 'Lato',
                    }}
                  >
                    | Choose a template for your email
                  </Typography>
                </Stack>
              }
              actions={[
                {
                  icon: iconClose,
                  onClick: () => {
                    setshowOverlay(false);
                  },
                  classes: 'btn common-btn',
                  isPrimary: false,
                },
              ]}
            />
            <div className="campaign-design">
              <div className="campaign-sidebar">
                <div
                  onClick={() => {
                    setIsTemplate(true);
                    setIsSavedTemplate(false);
                  }}
                  className={` ${istemplate ? 'active' : ''}`}
                >
                  AdFlipr Templates
                </div>
                <div
                  onClick={() => {
                    setIsSavedTemplate(true);
                    setIsTemplate(false);
                  }}
                  className={` ${isSavedTemplate ? 'active' : ''}`}
                >
                  Saved Templates
                </div>
              </div>
              <div className="main-content">
                <div className="heading">AdFlipr Templates</div>
                <div className="prebuilt-template-wrapper ">
                  <div className="prebuilt-template-card-container">
                    <CardContainer
                      cards={prebuiltCards}
                      isStartFromBlank={true}
                      setTemplateLayout={setshowOverlay}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonOverlay>
      )}
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          //onConnect={onConnect}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          minZoom={0.25} // Prevents zooming out too much
          maxZoom={1.5} // Prevents zooming in too much
          panOnScroll={true} // Enable panning when scrolling
          zoomOnScroll={false} // Disable zooming on scroll
          zoomOnDoubleClick={false} // Disable zooming on double click
          defaultViewport={{ x: 350, y: 25, zoom: 1 }} // Start the view at the top-left corner
        />
        <Controls />
        <MiniMap
          nodeColor={(node) => 'red'} // Set node color to red
          nodeBorderColor={(node) => 'red'} // Optional: Set node border color to red
        />
        {/* <Background variant="dots" gap={12} size={1} /> */}
      </ReactFlowProvider>
      <Message
        open={alert.show}
        type={alert.type}
        message={alert.message}
        onMessageClose={() => {
          dispatch(hideFlowAlert());
        }}
      />
    </div>
  );
}
