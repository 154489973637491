import React, { useEffect, useState } from 'react';
import Sender from '../campaigns/Components/Sender';
import Header from '../../components/Header/Header';
import DynamicTabs from '../../components/Tab/Tab';
import iconConnect from '../../assets/icons/icon-connect-website.svg';
import iconConnectBlack from '../../assets/icons/icon-connect-black.svg';
import Table from '../../components/Table';
import { Button, Typography } from '@mui/material';
import ResourceApi from '../../api/resourceapi';
import CustomDialog from '../../components/Dialog/Dialog';
import iconVideo from '../../assets/icons/icon-video.svg';
import iconHelp from '../../assets/icons/icon-help-white.svg';
import GeneralDrawer from '../../components/Drawer/Drawer';
import { useForm } from 'react-hook-form';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Status from '../../components/Status/Status';
import iconInfo from '../../assets/icons/icon-info-error.svg';
import iconDelete from '../../assets/icons/icon-delete.svg';
import Progress from '../../components/Progress/Progess';
import iconPRO from '../../assets/icons/PRO.svg';
import formatDate from '../../utils/date-utils';
const Settings = () => {
  const Email = () => {
    const [sender, setSender] = useState([]);
    const [addSender, setAddSender] = useState(false);

    const [isSenderLoading, setIsSenderLoading] = useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isDomain, setIsDomain] = useState(false); //modified
    const [domainDetails, setDomainDetails] = useState({});
    const [isDomainVerified, setIsDomainVerified] = useState(false);
    const [copied, setCopied] = useState(false);
    const [pageNumber, setPageNumber] = useState('1');
    const [isLoading, setIsLoading] = useState(true);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isloader, setIsloader] = useState(false);
    const [errorNotice, setErrorNotice] = useState(false);
    const {
      register,
      handleSubmit,
      setError,
      formState: { errors },
      watch,
    } = useForm();
    const [deleteDialog, setDeleteDialog] = useState({
      title: '',
      open: false,
      onClose: () => {},
      type: '',
      actions: [],
    });
    const [deleteDomain, setDeleteDomain] = useState({
      title: '',
      open: false,
      onClose: () => {},
      type: '',
      actions: [],
    });

    useEffect(() => {
      getDomainDetails();
    }, []);

    const CloseDeleteDomain = () => {
      setDeleteDomain((prevConfig) => ({ ...prevConfig, open: false }));
    };

    const CloseDeleteDialog = () => {
      setDeleteDialog((prevConfig) => ({ ...prevConfig, open: false }));
    };
    const deleteSender = (ids) => {
      ResourceApi.sender.deleteById(ids).then(() => {
        console.log('deleted Sender successfully');
        CloseDeleteDialog();
        getSenders();
      });
    };

    const getSenders = () => {
      ResourceApi.sender.get().then(({ data }) => {
        setIsSenderLoading(false);
        setSender(data);
      });
    };

    useEffect(() => {
      getSenders();
    }, []);
    const handleDrawerClose = () => {
      setIsDrawerOpen(false);
    };

    const onSubmit = (data) => {
      console.log('onsubmit');
      domainVerify(data);
    };

    const domainDelete = () => {
      //setIsloader(true);
      ResourceApi.domain.domainDelete(domainDetails.id).then(({ data }) => {
        console.log(data);
        //setIsButtonLoading(false);
        setIsloader(false);
        setIsDomain(false);
        setDomainDetails({});
        setIsDomainVerified(false);
        CloseDeleteDomain();
      });
    };

    const authenticateDomain = () => {
      console.log('authentication is called');
      ResourceApi.domain
        .domainAuthentication(domainDetails.id)
        .then(({ data }) => {
          console.log(data);
          if (data.status === 'PENDING') {
            setErrorNotice(true);
          } else {
            setIsDomainVerified(true);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    };

    const getDomainDetails = () => {
      ResourceApi.domain
        .get()
        .then(({ data }) => {
          setIsLoading(false);
          console.log(data);
          if (data) {
            console.log(data.domain);
            setIsDomain(true);
            setPageNumber('2');
            setDomainDetails(data);
            if (data.status === 'SUCCESS') {
              setIsDomainVerified(true);
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err.message);
        });
    };

    const domainVerify = (data) => {
      let domain;
      domain = { domain: data.name };
      ResourceApi.domain.create(domain).then((response) => {
        setIsDomain(true);
        setPageNumber('2');
        setDomainDetails(response.data);
        if (response.data.status === 'SUCCESS') {
          setIsDomainVerified(true);
        }
        //  setIsDomainConnected(true);
      });
    };

    let value = 'adflipr-code:97f23dc72af7faa368595101a844c832';

    return (
      <div className="main-content-container">
        {addSender && isDomainVerified && (
          <Sender setAddSender={setAddSender} />
        )}

        <CustomDialog
          {...deleteDomain}
          content={
            deleteDomain.type === 'deleteDomain' ? deleteDomain.content : ''
          }
        />
        <CustomDialog
          {...deleteDialog}
          content={
            deleteDialog.type === 'deleteSender' ? deleteDialog.content : ''
          }
        />
        <GeneralDrawer
          heading={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>Connect your domain</div>
              <div> {pageNumber}/2</div>
            </div>
          }
          FooterComponent={
            <>
              {!isDomainVerified && (
                <>
                  {!isDomain ? (
                    <button
                      form="domainForm"
                      className="btn btn-grey botton"
                      style={{ width: '100%' }}
                      type="submit"
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      className="btn btn-grey botton"
                      style={{ width: '100%' }}
                      onClick={() => {
                        authenticateDomain();
                      }}
                    >
                      Authenticate this domain
                    </button>
                  )}
                </>
              )}
            </>
          }
          width="25vw"
          isOpen={isDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        >
          <hr />

          <form
            onSubmit={handleSubmit(onSubmit)}
            id="domainForm"
            className="domain-box"
          >
            {!isDomain && (
              <>
                <div className="text">
                  Add your own branded sending domain to improve your email
                  deliverability.
                </div>
                <div className="input-container">
                  <div className="email-dialog-label">
                    Enter you domain name
                  </div>
                  <input
                    type="text"
                    {...register('name', { required: 'Name is required' })}
                    className="email-dialog-input"
                  ></input>
                  {errors.name && (
                    <div className="error-msg">{errors.name.message}</div>
                  )}
                  <div className="input-description">
                    E.g adflipr.com (Don't use www or https)
                  </div>
                </div>
                {/* <button
         className="btn btn-grey botton"
         style={{marginTop: '115%'}}
         type="submit"
        >
         Next
        </button> */}
              </>
            )}
            {isDomain && (
              <>
                <div className="domain-drawer">
                  <div className="name">
                    Authenticate | {domainDetails.domain}
                  </div>
                  <div className="headers">
                    Now, go to your email domain provider account and add your
                    DNS record(s). Read our detailed instructions on
                    <span>how to add your DNS record</span>
                  </div>
                  {errorNotice && (
                    <div className="error">
                      <div className="heading">
                        <img src={iconInfo} /> We couldn't authenticate the
                        domain
                      </div>
                      <ul style={{ listStyleType: 'disc' }}>
                        <li>
                          We could not detect all the required DNS records on
                          your domain configuration.
                        </li>
                        <li>
                          Either there is a typo error or it may take up to
                          24-48 hours for DNS changes to fully propagate.
                        </li>
                      </ul>
                    </div>
                  )}
                  <div className="module" style={{ fontWeight: '600' }}>
                    DNS records for domain authentication
                  </div>
                  <div className="module">
                    <div className="heading">
                      Adflipr Code
                      {isDomainVerified && (
                        <div className="status VERIFIED">Verified</div>
                      )}
                      {!isDomainVerified && (
                        <div className="status DRAFT">Pending</div>
                      )}
                    </div>
                    <div className="type">
                      <div>
                        <div className="heading">Type</div>
                        <div>TXT</div>
                      </div>
                      <div>
                        <div className="heading">Name</div>
                        <div>{domainDetails.domain}</div>
                      </div>
                    </div>
                    <div className="value">
                      <div className="heading">Value</div>
                      <div className="txt">{value}</div>
                      <div
                        onClick={() => {
                          navigator.clipboard.writeText(value).then(() => {
                            setCopied(true);
                            setTimeout(() => setCopied(false), 2000); // Reset copied status after 2 seconds
                          });
                        }}
                        className="copy"
                      >
                        {copied ? 'Copied' : 'Copy'}
                      </div>
                    </div>
                  </div>
                  <div className="module">
                    <div className="heading">
                      DMARC
                      {isDomainVerified && (
                        <div className="status VERIFIED">Verified</div>
                      )}
                      {!isDomainVerified && (
                        <div className="status DRAFT">Pending</div>
                      )}
                    </div>
                    <div className="type">
                      <div>
                        <div className="heading">Type</div>
                        <div>TXT</div>
                      </div>
                      <div>
                        <div className="heading">Name</div>
                        <div>{domainDetails.sesDto.dmarc.name}</div>
                      </div>
                    </div>
                    <div className="value">
                      <div className="heading">Value</div>
                      <div className="txt">
                        {domainDetails.sesDto.dmarc.value}
                      </div>
                      <div
                        onClick={() => {
                          navigator.clipboard
                            .writeText(domainDetails.sesDto.dmarc.value)
                            .then(() => {
                              setCopied(true);
                              setTimeout(() => setCopied(false), 2000); // Reset copied status after 2 seconds
                            });
                        }}
                        className="copy"
                      >
                        {copied ? 'Copied' : 'Copy'}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </form>
        </GeneralDrawer>
        <div className="setting-email">
          {isLoading && <Progress />}
          {!isLoading && (
            <>
              <div className="module">
                <div className="heading">Connect domain</div>
                <div className="description">
                  Add your own branded sending domain to improve your email
                  deliverability. Currently, you are using our email delivery
                  service (send@adflipr.com).
                </div>
              </div>
              {!isDomain && (
                <>
                  <div className="module">
                    <div className="domain-connect">
                      <div className="content">
                        <img
                          src={iconConnect}
                          alt="connect domain"
                          className="icon-connect"
                        />
                        <div className="content-body">
                          <div className="txt">Connect your domain</div>
                          <div>
                            Once you connect your domain, you can send emails to
                            your customers using your own domain.
                          </div>
                          <div>
                            <div className="connect-link">
                              <div className="link">
                                <img src={iconVideo} alt="video" />
                                <span> watch video</span>
                              </div>
                              <div className="link">
                                <img src={iconHelp} alt="video" />
                                <span>Learn more about sending domain</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ alignContent: 'center' }}>
                        <button
                          className="btn btn-connect"
                          onClick={() => {
                            setIsDrawerOpen(true);
                          }}
                        >
                          connect
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {isDomain && (
                <>
                  <div className="module">
                    <div className="domain-connect connected">
                      <div className="content">
                        <img src={iconConnectBlack} className="icon-connect" />
                        <div className="content-body">
                          <div className="status">
                            <div className="txt">{domainDetails.domain}</div>
                            {isDomainVerified && (
                              <div className="status VERIFIED">Verified</div>
                            )}
                            {!isDomainVerified && (
                              <div className="status DRAFT">
                                Authentication Pending
                              </div>
                            )}
                          </div>
                          <div
                            className="link"
                            onClick={() => {
                              //setIsloader(true);

                              setDeleteDomain({
                                open: true,
                                title: 'Delete Domain',
                                onClose: CloseDeleteDomain,
                                type: 'deleteDomain',
                                content: `Are you sure you want to delete this domain?`,
                                actions: [
                                  {
                                    label: 'Cancel',
                                    onClick: CloseDeleteDomain,
                                    classes: 'btn-outline dark-border',
                                  },
                                  {
                                    label: 'Delete',
                                    onClick: () => domainDelete(),
                                    classes: 'btn-primary',
                                    render: (
                                      <Button
                                        className="btn btn-primary"
                                        variant="contained"
                                        onClick={() => {
                                          setIsloader(true);
                                          domainDelete();
                                        }}
                                      >
                                        {isloader ? (
                                          <Progress button={true} />
                                        ) : (
                                          ''
                                        )}
                                        <span
                                          style={{
                                            visibility: isloader
                                              ? 'hidden'
                                              : 'visible',
                                          }}
                                        >
                                          Delete
                                        </span>
                                      </Button>
                                    ),
                                  },
                                ],
                              });
                            }}
                          >
                            <img
                              src={iconDelete}
                              style={{ width: '16px', height: '16px' }}
                              alt="delete"
                            />
                            <div className="txt">Delete</div>
                          </div>
                        </div>
                      </div>
                      {!isDomainVerified && (
                        <div style={{ alignContent: 'center' }}>
                          <button
                            className="btn btn-grey"
                            onClick={() => {
                              setIsDrawerOpen(true);
                              setIsDomain(true);
                            }}
                          >
                            Complete authentication
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="module">
                <div className="heading">Sender</div>
                <div className="description">
                  Add your own branded sending domain to improve your email
                  deliverability. Currently, you are using our email delivery
                  service (send@adflipr.com).
                </div>
              </div>
              <div className="module">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setAddSender(true);
                  }}
                >
                  Sender
                </button>
                {addSender && !isDomainVerified && (
                  <div className="input-container">
                    <div className=" error-msg">Domain is not verified yet</div>
                  </div>
                )}
              </div>
              <div className="module" style={{ width: '60%' }}>
                {sender.length > 0 && (
                  <Table
                    headers={[
                      {
                        numeric: false,
                        key: 'name',
                        disablePadding: false,
                        //onClick: row => navigate(`/contacts/tags/${row.id}`),
                        label: 'Name',
                        render: (row) => {
                          return (
                            <Typography sx={{ fontWeight: '400' }}>
                              {row.name}
                            </Typography>
                          );
                        },
                      },
                      {
                        numeric: false,
                        key: 'email',
                        disablePadding: false,
                        //onClick: row => navigate(`/contacts/tags/${row.id}`),
                        label: 'Email',
                        render: (row) => {
                          return (
                            <Typography sx={{ fontWeight: '400' }}>
                              {row.email}
                            </Typography>
                          );
                        },
                      },
                      {
                        numeric: false,
                        key: 'details',
                        disablePadding: false,
                        label: 'Details',
                        customClass: 'tableDetails',
                        render: (row) => (
                          <div className="status VERIFIED">verified</div>
                        ),
                      },
                    ]}
                    rows={sender}
                    isLoading={isSenderLoading}
                    actions={[
                      {
                        label: 'Delete',
                        onClick: (ids) => {
                          setDeleteDialog({
                            open: true,
                            title: 'Delete Sender',
                            onClose: CloseDeleteDialog,
                            type: 'deleteSender',
                            content: `Are you sure you want to delete  campaigns(s)?`,
                            actions: [
                              {
                                label: 'Cancel',
                                onClick: CloseDeleteDialog,
                                classes: 'btn-outline dark-border',
                              },
                              {
                                label: 'Delete',
                                onClick: () => deleteSender(ids),
                                classes: 'btn-primary',
                                isLoading: { isButtonLoading },
                              },
                            ],
                          });
                        },
                      },
                    ]}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const tabs = ['Email', 'Billing', 'Users'];
  const panels = [
    <Email />,
    <div className="main-content-container setting-email">
      <div className="module">
        <div className="heading">Plan Details</div>
        <div className="description">
          Find all the details about your current plan
        </div>
      </div>
      <div className="billing-upgrade">
        <div className="content">
          <div className="upgrade-heading">Unlock More with PRO!</div>
          <div className="upgrade-description">
            Get more features and power for your email marketing. Upgrade now
            and unlock the full potential of AdFlipr!
          </div>
        </div>
        <div className="upgrade-botton">
          <Button className="btn btn-upgrade">
            <img className="icon" src={iconPRO} alt="icon" />
            Upgrade to PRO
          </Button>
        </div>
      </div>
      <div className="billing-plan">
        <div className="element">
          <div className="element-label">Plan Type</div>
          <div className="element-content">Free</div>
        </div>
        <div className="element">
          <div className="element-label">Purchased on</div>
          <div className="element-content">20 May 2024</div>
        </div>
        <div className="element">
          <div className="element-label">Expires On</div>
          <div className="element-content">19 May 2025</div>
        </div>
        <div className="element">
          <div className="element-label">Contacts</div>
          <div className="element-content">1500</div>
        </div>
        <div className="element">
          <div className="element-label">Emails</div>
          <div className="element-content">15000 / Per Month</div>
        </div>
        <div className="element">
          <div className="element-label">Email Usage (This Month)</div>
          <div className="element-content">800 / 15000</div>
        </div>
      </div>
      <div className="module">
        <div className="heading">Invoices</div>
        <div className="description">
          Access your purchase invoices and keep track of your transaction
          history with ease.
        </div>
      </div>
      <div style={{ width: '70%' }}>
        <Table
          headers={[
            {
              numeric: false,
              key: 'plan',
              disablePadding: false,
              label: 'Plan',
              onClick: (row) => {},
              render: (row) => `${row.plan}`,
            },
            {
              numeric: false,
              key: 'purchased',
              disablePadding: false,
              label: 'Purchased On',
              render: (row) => {
                return (
                  <div>
                    {/* {formatDate(row.created, true)} */}
                    {row.purchased}
                  </div>
                );
              },
            },

            {
              numeric: false,
              key: 'total',
              disablePadding: false,
              label: 'Total',
            },
            {
              numeric: false,
              key: 'status',
              disablePadding: false,
              label: 'Status',
              render: (row) => {
                return (
                  <div className="billing-status">
                    <div className="paid-status">{row.status}</div>
                    <div className="download-invoice">Download Invoice</div>
                  </div>
                );
              },
            },
          ]}
          noCheckBox={true}
          rows={[
            {
              plan: 'Standard',
              purchased: '20 may 2023',
              total: '20$',
              status: 'Paid',
            },
          ]}
        />
      </div>
    </div>,
    <div className="main-content-container Tab-container">
      Content for Tab Four
    </div>,
  ];
  return (
    <>
      <Header title="Settings" actions={[]} />
      <DynamicTabs tabs={tabs} panels={panels} />
    </>
  );
};

export default Settings;
