import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const Example = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState(new Date());

  const handleDateChange = (date) => {
    setStartDate(date); // Update local state
    if (onDateChange) {
      onDateChange(date); // Call the parent function with the selected date
    }
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={handleDateChange} // Use the new handler function
    />
  );
};

export default Example;
