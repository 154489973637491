import React from 'react';
import TodoList from './components/TodoList';
import iconAddCircle from '../../assets/icons/icon-add-circle.svg';
import RecentCard from './components/RecentCard';
import ImageNoSearch from '../../assets/images/no-search-results.png';
import PerformanceMetrics from './components/PerformanceMetrics';
import AnalyticsCard from './components/AnalyticsCard';

const Home = () => {
  const addTags = ['Store', 'Time Period', 'Compare'];
  return (
    <div>
      <div className="header">
        <div className="header-left">
          <span className="template-name"> Home </span>
        </div>
        <div className="header-center"></div>

        <div className="header-right"></div>
      </div>
      <div className="home-container">
        <div className="todo-container">
          <TodoList />
        </div>
        <div className="home-details">
          <div className="add-tags">
            {addTags.map((tag, index) => (
              <div className="tag">
                <img src={iconAddCircle} alt="Add Filter" />
                <span>{tag}</span>
              </div>
            ))}
          </div>
          <div className="cards-container">
            <div className="left-cards-container">
              <PerformanceMetrics />
              <RecentCard
                title="Recent Campaigns"
                linkText="View all Campaigns"
                imageText="No campaigns created"
                description="Once your campaign is published, you can start receiving data."
                buttonText="Create Campaign"
                iconSrc={ImageNoSearch}
                containerHeight={402}
              />
              <RecentCard
                title="Recent Carts"
                linkText="View all Carts"
                imageText="No carts created"
                buttonText="Connect your store"
                description="Whenever a cart is abandoned by a customer, we will immediately track it and display the cart details for you."
                iconSrc={ImageNoSearch}
                containerHeight={402}
              />
              <RecentCard
                title="Recent Automations"
                linkText="View all Automations"
                imageText="No automations available"
                buttonText="Create Automation"
                description="Once your automation is published, you can start receiving data."
                iconSrc={ImageNoSearch}
                containerHeight={402}
              />
            </div>
            <div className="right-cards-container">
              <RecentCard
              title="Recent Activities"
              imageText="No recent acitvity"
              iconSrc={ImageNoSearch}
              containerHeight={618}/>
              <AnalyticsCard
              title="Campaign Sales"
              centralValue="0"
              centralLabel="June"
              lastMonth="0"
              today="0"/>
              <AnalyticsCard
              title="Cart Recovery"
              centralValue="0"
              centralLabel="June"
              lastMonth="0"
              today="0"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
