import ProductIcon from '../../../../../assets/icons/icon-product.svg';
import ResourceApi from '../../../../../api/resourceapi';
import { updateProductBlockLayout } from '../utils';

export const registerProductBlock = (editor, opts = {}) => {
  const {
    setProductsOverlay,
    setSelectedProductBlock,
  } = opts;

  // Common function to create a td with Add Product button
  const createProductCell = (width = '50%') => ({
    tagName: 'td',
    type: 'cell',
    droppable: false,
    selectable: false,
    hoverable: false,
    draggable: false,
    style: {
      width: width,
      height: '100%',
      'vertical-align': 'middle',
      'text-align': 'center',
      border: '1px dashed #82838e',
      'border-radius': '8px',
      padding: '50px 0',
      background: '#f6f6f6',
      'font-family': 'Lato, Arial',
    },
    components: [
      {
        tagName: 'button',
        type: 'button',
        content: 'Add Product',
        droppable: false,
        draggable: false,
        selectable: false,
        hoverable: false,
        editable: true,
        style: {
          padding: '10px 20px',
          'font-size': '14px',
          'font-weight': '600',
          'text-decoration': 'none',
          color: '#ffffff',
          'font-family': 'Lato, Arial',
          background: '#ff385c',
          'border-radius': '4px',
          border: 'none',
          cursor: 'pointer',
        },
        attributes: {
          'data-gjs-clickable': 'true',
          'data-gjs-propagate': ['click'],
        },
      },
    ],
  });

  editor.DomComponents.addType('Product', {
    model: {
      defaults: {
        tagName: 'span',
        classes: ['block-div'],
        attributes: {
          'data-gjs-type': 'Product',
          'data-view-type': 'column',
          'data-columns': '2',
          'data-rows': '1',
        },
        selectable: true,
        hoverable: true,
        style: {
          display: 'block !important',
          width: '100%',
          height: '100%',
        },

        components: [
          {
            tagName: 'table',
            type: 'table',
            style: {
              display: 'table',
              width: '100%',
              height: '100%',
              'border-spacing': '10px',
              'table-layout': 'fixed',
            },
            droppable: false,
            selectable: false,
            hoverable: false,
            draggable: false,
            components: [
              {
                tagName: 'tbody',
                type: 'tbody',
                droppable: false,
                selectable: false,
                hoverable: false,
                draggable: false,
                components: [
                  {
                    tagName: 'tr',
                    type: 'row',
                    droppable: false,
                    selectable: false,
                    hoverable: false,
                    draggable: false,
                    components: [createProductCell(), createProductCell()],
                  },
                ],
              },
            ],
          },
        ],
        droppable: false,
        stylable: true,
      },
      init() {
        this.on(
          'change:attributes:data-view-type change:attributes:data-columns change:attributes:data-rows',
          this.handleLayoutChange,
        );
        this.set({ style: this.defaults.style });
      },
      handleLayoutChange() {
        const viewType = this.getAttributes()['data-view-type'];
        const columns = parseInt(this.getAttributes()['data-columns'], 10);
        const rows = parseInt(this.getAttributes()['data-rows'], 10);
        updateProductBlockLayout(this, viewType, columns, rows);
      },
    },
    view: {
      events: {
        'click button': 'handleClick',
      },
      handleClick(e) {
        e.preventDefault();
        e.stopPropagation();

        // Get the clicked button element
        const clickedButton = e.target;

        // Find the parent td of the clicked button
        let tdComponent = this.findParentTd(clickedButton);

        if (tdComponent) {
          setSelectedProductBlock(tdComponent);

          setProductsOverlay(true);
        }
      },
      findParentTd(element) {
        // Traverse up the DOM to find the parent td
        while (element && element.tagName.toLowerCase() !== 'td') {
          element = element.parentElement;
        }

        if (element && element.tagName.toLowerCase() === 'td') {
          return this.model
            .findType('cell')
            .find((component) => component.view.el === element);
        }

        return null;
      },
    },
  });

  // Add the block for ProductBlock
  editor.BlockManager.add('productBlock', {
    label: `<div class="gjs-block-label-container" data-category="Block"><img src=${ProductIcon} alt="Product Icon" height="24px" width="24px"/></div>
    <div class="block-label-text">Product Block</div>`,
    content: { type: 'Product' },
    category: 'Blocks',
    draggable: 'td',
  });
};
