import CustomerAddressIcon from '../../../../../assets/icons/icon-order-listing.svg';

export const registerCustomerAddressBlock = (editor) => {
  editor.DomComponents.addType('CustomerAddress', {
    model: {
      defaults: {
        tagName: 'span',
        attributes: { 'data-gjs-type': 'CustomerAddress' },
        classes: ['block-div'],
        style: { 
          display: 'block !important', 
          width: '100%' 
        },
        components: [
          {
            tagName: 'div',
            classes: ['customer-address-container'],
            components: [
              {
                tagName: 'div',
                classes: ['address-columns'],
                components: [
                  {
                    tagName: 'div',
                    classes: ['address-column'],
                    components: [
                      {
                        tagName: 'div',
                        classes: ['address-type'],
                        content: 'Billing Address'
                      },
                      {
                        tagName: 'div',
                        classes: ['address-details'],
                        content: `John Doe<br>
                                  548 Market St 70640<br>
                                  San Francisco CA<br>
                                  United States<br>
                                  94104-5401<br>
                                  johndoe@gmail.com`
                      }
                    ]
                  },
                  {
                    tagName: 'div',
                    classes: ['address-column'],
                    components: [
                      {
                        tagName: 'div',
                        classes: ['address-type'],
                        content: 'Shipping Address'
                      },
                      {
                        tagName: 'div',
                        classes: ['address-details'],
                        content: `John Doe<br>
                                  548 Market St 70640<br>
                                  San Francisco CA<br>
                                  United States<br>
                                  94104-5401`
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ],
        styles: `
          .customer-address-container {
            width: 100%;
            padding: 24px;
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            font-family: Arial, sans-serif;
          }
          .address-columns {
            display: flex;
            justify-content: space-between;
          }
          .address-column {
            width: 48%;
          }
          .address-type {
            font-weight: 600;
            font-family: inherit;
            font-size: 18px;
            color: #ff5722;
            margin-bottom: 8px;
          }
          .address-details {
            font-weight: 400;
            font-family: inherit;
            font-size: 14px;
            color: #ff5722;
            line-height: 1.4;
          }
        `,
        droppable: false,
        selectable: true,
        hoverable: true,
        stylable: true,
      },
      init() {
        this.set({ style: this.get('style') });
      },
    }
  });

  editor.BlockManager.add('customerAddressBlock', {
    label: `
      <div class="gjs-block-label-container" data-category="Block">
        <img src=${CustomerAddressIcon} alt="Customer Address Icon" height="24px" width="24px"/>
      </div>
      <div class="block-label-text">Customer Address</div>
    `,
    content: { type: 'CustomerAddress' },
    category: 'Blocks',
    draggable: 'td',
  });
};