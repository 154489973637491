import React from 'react';
import { useState, useEffect, useRef } from 'react';
import DynamicTabs from '../../../../components/Tab/Tab';
import { Box, TextField, Button } from '@mui/material';
import noData from '../../../../assets/icons/No Data.svg';
import ResourceApi from '../../../../api/resourceapi';
import { auth } from '../../../../api/authapi';
import formatDate from '../../../../utils/date-utils';
import iconMore from '../../../../assets/icons/icon-more.svg';
import iconEdit from '../../../../assets/icons/icon-edit.svg';
import iconDelete from '../../../../assets/icons/icon-delete.svg';
import CustomDialog from '../../../../components/Dialog/Dialog';
import FormRenderer from '../../../../components/FormRenderer';
import { useForm } from 'react-hook-form';
import { Menu } from '@mui/material';
import Progress from '../../../../components/Progress/Progess';
const TabForm = ({ id }) => {
  const [inputText, setInputText] = useState('');
  const [isEmpty, setIsEmpty] = useState(true);
  const [error, setError] = useState('');
  const [note, setNote] = useState({});
  const [rowSelected, setRowSelected] = useState([]);
  const [anchorE1, setAnchorE1] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isfetching, setIsFetching] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onBlur' });
  const [editDialogConfig, setEditDialogConfig] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const [dialogConfig, setDialogConfig] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  useEffect(() => {
    onNoteFetch();
  }, []);

  const createNote = (event) => {
    event.preventDefault();

    setInputText(''); // Optionally clear the textarea after submission

    const apiPayload = {
      text: inputText,
      contactId: id,
    };
    setError('');
    setIsLoading(true);
    if (validateInput(inputText)) {
      ResourceApi.note
        .create(apiPayload)
        .then(() => {
          setIsLoading(false);
          onNoteFetch();
        })
        .catch((error) => {
          console.error(
            'An error occurred while fetching the data:',
            error.message,
          );
        });
    } else {
      console.log(error);
    }
  };

  const validateInput = (input) => {
    if (!input) {
      setError('Text cannot be empty');
      setIsLoading(false);
      return false;
    }
    if (!/^[a-zA-Z0-9\s,.!?]*$/.test(input)) {
      setIsLoading(false);
      setError('Text contains invalid characters');
      return false;
    }
    return true;
  };
  //function to call get notes api
  const onNoteFetch = async () => {
    const token = localStorage.getItem('loginToken'); // Replace with your actual token if needed
    setIsFetching(true);
    try {
      const response = await auth.getNote(
        { id },
        {
          Authorization: `${token}`,
        },
      );
      handleTagResponse(response.data);
      if (response.data.length > 0) {
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleTagResponse = (data) => {
    data.sort((tag1, tag2) => {
      const date1 = new Date(tag1.updated);
      const date2 = new Date(tag2.updated);
      return date2.getTime() - date1.getTime();
    });
    setNote(data);
  };

  const editNote = (data) => {
    setIsFetching(true);
    ResourceApi.note
      .update(rowSelected, data)
      .then(() => {
        setIsFetching(false);
        onNoteFetch();
      })
      .catch((error) => {
        console.error(
          'An error occurred while fetching the data:',
          error.message,
        );
      });
  };

  const deleteNote = () => {
    setIsFetching(true);
    ResourceApi.note
      .deleteById(rowSelected)
      .then(() => {
        setIsFetching(false);
        onNoteFetch();
      })
      .catch((error) => {
        console.error(
          'An error occurred while fetching the data:',
          error.message,
        );
      });
  };

  const NoteEditForm = () => {
    const currentNote = note.find((note) => note.id === rowSelected);
    setValue('text', currentNote.text);

    return (
      <FormRenderer
        register={register}
        watch={watch}
        errors={errors}
        fields={[
          {
            type: 'textarea',
            name: 'text',
            placeholder: 'type here...',
            label: 'Description',
            validations: { required: 'Description is required' },
          },
        ]}
      />
    );
  };

  const onEditNoteFormClose = () => {
    onNoteCloseDialog();
    reset();
  };
  const onNoteCloseDialog = () => {
    setEditDialogConfig((prevConfig) => ({ ...prevConfig, open: false }));
  };
  const onNoteDeleteCloseDialog = () => {
    setDialogConfig((prevConfig) => ({ ...prevConfig, open: false }));
  };
  const onEditNoteFormOpen = () => {
    setEditDialogConfig({
      title: 'Edit Note',
      open: true,
      onClose: onEditNoteFormClose,
      type: 'editNote',
      actions: [
        {
          label: 'Send',
          onClick: () => {
            handleSubmit(editNote)();
            onEditNoteFormClose();
          },
          classes: 'btn-primary',
        },
      ],
    });
  };

  const handleMoreClick = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorE1(null);
  };
  const [userData, setUserData] = useState('');
  const getUserDetails = (id) => {
    ResourceApi.contact.getById(id).then(({ data }) => {
      console.log('user data:');

      const userData = data.firstName + ' ' + data.lastName;
      setUserData(userData);
    });
  };

  const getNotes = () => {
    getUserDetails(note[0].contactId);
    return (
      <>
        <>
          {note.map((note) => {
            return (
              <div className="notes-container">
                <div className="task-text">
                  {note.text}
                  <img
                    src={iconMore}
                    onClick={(event) => {
                      handleMoreClick(event);
                      setRowSelected(note.id);
                    }}
                    alt="three dots"
                  />
                </div>
                <div className="task-meta">
                  <span>
                    {userData} - {formatDate(note.updated, true)}
                  </span>
                </div>
              </div>
            );
          })}
        </>
      </>
    );
  };
  return (
    <div className="main-content-container Tab-container">
      <TextField
        placeholder="Type here..."
        multiline
        rows={4}
        value={inputText}
        onChange={handleInputChange}
        InputLabelProps={{
          shrink: false,
        }}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'grey', // Default border color
            },
            '&:hover fieldset': {
              borderColor: 'grey', // Hover border color
            },
            '&.Mui-focused fieldset': {
              borderColor: 'grey !important', // Focus border color
            },
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 2,
          width: '100%',
        }}
      >
        <Button
          className="btn btn-primary"
          variant="contained"
          onClick={createNote}
        >
          {isLoading ? <Progress button={true} /> : ''}
          <span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
            Add Note
          </span>
        </Button>
      </Box>
      <div className="container">
        {isfetching ? <Progress /> : ''}
        {isEmpty ? (
          <img src={noData} alt="No data" className="noData" />
        ) : (
          <Box
            sx={{
              width: '100%',
              marginTop: '24px',
              fontFamily: 'Lato',
            }}
          >
            {getNotes()}
          </Box>
        )}
      </div>
      <Menu
        anchorEl={anchorE1}
        open={Boolean(anchorE1)}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className="Menu">
          <div
            className="Menu-Item"
            onClick={() => {
              setAnchorE1(null);
              onEditNoteFormOpen();
            }}
          >
            <img src={iconEdit} alt="edit icon" />
            Edit
          </div>

          <div
            className="Menu-Item"
            onClick={() => {
              setAnchorE1(null);
              setDialogConfig({
                open: true,
                title: 'Delete Note',
                onClose: onNoteDeleteCloseDialog,
                type: 'deleteNote',
                content: `Are you sure you want to this note?`,
                actions: [
                  {
                    label: 'Cancel',
                    onClick: onNoteDeleteCloseDialog,
                    classes: 'btn-outline dark-border',
                  },
                  {
                    label: 'Delete',
                    onClick: () => {
                      onNoteDeleteCloseDialog();
                      deleteNote();
                    },
                    classes: 'btn-primary',
                  },
                ],
              });
            }}
          >
            <img src={iconDelete} alt="delete icon" />
            Delete
          </div>
        </div>
      </Menu>

      <CustomDialog
        {...editDialogConfig}
        content={
          editDialogConfig.type === 'editNote' ? (
            <NoteEditForm />
          ) : (
            editDialogConfig.content
          )
        }
      />
      <CustomDialog
        {...dialogConfig}
        content={dialogConfig.type === 'deleteNote' ? dialogConfig.content : ''}
      />
    </div>
  );
};

const MyTab = ({ id }) => {
  const tabs = ['Activities', 'Email', 'Notes', 'Automations'];
  const panels = [
    <div className="Tab-container">Content for Tab One</div>,
    <div className="Tab-container">Content for Tab Two</div>,
    <TabForm id={id} />,
    <div className="Tab-container">Content for Tab Four</div>,
  ];

  return (
    <>
      <DynamicTabs tabs={tabs} panels={panels} />
    </>
  );
};

export default MyTab;
