import * as React from 'react';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import formatDate from '../../utils/date-utils';
export default function BasicDateTimePicker({onDateTimeChange}) {
 const [open, setOpen] = React.useState(false);
 const [selectedDate, setSelectedDate] = React.useState(null); // State to hold the selected date

 const handleOpen = () => {
  setOpen(true);
 };

 const handleClose = () => {
  setOpen(false);
 };

 // Handler for when the date/time changes
 const handleDateChange = newDate => {
  setSelectedDate(newDate);
  if (onDateTimeChange) {
   onDateTimeChange(newDate); // Call the passed function with the new date value
  }
 };

 return (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
   <DemoContainer components={['DateTimePicker']}>
    <DateTimePicker
     open={open}
     onOpen={handleOpen}
     onClose={handleClose}
     value={selectedDate}
     onChange={handleDateChange} // Update the state and invoke the passed function
     renderInput={params => (
      <TextField
       {...params}
       onClick={handleOpen} // Open the calendar when the input is clicked
       InputProps={{
        ...params.InputProps,
        endAdornment: (
         <InputAdornment position="end">
          <ArrowDropDownIcon />
         </InputAdornment>
        )
       }}
      />
     )}
    />
   </DemoContainer>
  </LocalizationProvider>
 );
}
