import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './Tab.css';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
        //sx={{ p: 3 }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function DynamicTabs({ tabs, panels }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="dynamic tabs example"
          sx={{
            color: '#241C15 !important',
            '& .MuiTabs-indicator': {
              backgroundColor: '#FF385C', // Change indicator color to red
            },
            '& .Mui-selected': {
              color: '#FF385C !important', // Change selected tab text color to red
            },
            padding: '4px',
            marginLeft: '8px',
            p: 0,
          }}
        >
          {tabs.map((tabLabel, index) => (
            <Tab
              key={index}
              label={tabLabel}
              sx={{
                fontFamily: 'Lato',
                fontWeight: '600',
                color: '#241C15',
                fontSize: '14px',
                textTransform: 'none',
              }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {panels.map((panelContent, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {panelContent}
        </CustomTabPanel>
      ))}
    </>
  );
}

DynamicTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  panels: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default DynamicTabs;
