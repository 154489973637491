import React, { useCallback, useEffect } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  ReactFlowProvider,
  // useReactFlow,
} from 'reactflow';
import dagre from 'dagre';
import { useSelector, useDispatch } from 'react-redux';
import { hideFlowAlert } from '../../store/store';
import { showFlowAlert } from '../../store/store';
import 'reactflow/dist/style.css';
import CustomCurvedEdge from './components/CurvedEdge';
import Node from './components/Node';
import CustomEdge from './components/CustomEdge';
import JumpEdge from './components/JumpEdge';
import End from './components/End';
import Message from '../../components/Message';
import { useForm } from 'react-hook-form';
import CustomDialog from '../../components/Dialog/Dialog';
import RadioButtonsGroup from '../../components/RadioGroup/Radio';
import CustomAutocomplete from '../../components/AutoComplete/AutoComplete';
import iconEmail from '../../assets/icons/icon-email.svg';
import iconTag from '../../assets/icons/icon-tag-automation.svg';
import CommonOverlay from '../../components/Overlay/Overlay';
import Header from '../../components/Header';
import { Stack, Typography } from '@mui/material';
import CardContainer from '../../components/CardContainer/CardContainer';
import appIcon from '../../assets/icons/app-icon.svg';
import { usePrebuiltLayouts } from '../../hooks/usePrebuiltLayouts';
import CreateEmailDialog from '../../pages/templates/PrebuiltTemplates/components/CreateEmailDialog';
import iconClose from '../../assets/icons/icon-cross.svg';
import { toggleAutomationOverlay } from '../../store/store';
const nodeTypes = {
  textUpdater: Node,
  endNode: End,
};

const edgeTypes = {
  'custom-edge': CustomEdge,
  curvedEdge: CustomCurvedEdge,
  jumpEdge: JumpEdge,
};

const initialEdges = [
  {
    id: '1-1.5',
    type: 'custom-edge',
    source: '1',
    target: '1.5',
    sourceHandle: 'bottom-handle',
    targetHandle: 'top-handle',
  },
];

const nodeWidth = 180;
const nodeHeight = 40;
const horizontalSpacing = 300;
const verticalSpacing = 100;
// const getLayoutedElements = (nodes, edges) => {
//   const layoutNodes = [...nodes];
//   const nodeMap = new Map(layoutNodes.map((node) => [node.id, node]));
//   const childrenMap = new Map();
//   const parentsMap = new Map();

//   console.log(nodes, edges);
//   edges.forEach((edge) => {
//     if (!childrenMap.has(edge.source)) {
//       childrenMap.set(edge.source, []);
//     }
//     childrenMap.get(edge.source).push(edge.target);

//     if (!parentsMap.has(edge.target)) {
//       parentsMap.set(edge.target, []);
//     }
//     parentsMap.get(edge.target).push(edge.source);
//   });

//   const positionNodes = (nodeId, x = 0, y = 0) => {
//     const node = nodeMap.get(nodeId);

//     if (!node) {
//       console.error(`Node with ID ${nodeId} not found.`);
//       return;
//     }

//     node.position = { x, y };

//     const children = childrenMap.get(nodeId) || [];
//     if (children.length === 0) return;

//     children.forEach((childId) => {
//       const childParents = parentsMap.get(childId) || [];

//       if (childParents.length === 2) {
//         const parent1 = nodeMap.get(childParents[0]);
//         const parent2 = nodeMap.get(childParents[1]);

//         if (parent1 && parent2) {
//           // Calculate the midpoint between the two parent nodes
//           const midX = (parent1.position.x + parent2.position.x) / 2;
//           const maxY = Math.max(parent1.position.y, parent2.position.y);
//           positionNodes(
//             childId,
//             midX,
//             maxY + Math.max(parent1.height, parent2.height) + verticalSpacing,
//           );
//         }
//       } else if (children.length === 1) {
//         const parent = nodeMap.get(nodeId);
//         const childNode = nodeMap.get(childId);

//         if (!parent || !childNode) {
//           console.error(`Parent or child node with ID ${childId} not found.`);
//           return;
//         }

//         // Center the child node below its parent
//         const childX = x + (parent.width - childNode.width) / 2;
//         positionNodes(childId, childX, y + parent.height + verticalSpacing);
//       } else {
//         // Multiple children
//         const totalWidth =
//           children.length * nodeMap.get(children[0]).width +
//           (children.length - 1) * horizontalSpacing;
//         let startX = x + (node.width - totalWidth) / 2;

//         children.forEach((childId, index) => {
//           const childNode = nodeMap.get(childId);
//           const childX = startX + index * (childNode.width + horizontalSpacing);
//           positionNodes(childId, childX, y + node.height + verticalSpacing);
//         });
//       }
//     });
//   };

//   // Start positioning from the root node (assuming '1' is the root)
//   positionNodes('1');

//   return { nodes: layoutNodes, edges };
// };

const getLayoutedElements = (nodes, edges) => {
  const layoutNodes = [...nodes];
  const nodeMap = new Map(layoutNodes.map((node) => [node.id, node]));
  const childrenMap = new Map();
  const parentsMap = new Map();

  console.log(nodes, edges);
  edges.forEach((edge) => {
    if (!childrenMap.has(edge.source)) {
      childrenMap.set(edge.source, []);
    }
    childrenMap.get(edge.source).push(edge.target);

    if (!parentsMap.has(edge.target)) {
      parentsMap.set(edge.target, []);
    }
    parentsMap.get(edge.target).push(edge.source);
  });

  const horizontalSpacing = 100; // Space between siblings
  const verticalSpacing = 150; // Space between parent and child

  const calculateSubtreeWidth = (nodeId) => {
    const node = nodeMap.get(nodeId);
    const children = childrenMap.get(nodeId) || [];

    if (!node || !children) return;
    if (children.length === 0) {
      return node.width || 150; // Default width if not specified
    }

    const childrenWidths = children.map(calculateSubtreeWidth);
    return Math.max(
      node.width || 150,
      childrenWidths.reduce((sum, width) => sum + width, 0) +
        (children.length - 1) * horizontalSpacing,
    );
  };

  const positionNodes = (nodeId, x = 0, y = 0) => {
    const node = nodeMap.get(nodeId);

    if (!node) {
      console.error(`Node with ID ${nodeId} not found.`);
      return { width: 0, height: 0 };
    }

    const children = childrenMap.get(nodeId) || [];

    if (children.length === 0) {
      // Leaf node
      node.position = { x, y };
      return { width: node.width || 150, height: node.height || 50 };
    }

    let childrenTotalWidth = 0;
    let maxChildHeight = 0;
    const childrenInfo = children.map((childId) => {
      const childWidth = calculateSubtreeWidth(childId);
      const info = positionNodes(
        childId,
        x + childrenTotalWidth,
        y + node.height + verticalSpacing,
      );
      childrenTotalWidth += childWidth + horizontalSpacing;
      maxChildHeight = Math.max(maxChildHeight, info.height);
      return info;
    });

    // Remove last added horizontal spacing
    childrenTotalWidth -= horizontalSpacing;

    // Center the current node above its children
    const nodeX = x + (childrenTotalWidth - (node.width || 150)) / 2;
    node.position = { x: nodeX, y };

    return {
      width: Math.max(childrenTotalWidth, node.width || 150),
      height: node.height + verticalSpacing + maxChildHeight,
    };
  };

  const adjustMultiParentChildren = () => {
    layoutNodes.forEach((node) => {
      const parents = parentsMap.get(node.id) || [];
      if (parents.length === 2) {
        const [parent1, parent2] = parents
          .map((id) => nodeMap.get(id))
          .sort((a, b) => a.position.x - b.position.x);
        const leftParent = parent1;
        const rightParent = parent2;

        if (!leftParent || !rightParent) return;
        // Calculate the new position for the child node
        const previousPosition = { ...node.position }; // Keep track of old position
        const newX = (leftParent.position.x + rightParent.position.x) / 2;
        const newY =
          Math.max(leftParent.position.y, rightParent.position.y) +
          Math.max(leftParent.height, rightParent.height) +
          verticalSpacing;

        // Update the child node's position
        node.position = { x: newX, y: newY };

        // Recursively update positions of all descendants
        const updateDescendants = (nodeId, dx, dy) => {
          const children = childrenMap.get(nodeId) || [];
          children.forEach((childId) => {
            const childNode = nodeMap.get(childId);
            if (!childNode) return;
            childNode.position.x += dx;
            childNode.position.y += dy;
            updateDescendants(childId, dx, dy);
          });
        };

        const dx = newX - previousPosition.x;
        const dy = newY - previousPosition.y;

        // Handle descendant adjustments for left- and right-parent insertions
        updateDescendants(node.id, dx, dy);
      }
    });
  };

  // Find root nodes (nodes with no parents)
  const rootNodes = layoutNodes.filter(
    (node) => !parentsMap.has(node.id) || parentsMap.get(node.id).length === 0,
  );

  // Position each tree starting from root nodes
  let treeStartX = 0;
  rootNodes.forEach((rootNode) => {
    const { width } = positionNodes(rootNode.id, treeStartX, 0);
    treeStartX += width + horizontalSpacing * 2; // Extra spacing between trees
  });

  // Adjust positions of nodes with multiple parents
  adjustMultiParentChildren();

  return { nodes: layoutNodes, edges };
};

export default function NodeFlow({ data }) {
  const { register, handleSubmit, setValue } = useForm();

  const [selectedValue, setSelectedValue] = React.useState('Specific Tag');
  const values = ['Specific Tag', 'Any Tag'];
  const [autoCompleteValue, setAutoCompleteValue] = React.useState([]);
  const [selectedValue2, setSelectedValue2] = React.useState('Once');
  const values2 = ['Once', 'Multiple Times'];
  const [showOverlay, setshowOverlay] = React.useState(false);
  const [istemplate, setIsTemplate] = React.useState(true);
  const [isSavedTemplate, setIsSavedTemplate] = React.useState(false);
  //const reactFlowInstance = useReactFlow();
  const onSHowOverlay = () => {
    console.log('onSHowOverlay');
    setshowOverlay(true);
  };

  // const initialNodes = data.map((item, index) => ({
  //   id: item.id, // Dynamically assign ID
  //   type: item.type,
  //   data: {
  //     id: item.id,
  //     onSHowOverlay, // Pass onSHowOverlay function
  //     perform: () => {
  //       setChangeEvent({
  //         title: item.title, // Use title from the data prop
  //         open: true,
  //         onClose: closeChangeEvent,
  //         type: 'changeEvent',
  //         actions: [
  //           {
  //             label: 'Cancel',
  //             onClick: () => closeChangeEvent(),
  //             classes: 'btn-outline dark-border',
  //           },
  //           {
  //             label: 'Save',
  //             onClick: () => handleSubmit()(),
  //             classes: 'btn-primary',
  //           },
  //         ],
  //       });
  //     },
  //     header: item.title,
  //     type: item.event,
  //     label: item?.label,
  //     headerIcon: iconTag, // Use the iconTag passed in props
  //     status: 'Any tag', // Use status from the data prop
  //     headerDescription: item.headerDescription, // Use headerDescription from the data prop
  //     bodyContent: item.type !== 'event' && [
  //       { label: 'Active', data: 20 },
  //       { label: 'Active', data: 20 },
  //       { label: 'Active', data: 20 },
  //     ],
  //   },
  //   draggable: false,
  //   position: { x: 250, y: 5 }, // Default position
  // }));

  const initialNodes = [
    {
      id: '1',
      type: 'textUpdater',
      data: {
        id: '1',
        onSHowOverlay,
        perform: () => {
          setChangeEvent({
            title: data.title,
            open: true,
            onClose: closeChangeEvent,
            type: 'changeEvent',
            actions: [
              {
                label: 'Cancel',
                onClick: () => closeChangeEvent(),
                classes: 'btn-outline dark-border',
              },
              {
                label: 'Save',
                onClick: () => handleSubmit()(),
                classes: 'btn-primary',
              },
            ],
          });
        },
        header: data.title,
        type: 'event',
        headerIcon: iconTag,
        status: 'Any Tag',
        headerDescription: 'has included',
        bodyContent: [
          { label: 'Active', data: 20 },
          { label: 'Active', data: 20 },
          { label: 'Active', data: 20 },
        ],
      },
      draggable: false,
      position: { x: 250, y: 5 },
    },
    {
      id: '1.5',
      type: 'endNode',
      data: { label: 'End', onSHowOverlay },
      position: { x: 348, y: 200 },
      draggable: false,
    },
  ];
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);
  const reactFlowWrapper = React.useRef(null); // Create a ref for the ReactFlow instance
  const [reactFlowInstance, setReactFlowInstance] = React.useState(null); // Store the instance
  const [changeEvent, setChangeEvent] = React.useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const closeChangeEvent = () => {
    setChangeEvent((prevConfig) => ({ ...prevConfig, open: false }));
  };

  const alert = useSelector((state) => state.flowAlert);

  useEffect(() => {
    console.log(alert);
  }, [alert]);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log(edges);
    console.log(nodes);
  }, [edges]);

  const handleNodeClickInMinimap = useCallback(
    (event, node) => {
      if (reactFlowInstance && reactFlowWrapper.current) {
        const wrapperBounds = reactFlowWrapper.current.getBoundingClientRect();

        const nodeWidth = node.width; // Approximate node width (adjust as necessary)
        const nodeHeight = node.height; // Approximate node height (adjust as necessary)

        // Calculate the correct center by considering the node's position and wrapper dimensions
        const centerX = node.position.x + nodeWidth / 2;
        const centerY = node.position.y + nodeHeight;

        // Set the view to the center position of the node
        reactFlowInstance.setCenter(centerX, centerY, {
          zoom: 1, // optional zoom level, adjust if necessary
          duration: 800, // optional animation duration
        });
      }
    },
    [reactFlowInstance],
  );

  const onInit = (instance) => {
    setReactFlowInstance(instance); // Save the instance on initialization
  };

  const onNodeDelete = (nodeId) => {
    console.log('nodeDeleted');
    setEdges((eds) =>
      eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId),
    );
  };

  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => {
        changes.forEach((change) => {
          console.log(change.type);
        });
        const updatedNodes = applyNodeChanges(changes, nds);
        const layoutedElements = getLayoutedElements(updatedNodes, edges);
        return layoutedElements.nodes;
      });
    },
    [setNodes, edges],
  );
  const onEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges],
  );

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    setValue('radioOption', event.target.value);
  };
  const handleRadioChange2 = (event) => {
    setSelectedValue2(event.target.value);
    setValue('runContact', event.target.value);
  };

  const { fetchPrebuiltLayout } = usePrebuiltLayouts();

  useEffect(() => {
    fetchPrebuiltLayout();
  }, [fetchPrebuiltLayout]);

  const isCreateTemplateDialog = useSelector(
    (state) => state.isCreateTemplateDialog,
  );

  const prebuiltLayoutData = useSelector((state) => state.prebuiltLayoutData);
  const prebuiltCards = prebuiltLayoutData.map((template, index) => {
    return {
      name: template.name,
      content: `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Preview</title>
      <style>
      </style>
    </head>
    <body>
      ${template.htmlContent}
    </body>
    </html>
  `,
      showPreviewButton: true,
      id: template.id,
    };
  });

  return (
    <div style={{ width: '100vw', height: '100vh' }} ref={reactFlowWrapper}>
      <CustomDialog
        {...changeEvent}
        content={
          changeEvent.type === 'changeEvent' ? (
            <>
              <form>
                <div
                  className="input-container"
                  style={{ marginBottom: '0px ', gap: '4px' }}
                >
                  <RadioButtonsGroup
                    values={values}
                    onChange={handleRadioChange}
                    checked={selectedValue}
                    direction="row"
                  />
                  <CustomAutocomplete
                    label="Tags"
                    placeholder="Search to select"
                    fetchUrl="/tag/search"
                    createUrl="/tag/"
                    onChange={setAutoCompleteValue}
                    autoCompleteValue={autoCompleteValue}
                  />
                </div>

                <div
                  className="input-container"
                  style={{ marginBottom: '0px' }}
                >
                  <label className="email-dialog-label">Runs of Contact</label>
                  <RadioButtonsGroup
                    values={values2}
                    onChange={handleRadioChange2}
                    checked={selectedValue2}
                    direction="row"
                  />
                </div>
              </form>
            </>
          ) : (
            changeEvent.content
          )
        }
      />
      {isCreateTemplateDialog ? <CreateEmailDialog /> : ''}

      {showOverlay && (
        <CommonOverlay
          open={true}
          onClose={() => {
            setshowOverlay(false);
          }}
        >
          <div className="template-layout-wrapper">
            <Header
              title={
                <Stack direction="row" gap={2} alignItems="center">
                  <img src={appIcon} alt="icon-app" />

                  <Typography
                    variant="h1"
                    sx={{
                      lineHeight: 1.2,
                      fontWeight: 600,
                      fontSize: '1.25rem',
                      fontFamily: 'Lato',
                    }}
                  >
                    | Choose a template for your email
                  </Typography>
                </Stack>
              }
              actions={[
                {
                  icon: iconClose,
                  onClick: () => {
                    setshowOverlay(false);
                  },
                  classes: 'btn common-btn',
                  isPrimary: false,
                },
              ]}
            />
            <div className="campaign-design">
              <div className="campaign-sidebar">
                <div
                  onClick={() => {
                    setIsTemplate(true);
                    setIsSavedTemplate(false);
                  }}
                  className={` ${istemplate ? 'active' : ''}`}
                >
                  AdFlipr Templates
                </div>
                <div
                  onClick={() => {
                    setIsSavedTemplate(true);
                    setIsTemplate(false);
                  }}
                  className={` ${isSavedTemplate ? 'active' : ''}`}
                >
                  Saved Templates
                </div>
              </div>
              <div className="main-content">
                <div className="heading">AdFlipr Templates</div>
                <div className="prebuilt-template-wrapper ">
                  <div className="prebuilt-template-card-container">
                    <CardContainer
                      cards={prebuiltCards}
                      isStartFromBlank={true}
                      setTemplateLayout={setshowOverlay}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonOverlay>
      )}
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onNodeDelete={onNodeDelete}
          onEdgesChange={onEdgesChange}
          //onConnect={onConnect}
          onInit={onInit}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          minZoom={0.25} // Prevents zooming out too much
          maxZoom={1.5} // Prevents zooming in too much
          panOnScroll={true} // Enable panning when scrolling
          zoomOnScroll={false} // Disable zooming on scroll
          zoomOnDoubleClick={false} // Disable zooming on double click
          defaultViewport={{ x: 350, y: 25, zoom: 1 }} // Start the view at the top-left corner
        />
        <Controls />
        <MiniMap
          nodeColor={(node) => 'red'} // Set node color to red
          nodeBorderColor={(node) => 'red'} // Optional: Set node border color to red
          onNodeClick={handleNodeClickInMinimap} // handle click on node in minimap
        />
        {/* <Background variant="dots" gap={12} size={1} /> */}
      </ReactFlowProvider>
      <Message
        open={alert.show}
        type={alert.type}
        message={alert.message}
        onMessageClose={() => {
          dispatch(hideFlowAlert());
        }}
      />
    </div>
  );
}
