export const defaultRTEConfig = {
    base: {
      bold: true,
      italic: true,
      underline: true,
      strikethrough: true,
      link: false,
      wrapforstyle: false,
    },
    fonts: {
      fontSize: true,
      fontColor: false,
      hilite: false,
    },
    format: {
      heading1: false,
      heading2: false,
      heading3: false,
      heading4: false,
      heading5: false,
      heading6: false,
      paragraph: false,
      quote: false,
      clearFormatting: false,
    },
    subscriptSuperscript: true,
    indentOutdent: false,
    list: true,
    align: false,
    actions: false,
    undoredo: false,
    extra: false,
    darkColorPicker: true,
    maxWidth: '1500px',
  };

 export const productTextConfig = {
    base: {
      bold: true,
      italic: true,
      underline: false,
      strikethrough: false,
      link: false,
      wrapforstyle: false,
    },
    fonts: {
      fontSize: true,
      fontColor: true,
      hilite: false,
    },
    format: {
      heading1: false,
      heading2: false,
      heading3: false,
      heading4: false,
      heading5: false,
      heading6: false,
      paragraph: false,
      quote: false,
      clearFormatting: false,
    },
    subscriptSuperscript: false,
    indentOutdent: false,
    list: false,
    align: false,
    undoredo: false,
    extra: false,
    darkColorPicker: false,
    maxWidth: '1500px',
  };

 export const productButtonConfig = {
    base: {
      bold: true,
      italic: true,
      underline: false,
      strikethrough: false,
      link: true,
      wrapforstyle: false,
    },
    fonts: {
      fontSize: true,
      fontColor: true,
      hilite: false,
    },
    format: {
      heading1: false,
      heading2: false,
      heading3: false,
      heading4: false,
      heading5: false,
      heading6: false,
      paragraph: false,
      quote: false,
      clearFormatting: false,
    },
    subscriptSuperscript: false,
    indentOutdent: false,
    list: false,
    align: false,
    undoredo: false,
    extra: true,
    darkColorPicker: false,
    maxWidth: '1500px',
  };