import React, { useEffect, useRef, useState } from 'react';
import Card from '@mui/material/Card';
import { Handle } from 'reactflow';
import iconDoThis from '../../../assets/icons/icon-dothis.svg';
import { useReactFlow } from 'reactflow';
const Node = ({ id, data }) => {
  const { setNodes, getNodes } = useReactFlow();
  const { setEdges, getEdges } = useReactFlow();
  const nodes = getNodes();
  const nodeRef = useRef(null);
  const [nodeDimensions, setNodeDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const updateNodeSize = () => {
      if (nodeRef.current) {
        const { width, height } = nodeRef.current.getBoundingClientRect();
        setNodeDimensions({ width, height });

        setNodes((nds) =>
          nds.map((node) => {
            if (node.id === id) {
              // Update the node with its dimensions
              return { ...node, width, height };
            }
            return node;
          }),
        );
      }
    };

    // Initial measurement
    updateNodeSize();

    // Set up a ResizeObserver to detect size changes
    const resizeObserver = new ResizeObserver(updateNodeSize);
    if (nodeRef.current) {
      resizeObserver.observe(nodeRef.current);
    }

    return () => {
      if (nodeRef.current) {
        resizeObserver.unobserve(nodeRef.current);
      }
    };
  }, [id, setNodes, data]); // Add data to dependencies to recalculate on content change

  const currentNode = nodes.filter((node) => node.id === id);
  console.log(currentNode);
  return (
    <div ref={nodeRef}>
      <Card
        sx={{
          boxShadow: 'none',
        }}
      >
        {data?.type === 'dot' ? (
          <div style={{ border: '1px solid black' }}></div>
        ) : (
          <div
            style={{
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
              padding: '4px 12px 4px 12px',
              borderRadius: '8px',
            }}
            className={
              data?.type === 'conditional' || data?.type === 'splitPath'
                ? 'status SUCCESS'
                : 'status endNode'
            }
          >
            <div>{data.label}</div>
          </div>
        )}

        {/* <Handle
     type="source"
     position="bottom"
     id="source-handle"
     style={{background: '#555'}}
    /> */}
        <Handle
          type="target"
          position="top"
          id="target-handle"
          style={{ background: '#555' }}
        />
        {(data?.type === 'conditional' || data?.type === 'splitPath') && (
          <Handle
            type="source"
            position="bottom"
            id="source-handle"
            style={{ background: '#555' }}
          />
        )}
        {data?.type === 'dot' && (
          <Handle type="source" position="bottom" id="source-handle" />
        )}
      </Card>
    </div>
  );
};

export default Node;
