import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import CustomChip from '../../../../components/CustomChip';
import Icon from '../../../../components/Icon';
import ResourceApi from '../../../../api/resourceapi';
import styles from './ContactDetails.module.scss';
import Header from '../../../../components/Header';
import Progress from '../../../../components/Progress/Progess';
import FormRenderer from '../../../../components/FormRenderer';
import CustomMenu from '../../../../components/Menu/Menu';
import MyTab from './MyTab';
import CustomDialog from '../../../../components/Dialog';
import DatePickerCalendar from '../../../../components/Calendar/DatePicker';
import { TextField } from '@mui/material';
import iconArrow from '../../../../assets/icons/icon-arrow.svg';
import CustomAutocomplete from '../../../../components/AutoComplete/AutoComplete';
import CountryRegionSelector from '../../../../components/CountrySelector/CountrySelector';
import TimezoneSelector from '../../../../components/Timezone/TimeZone';
import Example from '../../../../components/Calendar/DateCalendar';
import CustomizedAutocomplete from '../../../../components/AutoComplete/CustomizedAutoComplete';
import CustomCheckBox from '../../../../components/CheckBox/CheckBox';
import RadioButtonsGroup from '../../../../components/RadioGroup/Radio';

const fields = {
  firstName: {
    type: 'text',
    name: 'firstName',
    placeholder: 'Type here',
    label: 'First name',
    validations: { required: 'First name is required' },
  },
  lastName: {
    type: 'text',
    name: 'lastName',
    placeholder: 'Type here',
    label: 'Last name',
    validations: {
      required: 'Last name is required',
    },
  },
  email: {
    type: 'text',
    name: 'email',
    placeholder: 'Type here',
    label: 'Email',
    validations: {
      required: 'Email is required',
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: 'Invalid email address',
      },
    },
  },
  phone: {
    type: 'text',
    name: 'phone',
    placeholder: 'Type here',
    label: 'Phone',
    validations: {
      pattern: {
        value: /^\+\d{1,2} \(\d{3}\) \d{3}-\d{4}$/,
        message: 'Phone number should be in correct format',
      },
    },
  },
  birthDate: {
    type: 'text',
    name: 'birthDate',
    placeholder: 'Type here',
    label: 'Date of Birth',
    validations: {
      required: 'Last name is required',
    },
  },
  address: {
    type: 'text',
    name: 'address',
    placeholder: 'Type here',
    label: 'Address',
    validations: { required: 'address is required' },
  },
  timezone: {
    type: 'text',
    name: 'timeZone',
    placeholder: 'Type here',
    label: 'Timezone',
    validations: {
      required: 'Timezone is required',
    },
  },
  gender: {
    type: 'text',
    name: 'gender',
    placeholder: 'Type here',
    label: 'Gender',
    validations: {
      required: 'Gender is required',
    },
  },
  company: {
    type: 'text',
    name: 'company',
    placeholder: 'Type here',
    label: 'Company',
    validations: {
      required: 'Company is required',
    },
  },
  tagResources: {
    type: 'text',
    name: 'tagResources',
    placeholder: 'Type here',
    label: 'Tag',
    validations: {
      required: 'Tag is required',
    },
  },
  listResources: {
    type: 'text',
    name: 'listResources',
    placeholder: 'Type here',
    label: 'Tag',
    validations: {
      required: 'Tag is required',
    },
  },
};

const contactStatusOptions = [
  {
    icon: 'icon-tick',
    label: 'Subscribed',
    value: 'SUBSCRIBE',
  },
  {
    icon: 'icon-unsubscribe',
    label: 'Unsubscribed',
    value: 'UNSUBSCRIBED',
  },
  {
    icon: 'icon-blocked',
    label: 'Blocked',
    value: 'BLOCKED',
  },
  {
    icon: 'icon-bounced',
    label: 'Bounced',
    value: 'BOUNCE',
  },
  {
    icon: 'icon-bounced',
    label: 'Marked as Spam',
    value: 'MARKED_AS_SPAMMED',
  },
];

const ContactDetails = ({ match }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [contact, setContact] = useState({});
  const [isContactLoading, setIsContactLoading] = useState(true);
  const [editAnchorElement, setEditAnchorElement] = useState(null);
  const [statusAnchorElement, setStatusAnchorElement] = useState(null);
  const [currentField, setCurrentField] = useState(null);
  const [genderAnchorElement, setGenderAnchorElement] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [autoCompleteList, setAutoCompleteList] = useState([]);
  const [contactFields, setContactFields] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur', // Configuration for when validation occurs
    defaultValues: {
      type: '', // default value for 'type'
    },
  });

  useEffect(() => {
    ResourceApi.contact.getById(id).then(({ data }) => {
      console.log(data);
      setIsContactLoading(false);
      setContact(data);
    });

    ResourceApi.users.getFields().then(({ data }) => {
      console.log(data.contactFields);

      const filteredData = data.contactFields.filter(
        (item) => item.type !== 'DEFAULT',
      );
      console.log(filteredData);
      setContactFields(filteredData);
    });
  }, []);

  const updateProfile = () => {
    ResourceApi.contact
      .update(contact.id, {
        ...contact,
        [currentField]: getValues(currentField),
      })
      .then(({ data }) => {
        console.log(data);
        setContact(data);
        handleEditClose();
      });
  };

  const handledynamicFields = ({ target }, field, type) => {
    setEditAnchorElement(target);
    setCurrentField(field);
    if (
      type === 'DROPDOWN' ||
      type === 'RADIO_BUTTON' ||
      type === 'CHECK_BOX'
    ) {
      const test1Field = contactFields.find((item) => item.label === field);
      let possibleValues = test1Field ? test1Field.possibleValues : null;
      if (type === 'DROPDOWN') {
        possibleValues = possibleValues.map((option) => ({
          label: option,
          value: option,
        }));
      }
      setValue('option', possibleValues);
    }
    setValue('type', type);
  };

  const handleEditClick = ({ target }, field) => {
    setEditAnchorElement(target);
    setCurrentField(field);
    setValue('type', '');
    setValue(field, contact[field]);
  };

  function checkFieldExistsAndNotNull(obj, field) {
    // Check if the object has the property and if it's not null or undefined
    return obj.hasOwnProperty(field) && obj[field] != null;
  }

  const handleEditClose = () => {
    setEditAnchorElement(null);
  };

  const onDeleteConfirmationClose = () => {
    setShowDeleteConfirmation(false);
  };

  const onContactDelete = () => {
    onDeleteConfirmationClose();
    ResourceApi.contact
      .delete({
        ids: [id],
      })
      .then(() => {
        navigate('/contacts/allContacts');
      });
  };

  const statusDetails = contactStatusOptions.find(
    (statusOption) => statusOption.value === contact.status,
  );

  return (
    <>
      <Header
        title={
          <Stack direction="row" gap={2} alignItems="center">
            <IconButton
              aria-label="back"
              onClick={() => {
                navigate('/contacts/allContacts');
              }}
            >
              <Icon type="icon-back" />
            </IconButton>
            {!isContactLoading && (
              <>
                <Typography
                  variant="h1"
                  sx={{ lineHeight: 1.2, fontWeight: 600, fontSize: '1.25rem' }}
                >
                  {`${contact.firstName} ${contact.lastName}`}
                </Typography>
                {statusDetails && (
                  <Button
                    variant="contained"
                    disableElevation
                    disableRipple
                    startIcon={<Icon type={statusDetails.icon} />}
                    endIcon={<Icon type="icon-arrow" />}
                    className={classNames(styles.btnStatus)}
                    onClick={(event) => {
                      setStatusAnchorElement(event.currentTarget);
                    }}
                  >
                    {statusDetails.label}
                  </Button>
                )}
              </>
            )}
          </Stack>
        }
        actions={[
          {
            label: 'Send Email',
            isPrimary: true,
            onClick: () => {},
          },
        ]}
        moreActions={[
          {
            label: 'Assign to Automation',
            value: 'assign_to_automation',
            icon: 'icon-add-circle',
          },
          {
            label: 'Delete',
            value: 'delete',
            icon: 'icon-delete',
            onClick: () => {
              setShowDeleteConfirmation(true);
            },
          },
        ]}
      />
      <div
        className="main-content-container"
        style={{
          backgroundColor: '#F9FAFB',
          minHeight: 'calc(100% - 56px)',
        }}
      >
        <Stack direction="row" gap={3}>
          <Stack gap={3} sx={{ width: '408px' }}>
            <Card variant="outlined" className={styles.container}>
              <CardHeader
                className={styles.header}
                title={
                  <Typography
                    variant="h2"
                    sx={{
                      lineHeight: 1.2,
                      fontWeight: 600,
                      fontSize: '1.25rem',
                    }}
                  >
                    Overview
                  </Typography>
                }
              />
              <CardContent className={styles.content}>
                {isContactLoading ? (
                  <Progress />
                ) : (
                  <>
                    <Stack direction="row" className={styles.row}>
                      <div className={styles.name}>First Name</div>
                      <div className={styles.value}>
                        {contact.firstName ?? ''}
                        <IconButton
                          aria-label="edit"
                          size="small"
                          sx={{ ml: '4px' }}
                          onClick={(event) =>
                            handleEditClick(event, 'firstName')
                          }
                        >
                          <Icon type="icon-edit" />
                        </IconButton>
                      </div>
                    </Stack>
                    <Stack className={styles.row}>
                      <div className={styles.name}>Last Name</div>
                      <div className={styles.value}>
                        {contact.lastName ?? ''}
                        <IconButton
                          aria-label="edit"
                          size="small"
                          sx={{ ml: '4px' }}
                          onClick={(event) =>
                            handleEditClick(event, 'lastName')
                          }
                        >
                          <Icon type="icon-edit" />
                        </IconButton>
                      </div>
                    </Stack>
                    <Stack className={styles.row}>
                      <div className={styles.name}>Email</div>
                      <div className={styles.value}>
                        {contact.email ?? ''}
                        <IconButton
                          aria-label="edit"
                          size="small"
                          sx={{ ml: '4px' }}
                          onClick={(event) => handleEditClick(event, 'email')}
                        >
                          <Icon type="icon-edit" />
                        </IconButton>
                      </div>
                    </Stack>
                    <Stack className={styles.row}>
                      <div className={styles.name}>Phone</div>
                      <div className={styles.value}>
                        {contact.phone ?? ''}
                        <IconButton
                          aria-label="edit"
                          size="small"
                          sx={{ ml: '4px' }}
                          onClick={(event) => handleEditClick(event, 'phone')}
                        >
                          <Icon type="icon-edit" />
                        </IconButton>
                      </div>
                    </Stack>
                    <Stack className={styles.row}>
                      <div className={styles.name}>Date of Birth</div>
                      <div className={styles.value}>
                        {contact.birthDate ? (
                          <>
                            {contact.birthDate}
                            <IconButton
                              aria-label="edit"
                              size="small"
                              sx={{ ml: '4px' }}
                              onClick={(event) =>
                                handleEditClick(event, 'birthDate')
                              }
                            >
                              <Icon type="icon-edit" />
                            </IconButton>
                          </>
                        ) : (
                          <div
                            className="add-profile-btn"
                            onClick={(event) =>
                              handleEditClick(event, 'birthDate')
                            }
                          >
                            <Icon type="icon-add-circle" />
                            <span>Add</span>
                          </div>
                        )}
                      </div>
                    </Stack>
                    <Stack direction="row" className={styles.row}>
                      <div className={styles.name}>Address</div>
                      <div className={styles.value}>
                        {contact.address ? (
                          <>
                            {Object.values(contact.address)
                              .filter(
                                (value) =>
                                  value !== null &&
                                  value !== undefined &&
                                  value !== '',
                              ) // Filter out null or undefined values
                              .join(', ')}
                            <IconButton
                              aria-label="edit"
                              size="small"
                              sx={{ ml: '4px' }}
                              onClick={(event) =>
                                handleEditClick(event, 'address')
                              }
                            >
                              <Icon type="icon-edit" />
                            </IconButton>
                          </>
                        ) : (
                          <div
                            className="add-profile-btn"
                            onClick={(event) =>
                              handleEditClick(event, 'address')
                            }
                          >
                            <Icon type="icon-add-circle" />
                            <span>Add</span>
                          </div>
                        )}
                      </div>
                    </Stack>
                    <Stack className={styles.row}>
                      <div className={styles.name}>Timezone</div>
                      <div className={styles.value}>
                        {contact.timezone ? (
                          <>
                            {contact.timezone}
                            <IconButton
                              aria-label="edit"
                              size="small"
                              sx={{ ml: '4px' }}
                              onClick={(event) =>
                                handleEditClick(event, 'timezone')
                              }
                            >
                              <Icon type="icon-edit" />
                            </IconButton>
                          </>
                        ) : (
                          <div
                            className="add-profile-btn"
                            onClick={(event) =>
                              handleEditClick(event, 'timezone')
                            }
                          >
                            <Icon type="icon-add-circle" />
                            <span>Add</span>
                          </div>
                        )}
                      </div>
                    </Stack>
                    <Stack className={styles.row}>
                      <div className={styles.name}>Gender</div>
                      <div className={styles.value}>
                        {contact.gender ? (
                          <>
                            {contact.gender}
                            <IconButton
                              aria-label="edit"
                              size="small"
                              sx={{ ml: '4px' }}
                              onClick={(event) =>
                                handleEditClick(event, 'gender')
                              }
                            >
                              <Icon type="icon-edit" />
                            </IconButton>
                          </>
                        ) : (
                          <div
                            className="add-profile-btn"
                            onClick={(event) =>
                              handleEditClick(event, 'gender')
                            }
                          >
                            <Icon type="icon-add-circle" />
                            <span>Add</span>
                          </div>
                        )}
                      </div>
                    </Stack>
                    <Stack className={styles.row}>
                      <div className={styles.name}>Tags</div>
                      <div className={styles.value}>
                        <Stack gap={1} direction="row" flexWrap="wrap">
                          {contact.tagResources?.map(({ name, id }) => (
                            <CustomChip
                              label={name}
                              key={id}
                              onDelete={() => {
                                ResourceApi.contact
                                  .update(contact.id, {
                                    ...contact,
                                    tagResources: contact.tagResources.filter(
                                      (tagResource) => tagResource.id !== id,
                                    ),
                                  })
                                  .then(({ data }) => {
                                    console.log(data);
                                    setContact(data);
                                  });
                              }}
                            />
                          ))}
                          <div
                            className="add-profile-btn"
                            onClick={(event) =>
                              handleEditClick(event, 'tagResources')
                            }
                          >
                            <Icon type="icon-add-circle" />
                            <span>Add</span>
                          </div>
                        </Stack>
                      </div>
                    </Stack>
                    <Stack className={styles.row}>
                      <div className={styles.name}>Lists</div>
                      <div className={styles.value}>
                        <Stack gap={1} direction="row" flexWrap="wrap">
                          {contact.listResources?.map(({ name, id }) => (
                            <CustomChip
                              label={name}
                              key={id}
                              onDelete={() => {
                                ResourceApi.contact
                                  .update(contact.id, {
                                    ...contact,
                                    listResources: contact.listResources.filter(
                                      (listResource) => listResource.id !== id,
                                    ),
                                  })
                                  .then(({ data }) => {
                                    console.log(data);
                                    setContact(data);
                                  });
                              }}
                            />
                          ))}
                          <div
                            className="add-profile-btn"
                            onClick={(event) =>
                              handleEditClick(event, 'listResources')
                            }
                          >
                            <Icon type="icon-add-circle" />
                            <span>Add</span>
                          </div>
                        </Stack>
                      </div>
                    </Stack>
                    {contactFields.map((item) => {
                      const label = item.label;
                      const type = item.type;
                      let value;
                      if (
                        contact?.dynamicField &&
                        label in contact.dynamicField
                      ) {
                        value = contact?.dynamicField[label];
                      }
                      return (
                        <Stack className={styles.row} key={item.id}>
                          <div className={styles.name}>{label}</div>
                          <div className={styles.value}>
                            {value ? (
                              <>
                                {value}
                                <IconButton
                                  aria-label="edit"
                                  size="small"
                                  sx={{ ml: '4px' }}
                                  onClick={(event) => {
                                    handledynamicFields(event, label, type);
                                  }}
                                >
                                  <Icon type="icon-edit" />
                                </IconButton>
                              </>
                            ) : (
                              <div
                                className="add-profile-btn"
                                onClick={
                                  (event) => {
                                    handledynamicFields(event, label, type);
                                  }
                                  //handleEditClick(event, 'company')
                                }
                              >
                                <Icon type="icon-add-circle" />
                                <span>Add</span>
                              </div>
                            )}
                          </div>
                        </Stack>
                      );
                    })}
                  </>
                )}
              </CardContent>
            </Card>
            <Card variant="outlined" className={styles.container}>
              <CardHeader
                className={styles.header}
                title={
                  <Typography
                    variant="h2"
                    sx={{
                      lineHeight: 1.2,
                      fontWeight: 600,
                      fontSize: '1.25rem',
                    }}
                  >
                    Engagements
                  </Typography>
                }
              />
              <CardContent className={styles.content}>
                <Grid container rowGap={2}>
                  <Grid item xs={6}>
                    <div>Sep 14, 2021 at 09:30</div>
                    <div className={styles.cell}>Last Updated</div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>Sep 14, 2021</div>
                    <div className={styles.cell}>Last Email Sent</div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>Sep 14, 2021</div>
                    <div className={styles.cell}>Last Click</div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>Sep 14, 2021</div>
                    <div className={styles.cell}>Last Open</div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card variant="outlined" className={styles.container}>
              <CardHeader
                className={styles.header}
                title={
                  <Typography
                    variant="h2"
                    sx={{
                      lineHeight: 1.2,
                      fontWeight: 600,
                      fontSize: '1.25rem',
                    }}
                  >
                    Orders
                  </Typography>
                }
              />
              <CardContent className={styles.content}>
                <Grid container rowGap={2}>
                  <Grid item xs={6}>
                    <div>1</div>
                    <div className={styles.cell}>Order(s)</div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>$80</div>
                    <div className={styles.cell}>Total Spent</div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>Sep 14, 2021</div>
                    <div className={styles.cell}>Last Ordered</div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>$50</div>
                    <div className={styles.cell}>AOV</div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Stack>
          <Stack
            sx={{ width: 'calc(100% - 408px)' }}
            gap={3}
            justifyContent="flex-start"
          >
            <Card variant="outlined" className={styles.container}>
              <CardHeader
                className={styles.header}
                title={
                  <Typography
                    variant="h2"
                    sx={{
                      lineHeight: 1.2,
                      fontWeight: 600,
                      fontSize: '1.25rem',
                    }}
                  >
                    Email Campaigns
                  </Typography>
                }
              />
              <CardContent className={styles.content}>
                <Grid container>
                  <Grid item xs={3}>
                    <div>10</div>
                    <div className={styles.cell}>Sent</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div>95%</div>
                    <div className={styles.cell}>Delivered</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div>95%</div>
                    <div className={styles.cell}>Unique Opening</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div>95%</div>
                    <div className={styles.cell}>Unique Clicks</div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card variant="outlined">
              <MyTab id={contact.id} />
            </Card>
          </Stack>
        </Stack>
      </div>
      <Popover
        open={!!editAnchorElement}
        anchorEl={editAnchorElement}
        onClose={handleEditClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <form>
          <div className={styles.editForm}>
            <Typography
              variant="h2"
              sx={{ fontSize: '16px', mb: 1, lineHeight: 1.5, fontWeight: 600 }}
            >
              {checkFieldExistsAndNotNull(contact, currentField)
                ? 'Edit '
                : 'Add '}
              {currentField}
            </Typography>
            {(getValues('type') === 'TEXT_INPUT' ||
              getValues('type') === 'NUMBER') && (
              <>
                <div className="input-container">
                  <input
                    type="text"
                    className="email-dialog-input"
                    {...register(currentField)}
                  />
                </div>
              </>
            )}
            {getValues('type') === 'TEXT_AREA' && (
              <>
                <div className="input-container">
                  <textarea
                    className="email-dialog-input"
                    {...register(currentField)}
                  />
                </div>
              </>
            )}

            {getValues('type') === 'CHECK_BOX' && (
              <>
                <div className="input-container">
                  <CustomCheckBox
                    values={getValues('option')}
                    onChange={(value) => {
                      setValue(currentField, value[0]);
                      setSelectedOption(value);
                      console.log(value);
                    }}
                    checked={selectedOption}
                    direction="row"
                  />
                </div>
              </>
            )}
            {getValues('type') === 'RADIO_BUTTON' && (
              <>
                <div className="input-container">
                  <RadioButtonsGroup
                    values={getValues('option')}
                    onChange={(event) => {
                      setValue(currentField, event.target.value);
                    }}
                    checked={getValues(currentField)}
                    direction="row"
                  />
                </div>
              </>
            )}

            {getValues('type') === 'DROPDOWN' && (
              <>
                <div className="input-container">
                  <TextField
                    variant="outlined"
                    placeholder="Select"
                    value={getValues(currentField)}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <img
                          src={iconArrow}
                          style={{ width: '16px', height: '16px' }}
                          alt="arrow"
                        />
                      ),
                    }}
                    onClick={(event) => {
                      setGenderAnchorElement(event.currentTarget);
                    }}
                  />
                </div>
                <CustomMenu
                  onClose={() => {
                    setGenderAnchorElement(null);
                  }}
                  onSelect={(value) => {
                    setGenderAnchorElement(null);
                    setValue(currentField, value);
                  }}
                  options={getValues('option')}
                  anchorEl={genderAnchorElement}
                  inputBox={true}
                />
              </>
            )}

            {currentField === 'gender' && (
              <>
                <div className="input-container">
                  <TextField
                    variant="outlined"
                    placeholder="Select"
                    value={getValues('gender')}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <img
                          src={iconArrow}
                          style={{ width: '16px', height: '16px' }}
                          alt="arrow"
                        />
                      ),
                    }}
                    onClick={(event) => {
                      setGenderAnchorElement(event.currentTarget);
                    }}
                  />
                </div>

                <CustomMenu
                  onClose={() => {
                    setGenderAnchorElement(null);
                  }}
                  onSelect={(value) => {
                    setGenderAnchorElement(null);
                    setValue('gender', value);
                  }}
                  options={[
                    { label: 'Male', value: 'male' },
                    { label: 'Female', value: 'female' },
                    { label: 'Others', value: 'others' },
                  ]}
                  anchorEl={genderAnchorElement}
                  inputBox={true}
                />
              </>
            )}
            {currentField === 'address' && (
              <>
                <div className="input-container">
                  <input
                    type="text"
                    {...register('line1', {
                      required: 'Address 1 is required',
                    })}
                    className="email-dialog-input"
                    placeholder="Enter Address 1"
                  />
                </div>
                <div className="input-container">
                  <input
                    type="text"
                    {...register('line2', {
                      required: 'Address 2 is required',
                    })}
                    className="email-dialog-input"
                    placeholder="Enter Address 2"
                  />
                </div>
                <div className="input-container">
                  <input
                    type="number"
                    {...register('zipcode', {
                      required: 'Postal/Zip code is required',
                    })}
                    className="email-dialog-input"
                    placeholder="Postal/Zip Code"
                  />
                </div>
                <div className="input-container">
                  <input
                    type="text"
                    {...register('city', { required: 'City is required' })}
                    className="email-dialog-input"
                    placeholder="Enter City"
                  />
                </div>
                <CountryRegionSelector
                  onSelectionChange={(selection) => {
                    setValue('country', selection.country);
                    setValue('state', selection.region);
                    console.log(selection);
                  }}
                />
              </>
            )}
            {currentField === 'tagResources' && (
              <>
                <div className="input-container">
                  <label className="email-dialog-label">Tags</label>
                  <CustomizedAutocomplete
                    label="Select to search"
                    placeholder="Select to search"
                    multiple={true}
                    type="tags"
                    onChange={(selectedValues) => {
                      console.log(selectedValues, 'Array of objects for Lists');
                      setAutoCompleteValue(selectedValues);
                    }}
                  />
                </div>
              </>
            )}
            {currentField === 'listResources' && (
              <>
                <div className="input-container">
                  <label className="email-dialog-label">Lists</label>

                  <CustomizedAutocomplete
                    label="Select to search"
                    placeholder="Select to search"
                    multiple={true}
                    type="list"
                    onChange={(selectedValues) => {
                      console.log(selectedValues, 'Array of objects for Lists');
                      setAutoCompleteList(selectedValues);
                    }}
                  />
                </div>
              </>
            )}
            {currentField === 'timezone' && (
              <>
                <TimezoneSelector
                  onTimezoneChange={(timezone) => {
                    console.log(timezone.label);
                    setValue('timezone', timezone.label);
                  }}
                />
              </>
            )}

            {(currentField === 'birthDate' || getValues('type') === 'DATE') && (
              <div className="input-container">
                <DatePickerCalendar
                  onDateChange={(selectedDate) => {
                    setValue(currentField, selectedDate.format('YYYY-MM-DD'));
                  }}
                />
              </div>
            )}
            {(currentField === 'firstName' ||
              currentField === 'lastName' ||
              currentField === 'email' ||
              currentField === 'phone') && (
              <FormRenderer
                errors={errors}
                register={register}
                watch={watch}
                fields={[fields[currentField]]}
              />
            )}
            <Stack
              direction="row"
              gap={1}
              marginTop={2}
              justifyContent="flex-end"
            >
              <Button
                className="btn btn-outline dark-border"
                onClick={handleEditClose}
              >
                Cancel
              </Button>
              <Button
                className="btn btn-primary"
                onClick={(event) => {
                  event.preventDefault();
                  console.log('save clicked');
                  console.log(currentField);
                  const { listResources, ...newContact } = contact;
                  if (listResources && Array.isArray(listResources)) {
                    newContact.tagResources = [
                      ...(newContact.tagResources || []), // Keep existing tagResources or create an empty array
                      ...listResources, // Add objects from listResources
                    ];
                  }
                  let data;

                  if (currentField === 'tagResources') {
                    console.log(autoCompleteValue);
                    if (newContact.tagResources) {
                      data = newContact.tagResources;
                      data = [...data, ...autoCompleteValue];
                    } else {
                      data = autoCompleteValue;
                    }
                  }
                  if (currentField === 'listResources') {
                    console.log(autoCompleteList);
                    if (newContact.tagResources) {
                      data = newContact.tagResources;
                      data = [...data, ...autoCompleteList];
                    } else {
                      data = autoCompleteList;
                    }
                  }
                  if (currentField === 'address') {
                    setValue('address', {
                      line1: getValues('line1'),
                      line2: getValues('line2'),
                      city: getValues('city'),
                      state: getValues('state'),
                      country: getValues('country'),
                      zipcode: getValues('zipcode'),
                    });
                  }
                  if (getValues('type') !== '') {
                    const updatedField = {
                      [currentField]: getValues(currentField),
                    };
                    const updatedData = { ...newContact };

                    // Add the new key-value pair to the dynamicField object
                    updatedData.dynamicField = {
                      ...updatedData.dynamicField,
                      ...updatedField,
                    };

                    ResourceApi.contact
                      .update(contact.id, updatedData)
                      .then(({ data }) => {
                        console.log(data);
                        setContact(data);
                        handleEditClose();
                      });
                  } else if (currentField === 'listResources') {
                    let apitarget = 'tagResources';
                    ResourceApi.contact
                      .update(contact.id, {
                        ...newContact,
                        [apitarget]:
                          currentField === 'tagResources' ||
                          currentField === 'listResources'
                            ? data // Replace with the value you want to pass for 'tag'
                            : getValues(currentField), // Default value for other fields
                      })
                      .then(({ data }) => {
                        console.log(data);
                        setContact(data);
                        handleEditClose();
                      });
                  } else {
                    ResourceApi.contact
                      .update(contact.id, {
                        ...newContact,
                        [currentField]:
                          currentField === 'tagResources' ||
                          currentField === 'listResources'
                            ? data // Replace with the value you want to pass for 'tag'
                            : getValues(currentField), // Default value for other fields
                      })
                      .then(({ data }) => {
                        console.log(data);
                        setContact(data);
                        handleEditClose();
                      });
                  }
                }}
              >
                Save
              </Button>
            </Stack>
          </div>
        </form>
      </Popover>

      <CustomMenu
        onClose={() => {
          setStatusAnchorElement(null);
        }}
        onSelect={(value) => {
          setStatusAnchorElement(null);
          ResourceApi.contact
            .updateStatus({ status: value }, contact.id)
            .then(({ data }) => {
              setContact(data);
            });
        }}
        options={contactStatusOptions}
        anchorEl={statusAnchorElement}
      />
      <CustomDialog
        title="Delete Contact"
        open={showDeleteConfirmation}
        onClose={onDeleteConfirmationClose}
        content="Are you sure you want to delete the contact?"
        actions={[
          {
            label: 'Cancel',
            onClick: onDeleteConfirmationClose,
            classes: 'btn-outline dark-border',
          },
          {
            label: 'Delete',
            onClick: onContactDelete,
            classes: 'btn-primary',
          },
        ]}
      />
    </>
  );
};

export default ContactDetails;
