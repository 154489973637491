import React from 'react';
import PropTypes from 'prop-types';

const CommonToggler = ({
  isOn,
  onToggle,
  icons,
  type,
  id = 'button-10',
  classNames = {},
}) => {
  const {
    toggleButtonCover = 'toggle-button-cover',
    buttonCover = type === 'container-toggler'
      ? 'container-button-cover'
      : 'button-cover',
    button = type === 'container-toggler' ? 'container-button' : 'button',
    checkbox = type === 'container-toggler' ? 'container-checkbox' : 'checkbox',
    knobs = type === 'container-toggler' ? 'container-knobs' : 'knobs',
    layer = 'layer',
  } = classNames;

  return (
    <div className={toggleButtonCover}>
      <div className={`${buttonCover}`}>
        <div
          className={`${button} b2 button-timer ${isOn ? 'active' : ''}`}
          id={id}
        >
          <input
            type="checkbox"
            className={checkbox}
            checked={isOn}
            onChange={onToggle}
          />
          <div className={knobs}>
            <span>{icons.off}</span>
          </div>
          <div className={layer}></div>
        </div>
      </div>
    </div>
  );
};

CommonToggler.propTypes = {
  isOn: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  icons: PropTypes.shape({
    on: PropTypes.node,
    off: PropTypes.node,
  }),
  type: PropTypes.string,
  id: PropTypes.string,
  classNames: PropTypes.shape({
    toggleButtonCover: PropTypes.string,
    buttonCover: PropTypes.string,
    button: PropTypes.string,
    checkbox: PropTypes.string,
    knobs: PropTypes.string,
    layer: PropTypes.string,
  }),
};

export default CommonToggler;
