import ProductIcon from '../../../../../assets/icons/icon-product.svg';
import { renderProductBlockTraitManager } from '../utils';

export const registerProductBlock = (editor, brandStyles, opts = {}) => {
  // Define the custom component type for Product Block
  editor.DomComponents.addType('Product', {
    model: {
      defaults: {
        tagName: 'span',
        classes: ['block-div'],
        attributes: { 'data-gjs-type': 'Product' },
        selectable: true, 
        hoverable: true,
        style: {
          display: 'block !important',
          'font-family': `${brandStyles?.typeGraphy?.fontFamily}, ${brandStyles?.typeGraphy?.backupFontFamily}` || 'Arial',
          width: '100%',
          height: '100%',
        },

        components: [
          {
            tagName: 'table',
            type: 'table',
            style: {
              display: 'table',
              width: '100%',
              height: '100%',
              'border-spacing': '10px',
              'table-layout': 'fixed',
            },
            droppable: false,
            selectable: false, 
            hoverable: false,  
            components: [
              {
                tagName: 'tbody',
                type: 'tbody',
                droppable: false,
                selectable: false, 
                hoverable: false,  
                components: [
                  {
                    tagName: 'tr',
                    type: 'row',
                    droppable: false,
                    selectable: false, 
                    hoverable: false,  
                    components: [
                      {
                        tagName: 'td',
                        type: 'cell',
                        droppable: false,
                        selectable: false, 
                        hoverable: false,  
                        style: {
                            width: '50%',
                            height: '100%',
                            'vertical-align': 'middle', 
                            'text-align': 'center',    
                          },
                        components: [
                          {
                            tagName: 'button',
                            type: 'button',
                            content: 'Add Product',
                            droppable: false,
                            selectable: false, 
                            hoverable: false,  
                            editable: true,
                            style: {
                              padding: '10px 20px',
                              'font-size': brandStyles?.typeGraphy?.fontSize || '14px',
                              'font-weight': '600',
                              'text-decoration': 'none',
                              color: '#ffffff',
                              background: '#007BFF',
                              'border-radius': '4px',
                              'border': 'none',
                              cursor: 'pointer',
                            },
                          },
                        ],
                      },
                      {
                        tagName: 'td',
                        type: 'cell',
                        droppable: false,
                        selectable: false, 
                        hoverable: false,  
                        style: {
                            width: '50%',
                            height: '100%',
                            'vertical-align': 'middle', 
                            'text-align': 'center',    
                          },
                        components: [
                          {
                            tagName: 'button',
                            type: 'button',
                            content: 'Add Product',
                            droppable: false,
                            selectable: false, 
                            hoverable: false,  
                            editable: true,
                            style: {
                              padding: '10px 20px',
                              'font-size': brandStyles?.typeGraphy?.fontSize || '14px',
                              'font-weight': '600',
                              'text-decoration': 'none',
                              color: '#ffffff',
                              background: '#007BFF',
                              'border-radius': '4px',
                              'border': 'none',
                              cursor: 'pointer',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        droppable: false,
        stylable: true,
      },
      init() {
        // Ensure styles are inline when the component is initialized
        this.set({ style: this.defaults.style });
      },
    },
  });

  // Add the block for ProductBlock
  editor.BlockManager.add('productBlock', {
    label: `<div class="gjs-block-label-container" data-category="Block"><img src=${ProductIcon} alt="Product Icon" height="24px" width="24px"/></div>
    <div class="block-label-text">Product Block</div>`,
    content: { type: 'Product' },
    category: 'Blocks',
    draggable: 'td',
  });
};
