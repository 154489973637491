import React, { useState } from 'react';
import iconPage from '../../assets/icons/icon-page.svg';
import CommonOverlay from '../../components/Overlay/Overlay';
import iconBack from '../../assets/icons/icon-back.svg';
import iconClock from '../../assets/icons/icon-time.svg';
import { Button, CircularProgress } from '@mui/material';
import DynamicTabs from '../../components/Tab/Tab';
import UnsubscribeComponent from './components/UnsubscribeComponent';

const Pages = () => {
  const [isUnsubscribeOverlay, setIsUnsubscribeOverlay] = useState(false);
  const [isSavePageLoader, setIsSavePageLoader] = useState(false);
  const openUnsubscribeOverlay = () => {
    setIsUnsubscribeOverlay(true);
  };

  const closeUnsubscribeOverlay = () => {
    setIsUnsubscribeOverlay(false);
  };

  const tabs = ['Form', 'Thanks'];

  const reasons = [
    'Emails are sent too frequently',
    'I never signed up for this mailing list',
    'The emails are inappropriate',
    'The emails are spam and should be reported',
    'Other'
  ];

  return (
    <>
      <CommonOverlay
        open={isUnsubscribeOverlay}
        onClose={closeUnsubscribeOverlay}
      >
        <div className="unsubscribe-layout-wrapper">
          <div className="header email-builder-header">
            <div className="header-left">
              <Button
                variant="outline"
                className="back-icon common-btn"
                onClick={closeUnsubscribeOverlay}
              >
                <img
                  src={iconBack}
                  alt="Back button"
                  style={{ transform: 'rotate(-90deg)' }}
                />
              </Button>
              <span className="template-name email-builder-template-name">
                Unsubscribe Page
              </span>
            </div>

            <div className="header-right">
              <span className="last-save">
                <span className="clock-icon">
                  <img src={iconClock} alt="clock-icon" />
                </span>
                Last save 1hr ago
              </span>

              <div className="btn-container">
                <Button className="preview btn btn-outline dark-border">
                  Preview
                </Button>

                <Button className="btn-primary" variant="contained">
                  {isSavePageLoader ? (
                    <CircularProgress className="loader-btn" />
                  ) : (
                    ''
                  )}
                  <span
                    style={{
                      visibility: isSavePageLoader ? 'hidden' : 'visible',
                    }}
                  >
                    Save
                  </span>
                </Button>
              </div>
            </div>
          </div>
          <div className='tabs'>
            <DynamicTabs
              tabs={tabs}
              panels={[
                <div className="page-wrapper">
                  <div className="preview-container"><UnsubscribeComponent success={false} reasons={reasons} /></div>
                  <div className="preview-properties">Properties Here...</div>
                </div>,
                <>
                  <div className="page-wrapper">
                    <div className="preview-container">
                    <UnsubscribeComponent success={true}  /></div>
                    <div className="preview-properties">Properties Here...</div>
                  </div>
                </>,
              ]}
            />
          </div>
        </div>
      </CommonOverlay>
      <div className="header">
        <div className="header-left">
          <span className="template-name"> Pages </span>
        </div>
        <div className="header-center"></div>

        <div className="header-right"></div>
      </div>
      <div className="page-container">
        <div className="card">
          <div className="card-top">
            <div className="card-title">
              <img src={iconPage} />
              <h3>Unsubscribe Page</h3>
            </div>
            <span className="card-subtitle">
              Last Update: 20 May 2024 08:30
            </span>
          </div>
          <div className="card-bottom">
            <button className="btn btn-outline dark-border">Preview</button>
            <button
              className="btn btn-primary"
              onClick={openUnsubscribeOverlay}
            >
              Edit Page
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pages;
