import React from 'react';
const Segments = () => {
  return (
    <div>
      <div className="header">
        <div className="header-left">
          <span className="template-name"> Segments </span>
        </div>
        <div className="header-center"></div>

        <div className="header-right"></div>
      </div>
      <div>Segments</div>
    </div>
  );
};

export default Segments;
