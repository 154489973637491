import React from 'react';
import iconArrow from '../../../assets/icons/icon-back.svg';

const RecentCard = ({
  title,
  buttonText,
  buttonAction,
  imageText,
  description,
  iconSrc,
  linkText,
  linkAction,
  containerHeight
}) => {
  return (
    <div className="automation-card-container" style={{height: containerHeight+'px'}}>
      <div className="automation-card-header">
        <h3>{title}</h3>
        {linkText && (
          <button className="view-all-carts-btn" onClick={linkAction}>
            <span>{linkText}</span>
            <img src={iconArrow} alt="Open details" />
          </button>
        )}
      </div>
      <div className="automation-card-body">
        {iconSrc && (
          <img className="automation-icon" src={iconSrc} alt="No Automation" />
        )}
        <p className="automation-description">{imageText}</p>
        <p className="automation-description">{description}</p>
        {buttonText && (
          <button className="btn btn-recent" onClick={buttonAction}>
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default RecentCard;
