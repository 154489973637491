import React from 'react';

const TodoList = () => {
  const tasks = [
    {
      title: 'Create First Contact',
      description:
        'Add All Details About Your Contacts; Track All Activities Between You and Your Customer',
      actionLabel: 'Create',
      completed: false,
    },
    {
      title: 'Connect Your Store',
      description:
        'Connect your Shopify or WooCommerce store for personalized email marketing with cart abandonment tracking, product fetching, and dynamic coupon integration.',
      actionLabel: 'Connect',
      completed: false,
    },
    {
      title: 'Connect Your Domain',
      description:
        'By sending email with your own domain, it gains the users trust and boosts the conversions',
      actionLabel: 'Connect',
      completed: false,
    },
    {
      title: 'Add Business Details',
      description:
        'As per Gmail, Outlook, and Yahoo spam compliance, you need to add your business name and address in the email.',
      actionLabel: 'Add',
      completed: false,
    },
  ];

  const completedTasks = 1;
  const totalTasks = tasks.length;

  return (
    <>
      <div className="todo-list-header">
        <h3>Todo List</h3>
        <div className="task-progress">
          <span>{`${completedTasks} of ${totalTasks} tasks`}</span>
          <div className="progress-bar">
            <div
              className="progress-bar-fill"
              style={{ width: `${(completedTasks / totalTasks) * 100}%` }}
            ></div>
          </div>
        </div>
      </div>
      <div className="todo-items">
        {tasks.map((task, index) => (
          <div className="todo-item">
            <div className="todo-left">
              <div className="task-icon"></div>
              <div className="task-details">
                <h4 className="task-title">{task.title}</h4>
                <p className="task-description">{task.description}</p>
              </div>
            </div>
            {!task.completed && (
              <div className="todo-right">
                <button className="task-button">{task.actionLabel}</button>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default TodoList;
