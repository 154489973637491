import React, {useEffect, useRef} from 'react';
import Card from '@mui/material/Card';
import {Handle} from 'reactflow';
import iconDoThis from '../../../assets/icons/icon-dothis.svg';
import {useReactFlow} from 'reactflow';
const Node = ({id, data}) => {
 // Sample chip data array
 //  const bodyData = [
 //   {label: 'Active', data: 20},
 //   {label: 'Active', data: 20},
 //   {label: 'Active', data: 20}
 //  ];
 const {setNodes, getNodes} = useReactFlow();
 const {setEdges, getEdges} = useReactFlow();
 const nodes = getNodes();

 const currentNode = nodes.filter(node => node.id === id);
 console.log(currentNode);
 return (
  <div>
   <Card
    sx={{
     boxShadow: 'none'
    }}
   >
    {data?.type === 'dot' ? (
     <div style={{border: '1px solid black'}}></div>
    ) : (
     <div
      style={{
       display: 'flex',
       gap: '4px',
       alignItems: 'center',
       padding: '4px 12px 4px 12px',
       borderRadius: '8px'
      }}
      className={
       data?.type === 'conditional' || data?.type === 'splitPath'
        ? 'status SUCCESS'
        : 'status endNode'
      }
     >
      <div>{data.label}</div>
     </div>
    )}

    {/* <Handle
     type="source"
     position="bottom"
     id="source-handle"
     style={{background: '#555'}}
    /> */}
    <Handle
     type="target"
     position="top"
     id="target-handle"
     style={{background: '#555'}}
    />
    {(data?.type === 'conditional' || data?.type === 'splitPath') && (
     <Handle
      type="source"
      position="bottom"
      id="source-handle"
      style={{background: '#555'}}
     />
    )}
    {data?.type === 'dot' && (
     <Handle type="source" position="bottom" id="source-handle" />
    )}
   </Card>
  </div>
 );
};

export default Node;
