import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentPage,
  toggleEmailBuilder,
  toggleSidebar,
  toggleWorkflow,
} from '../store/store';
import { useNavigate } from 'react-router-dom';
import logout from '../auth/logout';
import getUserData from '../auth/getUser';
import {useEffect} from 'react';
// Icons
import iconHamburger from '../assets/icons/icon-menu.svg';
import iconContacts from '../assets/icons/icon-contacts.svg';
import iconDashboard from '../assets/icons/icon-dashboard.svg';
import iconCampaigns from '../assets/icons/icon-campaigns.svg';
import iconAutomation from '../assets/icons/icon-automations.svg';
import iconAnalytics from '../assets/icons/icon-graph.svg';
import iconTemplate from '../assets/icons/icon-templates.svg';
import iconPages from '../assets/icons/icon-page.svg';
import iconDocumentation from '../assets/icons/icon-document.svg';
import iconSettings from '../assets/icons/icon-settings.svg';
import appLogo from '../assets/images/appLogo.png';
import iconAccordion from '../assets/icons/icon-arrow.svg';
import iconMore from '../assets/icons/icon-more.svg';
import iconCart from '../assets/icons/icon-cart.svg';
import iconHome from "../assets/icons/icon-home.svg";
const drawerWidthOpen = 240;
const drawerWidthMini = 60;

function MiniVariantDrawer(props) {
 const {window} = props;
 const [mobileOpen, setMobileOpen] = React.useState(false);
 const drawerOpen = useSelector(state => state.isSidebar);
 const [openContacts, setOpenContacts] = React.useState(false);
 const [openEmail, setOpenEmail] = React.useState(false);
 const [anchorEl, setAnchorEl] = React.useState(null); // State for the anchor element of the menu
 const [firstName, setFirstName] = React.useState('');
 const [lastName, setlasttName] = React.useState('');
 const dispatch = useDispatch();
 const navigate = useNavigate(); // Added for routing
 const updateName = useSelector(state => state.isNameUpdated);
 const handleMenuItemClick = path => {
  dispatch(setCurrentPage(path));
  navigate(`/${path}`);
  if (mobileOpen) {
   setMobileOpen(false); // Close the drawer in mobile view after selection
  }
  setAnchorEl(null);
 };

 //getting user data
 useEffect(() => {
  displayUserData();
 }, [updateName]);
 async function displayUserData() {
  try {
   const userData = await getUserData();
   setFirstName(userData.firstName);
    setlasttName(userData.lastName);
  } catch (error) {
   console.error('Failed to get user data:', error);
  }
 }
 displayUserData();

  const greetingMsg = `Hi ${firstName} ${lastName}`;
  let greetingTag = 'NA';
  if (firstName) {
    greetingTag = `${firstName[0]}${lastName[0]}`;
  }

  const openEmailBuilder = () => {
    navigate('/email-builder');
  };

  const openWorkflow = () => {
    navigate('/workflow');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleDrawer = () => {
    dispatch(toggleSidebar());
    setOpenContacts(false);
    setOpenEmail(false);
  };

  const handleClickContacts = () => {
    setOpenContacts(!openContacts);
  };

  const handleClickEmail = () => {
    setOpenEmail(!openEmail);
  };

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };

  const contactsTooltip = (
    <List component="div" disablePadding>
      <ListItemButton
        onClick={() => handleMenuItemClick('contacts/allContacts')}
        sx={{ pl: 4, color: 'white' }}
      >
        <ListItemText primary="All Contacts" />
      </ListItemButton>
      <ListItemButton
        onClick={() => handleMenuItemClick('contacts/segments')}
        sx={{ pl: 4 }}
      >
        <ListItemText primary="Segments" />
      </ListItemButton>
      <ListItemButton
        onClick={() => handleMenuItemClick('contacts/tags')}
        sx={{ pl: 4 }}
      >
        <ListItemText primary="Tags" />
      </ListItemButton>
      <ListItemButton
        onClick={() => handleMenuItemClick('contacts/list')}
        sx={{ pl: 4 }}
      >
        <ListItemText primary="List" />
      </ListItemButton>
      <ListItemButton
        onClick={() => handleMenuItemClick('contacts/fields')}
        sx={{ pl: 4 }}
      >
        <ListItemText primary="Fields" />
      </ListItemButton>
    </List>
  );

  const templatesTooltip = (
    <List component="div" disablePadding>
      <ListItemButton
        onClick={() => handleMenuItemClick('templates/my-templates')}
        sx={{ pl: 4 }}
      >
        <ListItemText primary="My Templates" />
      </ListItemButton>
      <ListItemButton
        onClick={() => handleMenuItemClick('templates/prebuilt-templates')}
        sx={{ pl: 4 }}
      >
        <ListItemText primary="Pre-built Templates" />
      </ListItemButton>
    </List>
  );

  const drawer = (
    <div>
      <Toolbar>
        <div className="sidebar-header">
          <img
            src={appLogo}
            height={'24px'}
            style={{ display: drawerOpen ? 'block' : 'none' }}
          />
          <IconButton onClick={toggleDrawer}>
            <img src={iconHamburger} />
          </IconButton>
        </div>
      </Toolbar>

      <Typography
        variant="subtitle1"
        color="textSecondary"
        sx={{ paddingLeft: 2, paddingTop: 1, paddingBottom: 1 }}
      >
        Main
      </Typography>

      <List>
      <Tooltip title={drawerOpen ? '' : 'Home'} placement="right">
          <ListItemButton onClick={() => handleMenuItemClick('home')}>
            <ListItemIcon>
              <img src={iconHome} />
            </ListItemIcon>
            <ListItemText
              primary="Home"
              sx={{ display: drawerOpen ? 'block' : 'none' }}
            />
          </ListItemButton>
        </Tooltip>
        <Tooltip title={drawerOpen ? '' : 'Dashboard'} placement="right">
          <ListItemButton onClick={() => handleMenuItemClick('dashboard')}>
            <ListItemIcon>
              <img src={iconDashboard} />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              sx={{ display: drawerOpen ? 'block' : 'none' }}
            />
          </ListItemButton>
        </Tooltip>

        <Tooltip title={drawerOpen ? '' : contactsTooltip} placement="right">
          <ListItemButton onClick={handleClickContacts}>
            <ListItemIcon>
              <img src={iconContacts} />
            </ListItemIcon>
            <ListItemText
              primary="Contacts"
              sx={{ display: drawerOpen ? 'block' : 'none' }}
            />
            {drawerOpen &&
              (openContacts ? (
                <img src={iconAccordion} className="accordion-up arrow" />
              ) : (
                <img src={iconAccordion} className="arrow" />
              ))}
          </ListItemButton>
        </Tooltip>

        <Collapse in={openContacts && drawerOpen} timeout="auto" unmountOnExit>
          {contactsTooltip}
        </Collapse>

        <Tooltip title={drawerOpen ? '' : 'Email Builder'} placement="right">
          <ListItemButton onClick={() => handleMenuItemClick('campaigns')}>
            <ListItemIcon>
              <img src={iconCampaigns} />
            </ListItemIcon>
            <ListItemText
              primary="Email Campaigns"
              sx={{ display: drawerOpen ? 'block' : 'none' }}
            />
          </ListItemButton>
        </Tooltip>

    <Tooltip title={drawerOpen ? '' : 'Automations'} placement="right">
     <ListItemButton onClick={() => handleMenuItemClick('automations')}>
      <ListItemIcon>
       <img src={iconAutomation} />
      </ListItemIcon>
      <ListItemText
       primary="Automations"
       sx={{display: drawerOpen ? 'block' : 'none'}}
      />
     </ListItemButton>
    </Tooltip>

        <Tooltip title={drawerOpen ? '' : 'Abandoned Cart'} placement="right">
          <ListItemButton onClick={() => handleMenuItemClick('abandoned-cart')}>
            <ListItemIcon>
              <img src={iconCart} />
            </ListItemIcon>
            <ListItemText
              primary="Abandoned Cart"
              sx={{ display: drawerOpen ? 'block' : 'none' }}
            />
          </ListItemButton>
        </Tooltip>

        <Tooltip title={drawerOpen ? '' : 'Analytics'} placement="right">
          <ListItemButton onClick={() => handleMenuItemClick('analytics')}>
            <ListItemIcon>
              <img src={iconAnalytics} />
            </ListItemIcon>
            <ListItemText
              primary="Analytics"
              sx={{ display: drawerOpen ? 'block' : 'none' }}
            />
          </ListItemButton>
        </Tooltip>
        <Tooltip title={drawerOpen ? '' : templatesTooltip} placement="right">
          <ListItemButton onClick={handleClickEmail}>
            <ListItemIcon>
              <img src={iconTemplate} />
            </ListItemIcon>
            <ListItemText
              primary="Templates"
              sx={{ display: drawerOpen ? 'block' : 'none' }}
            />
            {drawerOpen &&
              (openEmail ? (
                <img src={iconAccordion} className="accordion-up arrow" />
              ) : (
                <img src={iconAccordion} className="arrow" />
              ))}
          </ListItemButton>
        </Tooltip>
        <Collapse in={openEmail && drawerOpen} timeout="auto" unmountOnExit>
          {templatesTooltip}
        </Collapse>
        <Tooltip title={drawerOpen ? '' : 'Pages'} placement="right">
          <ListItemButton onClick={() => {handleMenuItemClick('pages')}}>
            <ListItemIcon>
              <img src={iconPages} />
            </ListItemIcon>
            <ListItemText
              primary="Pages"
              sx={{ display: drawerOpen ? 'block' : 'none' }}
            />
          </ListItemButton>
        </Tooltip>
        
      </List>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        sx={{ paddingLeft: 2, paddingTop: 1, paddingBottom: 1 }}
      >
        Other
      </Typography>
      <List>
        <Tooltip title={drawerOpen ? '' : 'App Integrations'} placement="right">
          <ListItemButton onClick={() => handleMenuItemClick('app-integration')}>
            <ListItemIcon>
              <img src={iconDashboard} />
            </ListItemIcon>
            <ListItemText
              primary="App Integrations"
              sx={{ display: drawerOpen ? 'block' : 'none' }}
            />
          </ListItemButton>
        </Tooltip>

        <Tooltip title={drawerOpen ? '' : 'Documentation'} placement="right">
          <ListItemButton onClick={() => handleMenuItemClick('documentation')}>
            <ListItemIcon>
              <img src={iconDocumentation} />
            </ListItemIcon>
            <ListItemText
              primary="Documentation"
              sx={{ display: drawerOpen ? 'block' : 'none' }}
            />
          </ListItemButton>
        </Tooltip>

    <Tooltip title={drawerOpen ? '' : 'Settings'} placement="right">
     <ListItemButton onClick={() => handleMenuItemClick('settings')}>
      <ListItemIcon>
       <img src={iconSettings} />
      </ListItemIcon>
      <ListItemText
       primary="Settings"
       sx={{display: drawerOpen ? 'block' : 'none'}}
      />
     </ListItemButton>
    </Tooltip>
   </List>
   <List
    sx={{
     position: 'fixed',
     bottom: 0,
     width: drawerOpen ? drawerWidthOpen : drawerWidthMini,
     backgroundColor: 'var(--color-white)',
     borderTop: '1px solid var(--color-border)',
     borderRight: '1px solid var(--color-border)',
     borderRadius: '0 !important',
     paddingTop: '8px !important'
    }}
   >
    <ListItemButton onClick={handleMoreClick}>
     <ListItemIcon>
      <span className="name-initial">{greetingTag}</span>
     </ListItemIcon>
     <ListItemText
      primary={greetingMsg}
      sx={{display: drawerOpen ? 'block' : 'none'}}
     />
     {drawerOpen && <img src={iconMore} />}
    </ListItemButton>
   </List>
   <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleMenuClose}
    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
    transformOrigin={{vertical: 'top', horizontal: 'left'}}
   >
    <MenuItem
     onClick={() => {
      handleMenuItemClick('profile');
     }}
    >
     My Profile
    </MenuItem>
    <MenuItem onClick={handleLogout}>Logout</MenuItem>
   </Menu>
  </div>
 );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{
          width: { sm: drawerOpen ? drawerWidthOpen : drawerWidthMini },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerOpen ? drawerWidthOpen : drawerWidthMini,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerOpen ? drawerWidthOpen : drawerWidthMini,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

MiniVariantDrawer.propTypes = {
  window: PropTypes.func,
};

export default MiniVariantDrawer;
