/* eslint-disable jsx-a11y/iframe-has-title */
import React, {useState, useEffect} from 'react';
import {
 Dialog,
 DialogContent,
 Box,
 Button,
 CircularProgress
} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import {toggleBrandStyle, setBrandStyles} from '../../../../store/store.js';
import DesktopPreviewImg from '../../../../assets/images/web_preview.png';
import CloseBtn from '../../../../assets/icons/icon-arrow.svg';
import OverlayDrawer from './OverlaySidebar.jsx';
import './Overlay.css';
import ResourceApi from '../../../../api/resourceapi';
import {socialTypes} from '../GrapesJSComponents/customBlocks/socialTypes.js';

const Overlay = ({isOpen}) => {
 const dispatch = useDispatch();

 const brandStyles = useSelector(state => state.brandStyles);

  const [brandLogo, setBrandLogo] = useState("");
  const [brandLogoLink, setBrandLogoLink] = useState("");
  const [brandLogoWidth, setBrandLogoWidth] = useState("30");
  const [brandLogoPadding, setBrandLogoPadding] = useState("");
  const [brandLogoBorderRadius, setBrandLogoBorderRadius] = useState("");
  const [typegraphyTraits, setTypegraphyTraits] = useState({});
  const [colorTraits, setColorTraits] = useState([]);
  const [buttonTraits, setButtonTraits] = useState({});
  const [footerTraits, setFooterTraits] = useState({});
  const [socialTraits, setSocialTraits] = useState({});
  const [isSaveBrandStyleLoader, setIsSaveBrandStyleLoader] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");

 useEffect(() => {
  setTypegraphyTraits(brandStyles.typeGraphy);
  setColorTraits(brandStyles.colors);
  setButtonTraits(brandStyles.button);
  setFooterTraits(brandStyles.footer);
  setSocialTraits(brandStyles.social);
  setBrandLogo(brandStyles.logo);
  setBrandLogoLink(brandStyles.link);
  setBrandLogoWidth(brandStyles.widthInPercentage);
  setBrandLogoPadding(brandStyles.padding);
  setBrandLogoBorderRadius(brandStyles.borderRadius);

  const socialLinksHtml = brandStyles.social
   ? Object.keys(brandStyles.social)
      .map(socialId => {
       const social = socialTypes.find(s => s.id === socialId);
       return social
        ? `
          <a href="${brandStyles.social[socialId]}" target="_blank" data-non-selectable="true" class="non-selectable" style="box-sizing: border-box; margin-left: 4px; margin-right: 4px;">
            <img src="${social.icon}" alt="${socialId}" width="24" class="non-selectable" style="box-sizing: border-box; width: 24px; border-radius: 4px;">
          </a>`
        : '';
      })
      .join('')
   : '';

  setHtmlContent(
   `
  <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Preview</title>
      <style>
        @media only screen and (max-width:768px){
          .container{
            float: left !important;
            width: 100% !important;
          }
        }
      </style>
    </head>
    <body style="box-sizing: border-box; margin: 0;">
      <!-- First Table: Image Block -->
      <table align="center" type="section-table" class="content-table" style="box-sizing: border-box; width: 100%; max-width: 640px; border-spacing: 16px; border-collapse: separate; background-color: white;" width="100%" bgcolor="white">
        <tbody style="box-sizing: border-box;">
          <tr style="box-sizing: border-box;">
            <td type="column" class="container" style="box-sizing: border-box; width: 100%; height: auto;" width="100%">
              <span data-gjs-type="Image" class="block-div image-container" style="box-sizing: border-box; width: 100%; text-align: center; display: block; padding: ${brandStyles?.padding}; ">
                <a href=${brandStyles?.link} target="_blank">
                  <img class="image-block" src=${brandStyles?.logo} alt="" data-non-selectable="true" class="non-selectable" style="box-sizing: border-box; display: inline-block; border: none; outline: none; width: ${brandStyles?.widthInPercentage}; height: auto; border-radius: ${brandStyles?.borderRadius}" >
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      
      <!-- Second Table: Text Block -->
      <table align="center" type="section-table" class="content-table" style="box-sizing: border-box; width: 100%; max-width: 640px; border-spacing: 16px; border-collapse: separate; background-color: white;" width="100%" bgcolor="white">
        <tbody style="box-sizing: border-box;">
          <tr style="box-sizing: border-box;">
            <td type="column" class="container" style="box-sizing: border-box; width: 100%; height: auto;" width="100%">
              <span data-gjs-type="Text" class="block-div" style="box-sizing: border-box; width: 100%; display: block;">
                <div class="text-block" style="box-sizing: border-box; font-size: ${brandStyles?.typeGraphy?.fontSize}; font-family: ${brandStyles?.typeGraphy?.fontFamily}, ${brandStyles?.typeGraphy?.backupFontFamily}; line-height: 1.5; min-height: 20px; text-align: left;">
                  <span class="c43453" style="box-sizing: border-box; white-space-collapse: preserve;">Lorem Ipsum&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
                </div>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      
      <!-- Third Table: Button -->
      <table align="center" type="section-table" class="content-table" style="box-sizing: border-box; width: 100%; max-width: 640px; border-spacing: 16px; border-collapse: separate; background-color: white;" width="100%" bgcolor="white">
        <tbody style="box-sizing: border-box;">
          <tr style="box-sizing: border-box;">
            <td type="column" class="container" style="box-sizing: border-box; width: 100%; height: auto;" width="100%">
              <span data-gjs-type="Button" class="block-div button-block" style="box-sizing: border-box; width: 100%; text-align: center; display: block;">
                <a href="" target="_blank" data-non-selectable="true" class="non-selectable" style="box-sizing: border-box;">
                  <span data-non-selectable="true" class="button non-selectable" style="box-sizing: border-box; display: inline-block; padding: ${brandStyles?.button?.padding}; background-color: ${brandStyles?.button?.backgroundColor}; border-radius: ${brandStyles?.button?.borderRadius}; text-align: center; color: ${brandStyles?.button?.color}; font-size: ${brandStyles?.button?.fontSize}; width: ${brandStyles?.button?.width}">Button</span>
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      
      <!-- Fourth Table: Social Media Icons -->
      <table align="center" type="section-table" class="content-table" style="box-sizing: border-box; width: 100%; max-width: 640px; border-spacing: 16px; border-collapse: separate; background-color: white;" width="100%" bgcolor="white">
        <tbody style="box-sizing: border-box;">
          <tr style="box-sizing: border-box;">
            <td type="column" class="container" style="box-sizing: border-box; width: 100%; height: auto;" width="100%">
              <span data-gjs-type="Social" class="block-div" style="box-sizing: border-box; width: 100%; text-align: center; display: block;">
                <div data-non-selectable="true" class="social-block non-selectable" style="box-sizing: border-box; display: inline-block;">
                   ${socialLinksHtml}
                </div>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      
      <!-- Footer Section -->
      <table align="center" type="section-table" class="content-table" style="box-sizing: border-box; width: 100%; max-width: 640px; border-spacing: 16px; border-collapse: separate; background-color: white;" width="100%" bgcolor="white">
        <tbody style="box-sizing: border-box;">
          <tr style="box-sizing: border-box;">
            <td type="column" class="container" style="box-sizing: border-box; width: 100%; height: auto;" width="100%">
              <span data-gjs-type="Footer" class="block-div" style="box-sizing: border-box; width: 100%; display: block;">
                <div class="footer-block" style="box-sizing: border-box; display: inline-block; padding: 16px;">${brandStyles?.footer?.content}</div>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </body>
  </html>
  `
  );
 }, [brandStyles]);

 const handleClose = () => {
  dispatch(toggleBrandStyle());
 };

 const updateBrandStyles = async () => {
  try {
   setIsSaveBrandStyleLoader(true);
   const response = await ResourceApi.brandstyle.updateUser({
    ...brandStyles,
    logo: brandLogo,
    link: brandLogoLink,
    widthInPercentage: brandLogoWidth,
    padding: brandLogoPadding,
    borderRadius: brandLogoBorderRadius,
    typeGraphy: typegraphyTraits,
    colors: colorTraits,
    button: buttonTraits,
    footer: footerTraits,
    social: socialTraits
   });
   dispatch(setBrandStyles(response.data));
   console.log('BrandStyles updated', response.data);
  } catch (err) {
   console.log(err);
  } finally {
   setIsSaveBrandStyleLoader(false);
  }
 };

 React.useEffect(() => {
  document.body.style.overflow = 'hidden';

  return () => {
   // Clean up by re-enabling body scrolling when the component unmounts
   document.body.style.overflow = 'auto';
  };
 }, []);
 const modalStylesDesktop = {
  position: 'relative',
  width: '768px',
  height: '590px',
  backgroundImage: `url(${DesktopPreviewImg})`,
  backgroundSize: '768px 590px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '50px 36px',
  overflow: 'hidden'
 };

  const previewStylesDesktop = {};

 const updateHtmlContent = updatedContent => {
  setHtmlContent(updatedContent);
 };

 return (
  <Dialog
   fullScreen
   open={isOpen}
   onClose={handleClose}
   aria-labelledby="preview-modal-title"
   className="preview-modal"
  >
   <div className="header" style={{zIndex: 1}}>
    <div className="header-left">
     <div>
      <Button
       variant="outline"
       className="back-icon common-btn"
       onClick={handleClose}
      >
       <img src={CloseBtn} alt="Back button" />
      </Button>
     </div>
     <span className="template-name" style={{borderRight: 'none'}}>
      Brand Library
     </span>
    </div>
    <div className="header-right">
     <div className="email-preview">
      <Button
       className="btn-primary"
       variant="contained"
       onClick={updateBrandStyles}
      >
       {isSaveBrandStyleLoader ? (
        <CircularProgress className="loader-btn" />
       ) : (
        ''
       )}
       <span
        style={{
         visibility: isSaveBrandStyleLoader ? 'hidden' : 'visible'
        }}
       >
        Save
       </span>
      </Button>
     </div>
    </div>
   </div>
   <div
    className="modal-body"
    style={{display: 'flex', height: 'calc(100% - 56px)'}}
   >
    <div className="OverlaySidebar ">
     <OverlayDrawer
      htmlContent={htmlContent}
      updateHtmlContent={updateHtmlContent}
      buttonTraits={buttonTraits}
      setButtonTraits={setButtonTraits}
      typegraphyTraits={typegraphyTraits}
      setTypegraphyTraits={setTypegraphyTraits}
      setColorTraits={setColorTraits}
      socialTraits={socialTraits}
      setSocialTraits={setSocialTraits}
      footerTraits={footerTraits}
      setFooterTraits={setFooterTraits}
      brandLogo={brandLogo}
      setBrandLogo={setBrandLogo}
      brandLogoLink={brandLogoLink}
      setBrandLogoLink={setBrandLogoLink}
      brandLogoWidth={brandLogoWidth}
      setBrandLogoWidth={setBrandLogoWidth}
      brandLogoPadding={brandLogoPadding}
      setBrandLogoPadding={setBrandLogoPadding}
      brandLogoBorderRadius={brandLogoBorderRadius}
      setBrandLogoBorderRadius={setBrandLogoBorderRadius}
     />
    </div>
    <div className="preview-container">
     <section className="modal-main-section">
      <DialogContent style={modalStylesDesktop}>
       <iframe
        style={previewStylesDesktop}
        srcDoc={htmlContent}
        frameBorder="0"
        width="100%"
        height="100%"
       ></iframe>
      </DialogContent>
     </section>
    </div>
   </div>
  </Dialog>
 );
};

export default Overlay;
