export const customContainers = [
  {
    name: '1 Container',
    label: `<div class="container-block" data-category="Container"><div class="container-inner-block"></div></div>`,
    category: 'Containers',
    content: `
          <table class='content-table' align="center" style="width: 100%; border-spacing: 16px; border-collapse: separate; background-color: white;" type="section-table" custom-name="Section">
                  <tbody>
                    <tr>
                      <td style="width:100%;  " class='container-td container' type="column" custom-name="Column">
                      </td>
                    </tr>
                  </tbody>
                </table>
              
      `,
  },
  {
    name: '1/2 Container',
    label: `<div class="container-block" data-category="Container"><div class="container-inner-div" style="width:50%"></div><div class="container-inner-div" style="width:50%"></div></div>`,
    category: 'Containers',
    content: `
          <table class='content-table' align="center" style="width: 100%; border-spacing: 16px; border-collapse: separate; background-color: white;" type="section-table" custom-name="Section">
                  <tbody>
                    <tr>
                      <td style=" width: 50%; " class='container-td container' type="column" custom-name="Column"></td>
                      <td style=" width: 50%; " class='container-td container' type="column" custom-name="Column"></td>
                    </tr>
                  </tbody>
                </table>
              `,
  },
  {
    name: '3/7 Container',
    label: `<div class="container-block" data-category="Container"><div class="container-inner-div" style="width: 30%"></div><div class="container-inner-div" style="width:70%"></div></div>`,
    category: 'Containers',
    content: `
        <table class='content-table' align="center" style="width: 100%; border-spacing: 16px; border-collapse: separate; background-color: white;" type="section-table" custom-name="Section">
                <tbody>
                  <tr>
                    <td style=" width: 30%; " class='container-td container' type="column" custom-name="Column"></td>
                    <td style=" width: 70%; " class='container-td container' type="column" custom-name="Column"></td>
                  </tr>
                </tbody>
              </table>
            

    `,
  },
  {
    name: '7/3 Container',
    label: `<div class="container-block" data-category="Container"><div class="container-inner-div" style="width:70%"></div><div class="container-inner-div" style="width:30%"></div></div>`,
    category: 'Containers',
    content: `
      <table class='content-table' align="center" style="width: 100%; border-spacing: 16px; border-collapse: separate; background-color: white;" type="section-table" custom-name="Section">
                <tbody>
                  <tr>
                    <td style=" width: 70%; " class='container-td container' type="column" custom-name="Column"></td>
                    <td style=" width: 30%; " class='container-td container' type="column" custom-name="Column"></td>                
                  </tr>
                </tbody>
              </table>
            

      `,
  },
  {
    name: '1/3 Container',
    label: `<div class="container-block" data-category="Container"><div class="container-inner-div" style="width:33.3%"></div><div class="container-inner-div" style="width:33.3%"></div><div class="container-inner-div" style="width:33.3%"></div></div>`,
    category: 'Containers',
    content: `
        <table class='content-table' align="center" style="width: 100%; border-spacing: 16px; border-collapse: separate; background-color: white;" type="section-table" custom-name="Section">
                <tbody>
                  <tr>                  
                    <td style=" width: 33.3%; " class='container-td container' type="column" custom-name="Column"></td>
                    <td style=" width: 33.3%; " class='container-td container' type="column" custom-name="Column"></td>
                    <td style=" width: 33.3%; " class='container-td container' type="column" custom-name="Column"></td>
                  </tr>
                </tbody>
              </table>
            

      `,
  },
  {
    name: '25-50-25 Container',
    label: `<div class="container-block" data-category="Container"><div class="container-inner-div" style="width:25%"></div><div class="container-inner-div" style="width:50%"></div><div class="container-inner-div" style="width:25%"></div></div>`,
    category: 'Containers',
    content: `
      <table class='content-table' align="center" style="width: 100%; border-spacing: 16px; border-collapse: separate; background-color: white;" type="section-table" custom-name="Section">
                <tbody>
                  <tr>
                    <td style=" width: 25%; " class='container-td container' type="column" custom-name="Column"></td>
                    <td style=" width: 50%; " class='container-td container' type="column" custom-name="Column"></td>
                    <td style=" width: 25%; " class='container-td container' type="column" custom-name="Column"></td>
                  </tr>
                </tbody>
              </table>
            
      `,
  },
  {
    name: '1/4 Container',
    label: `<div class="container-block" data-category="Container"><div class="container-inner-div" style="width:25%"></div><div class="container-inner-div" style="width:25%"></div><div class="container-inner-div" style="width:25%"></div><div class="container-inner-div" style="width:25%"></div></div>`,
    category: 'Containers',
    content: `
              <table class='content-table' align="center" style="width: 100%; border-spacing: 16px; border-collapse: separate; background-color: white;" type="section-table" custom-name="Section">
                <tbody>
                  <tr>
                    <td style=" width: 25%; " class='container-td container' type="column" custom-name="Column"></td>
                    <td style=" width: 25%; " class='container-td container' type="column" custom-name="Column"></td>
                    <td style=" width: 25%; " class='container-td container' type="column" custom-name="Column"></td>
                    <td style=" width: 25%; " class='container-td container' type="column" custom-name="Column"></td>
                  </tr>
                </tbody>
              </table>
            
`,
  },
  {
    name: '20-30-20-30 Container',
    label: `<div class="container-block" data-category="Container"><div class="container-inner-div" style="width:20%"></div><div class="container-inner-div" style="width:30%"></div><div class="container-inner-div" style="width:20%"></div><div class="container-inner-div" style="width:30%"></div></div>`,
    category: 'Containers',
    content: `
              <table class='content-table' align="center" style="width: 100%; border-spacing: 16px; border-collapse: separate; background-color: white;" type="section-table" custom-name="Section">
                <tbody>
                  <tr>
                    <td style=" width: 20%; " class='container-td container' type="column" custom-name="Column"></td>
                    <td style=" width: 30%; " class='container-td container' type="column" custom-name="Column"></td>
                    <td style=" width: 20%; " class='container-td container' type="column" custom-name="Column"></td>
                    <td style=" width: 30%; " class='container-td container' type="column" custom-name="Column"></td>
                  </tr>
                </tbody>
              </table>
            

      `,
  },
];
